import gql from "graphql-tag";
import { GROUP_FRAGMENT, SITE_FRAGMENT, USER_FRAGMENT } from "../fragments";

export const GET_USER_LIST = gql`
  query ($limit: Int, $offset: Int, $filterOption: UserFilterOption, $searchText: String) {
    GetUsers(limit: $limit, offset: $offset, filterOption: $filterOption, searchText: $searchText) {
      code
      success
      message
      count
      results {
        ...UserType
        sites {
          ...SiteType
        }
        group {
          ...GroupType
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${SITE_FRAGMENT}
  ${GROUP_FRAGMENT}
`;

export const GET_ALL_USERS_LIST = gql`
  query ($limit: Int, $offset: Int, $filterOption: UserFilterOption, $searchText: String) {
    GetAllUsers(limit: $limit, offset: $offset, filterOption: $filterOption, searchText: $searchText) {
      code
      success
      message
      count
      results {
        ...UserType
        sites {
          ...SiteType
        }
        group {
          ...GroupType
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${SITE_FRAGMENT}
  ${GROUP_FRAGMENT}
`;

export const GET_ALL_MICRON_USERS = gql`
  query ($limit: Int, $offset: Int, $filterOption: UserFilterOption, $searchText: String) {
    GetAllMicronUsers(limit: $limit, offset: $offset, filterOption: $filterOption, searchText: $searchText) {
      code
      success
      message
      count
      results {
        ...UserType
        sites {
          ...SiteType
        }
        group {
          ...GroupType
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${SITE_FRAGMENT}
  ${GROUP_FRAGMENT}
`;

export const GET_MTGROUP_USER_LIST = gql`
  query ($limit: Int, $offset: Int, $filterOption: UserFilterOption, $searchText: String) {
    GetMTGroupUsers(limit: $limit, offset: $offset, filterOption: $filterOption, searchText: $searchText) {
      code
      success
      message
      count
      results {
        ...UserType
        sites {
          ...SiteType
        }
        group {
          ...GroupType
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${SITE_FRAGMENT}
  ${GROUP_FRAGMENT}
`;

export const GET_SINGLE_USER = gql`
  query ($id: ID) {
    GetUser(id: $id) {
      code
      message
      user {
        ...UserType
        sites {
          ...SiteType
        }
        group {
          ...GroupType
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${SITE_FRAGMENT}
  ${GROUP_FRAGMENT}
`;

export const CREATE_USER = gql`
  mutation ($data: UserInput!) {
    CreateUser(data: $data) {
      code
      success
      message
    }
  }
`;

export const UPDATE_USER = gql`
  mutation ($id: ID!, $data: UserUpdateInput) {
    UpdateUser(id: $id, data: $data) {
      code
      success
      message
    }
  }
`;

export const DELETE_USER = gql`
  mutation ($id: ID!) {
    DeleteUser(id: $id) {
      code
      success
      message
    }
  }
`;
