import React, { useState, useEffect, Fragment } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Link, useHistory } from "react-router-dom";
import { EllipsisOutlined, SearchOutlined } from "@ant-design/icons";
import { PermitStatus, UserRole, StatusColor, PermitStatusArray } from "../config";
import { GET_CHILD_PERMITS, GET_SITE_LIST, GET_ALL_DISCIPLINE } from "../graphql/modules";
import { Checkbox, Tag, Dropdown, Menu, Row, Col, Space, Button, Input } from "antd";
import Highlighter from "react-highlight-words";
import { DynamicList } from "../components/Shared";
import { formatDate, getNextReviewer, TABLE_LIST_LIMIT } from "../util";
import { useSelector } from "react-redux";

const startDateVariables = [
  "startDate",
  "D03startDate",
  "D04startDate",
  "D05startDate",
  "D06startDate",
  "D07startDate",
  "D08startDate",
  "D09startDate",
  "D10startDate",
  "D11startDate",
  "D12startDate",
  "D13startDate",
  "D14startDate",
  "C11StartDate",
  "G11StartDate",
  "GW211StartDate",
  "D21StartDate",
];

const endDateVariables = [
  "preferredEndDate",
  "D1endDate",
  "D2endDate",
  "D03endDate",
  "D04endDate",
  "D05endDate",
  "D06endDate",
  "D07endDate",
  "D08endDate",
  "D09endDate",
  "D10endDate",
  "D11endDate",
  "D12endDate",
  "D13endDate",
  "D14endDate",
  "C11EndTime",
  "G11EndTime",
  "GW211EndDate",
  "D21EndDate",
];

export const AllChildPermits = ({ match }) => {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const [limit, setLimit] = useState(TABLE_LIST_LIMIT);
  const [page, setPage] = useState(1);
  const [value, setValue] = useState("");
  const [disciplineName, setDisciplineName] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [statusCheck, setStatusCheck] = useState([]);
  const [searchApplierName, setSearchApplierName] = useState([]);

  /** START For Date Applied (createdAt) Filtering */
  const [searchDateApplied, setSearchDateApplied] = useState("");
  const [searchedDateAppliedColumn, setSearchedDateAppliedColumn] = useState("");
  /** END For Date Applied (createdAt) Filtering */

  /** START For Start Date Filtering */
  const [searchStartDate, setSearchStartDate] = useState("");
  const [searchedStartDateColumn, setSearchedStartDateColumn] = useState("");
  /** END For Start Date Filtering */

  /** START For End Date Filtering */
  const [searchEndDate, setSearchDateEnd] = useState("");
  const [searchedEndDateColumn, setSearchedDateEndColumn] = useState("");
  /** END For End Date Filtering */

  useEffect(() => {
    var getFilter = history.location?.state?.query;
    if (getFilter) {
      setDisciplineName(getFilter.disciplineName);
      setStatusCheck(getFilter.statusCheck);
      setSearchDateApplied(getFilter.searchCreated);
    }
  }, []);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleDisciplineNameReset = () => {
    setDisciplineName([]);
  };

  const handleDisciplineNameCheckbox = (e) => {
    setDisciplineName(e);
  };

  const { data, loading, error, refetch } = useQuery(GET_CHILD_PERMITS, {
    variables: {
      searchText: value,
      limit,
      offset: page - 1,
      statusCheck: statusCheck,
      disciplineName: disciplineName,
      searchCreated: searchDateApplied,
      searchStartDate: searchStartDate,
      searchEndDate: searchEndDate,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: SiteList } = useQuery(GET_SITE_LIST, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  const { data: DisciplineList } = useQuery(GET_ALL_DISCIPLINE);

  let disciplineList = [];
  if (DisciplineList?.GetAllDisciplines?.results?.length) {
    DisciplineList?.GetAllDisciplines?.results?.map((discipline) => {
      disciplineList.push({
        text: discipline.code,
        value: discipline.code,
      });
    });
  }

  const total = data?.GetChildPermits?.count;
  let results = JSON.parse(data?.GetChildPermits?.results || "[]");
  let siteList = [];

  if (SiteList?.GetSites?.results?.length) {
    SiteList?.GetSites?.results?.map((site) => {
      siteList.push({
        text: site.code,
        value: site.code,
      });
    });
  }

  const getDiscipline = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Checkbox.Group
          style={{ width: "100%" }}
          onChange={handleDisciplineNameCheckbox}
          value={disciplineName || disciplineName}
        >
          {disciplineList?.map((res) => {
            return (
              <Row>
                <Col span={24}>
                  <Checkbox value={res.value}>{res.text}</Checkbox>
                </Col>
              </Row>
            );
          })}
        </Checkbox.Group>
        <Space>
          <Button onClick={handleDisciplineNameReset} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
  });

  /** START CHILD PERMIT TITLE FILTERING */
  const getChildPermitTitleProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (code, row) =>
      row?.form?.title ? row?.form?.title?.toString().toLowerCase().includes(code.toLowerCase()) : "",
    render: (_text, _row) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={_row?.form?.title ? _row?.form?.title.toString() : ""}
        />
      ) : (
        _row?.form?.title
      ),
  });
  /** END CHILD PERMIT TITLE FILTERING */

  /** START CHILD PERMIT NUMBER FILTERING */
  const getChildPermitCodeProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (code, row) => (row?.code ? row?.code?.toString().toLowerCase().includes(code.toLowerCase()) : ""),
    render: (_text, _row) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={_row?.code ? _row?.code.toString() : ""}
        />
      ) : (
        _row?.code
      ),
  });
  /** END CHILD PERMIT NUMBER FILTERING */

  /** START PARENT PERMIT NUMBER FILTERING */
  const getParentPermitCodeProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (code, row) =>
      row?.parentPermit?.code ? row?.parentPermit?.code?.toString().toLowerCase().includes(code.toLowerCase()) : "",
    render: (_text, _row) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={_row?.parentPermit?.code ? _row?.parentPermit?.code.toString() : ""}
        />
      ) : (
        _row?.parentPermit?.code
      ),
  });
  /** END PARENT PERMIT FILTERING */

  /** START APPLIER NAME FILTERING */
  const getApplierNameProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (text, row) =>
      row?.submittedBy?.fullname
        ? row?.submittedBy?.fullname.toString().toLowerCase().includes(text.toLowerCase())
        : "",
    render: (_text, _row) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={_row?.submittedBy?.fullname ? _row?.submittedBy?.fullname.toString() : ""}
        />
      ) : (
        _row?.submittedBy?.fullname
      ),
  });
  /** END APPLIER NAME FILTERING */

  /** START HOST/SYSTEM OWNER FILTERING */
  const getHostOwnerSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (email, row) =>
      row?.hostSystemOwner ? row?.hostSystemOwner?.email?.toString().toLowerCase().includes(email.toLowerCase()) : "",
    render: (_text, _row) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={_row?.hostSystemOwner ? _row?.hostSystemOwner?.email.toString() : ""}
        />
      ) : (
        _row?.hostSystemOwner?.email
      ),
  });
  /** END HOST/SYSTEM OWNER FILTERING */

  /** START DATE APPLIED FILTERING */
  const getDateAppliedSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (text, row) =>
      row?.createdAt ? formatDate(row?.createdAt).toLowerCase().includes(text.toLowerCase()) : "",
    render: (_text, _row) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={_row?.createdAt ? formatDate(_row?.createdAt) : ""}
        />
      ) : _row?.createdAt ? (
        formatDate(_row?.createdAt)
      ) : (
        " "
      ),
  });
  /** END DATE APPLIED FILTERING */

  /** START START DATE FILTERING */
  const getStartDateSearchProps = (dataIndex) => ({
    // var startDate = getStartDate()
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (text, row) => filterStartDate(text, row),
    render: (_text, _row) => getChildPermitStartDate(_row) ?? "",
  });

  const filterStartDate = (text, permit) => {
    if (permit?.form?.title === "B2 - CONFINED SPACE") {
      return formatDate(permit?.submission?.C11StartDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B3 - LIFTING") {
      return formatDate(permit?.submission?.G11StartDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B4 - WORK AT HEIGHT WEEK 1") {
      return formatDate(permit?.submission?.GW211StartDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B5 - LSS IMPAIRMENT PERMIT") {
      return formatDate(permit?.submission?.startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B7 - SIPP PERMIT") {
      return formatDate(permit?.submission?.startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D01 - HOT WORKS DAY 01") {
      return formatDate(permit?.submission?.D1startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D02 - HOT WORKS DAY 02") {
      return formatDate(permit?.submission?.D2startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D03 - HOT WORKS DAY 03") {
      return formatDate(permit?.submission?.D03startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D04 - HOT WORKS DAY 04") {
      return formatDate(permit?.submission?.D04startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D05 - HOT WORKS DAY 05") {
      return formatDate(permit?.submission?.D05startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D06 - HOT WORKS DAY 06") {
      return formatDate(permit?.submission?.D06startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D07 - HOT WORKS DAY 07") {
      return formatDate(permit?.submission?.D07startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D08 - HOT WORKS DAY 08") {
      return formatDate(permit?.submission?.D08startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D09 - HOT WORKS DAY 09") {
      return formatDate(permit?.submission?.D09startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D10 - HOT WORKS DAY 10") {
      return formatDate(permit?.submission?.D10startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D11 - HOT WORKS DAY 11") {
      return formatDate(permit?.submission?.D11startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D12 - HOT WORKS DAY 12") {
      return formatDate(permit?.submission?.D12startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D13 - HOT WORKS DAY 13") {
      return formatDate(permit?.submission?.D13startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D14 - HOT WORKS DAY 14") {
      return formatDate(permit?.submission?.D14startDate).toLowerCase().includes(text.toLowerCase());
    }
  };

  const getChildPermitStartDate = (permit) => {
    if (permit?.form?.title === "B2 - CONFINED SPACE") {
      return formatDate(permit?.submission?.C11StartDate) ?? "";
    }

    if (permit?.form?.title === "B3 - LIFTING") {
      return formatDate(permit?.submission?.G11StartDate) ?? "";
    }

    if (permit?.form?.title === "B4 - WORK AT HEIGHT WEEK 1") {
      return formatDate(permit?.submission?.GW211StartDate) ?? "";
    }

    if (permit?.form?.title === "B4 - WORK AT HEIGHT WEEK 2") {
      return formatDate(permit?.submission?.startDate) ?? "";
    }

    if (permit?.form?.title === "B5 - LSS IMPAIRMENT PERMIT") {
      return formatDate(permit?.submission?.startDate) ?? "";
    }

    if (permit?.form?.title === "B7 - SIPP PERMIT") {
      return formatDate(permit?.submission?.startDate) ?? "";
    }

    if (permit?.form?.title === "B1D01 - HOT WORKS DAY 01") {
      return formatDate(permit?.submission?.D1startDate) ?? "";
    }

    if (permit?.form?.title === "B1D02 - HOT WORKS DAY 02") {
      return formatDate(permit?.submission?.D2startDate) ?? "";
    }

    if (permit?.form?.title === "B1D03 - HOT WORKS DAY 03") {
      return formatDate(permit?.submission?.D03startDate) ?? "";
    }

    if (permit?.form?.title === "B1D04 - HOT WORKS DAY 04") {
      return formatDate(permit?.submission?.D04startDate) ?? "";
    }

    if (permit?.form?.title === "B1D05 - HOT WORKS DAY 05") {
      return formatDate(permit?.submission?.D05startDate) ?? "";
    }

    if (permit?.form?.title === "B1D06 - HOT WORKS DAY 06") {
      return formatDate(permit?.submission?.D06startDate) ?? "";
    }

    if (permit?.form?.title === "B1D07 - HOT WORKS DAY 07") {
      return formatDate(permit?.submission?.D07startDate) ?? "";
    }

    if (permit?.form?.title === "B1D08 - HOT WORKS DAY 08") {
      return formatDate(permit?.submission?.D08startDate) ?? "";
    }

    if (permit?.form?.title === "B1D09 - HOT WORKS DAY 09") {
      return formatDate(permit?.submission?.D09startDate) ?? "";
    }

    if (permit?.form?.title === "B1D10 - HOT WORKS DAY 10") {
      return formatDate(permit?.submission?.D10startDate) ?? "";
    }

    if (permit?.form?.title === "B1D11 - HOT WORKS DAY 11") {
      return formatDate(permit?.submission?.D11startDate) ?? "";
    }

    if (permit?.form?.title === "B1D12 - HOT WORKS DAY 12") {
      return formatDate(permit?.submission?.D12startDate) ?? "";
    }

    if (permit?.form?.title === "B1D13 - HOT WORKS DAY 13") {
      return formatDate(permit?.submission?.D13startDate) ?? "";
    }

    if (permit?.form?.title === "B1D14 - HOT WORKS DAY 14") {
      return formatDate(permit?.submission?.D14startDate) ?? "";
    }
  };
  /** END START DATE FILTERING */

  /** START END DATE FILTERING */
  const getEndDateSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (text, row) => filterEndDate(text, row),
    render: (_text, _row) => getChildPermitEndDate(_row) ?? "",
  });

  const filterEndDate = (text, permit) => {
    if (permit?.form?.title === "B2 - CONFINED SPACE") {
      return formatDate(permit?.submission?.C11EndDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B3 - LIFTING") {
      return formatDate(permit?.submission?.G11EndDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B4 - WORK AT HEIGHT WEEK 1") {
      return formatDate(permit?.submission?.GW211EndDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B4 - WORK AT HEIGHT WEEK 2") {
      return formatDate(permit?.submission?.dateEnd).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B5 - LSS IMPAIRMENT PERMIT") {
      return formatDate(permit?.submission?.dateEnd).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B7 - SIPP PERMIT") {
      return formatDate(permit?.submission?.preferredEndDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D01 - HOT WORKS DAY 01") {
      return formatDate(permit?.submission?.D1endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D02 - HOT WORKS DAY 02") {
      return formatDate(permit?.submission?.D2endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D03 - HOT WORKS DAY 03") {
      return formatDate(permit?.submission?.D03endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D04 - HOT WORKS DAY 04") {
      return formatDate(permit?.submission?.D04endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D05 - HOT WORKS DAY 05") {
      return formatDate(permit?.submission?.D05endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D06 - HOT WORKS DAY 06") {
      return formatDate(permit?.submission?.D06endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D07 - HOT WORKS DAY 07") {
      return formatDate(permit?.submission?.D07endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D08 - HOT WORKS DAY 08") {
      return formatDate(permit?.submission?.D08endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D09 - HOT WORKS DAY 09") {
      return formatDate(permit?.submission?.D09endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D10 - HOT WORKS DAY 10") {
      return formatDate(permit?.submission?.D10endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D11 - HOT WORKS DAY 11") {
      return formatDate(permit?.submission?.D11endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D12 - HOT WORKS DAY 12") {
      return formatDate(permit?.submission?.D12endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D13 - HOT WORKS DAY 13") {
      return formatDate(permit?.submission?.D13endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D14 - HOT WORKS DAY 14") {
      return formatDate(permit?.submission?.D14endDate).toLowerCase().includes(text.toLowerCase());
    }
  };
  const getChildPermitEndDate = (permit) => {
    if (permit?.form?.title === "B2 - CONFINED SPACE") {
      return formatDate(permit?.submission?.C11EndDate) ?? "";
    }

    if (permit?.form?.title === "B3 - LIFTING") {
      return formatDate(permit?.submission?.G11EndDate) ?? "";
    }

    if (permit?.form?.title === "B4 - WORK AT HEIGHT WEEK 1") {
      return formatDate(permit?.submission?.GW211EndDate) ?? "";
    }

    if (permit?.form?.title === "B4 - WORK AT HEIGHT WEEK 2") {
      return formatDate(permit?.submission?.dateEnd) ?? "";
    }

    if (permit?.form?.title === "B5 - LSS IMPAIRMENT PERMIT") {
      return formatDate(permit?.submission?.dateEnd) ?? "";
    }

    if (permit?.form?.title === "B7 - SIPP PERMIT") {
      return formatDate(permit?.submission?.preferredEndDate) ?? "";
    }

    if (permit?.form?.title === "B1D01 - HOT WORKS DAY 01") {
      return formatDate(permit?.submission?.D1endDate) ?? "";
    }

    if (permit?.form?.title === "B1D02 - HOT WORKS DAY 02") {
      return formatDate(permit?.submission?.D2endDate) ?? "";
    }

    if (permit?.form?.title === "B1D03 - HOT WORKS DAY 03") {
      return formatDate(permit?.submission?.D03endDate) ?? "";
    }

    if (permit?.form?.title === "B1D04 - HOT WORKS DAY 04") {
      return formatDate(permit?.submission?.D04endDate) ?? "";
    }

    if (permit?.form?.title === "B1D05 - HOT WORKS DAY 05") {
      return formatDate(permit?.submission?.D05endDate) ?? "";
    }

    if (permit?.form?.title === "B1D06 - HOT WORKS DAY 06") {
      return formatDate(permit?.submission?.D06endDate) ?? "";
    }

    if (permit?.form?.title === "B1D07 - HOT WORKS DAY 07") {
      return formatDate(permit?.submission?.D07endDate) ?? "";
    }

    if (permit?.form?.title === "B1D08 - HOT WORKS DAY 08") {
      return formatDate(permit?.submission?.D08endDate) ?? "";
    }

    if (permit?.form?.title === "B1D09 - HOT WORKS DAY 09") {
      return formatDate(permit?.submission?.D09endDate) ?? "";
    }

    if (permit?.form?.title === "B1D10 - HOT WORKS DAY 10") {
      return formatDate(permit?.submission?.D10endDate) ?? "";
    }

    if (permit?.form?.title === "B1D11 - HOT WORKS DAY 11") {
      return formatDate(permit?.submission?.D11endDate) ?? "";
    }

    if (permit?.form?.title === "B1D12 - HOT WORKS DAY 12") {
      return formatDate(permit?.submission?.D12endDate) ?? "";
    }

    if (permit?.form?.title === "B1D13 - HOT WORKS DAY 13") {
      return formatDate(permit?.submission?.D13endDate) ?? "";
    }

    if (permit?.form?.title === "B1D14 - HOT WORKS DAY 14") {
      return formatDate(permit?.submission?.D14endDate) ?? "";
    }
  };
  /** END END DATE FILTERING */

  /** START STATUS Permit Function */
  const handleSearchStatusReset = () => {
    setStatusCheck([]);
  };

  const handleCheckboxSelect = (e) => {
    setStatusCheck(e);
  };

  const getStatusData = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Checkbox.Group style={{ width: "100%" }} onChange={handleCheckboxSelect} value={statusCheck}>
          {PermitStatusArray?.map((res) => {
            return (
              <Row>
                <Col span={24}>
                  <Checkbox value={res.value}>{res.text}</Checkbox>
                </Col>
              </Row>
            );
          })}
        </Checkbox.Group>
        <Space>
          <Button onClick={handleSearchStatusReset} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
  });
  /** END STATUS Permit Function */

  const columns = [
    // {
    //   title: "#",
    //   render: (_text, _row, index) => index + 1,
    // },
    {
      title: "Child Permit",
      // render: (_text, _row) => _row?.form?.title,
      ...getChildPermitTitleProps("title"),
    },
    {
      title: "Child Permit Number",
      // dataIndex: "code",
      ...getChildPermitCodeProps("code"),
    },
    {
      title: "Parent Permit",
      // render: (_text, _row) => _row?.parentPermit?.code,
      ...getParentPermitCodeProps("parentPermit"),
    },
    {
      title: "Discipline",
      filters: disciplineList,
      onFilter: (_text, _row) => _row?.discipline?.code?.indexOf(_text) === 0,
      render: (_text, _row) => (_row?.discipline?.code !== undefined ? _row?.discipline?.code : ""),
    },
    {
      title: "Host/System Owner",
      ...getHostOwnerSearchProps("hostSystemOwner"),
    },
    {
      title: "Applier name",
      ...getApplierNameProps("Applier Name"),
    },
    {
      title: "Date applied",
      ...getDateAppliedSearchProps("Date Applied"),
      width: "10%",
    },
    {
      title: "Start Date",
      ...getStartDateSearchProps("Start Date"),
      width: "10%",
    },
    {
      title: "End Date",
      ...getEndDateSearchProps("End Date"),
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "15%",
      ...getStatusData("statusData"),
      render: (status, row) => {
        let color = "orange";
        if (status === PermitStatus.Rejected) color = StatusColor.Rejected;
        else if (status === PermitStatus.Approved) color = StatusColor.Approved;
        else if (status === PermitStatus.Completed) color = StatusColor.Completed;
        else if (status === PermitStatus.Cancelled) color = StatusColor.Cancelled;
        else if (status === PermitStatus.PendingClosure) color = StatusColor.PendingClosure;
        else if (status === PermitStatus.PendingCancel) color = StatusColor.PendingCancel;

        const approvedBy = row?.approvedBy?.length ? row.approvedBy[row?.approvedBy?.length - 1] : null;
        const rejectedBy = row?.rejectedBy;

        let nextReviewer = getNextReviewer(row);

        return (
          <div>
            {status === PermitStatus.Pending ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By PM
              </Tag>
            ) : status === PermitStatus.PendingApproval ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By {nextReviewer}
              </Tag>
            ) : status === PermitStatus.Rejected && rejectedBy?.group?.name ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Rejected By {rejectedBy?.fullname}
              </Tag>
            ) : status === PermitStatus.Approved && approvedBy ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Final Approved By {approvedBy?.fullname}
              </Tag>
            ) : status === undefined || status === null ? (
              " "
            ) : (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                {status}
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Action",
      width: "15%",
      render: (text, record) => {
        const menu = (
          <Menu hidden={record.code === undefined}>
            <Menu.Item key="0" disabled={record.code === undefined}>
              <Link
                style={{ marginRight: "15px" }}
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`${match.path}/${record._id}`, {
                    user: record,
                  });
                }}
                hidden={record.code === undefined}
              >
                View
              </Link>
            </Menu.Item>
            {(user.role === UserRole.ContractorRequestor ||
              user.role === UserRole.User ||
              user.role === UserRole.MTGroup) &&
              record.status === PermitStatus.Approved && (
                <Menu.Item key="5">
                  <Link
                    style={{ marginRight: "15px" }}
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`${match.path}/review/${record._id}`, {
                        user: record,
                      });
                    }}
                  >
                    Review
                  </Link>
                </Menu.Item>
              )}
            {user.role === UserRole.ContractorRequestor && record.status === PermitStatus.Rejected && (
              <Menu.Item key="5">
                <Link
                  style={{ marginRight: "15px" }}
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(`${match.path}/review/${record._id}`, {
                      user: record,
                    });
                  }}
                >
                  Resubmit
                </Link>
              </Menu.Item>
            )}

            {(user.role === UserRole.ContractorPM ||
              user.role === UserRole.MTGroup ||
              user.role === UserRole.MicronSupervisor ||
              user.role === UserRole.MicronManager ||
              user.role === UserRole.SiteAdmin) &&
              record.status !== PermitStatus.Approved && (
                <Fragment>
                  <Menu.Divider />
                  <Menu.Item key="3">
                    <Link
                      style={{ marginRight: "15px" }}
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`${match.path}/review/${record._id}`, {
                          user: record,
                        });
                      }}
                    >
                      Review
                    </Link>
                  </Menu.Item>
                </Fragment>
              )}
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <EllipsisOutlined />
          </Dropdown>
        );
      },
    },
  ];

  if (error) {
    return <div className="card">Something went wrong</div>;
  }

  let createBtn = null;

  if (
    user.role === UserRole.User ||
    user.role === UserRole.ContractorRequestor ||
    user.role === UserRole.SiteAdmin ||
    user.role === UserRole.MTGroup
  ) {
    createBtn = (
      <Link to={match.path + "/create-child-permit"} className="btn btn-primary mr-2">
        Create Child Permit <i className="fas fa-plus-circle"></i>
      </Link>
    );
  }

  const onChangePagination = (p, pageSize) => {
    setPage(p);
    setLimit(pageSize);
  };

  const onChangeSearch = (datavalue) => {
    setValue(datavalue);
    setPage(1);
  };

  return (
    <Fragment>
      <DynamicList
        title="List of Child Permits"
        loading={loading}
        columns={columns}
        data={results}
        actions={createBtn}
        total={total}
        pageSize={limit}
        pageNo={page}
        onChange={onChangePagination}
        showSearch={true}
        onChangeSearch={onChangeSearch}
      />
    </Fragment>
  );
};
