import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Button, Spin } from "antd";
import { GET_SITE_LIST, USER_SIGNUP } from "../graphql/modules";
import { successNotify, warnNotify } from "../util";
import { CustomButton } from "../components/Shared";
import { Link } from "react-router-dom";

export const SignUp = () => {
  //initial state
  const [state, setState] = useState({
    email: "",
    fullname: "",
    password: "",
    cpassword: "",
    company: "",
    site: "",
  });

  const [error, setError] = useState(null);

  const onBlurPassword = () => {
    if (state.password.length < 6) {
      setError({ ...error, password: "Password must be more than 6 character" });
    } else {
      setError({ ...error, password: "" });
    }
  };

  const onBlurCPassword = () => {
    if (state.password !== state.cpassword) {
      setError({ ...error, cpassword: "Password and confirm password mismatch" });
    } else {
      setError({ ...error, cpassword: "" });
    }
  };

  const { cpassword, ...regData } = state;
  //register api
  const [RegisterMutation, { loading }] = useMutation(USER_SIGNUP, {
    variables: {
      data: regData,
    },
  });
  console.log(regData);

  const onRegister = async (e) => {
    e.preventDefault();
    try {
      const {
        data: { Register },
      } = await RegisterMutation();

      if (Register.success) {
        successNotify(Register.message);
      } else {
        warnNotify(Register.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const { data } = useQuery(GET_SITE_LIST, {
    variables: {
      offset: 0,
      limit: 100,
    },
  });

  const onChange = (e) => setState({ ...state, [e.target.name]: e.target.value });

  const disable =
    !state.email ||
    !state.password ||
    !state.fullname ||
    !state.site ||
    state.password.length < 6 ||
    state.password !== state.cpassword;

  return (
    <div className="col-md-8 m-auto">
      <div className="card" style={{ margin: "auto" }}>
        <Spin spinning={loading}>
          <div className="login-form">
            <h1 className="title">Sing Up</h1>
            <form onSubmit={onRegister}>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    name="email"
                    value={state.email}
                    onChange={onChange}
                  />
                </div>
                <div className="form-group col-md-6">
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Fullname"
                    name="fullname"
                    value={state.fullname}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    name="password"
                    value={state.password}
                    onChange={onChange}
                    onBlur={onBlurPassword}
                  />
                  {error?.password && <small className="text-danger">{error?.password}</small>}
                </div>
                <div className="form-group col-md-6">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Confirm Password"
                    name="cpassword"
                    value={state.cpassword}
                    onChange={onChange}
                    onBlur={onBlurCPassword}
                  />
                  {error?.cpassword && <small className="text-danger">{error?.cpassword}</small>}
                </div>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <select id="inputState" className="form-control" name="site" value={state.site} onChange={onChange}>
                    <option value="">Site Selection</option>
                    {data?.GetSites?.results?.map((site) => (
                      <option key={site._id} value={site._id}>
                        {site.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-6">
                  <input type="text" className="form-control" placeholder="Company Name" />
                </div>
              </div>
              <div className="form-group">
                <CustomButton
                  type="submit"
                  disabled={disable}
                  // loading={loading}
                  className="btn w-full btn-primary rounded-0"
                >
                  Sign Up
                </CustomButton>
              </div>
            </form>
            <Link to="/signin" style={{ color: "#3876e3", textDecoration: "underline", fontWeight: "bold" }}>
              Already have account. Signin
            </Link>
          </div>
        </Spin>
      </div>
    </div>
  );
};
