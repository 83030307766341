import { useMutation, useQuery } from "@apollo/react-hooks";
import { Spin } from "antd";
import React, { Fragment, useEffect, useState } from "react";
import { ChildSingle } from "../components/Child";
import { GET_CHILD_PERMIT, UPDATE_PERMIT_STATUS, RECALL_CHILD_PERMIT } from "../graphql/modules";
import { successNotify, warnNotify } from "../util";
import { useHistory } from "react-router-dom";
import { CustomButton } from "../components/Shared";

import { useSelector } from "react-redux";
import { UserRole, PermitStatus } from "../config";

export const SingleChildPermit = ({ match }) => {
  const { user: authUser } = useSelector((state) => state.auth);

  const { data, loading, refetch } = useQuery(GET_CHILD_PERMIT, {
    variables: {
      id: match.params.id,
    },
  });

  const [UpdatePermitStatus, { loading: updateLoading }] = useMutation(UPDATE_PERMIT_STATUS);

  const [RecallChildPermit, { loading: recallChildLoading }] = useMutation(RECALL_CHILD_PERMIT);

  const [parentID, setParentId] = useState(null);
  const [formId, setFormId] = useState(null);

  useEffect(() => {
    setParentId(permit?.parentPermit?._id?._id);
    setFormId(permit?.form?._id);
  }, [data]);

  const onUpdate = async (values) => {
    try {
      const { data } = await UpdatePermitStatus({
        variables: {
          id: match.params.id,
          status: values.status,
        },
      });
      if (data.UpdatePermitStatus.success) {
        successNotify(data.UpdatePermitStatus.message);
        refetch();
      } else {
        warnNotify(data.UpdatePermitStatus.message);
      }
    } catch (error) {}
  };

  const onUpdateRecallPermit = async (values) => {
    try {
      const { data } = await RecallChildPermit({
        variables: {
          id: match.params.id,
          status: values.status,
          parentID: parentID,
          formId: formId,
        },
      });
      if (data.RecallChildPermit.success) {
        successNotify(data.RecallChildPermit.message);
        refetch();
      } else {
        warnNotify(data.RecallChildPermit.message);
      }
    } catch (error) {}
  };

  const history = useHistory();
  let hiddenReviewButton = true;
  let permit = {};
  let amIFinalApprover = data?.GetChildPermit?.isFinalApprover ? data?.GetChildPermit?.isFinalApprover : false;

  if (data?.GetChildPermit?.permit) {
    permit = JSON.parse(data?.GetChildPermit?.permit || {});
  }

  if (data?.GetChildPermit?.success === false) {
    return <div className="card">Not found</div>;
  }

  if (authUser.role !== UserRole.ContractorRequestor || authUser._id !== permit?.submittedBy?._id) {
    hiddenReviewButton = false;
  }

  console.log("permit: " + permit);

  if (permit !== null) {
    return (
      <Spin spinning={loading}>
        <ChildSingle permit={permit} onUpdate={onUpdate} updateLoading={updateLoading} />

        {Array.isArray(permit?.children) && (
          <Fragment>
            {permit?.children?.map((child) => (
              <div>
                <div className="heading col-12">
                  <span className="btn-link">{child?.form?.title}</span>
                </div>
                <ChildSingle permit={child} onUpdate={onUpdate} updateLoading={updateLoading} isChildren={true} />
              </div>
            ))}
          </Fragment>
        )}
        <div className="account-details-btn" hidden={hiddenReviewButton}>
          <CustomButton
            className="btn btn-primary ml-3"
            style={{ maxWidth: 300 }}
            onClick={(e) => {
              e.preventDefault();
              history.push(`review/${permit._id}`, {
                user: permit,
              });
            }}
          >
            Review Permit
          </CustomButton>

          {amIFinalApprover ? (
            <CustomButton
              className="btn btn-primary ml-3"
              hidden={permit.status !== PermitStatus.Approved}
              style={{ maxWidth: 300 }}
              onClick={(e) => {
                e.preventDefault();
                onUpdateRecallPermit({
                  status: PermitStatus.PendingApproval,
                });
                console.log("Recall Approved Status Button Pressed");
              }}
              loading={recallChildLoading}
            >
              Recall Approved Status
            </CustomButton>
          ) : (
            ""
          )}
        </div>
      </Spin>
    );
  } else {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <p>
          {" "}
          Viewing Child Permits are not available at the moment. Kindly view the Parent Permit to see the Child Permit.
        </p>
      </div>
    );
  }
};
