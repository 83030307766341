import moment from "moment";
// import { S3_BASE_URL } from "../config";

export function dataURItoBlob(dataURI) {
  var byteString = atob(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to an ArrayBuffer
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
}

export const formatDate = (data, format = "ll") => {
  return moment(data).format(format);
};

export const formatImage = (name) => {
  return process.env.REACT_APP_IMAGE_URL + name;
};

export const getWorkHours = (startTime, endTime, startDate, endDate) => {
  // console.log("getWorkHours function called.");
  if (startTime !== null && endTime !== null && startTime !== "" && endTime !== "") {
    var numberOfDays = getDaysBetweenDates(startDate, endDate);
    var start = startTime?.substring(0, 2);
    var end = endTime?.substring(0, 2);

    var duration = Number(end) - Number(start);

    var totalDuration = Number(duration) * Number(numberOfDays);

    return Math.abs(totalDuration);
  } else {
    return "";
  }
};

export const getDaysBetweenDates = (startDate, endDate) => {
  // console.log("getDaysBetweenDates function called.");
  var start = moment(startDate, "YYYY-MM-DD");
  var end = moment(endDate, "YYYY-MM-DD");

  return moment.duration(start.diff(end)).asDays();
};
