import { Select } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { PermitStatus, StatusColor } from "../../config";
import { CustomButton } from "../Shared";
import { getNextReviewer } from "../../util";

export const ChildUserInfo = ({ permit = {}, showAction, updateLoading, handleStatusUpdate }) => {
  const { user: authUser } = useSelector((state) => state.auth);
  const user = permit?.submittedBy;
  const status = permit?.status;
  const reason = permit?.reason;
  const permitCode = permit?.code;

  const lastApprovedBy = permit?.approvedBy?.length ? permit.approvedBy[permit?.approvedBy?.length - 1] : {};

  const rejectedBy = permit.rejectedBy;
  const historyLogs = permit?.logs;
  let showActionBtn = false;

  let showApprovedOrRejectedPermitDiv = false;

  if (status === PermitStatus.Approved || status === PermitStatus.Rejected) {
    showApprovedOrRejectedPermitDiv = true;
  }

  if (showAction !== undefined) {
    showActionBtn = showAction;
  }

  let color = "alert-warning";
  if (status === PermitStatus.Approved) color = "alert-success";
  else if (status === PermitStatus.Rejected) color = "alert-danger";
  else if (status === PermitStatus.Completed) color = StatusColor.Completed;
  else if (status === PermitStatus.Cancelled) color = StatusColor.Cancelled;
  else if (status === PermitStatus.PendingClosure) color = StatusColor.PendingClosure;
  else if (status === PermitStatus.PendingCancel) color = StatusColor.PendingCancel;

  let nextReviewer = getNextReviewer(permit);

  console.log(
    "\n=================================\n" + JSON.stringify(permitCode) + "\n=================================\n"
  );
  console.log("[43] nextReviewer: " + JSON.stringify(nextReviewer, null, 2));

  return (
    <div className="card full-details">
      <div className="account-details">
        <div className="account-details-colum">
          <h6>Full Name</h6>
          <p>{user?.fullname}</p>
        </div>
        <div className="account-details-colum">
          <h6>Company</h6>
          <p>{user?.company || "-"}</p>
        </div>
        <div className="account-details-colum">
          <h6>Permit Number</h6>
          <p>{permitCode}</p>
        </div>
        <div className="account-details-colum">
          <h6>Email</h6>
          <p>{user?.email}</p>
        </div>
        <div className="account-details-colum">
          <h6>Site</h6>
          <p>{user?.site?.name || "-"}</p>
        </div>
        <div className="account-details-colum">
          <h6>Status</h6>
          {showApprovedOrRejectedPermitDiv ? (
            <div className="pending-btn permit-btn">
              <button className={`btn ${color}`}>
                {status === PermitStatus.Approved
                  ? `Approved By ${lastApprovedBy?.group?.name ? lastApprovedBy?.group?.name : lastApprovedBy.role}`
                  : status === PermitStatus.Rejected
                  ? `Rejected By ${rejectedBy?.group?.name ? rejectedBy?.group?.name : rejectedBy?.role}`
                  : status}
              </button>
              <p>{status === PermitStatus.Rejected ? `Reason: ${reason}` : ""}</p>
            </div>
          ) : status === PermitStatus.PendingApproval ? (
            <div className="pending-btn permit-btn">
              <button className={`btn ${color}`}>Pending Approval by {nextReviewer}</button>
            </div>
          ) : status === PermitStatus.Pending ? (
            <div className="pending-btn permit-btn">
              <button className={`btn ${color}`}>Pending Approval by PM</button>
            </div>
          ) : (
            <div className="pending-btn permit-btn">
              <button className={`btn ${color}`}> {status}</button>
            </div>
          )}
        </div>
        <div className="account-details-colum">
          <h6>History Logs</h6>
          {
            <span style={{ fontSize: "10px" }}>
              {historyLogs &&
                historyLogs.map((result) => (
                  <React.Fragment>
                    *{result}
                    <br />
                  </React.Fragment>
                ))}
            </span>
          }
        </div>

        {showActionBtn && (
          <div className="account-details-colum">
            <div className="pending-btn permit-btn">
              <Select
                className={`btn ${color} p-0`}
                value={status}
                onChange={(value) => handleStatusUpdate({ status: value })}
              >
                <Select.Option value="Pending">Pending</Select.Option>
                <Select.Option value="Approved">Approved</Select.Option>
                <Select.Option value="Rejected">Rejected</Select.Option>
              </Select>
            </div>
          </div>
        )}
        {/* {showActionBtn && (
          <div className="account-details-colum pending-btn">
            <CustomButton
              loading={updateLoading && status === PermitStatus.Rejected}
              className="btn btn-danger mr-2"
              onClick={() => handleStatusUpdate({ status: "Rejected" })}
            >
              Reject
            </CustomButton>
            <CustomButton
              loading={updateLoading && status === PermitStatus.Approved}
              className="btn btn-success"
              onClick={() => handleStatusUpdate({ status: "Approved" })}
            >
              Approve
            </CustomButton>
          </div>
        )} */}
      </div>
    </div>
  );
};
