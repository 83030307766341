import gql from "graphql-tag";

export const GET_ACTIVE_ANNOUCEMENT = gql`
query($active: Boolean){
  GetActiveAnnoucement(active:$active){
    annoucement
    message
    code
    success
  }
}
`;
