import React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import { apollo } from "../graphql";
import { store } from "../store";
import { Provider } from "react-redux";
import AppRouter from "../routes";
import IdleUser from "./IdleUser";
import "antd/dist/antd.min.css";
import "../styles/index.scss";

const CustomApp = () => (
  <ApolloProvider client={apollo}>
    <Provider store={store}>
      <AppRouter />
      <IdleUser />
    </Provider>
  </ApolloProvider>
);

export default CustomApp;
