// payment status
export const PAYMENT_SUCCESS = "PAYMENT_SUCCESS";
export const PAYMENT_PENDING = "PAYMENT_PENDING";
export const WALLET_PAYMENT_SUCCESS = "WALLET_PAYMENT_SUCCESS";
export const PAYOUT_SUCCESS = "PAYOUT_SUCCESS";
export const PAYOUT_PENDING = "PAYOUT_PENDING";
export const PAYMENT_REFUNDED = "PAYMENT_REFUNDED";
export const PAYMENT_REFUND_FAILED = "PAYMENT_REFUND_FAILED";

// status function

export const statusCode = {
  BAD_REQUEST: "BAD_REQUEST",
  BLOCKED: "BLOCKED",
  VALIDATION_ERROR: "VALIDATION_ERROR",
  UNAUTHORIZED: "UNAUTHORIZED",
  INTERNAL_ERROR: "INTERNAL_ERROR",
  PERMISSION_DENIED: "PERMISSION_DENIED",
  ACCESS_DENIED: "ACCESS_DENIED",
  NOT_FOUND: "NOT_FOUND",
  CREATED: "CREATED",
  ADDED: "ADDED",
  UPDATED: "UPDATED",
  DELETED: "DELETED",
  OK: "OK",
  LIMIT_EXCEED: "LIMIT_EXCEED",
};

export const payMethod = {
  WALLET: "WALLET",
  INSTALLMENT: "INSTALLMENT",
  WALLET_INSTALLMENT: "WALLET_INSTALLMENT",
};

export const GENERAL_PERMIT_ID = "60500a1f050c9442a394437c";

export const TABLE_LIST_LIMIT = 20;

export const PERMITTOCLOSED = 10;

export const A2 = "604ee2f8967a3139c7ff4eb7";
export const A3 = "604ee3cf967a3139c7ff4ebd";
export const A4 = "604ee609967a3139c7ff4eca";
export const A5 = "604ee64b967a3139c7ff4edd";
export const A6 = "604ee6c5967a3139c7ff4ee0";
export const A7 = "604ee7dd967a3139c7ff4ef2";
export const A8 = "604ee857967a3139c7ff4ef7";
export const A9 = "604eead7967a3139c7ff4efd";
export const A10 = "604ee701967a3139c7ff4ee3";
export const A11 = "604ee74f967a3139c7ff4ee8";
export const B1 = "";
export const B2 = "";
export const B3 = "";
export const B4 = "";
export const B5 = "";

export const FilterA2 = "#A2";
export const FilterA3 = "#A3";
export const FilterA4 = "#A4";
export const FilterA5 = "#A5";
export const FilterA6 = "#A6";
export const FilterA7 = "#A7";
export const FilterA8 = "#A8";
export const FilterA9 = "#A9";
export const FilterA10 = "#A10";
export const FilterA11 = "#A11";

export const FilterB1 = "Hot Work Permit";
export const FilterB2 = "Confined Space Entry Work Permit";
export const FilterB3 = "Crane Lifting Permit";
export const FilterB4 = "Work At Height Permit";
export const FilterB5 = "LSS Impairment Permit";
