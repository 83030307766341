import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { GET_SITE_LIST, CREATE_USER, GET_GROUP_LIST, ADMIN_RESET_PASSWORD } from "../../graphql/modules";
import { successNotify, warnNotify } from "../../util";
import { CustomButton } from "../Shared";
import { UserRole } from "../../config";
import { useSelector } from "react-redux";
import { SecurityScanTwoTone } from "@ant-design/icons";
import { Select, Space } from "antd";

export const UserForm = ({
  title,
  btnText,
  loading,
  btnPassword,
  resetId,
  state = {},
  error = {},
  handleChange = () => {},
  onSubmit = () => {},
  userId = null,
}) => {
  const { user } = useSelector((state) => state.auth);

  // CREATE USER
  const [GetUserGroupList, { data: UserGroup, loading: groupLoading }] = useLazyQuery(GET_GROUP_LIST, {
    variables: {
      offset: 0,
      limit: 100,
      sites: state?.sites,
    },
  });

  const [ResetPassword, { loading: ResetLoading }] = useMutation(ADMIN_RESET_PASSWORD, {
    variables: {
      userId: resetId
    },
  });

  useEffect(() => {
    if (state.role === UserRole.MTGroup) {
      GetUserGroupList();
    } else {
      handleChange({ group: null });
    }
  }, [state.role]);

  // GET SITES LIST
  const { data } = useQuery(GET_SITE_LIST, {
    variables: {
      offset: 0,
      limit: 100,
    },
  });

  const onChange = (e) => handleChange({ [e.target.name]: e.target.value });

  let disable = !state.email || !state.fullname || !state.role || state?.sites?.length === 0;

  const isMyProfile = userId === user._id;

  if (isMyProfile) disable = !state.email || !state.fullname;

  const onChangePassword = async (e) => {
    e.preventDefault();
    try {
      const { data: { ResetPasswordByAdmin }} = await ResetPassword();
      console.log(ResetPasswordByAdmin)
      if (ResetPasswordByAdmin.success) {
        successNotify(ResetPasswordByAdmin.message);
      } else {
        warnNotify(ResetPasswordByAdmin.message);
      }
    } catch (error) {
      warnNotify(error.message);
      console.log(error.message);
    }
  };

  return (
    <div>
      <div className="heading"></div>
      <h1 className="title">{title}</h1>
      <form onSubmit={onSubmit}>
        <div className="card">
          <div className="create-permit-details">
            <div className="form-row">
              <div className="form-group col-md-4">
                <input
                  type="name"
                  className="form-control"
                  placeholder="Fullname"
                  name="fullname"
                  value={state.fullname}
                  onChange={onChange}
                />
                {error?.fullname && <small className="text-danger">{error?.fullname}</small>}
              </div>
              <div className="form-group col-md-4">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  name="email"
                  value={state.email || ""}
                  onChange={onChange}
                />
                {error?.email && <small className="text-danger">{error?.email}</small>}
              </div>
              {isMyProfile ? null : (
                <div className="form-group col-md-4" id="permit-input">
                  <Select
                    style={{ width: "100%" }}
                    onChange={(value) => handleChange({ sites: value })}
                    placeholder="Select Site"
                    className="form-control-select"
                    showSearch
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    mode="multiple"
                    value={state.sites}
                  >
                    {data?.GetSites?.results?.map((site) => (
                      <Select.Option key={site._id} value={site._id} site={site}>
                        {site.name}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              )}
              {isMyProfile ? null : (
                <div className="form-group col-md-4">
                  <select id="inputState" className="form-control" name="role" value={state.role} onChange={onChange}>
                    <option value="">Role Selection</option>
                    {Object.values(UserRole).map((key) => {
                      if (
                        user.role === UserRole.SiteAdmin &&
                        (key === UserRole.SuperAdmin || key === UserRole.SiteAdmin)
                      )
                        return null;
                      return (
                        <option key={key} value={key}>
                          {key}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}

              {isMyProfile ? null : (
                <div className="form-group col-md-4">
                  <input
                    type="name"
                    className="form-control"
                    placeholder="Company Name"
                    name="company"
                    value={state.company || ""}
                    onChange={onChange}
                  />
                </div>
              )}

              <div className="form-group col-md-4">
                <input
                  type="name"
                  className="form-control"
                  placeholder="Phone Number"
                  name="phone"
                  value={state.phone || ""}
                  onChange={onChange}
                />
              </div>
            </div>

            {/* <div className="form-row">
              {state.role === UserRole.MTGroup && (
                <div className="form-group col-md-4">
                  <select
                    id="inputState2"
                    className="form-control"
                    name="group"
                    value={state.permitCloser}
                    onChange={onChange}
                  >
                    <option value="">Permit Closer?</option>
                    <option key={1} value={true}>
                      YES
                    </option>
                    <option key={2} value={false}>
                      NO
                    </option>
                  </select>
                </div>
              )}
            </div> */}

            <div className="form-row" hidden>
              {state.role === UserRole.MTGroup && (
                <div className="form-group col-md-4">
                  <select
                    id="inputState2"
                    className="form-control"
                    name="group"
                    value={state.group}
                    onChange={onChange}
                  >
                    <option value="">Group Selection</option>
                    {UserGroup?.GetUserGroups?.results?.map((group) => {
                      return (
                        <option key={group._id} value={group._id}>
                          {group.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="account-details-btn">
          <Space>
          <CustomButton type="submit" disabled={disable} loading={loading} className="btn btn-primary">
            {btnText}
          </CustomButton>
          <CustomButton onClick={onChangePassword} disabled={disable} loading={loading} className="btn btn-primary" hidden={btnPassword === undefined || btnPassword === "" || user.role !== "SuperAdmin" ? true : false }>
            {btnPassword}
          </CustomButton>
          </Space>
        </div>
      </form>
    </div>
  );
};
