import React, { useRef } from "react";
import IdleTimer from "react-idle-timer";
import { useQuery } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";
import { logoutUser } from "../store/modules/auth";
import { GET_UNLCOSED_PERMIT } from "../graphql/modules";

function IdleTimerUser() {
  const { data: timerCount, loading: timerLoad } = useQuery(GET_UNLCOSED_PERMIT);
  const getTimerCount = timerCount?.CountUnClosedPermit?.timerCount;
  console.log(timerCount);
  const idleTimerRef = useRef(null);
  const dispatch = useDispatch();

  const handleOnIdle = () => {
    dispatch(logoutUser());
  };
  return (
    <div>
      <IdleTimer
        ref={idleTimerRef}
        timeout={getTimerCount ? getTimerCount * 1000 : 10800 * 1000}
        onIdle={handleOnIdle}
      />
    </div>
  );
}

export default IdleTimerUser;
