import React, { useCallback, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import { CREATE_DISCIPLINE } from "../graphql/modules";
import { successNotify, warnNotify } from "../util";
import { DisciplineForm } from "../components/Discipline";

export const CreateDiscipline = ({}) => {
  const history = useHistory();
  //initial state
  const [state, setState] = useState({
    code: "",
    description: "",
  });

  const [error, setError] = useState(null);
  // CREATE USER
  const [CreateMutation, { loading }] = useMutation(CREATE_DISCIPLINE, {
    variables: {
      data: state,
    },
  });

  // SUBMIT HANDLER
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const {
        data: { CreateDiscipline },
      } = await CreateMutation();

      if (CreateDiscipline.success) {
        successNotify(CreateDiscipline.message);
        setState({
          code: "",
          description: "",
        });
        history.push("/disciplines");
      } else {
        warnNotify(CreateDiscipline.message);
      }
    } catch (error) {
      warnNotify(error.message);
      console.log(error.message);
    }
  };

  const handleChange = (value) => setState({ ...state, ...value });

  console.log(state)

  return (
    <DisciplineForm
      title="Create New Discipline"
      btnText="Create Discipline"
      btnPassword=""
      onSubmit={onSubmit}
      loading={loading}
      handleChange={handleChange}
      state={state}
    />
  );
};
