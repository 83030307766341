import React from "react";
import { useQuery } from "@apollo/react-hooks";
import {
  GET_ALL_MTGROUPS,
  GET_MY_PENDING_APPROVAL_PERMITS,
  GET_PENDING_FINAL_APPROVAL_LSS_PERMITS,
  GET_CHILD_PENDING_FINAL_APPROVAL_LSS_PERMITS,
  GET_PERMITS_I_APPROVED,
  GET_CHILD_NEXT_APPROVAL_PERMITS,
} from "../graphql/modules";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { logoutUser } from "../store/modules/auth";
import { getSidebar } from "./sidebar";
import Badge from "@material-ui/core/Badge";
import { GooglePlusOutlined, DownloadOutlined, UserOutlined, FileSyncOutlined, CodeOutlined } from "@ant-design/icons";
import packageJson from "../../package.json";
import { TABLE_LIST_LIMIT } from "../util";

import { LoadingOutlined } from "@ant-design/icons";

const nodeEnv = process.env.REACT_APP_NODE_ENV;

const Layout = (props) => {
  const dispatch = useDispatch();

  const role = useSelector((state) => state.auth?.user?.role);
  const userInfo = useSelector((state) => state.auth?.user);

  const { data: GroupList } = useQuery(GET_ALL_MTGROUPS, {
    variables: {
      variables: {
        limit: TABLE_LIST_LIMIT,
        offset: 0,
      },
    },
  });

  const { data: AllPermitsIApproved, loading: AllPermitsIApprovedLoading } = useQuery(GET_PERMITS_I_APPROVED, {
    variables: {
      limit: TABLE_LIST_LIMIT,
      offset: 0,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: PendingApprovalPermits, loading: PendingApprovalPermitsLoading } = useQuery(
    GET_MY_PENDING_APPROVAL_PERMITS,
    {
      variables: {
        limit: TABLE_LIST_LIMIT,
        offset: 0,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const { data: PendingFinalLSSPermits, loading: PendingFinalLSSPermitsLoading } = useQuery(
    GET_PENDING_FINAL_APPROVAL_LSS_PERMITS,
    {
      variables: {
        limit: TABLE_LIST_LIMIT,
        offset: 0,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const { data: PendingChildFinalLSSPermits } = useQuery(GET_CHILD_PENDING_FINAL_APPROVAL_LSS_PERMITS, {
    variables: {
      limit: TABLE_LIST_LIMIT,
      offset: 0,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: MyChildPermitsPendingApproval, loading: MyChildPermitsPendingApprovalLoading } = useQuery(
    GET_CHILD_NEXT_APPROVAL_PERMITS,
    {
      variables: {
        limit: TABLE_LIST_LIMIT,
        offset: 0,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  const totalPermitsPendingApproval = PendingApprovalPermits?.GetMyPendingApprovalPermits?.count;
  let showPermitCancelClose = false;

  const totalPermitsPendingFinalLSS = PendingFinalLSSPermits?.GetFinalPendingApprovalLSSPermits?.count;
  const totalChildPermitsPendingFinalLSS = PendingChildFinalLSSPermits?.GetChildFinalPendingApprovalLSSPermits?.count;

  var finalTotalLSSCount = totalPermitsPendingFinalLSS + totalChildPermitsPendingFinalLSS;

  const totalPermitsIApproved = AllPermitsIApproved?.GetPermitsIApproved?.count;

  const totalChildPermitsPendingMyApproval = MyChildPermitsPendingApproval?.GetMyNextPendingApprovalChildPermits?.count;

  try {
    const groupData = GroupList?.GetAllMTGroups?.results;
    // console.log("IsPermitCloser : GroupList?.GetAllMTGroups?.count: " + GroupList?.GetAllMTGroups?.count);

    if (groupData !== null || groupData !== undefined || groupData !== []) {
      for (var i = 0; i < GroupList?.GetAllMTGroups?.count; i++) {
        if (groupData[i].members.some((usr) => usr._id.toString() === userInfo._id.toString())) {
          // console.log("user is member of " + groupData[i].name + " MTGroup");
          // console.log(groupData[i].name + " MTGroup can close permit? " + groupData[i].permitCloser);

          if (groupData[i].permitCloser === true) {
            showPermitCancelClose = true;
          }
          break;
        }
      }
    } else {
      // console.log("No MTGroups available.");
      showPermitCancelClose = false;
      // resolve(isUserPermitCloserMember);
    }
  } catch (err) {
    console.log(err);
    showPermitCancelClose = false;
  }

  const onLogout = (e) => {
    e.preventDefault();
    dispatch(logoutUser());
  };

  const sidebar = getSidebar(role) || [];

  const onClickToggleMenu = () => {
    document.querySelector(".sidebar-menu").classList.add("open");
    document.body.classList.add("open");
  };

  const onClickCloseMenu = () => {
    document.querySelector(".sidebar-menu").classList.remove("open");
    document.body.classList.remove("open");
  };

  return (
    <section className="main-section">
      <div className="sidebar-menu">
        <div className="left-menu-wrapper">
          <div className="close-menu" onClick={onClickCloseMenu}>
            <a href="#/">
              <i className="fal fa-times"></i>
            </a>
          </div>
          <div className="left-logo">
            <a href="#/">
              <img src="assets/img/Micron-logo.svg" alt="" width="130" />
            </a>
          </div>

          <ul className="left-menu">
            {sidebar.map((item) => (
              <li key={item.path}>
                <Badge
                  badgeContent={
                    MyChildPermitsPendingApprovalLoading ? <LoadingOutlined /> : totalChildPermitsPendingMyApproval
                  }
                  color="secondary"
                  max={9999}
                  invisible={item.label === "My Child Permits Pending Approval" ? false : true}
                  exact={true}
                  style={{ width: "100%" }}
                >
                  <Badge
                    badgeContent={AllPermitsIApprovedLoading ? <LoadingOutlined /> : totalPermitsIApproved}
                    color="primary"
                    max={9999}
                    invisible={item.label === "Past Permits I Approved" ? false : true}
                    exact={true}
                    style={{ width: "100%" }}
                  >
                    <Badge
                      badgeContent={PendingFinalLSSPermitsLoading ? <LoadingOutlined /> : finalTotalLSSCount}
                      color="secondary"
                      max={9999}
                      invisible={item.label === "LSS Permits" ? false : true}
                      exact={true}
                      style={{ width: "100%" }}
                    >
                      <Badge
                        badgeContent={PendingApprovalPermitsLoading ? <LoadingOutlined /> : totalPermitsPendingApproval}
                        color="secondary"
                        max={9999}
                        invisible={item.label === "Permits Pending My Approval" ? false : true}
                        exact={true}
                        style={{ width: "100%" }}
                      >
                        <NavLink exact={item.exact} to={item.path} style={{ width: "100%" }}>
                          <i className={item.icon}></i> {item.label}
                        </NavLink>
                      </Badge>
                    </Badge>
                  </Badge>
                </Badge>
              </li>
            ))}

            {showPermitCancelClose ? (
              <li>
                <NavLink exact={true} to="/permits-pending-close-cancel">
                  <i>
                    <FileSyncOutlined />
                  </i>{" "}
                  Permits Pending Closure or Pending Cancellation
                </NavLink>
              </li>
            ) : (
              ""
            )}

            <li>
              <NavLink exact={true} to="/my-profile">
                <i>
                  <UserOutlined />
                </i>{" "}
                Account
              </NavLink>
            </li>

            <li>
              <NavLink exact={true} to="/forms-download">
                <i>
                  <DownloadOutlined />
                </i>{" "}
                Forms Download
              </NavLink>
            </li>

            <li>
              <a href={process.env.REACT_APP_FEEDBACK_FORM} rel="noopener noreferrer" target="_blank">
                <i>
                  <GooglePlusOutlined />
                </i>{" "}
                Feedback Form
              </a>
            </li>

            <li>
              <NavLink exact={true} to="/changelogs">
                <i>
                  <CodeOutlined />
                </i>{" "}
                Change Logs
              </NavLink>
            </li>

            <li>
              <a href="#/" onClick={onLogout}>
                <i className="far fa-book-alt"></i> Logout
              </a>
            </li>
          </ul>
        </div>

        {nodeEnv === "production" ? (
          ""
        ) : (
          <div
            style={{
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <p style={{ color: "white" }}>[Build Version: {packageJson.gitBuildVersion}]</p>
          </div>
        )}
      </div>
      <div className="right-main-content">
        <div className="mobile-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="mobile-header-wrapper">
                  <div className="mobile-logo">
                    <a href="#/">
                      <img src="assets/img/mobile-logo.png" alt="" width="40" />
                    </a>
                  </div>
                  <div className="toggle-menu" onClick={onClickToggleMenu}>
                    <a href="#/" className="toggle-icon">
                      <i className="fas fa-bars"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {props.children}
      </div>
    </section>
  );
};

export default Layout;
