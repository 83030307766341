import { useMutation } from "@apollo/react-hooks";
import { message } from "antd";
import React, { useState } from "react";
import { CustomButton } from "../components/Shared";
import { CREATE_SITE } from "../graphql/modules";

export const CreateSite = ({ history }) => {
  const [state, setState] = useState({
    name: "",
    code: "",
    address: "",
  });

  const onChange = (e) => setState({ ...state, [e.target.name]: e.target.value });

  const [CreateSiteMutation, { loading }] = useMutation(CREATE_SITE);
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await CreateSiteMutation({
        variables: {
          data: state,
        },
      });
      if (data?.CreateSite?.success) {
        message.success(data?.CreateSite?.message);
        history.push("/sites");
      } else {
        message.warn(data?.CreateSite?.message);
      }
    } catch (error) {}
  };

  const disable = !state.name;
  return (
    <div>
      <div className="heading"></div>
      <h1 className="title">Create New Site</h1>
      <form onSubmit={onSubmit}>
        <div className="card">
          <div className="create-permit-details">
            <div className="form-row">
              <div className="form-group col-md-4">
                <input type="name" className="form-control" placeholder="Site name" name="name" onChange={onChange} />
              </div>
              <div className="form-group col-md-4">
                <input type="name" className="form-control" placeholder="Site code" name="code" onChange={onChange} />
              </div>
              <div className="form-group col-md-4">
                <input
                  type="name"
                  className="form-control"
                  placeholder="Site address"
                  name="address"
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="account-details-btn ">
          <CustomButton type="submit" disabled={disable} loading={loading} className="btn btn-primary ml-auto">
            Create Site
          </CustomButton>
        </div>
      </form>
    </div>
  );
};
