import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Link, useHistory } from "react-router-dom";
import { EllipsisOutlined, SearchOutlined } from "@ant-design/icons";
import { UserStatus } from "../config";
import { GET_ALL_DISCIPLINE, DELETE_DISCIPLINE, UPDATE_DISCIPLINE } from "../graphql/modules";
import { Pagination, Tag, Popconfirm, Dropdown, Menu, Input, Button, Space } from "antd";
import Highlighter from "react-highlight-words";

import { DynamicList } from "../components/Shared";
import { successNotify, warnNotify, TABLE_LIST_LIMIT } from "../util";

export const AllDisciplines = ({ match }) => {
  const history = useHistory();
  const [limit, setLimit] = useState(TABLE_LIST_LIMIT);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [value, setValue] = useState("");
  const { data, loading, error, refetch } = useQuery(GET_ALL_DISCIPLINE, {
    variables: {
      searchText: value,
      limit,
      offset: page - 1,
    },
    fetchPolicy: "cache-and-network",
  });

  const onChangePagination = (p, pageSize) => {
    setPage(p);
    setLimit(pageSize);
  };


  const [DeleteMutation] = useMutation(DELETE_DISCIPLINE);

  const handleDelete = async (key) => {
    try {
      const {
        data: { DeleteUser },
      } = await DeleteMutation({
        variables: {
          id: key,
        },
      });

      if (DeleteUser.success) {
        successNotify(DeleteUser.message);
        refetch();
      } else {
        warnNotify(DeleteUser.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [UpdateMutation, { loading: updateLoading }] = useMutation(UPDATE_DISCIPLINE);

  const onApproveUser = async (key) => {
    try {
      const {
        data: { UpdateUser: UpdateDiscipline },
      } = await UpdateMutation({
        variables: {
          id: key,
          data: { status: UserStatus.Approved },
        },
      });

      if (UpdateDiscipline.success) {
        successNotify(UpdateDiscipline.message);
        refetch();
      } else {
        warnNotify(UpdateDiscipline.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const total = data?.GetAllDisciplines?.count;
  const results = data?.GetAllDisciplines?.results;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
            // this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={searchText}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  function handleSearchDiscipline(datavalue) {
    setValue(datavalue);
    setPage(1);
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "#",
      render: (_text, _row, index) => limit * (page - 1) + index + 1,
    },
    {
      title: "Discipline Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      width: "15%",
      render: (text, record) => {
        const menu = (
          <Menu>
            <Menu.Item key="0">
            </Menu.Item>
            <Menu.Item key="1">
              <Link style={{ marginRight: "15px" }} to={`${match.path}/edit-discipline/${record._id}`}>
                Edit
              </Link>
            </Menu.Item>
            {/* <Menu.Divider /> */}
            {/* <Menu.Item key="3">
              <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record._id)}>
                Disable
              </Popconfirm>
            </Menu.Item> */}
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <EllipsisOutlined />
          </Dropdown>
        );
      },
    },
  ];

  if (error) {
    return <div className="card">Something went wrong</div>;
  }

  return (
    <DynamicList
      title="List of Disciplines"
      loading={loading}
      columns={columns}
      data={results}
      actions={
        <Link to={match.path + "/create-discipline"} className="btn btn-primary mr-2">
          Create Discipline<i className="fas fa-plus-circle"></i>
        </Link>
      }
      total={total}
      pageSize={limit}
      onChange={onChangePagination}
      pageNo={page}
      showSearch={true}
      onChangeSearch={handleSearchDiscipline}
    />
  );
};
