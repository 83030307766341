import gql from "graphql-tag";
import { SITE_FRAGMENT } from "../fragments";

export const GET_SITE_LIST = gql`
  query($limit: Int, $offset: Int) {
    GetSites(limit: $limit, offset: $offset) {
      code
      success
      message
      count
      results {
        ...SiteType
      }
    }
  }
  ${SITE_FRAGMENT}
`;

export const CREATE_SITE = gql`
  mutation($data: SiteInput) {
    CreateSite(data: $data) {
      code
      success
      message
    }
  }
`;


export const DELETE_SITE = gql`
  mutation($id: ID!) {
    DeleteSite(id: $id) {
      code
      success
      message
    }
  }
`;