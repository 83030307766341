import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Link, useHistory } from "react-router-dom";
import { EllipsisOutlined } from "@ant-design/icons";
import { UserStatus } from "../config";
import { Pagination, Tag, Popconfirm, Dropdown, Menu } from "antd";
import { DynamicList } from "../components/Shared";
import { successNotify, warnNotify } from "../util";
import { __data, userGuides, youtubeLinks } from "../config/constants";

export const FormDownloads = ({ match }) => {
  const history = useHistory();
  const [limit] = useState(20);
  const [page, setPage] = useState(1);
  const [userPage, setUserPage] = useState(1);
  const [ytPage, setYtPage] = useState(1);

  const onChangePagination = (p) => {
    setPage(p);
  };

  const onUserGuideChangePagination = (p) => {
    setUserPage(p);
  };

  const onYoutubeLinkChangePagination = (p) => {
    setYtPage(p);
  };

  // results.sort((a, b) => (a.title > b.title ? 1 : -1));

  const columns = [
    {
      title: "#",
      render: (_text, _row, index) => limit * (page - 1) + index + 1,
      width: "8%",
    },
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
      render: (text) => (
        <a href={process.env.PUBLIC_URL + "/formdownloads/" + text} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: "Last Updated",
      dataIndex: "lastUpdated",
      key: "lastUpdated",
    },
  ];

  const columns_user_guide = [
    {
      title: "#",
      render: (_text, _row, index) => limit * (page - 1) + index + 1,
      width: "8%",
    },
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
      render: (text) => (
        <a href={process.env.PUBLIC_URL + "/userguides/" + text} target="_blank" rel="noopener noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: "Last Updated",
      dataIndex: "lastUpdated",
      key: "lastUpdated",
    },
  ];

  const columns_youtubeLinks = [
    {
      title: "#",
      render: (_text, _row, index) => limit * (page - 1) + index + 1,
      width: "8%",
    },
    {
      title: "Title",
      dataIndex: "fileName",
      key: "fileName",
    },
    {
      title: "Video Link",
      dataIndex: "url",
      key: "url",
      render: (text) => (
        <a href={text} target="_blank" rel="noopener noreferrer">
          Click Here
        </a>
      ),
    },
    {
      title: "Last Updated",
      dataIndex: "lastUpdated",
      key: "lastUpdated",
    },
  ];

  const total = __data.length;
  const userGuideTotal = userGuides.length;
  const youtubeLinksTotal = youtubeLinks.length;

  return (
    <div>
      <DynamicList
        title="Forms Download"
        //   loading={loading}
        columns={columns}
        data={__data}
        pagination={
          <Pagination
            className="pagination"
            total={total}
            pageSize={limit}
            current={page}
            showSizeChanger={false}
            onChange={onChangePagination}
          />
        }
      />
      <hr />

      <DynamicList
        title="User Guides"
        //   loading={loading}
        columns={columns_user_guide}
        data={userGuides}
        pagination={
          <Pagination
            className="pagination"
            total={userGuideTotal}
            pageSize={limit}
            current={userPage}
            showSizeChanger={false}
            onChange={onUserGuideChangePagination}
          />
        }
      />
      <hr />

      <DynamicList
        title="Tutorial Video"
        //   loading={loading}
        columns={columns_youtubeLinks}
        data={youtubeLinks}
        pagination={
          <Pagination
            className="pagination"
            total={youtubeLinksTotal}
            pageSize={limit}
            current={ytPage}
            showSizeChanger={false}
            onChange={onYoutubeLinkChangePagination}
          />
        }
      />
      <hr />


    </div>
  );
};
