import { useMutation, useQuery } from "@apollo/react-hooks";
import { Modal, Spin, Button, Checkbox, Alert, Typography } from "antd";
import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { USER_LOGIN, GET_ACTIVE_ANNOUCEMENT } from "../graphql/modules";
import { loginUser } from "../store/modules/auth";
import { errorNotify, successNotify, warnNotify } from "../util";

const react_env = process.env.REACT_APP_NODE_ENV;
const { Title } = Typography;

let sso_url = "localhost/adlogin";

if (react_env === "development") {
  sso_url = "https://myost.app/adlogin";
} else if (react_env === "production") {
  sso_url = "https:///f10microneptw.com/adlogin";
} else {
  sso_url = "https://myost.app/adlogin";
}

export const SignIn = () => {
  console.log("First");
  const dispatch = useDispatch();
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const {
    data,
    loading: loadindata,
    error,
    refetch,
  } = useQuery(GET_ACTIVE_ANNOUCEMENT, {
    variables: {
      active: true,
    },
    fetchPolicy: "cache-and-network",
  });
  var annoucmentDetails = JSON.parse(data?.GetActiveAnnoucement?.annoucement || "[]");
  console.log(annoucmentDetails);
  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const [LoginMutation, { loading }] = useMutation(USER_LOGIN, {
    variables: {
      email: state.email,
      password: state.password,
    },
  });

  const onLogin = async (e) => {
    e.preventDefault();

    try {
      const {
        data: { Login },
      } = await LoginMutation();

      if (Login.success) {
        successNotify(Login.message);
        console.log("Before Last");
        dispatch(loginUser(Login));
        console.log("Last");
      } else {
        warnNotify(Login.message);
      }
    } catch (error) {
      errorNotify(error.message);
      console.log("M ", error.message);
    }
  };

  const disable = !state.email || !state.password;

  const [isModalVisible, setIsModalVisible] = useState(true);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [isButtonEnable, setIsButtonEnable] = useState(true);

  // const onConfirm = () => {
  //   setIsButtonEnable(false);
  // };

  const onConfirm = (e) => {
    if (e.target.checked === true) {
      setIsButtonEnable(false);
    } else {
      setIsButtonEnable(true);
    }
  };

  return (
    <div className="col-md-5 m-auto">
      <Modal
        style={{ marginTop: "10%" }}
        title={"DISCLAIMER"}
        extra={<a href="#">More</a>}
        visible={isModalVisible}
        onOk={handleOk}
        okButtonProps={{ disabled: isButtonEnable }}
        onCancel={handleCancel}
        cancelButtonProps={{ hidden: true }}
        closable={false}
        maskClosable={false}
        keyboard={false}
        width={1000}
      >
        <div hidden={annoucmentDetails.length === 0 ? true : false}>
          <Alert
            message={<Title level={2}>{annoucmentDetails[0]?.title}</Title>}
            description={
              <Title level={3}>
                <div dangerouslySetInnerHTML={{ __html: annoucmentDetails[0]?.message }} />
              </Title>
            }
            type={annoucmentDetails[0]?.type}
            showIcon
            style={{ backgroundColor: annoucmentDetails[0]?.color }}
          />
        </div>
        <br />

        <h5 style={{ fontSize: "20px" }}>
          Dear requester, please close all completed permits by clicking “Pending for Closure” and it will route to OPS
          to close the permit completely.{" "}
        </h5>
        <h5>📝 Take note: that you are not allowed to apply for additional permits once your quota is up.</h5>
        {/* <Checkbox value="A" style={{ color: "red" }} >“Please fill up all the checkbox and lines and do not leave empty for all permits applied to avoid any permit rejection “</Checkbox> */}
        <div>
          <Checkbox onChange={onConfirm} style={{ width: "100%", fontSize: "16px", color: "red" }}>
            Please fill up all the checkbox and lines and do not leave empty for all permits applied to avoid any permit
            rejection
          </Checkbox>
        </div>
      </Modal>
      <div className="card" style={{ margin: "auto" }}>
        <Spin spinning={loading}>
          <div className="login-form">
            <h1 className="title">Sign In</h1>
            <form onSubmit={onLogin}>
              <p style={{ textAlign: "center" }}> For Micron Partners Only </p>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <Form.Control
                    type="email"
                    name="email"
                    value={state.email}
                    onChange={onChange}
                    placeholder="Enter email"
                  />
                </div>
                <div className="form-group col-md-12">
                  <Form.Control
                    type="password"
                    name="password"
                    value={state.password}
                    onChange={onChange}
                    placeholder="Password"
                  />
                </div>
                <Link to="forgot-password" style={{ textDecoration: "underline", marginLeft: "2%" }} hidden>
                  Forgot Password?
                </Link>
              </div>
              <div className="form-group">
                <button
                  className="btn w-full btn-primary rounded-0 d-flex justify-content-between"
                  style={{ marginTop: "2%", fontSize: "18px" }}
                  disabled={disable}
                  type="submit"
                >
                  <span style={{ textAlign: "left", display: "block", float: "left" }}>Sign In</span>{" "}
                  <i className="fad fa-arrow-circle-right"></i>
                </button>
              </div>
              <div className="form-group">
                {/* <p style={{ textAlign: "center" }}> or </p> */}
                <hr />
              </div>
              <p style={{ textAlign: "center" }}> For Micron Team Members Only </p>
              <div className="form-group">
                <Button type="primary" block href={sso_url}>
                  Login using your Micron Account
                </Button>
              </div>
            </form>
          </div>
        </Spin>
      </div>
    </div>
  );
};
