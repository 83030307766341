import gql from "graphql-tag";

export const GET_ALL_DISCIPLINE = gql`
    query ($limit: Int, $offset: Int, $filterOption: UserFilterOption, $searchText: String) {
        GetAllDisciplines (limit: $limit, offset: $offset, filterOption: $filterOption, searchText: $searchText) {
            code
            success
            count
            results{
                _id
                code
                description
            }
        }
    }
`;

export const GET_SINGLE_DISCIPLINE = gql`
    query($id: ID!) {
        GetDiscipline(id: $id) {
            code
            success
            message
            result {
                _id
                code
                description
            }
        }
    }
`;

export const CREATE_DISCIPLINE = gql`
    mutation($data: DisciplineInput!){
        CreateDiscipline(data: $data){
            code
            success
            message
        }
    }
`;

export const UPDATE_DISCIPLINE = gql`
    mutation($id: ID!, $data: DisciplineInput!){
        UpdateDiscipline(id: $id, data: $data){
            code
            success
            message
        }
    }
`;

export const DELETE_DISCIPLINE = gql`
    mutation($id: ID!){
        DeleteDiscipline(id: $id){
            code
            success
            message
        }
    }
`;