import gql from "graphql-tag";

export const CREATE_CHILD_PERMIT = gql`
  mutation ($data: ChildPermitInput!) {
    CreateChildPermit(data: $data) {
      code
      success
      message
    }
  }
`;

export const GET_MY_CHILD_PERMITS = gql`
  query ($limit: Int, $offset: Int, $searchText: String, $disciplineName: [String]) {
    GetChildPermits(limit: $limit, offset: $offset, searchText: $searchText, disciplineName: $disciplineName) {
      code
      success
      message
      results
      count
      isFinalApprover
    }
  }
`;

export const GET_MY_SUBMITTED_CHILD_PERMITS = gql`
  query ($limit: Int, $offset: Int, $searchText: String, $disciplineName: [String]) {
    GetMyChildPermits(limit: $limit, offset: $offset, searchText: $searchText, disciplineName: $disciplineName) {
      code
      success
      message
      results
      count
    }
  }
`;

export const GET_CHILD_PERMIT = gql`
  query ($id: ID!) {
    GetChildPermit(id: $id) {
      code
      success
      message
      permit
      isFinalApprover
    }
  }
`;

export const GET_CHILD_PERMITS = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $disciplineName: [String]
    $statusCheck: [String]
  ) {
    GetChildPermits(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      disciplineName: $disciplineName
      statusCheck: $statusCheck
    ) {
      code
      success
      message
      results
      count
    }
  }
`;

export const GET_CHILD_PENDING_FINAL_APPROVAL_LSS_PERMITS = gql`
  query ($limit: Int, $offset: Int, $searchText: String) {
    GetChildFinalPendingApprovalLSSPermits(limit: $limit, offset: $offset, searchText: $searchText) {
      code
      success
      message
      results
      count
    }
  }
`;

export const GET_CHILD_NEXT_APPROVAL_PERMITS = gql`
  query ($limit: Int, $offset: Int, $searchText: String) {
    GetMyNextPendingApprovalChildPermits(limit: $limit, offset: $offset, searchText: $searchText) {
      code
      success
      message
      results
      count
    }
  }
`;

export const GET_CHILD_NEXT_APPROVAL_PERMITS_COUNT = gql`
  query ($limit: Int, $offset: Int, $searchText: String) {
    GetMyNextPendingApprovalChildPermitsCount(limit: $limit, offset: $offset, searchText: $searchText) {
      code
      success
      message
      count
    }
  }
`;

export const UPDATE_CHILD_PERMIT = gql`
  mutation ($id: ID!, $submission: String, $status: PermitStatus, $parentID: ID!, $formId: ID!) {
    UpdateChildPermit(id: $id, submission: $submission, status: $status, parentID: $parentID, formId: $formId) {
      code
      success
      message
    }
  }
`;

export const RECALL_CHILD_PERMIT = gql`
  mutation ($id: ID!, $submission: String, $status: PermitStatus, $parentID: ID!, $formId: ID!) {
    RecallChildPermit(id: $id, submission: $submission, status: $status, parentID: $parentID, formId: $formId) {
      code
      success
      message
    }
  }
`;

export const UPDATE_CHILD_PERMIT_STATUS = gql`
  mutation ($id: ID!, $status: PermitStatus, $reason: String) {
    UpdateChildPermitStatus(id: $id, status: $status, reason: $reason) {
      code
      success
      message
    }
  }
`;

export const UPDATE_CHILD_PERMIT_REVIEWERS = gql`
  mutation ($id: ID!, $newIndividualReviewers: [String], $newMTGroups: [String]) {
    UpdateChildPermitReviewers(id: $id, newIndividualReviewers: $newIndividualReviewers, newMTGroups: $newMTGroups) {
      code
      success
      message
    }
  }
`;

export const REMOVE_CHILD_INDIVIDUAL_REVIEWER = gql`
  mutation ($id: ID!, $userId: ID!) {
    UpdateRemoveChildIndividualReviewer(id: $id, userId: $userId) {
      code
      success
      message
    }
  }
`;

export const REMOVE_CHILD_MTGROUP_REVIEWER = gql`
  mutation ($id: ID!, $mtGroupId: ID!) {
    UpdateRemoveChildMTGroupReviewer(id: $id, mtGroupId: $mtGroupId) {
      code
      success
      message
    }
  }
`;
