import { UserRole } from "../config/constants";
import {
  AllMaps,
  ForgotPassword,
  ResetPassword,
  SignIn,
  SignUp,
  Dashboard,
  AllSites,
  ChangePassword,
  CreateUser,
  EditUser,
  AllUsers,
  SingleUser,
  AllTemplates,
  CreateTemplate,
  EmailVerification,
  AllPermits,
  EditTemplate,
  CreateSite,
  CreatePermit,
  MyProfile,
  SinglePermit,
  UpdateProfile,
  Groups,
  CreateNewGroup,
  ReviewPermit,
  SSOLogin,
  FormDownloads,
  AllMyPermits,
  AllPendingClosureCancelPermits,
  AllMyDraftPermits,
  SingleDraftPermit,
  ReviewDraftPermit,
  ChangeLogs,
  CreateMap,
  EditMap,
  AllChildPermits,
  AllMyChildPermits,
  CreateChildPermit,
  ReviewChildPermit,
  SingleChildPermit,
  AllMyPermitsPendingApproval,
  EditGroup,
  AllFinalApprovalLSSPermits,
  AllPermitsIApproved,
  AllMyPendingApprovalChildPermits,
  EptwInitiatives,
  SippWorks,
  AllDisciplines,
  CreateDiscipline
} from "../pages";
import { Redirect } from "react-router-dom";
import { EditDiscipline } from "../pages/EditDiscipline";

// Login or Sign in Routes
export const Auth = [
  { path: "/signin", exact: true, component: SignIn },
  { path: "/signup", exact: true, component: SignUp },
  { path: "/forgot-password", exact: true, component: ForgotPassword },
  { path: "/reset-password", exact: true, component: ResetPassword },
];

// Public Routes
export const Public = [
  { path: "/email-verification", exact: true, component: EmailVerification },
  { path: "/ssologin", exact: true, component: SSOLogin },
];

// Private Routes
export const Private = [
  {
    path: "/",
    component: () => <Redirect to="/permits" />,
    exact: true,
  },
  {
    path: "/sites",
    component: AllSites,
    exact: true,
    role: [UserRole.SuperAdmin],
  },
  {
    path: "/maps",
    component: AllMaps,
    exact: true,
    role: [UserRole.SuperAdmin],
  },
  {
    path: "/maps/create-map",
    component: CreateMap,
    exact: true,
    role: [UserRole.SuperAdmin],
  },
  {
    path: "/maps/edit-map/:id",
    component: EditMap,
    exact: true,
    role: [UserRole.SuperAdmin],
  },
  {
    path: "/sites/create-site",
    component: CreateSite,
    exact: true,
    role: [UserRole.SuperAdmin],
  },
  {
    path: "/users",
    component: AllUsers,
    exact: true,
    role: [UserRole.SuperAdmin, UserRole.SiteAdmin],
  },
  {
    path: "/disciplines",
    component: AllDisciplines,
    exact: true,
    role: [UserRole.SuperAdmin, UserRole.SiteAdmin],
  },
  {
    path: "/disciplines/create-discipline",
    component: CreateDiscipline,
    exact: true,
    role: [UserRole.SuperAdmin, UserRole.SiteAdmin],
  },
  {
    path: "/disciplines/edit-discipline/:id",
    component: EditDiscipline,
    exact: true,
    role: [UserRole.SuperAdmin, UserRole.SiteAdmin],
  },
  {
    path: "/disciplines/:id",
    component: SingleUser,
    exact: true,
    role: [UserRole.SuperAdmin, UserRole.SiteAdmin],
  },
  {
    path: "/mtgroup",
    component: Groups,
    exact: true,
    role: [UserRole.SuperAdmin, UserRole.SiteAdmin],
  },
  {
    path: "/mtgroup/create-group",
    component: CreateNewGroup,
    exact: true,
    role: [UserRole.SuperAdmin, UserRole.SiteAdmin],
  },
  {
    path: "/mtgroup/edit-group/:id",
    component: EditGroup,
    exact: true,
    role: [UserRole.SuperAdmin, UserRole.SiteAdmin],
  },
  {
    path: "/users/create-user",
    component: CreateUser,
    exact: true,
    role: [UserRole.SuperAdmin, UserRole.SiteAdmin],
  },
  {
    path: "/users/edit-user/:id",
    component: EditUser,
    exact: true,
    role: [UserRole.SuperAdmin, UserRole.SiteAdmin],
  },
  {
    path: "/users/:id",
    component: SingleUser,
    exact: true,
    role: [UserRole.SuperAdmin, UserRole.SiteAdmin],
  },
  {
    path: "/permits",
    component: AllPermits,
    exact: true,
    role: [
      UserRole.SuperAdmin,
      UserRole.SiteAdmin,
      UserRole.MTGroup,
      UserRole.ContractorPM,
      UserRole.ContractorRequestor,
      UserRole.MicronSupervisor,
      UserRole.MicronManager,
      UserRole.User,
    ],
    // role: [UserRole.User],
  },
  {
    path: "/all-my-permits",
    component: AllMyPermits,
    exact: true,
    role: [UserRole.SuperAdmin, UserRole.SiteAdmin, UserRole.MTGroup],
    // role: [UserRole.User],
  },
  {
    path: "/permits-pending-close-cancel",
    component: AllPendingClosureCancelPermits,
    exact: true,
    role: [UserRole.SuperAdmin, UserRole.SiteAdmin, UserRole.MTGroup],
    // role: [UserRole.User],
  },
  {
    path: "/permits/create-permit",
    component: CreatePermit,
    exact: true,
    // role: [UserRole.User],
  },
  {
    path: "/permits/review/:id",
    component: ReviewPermit,
    exact: true,
  },
  {
    path: "/permits/:id",
    component: SinglePermit,
    exact: true,
  },
  {
    path: "/my-profile",
    component: MyProfile,
    exact: true,
  },

  {
    path: "/update-profile",
    component: UpdateProfile,
    exact: true,
  },

  {
    path: "/change-password",
    component: ChangePassword,
    exact: true,
  },

  {
    path: "/templates",
    component: AllTemplates,
    exact: true,
    role: [UserRole.SuperAdmin],
  },
  {
    path: "/templates/create-template",
    component: CreateTemplate,
    exact: true,
    role: [UserRole.SuperAdmin],
  },
  {
    path: "/templates/edit-template/:id",
    component: EditTemplate,
    exact: true,
    role: [UserRole.SuperAdmin],
  },

  {
    path: "/forms-download",
    component: FormDownloads,
    exact: true,
  },
  {
    path: "/my-draft-permits",
    component: AllMyDraftPermits,
    exact: true,
  },
  {
    path: "/draftpermit/:id",
    component: SingleDraftPermit,
    exact: true,
  },
  {
    path: "/draftpermit/review/:id",
    component: ReviewDraftPermit,
    exact: true,
  },
  {
    path: "/changelogs",
    component: ChangeLogs,
    exact: true,
  },
  {
    path: "/childpermits",
    component: AllChildPermits,
    exact: true,
    role: [
      UserRole.SuperAdmin,
      UserRole.SiteAdmin,
      UserRole.MTGroup,
      UserRole.ContractorPM,
      UserRole.ContractorRequestor,
      UserRole.MicronSupervisor,
      UserRole.MicronManager,
      UserRole.User,
    ],
  },
  {
    path: "/my-child-permit",
    component: AllMyChildPermits,
    exact: true,
    role: [UserRole.SuperAdmin, UserRole.SiteAdmin, UserRole.MTGroup],
    // role: [UserRole.User],
  },
  {
    path: "/childpermits/create-child-permit",
    component: CreateChildPermit,
    exact: true,
  },
  {
    path: "/childpermits/review/:id",
    component: ReviewChildPermit,
    exact: true,
  },
  {
    path: "/childpermits/:id",
    component: SingleChildPermit,
    exact: true,
  },
  {
    path: "/my-pending-approval-permits",
    component: AllMyPermitsPendingApproval,
    exact: true,
  },
  {
    path: "/lss-tab-permits",
    component: AllFinalApprovalLSSPermits,
    exact: true,
  },
  {
    path: "/permits-i-approved",
    component: AllPermitsIApproved,
    exact: true,
  },
  {
    path: "/my-child-permits-pending-approval",
    component: AllMyPendingApprovalChildPermits,
    exact: true,
  },
  {
    path: "/initiatives",
    component: EptwInitiatives,
    exact: true,
    role: [
      UserRole.SuperAdmin,
      UserRole.SiteAdmin,
      UserRole.MTGroup,
      UserRole.ContractorPM,
      UserRole.ContractorRequestor,
      UserRole.MicronSupervisor,
      UserRole.MicronManager,
      UserRole.User,
    ],
  },
  {
    path: "/sipp-works",
    component: SippWorks,
    exact: true,
    role: [
      UserRole.SuperAdmin,
      UserRole.SiteAdmin,
      UserRole.MTGroup,
      UserRole.ContractorPM,
      UserRole.ContractorRequestor,
      UserRole.MicronSupervisor,
      UserRole.MicronManager,
      UserRole.User,
    ],
  },
];
