import { Checkbox, Modal } from "antd";
import React, { useState } from "react";
import { Fragment } from "react";
import { InputModal } from "./InputModal";
import { SectionModal } from "./SectionModal";

export const TemplateSection = ({ section, onDeleteSection }) => {
  const [visible, setVisible] = useState(false);
  const [editSection, setEditSection] = useState(false);
  const [sectionTitle, setSectionTitle] = useState(false);
  const initialState = {
    name: "",
    placeholder: "",
    label: "",
    type: "text",
    col: "6",
    required: false,
    options: []
  };

  const onCancel = () => {
    setVisible(false);
  };

  const onCancelSectionEdit = () => {
    setEditSection(false);
  };

  const onEditSection = () => {
    setEditSection(true);
    setSectionTitle(section.title);
  };

  const onUpdateSection = () => {
    Object.assign(section, { title: sectionTitle });
    setSectionTitle("");
    setEditSection(false);
  };

  const onAddForm = (values) => {
    // console.log(values)
    section.form.push(values);
    setVisible(false);
  };

  const onDeleteItem = (index) => {
    section.form.splice(index, 1);
    Object.assign(section, { form: section.form });
  };

  const emptyBox = (
    <i
      className="text-center fas fa-plus-circle cursor-pointer"
      style={{ fontSize: 20, border: "1px dashed #ddd", padding: 10 }}
      onClick={() => setVisible(true)}
    ></i>
  );

  return (
    <div className="card full-details" style={styles.section} id="SectionCard">
      <h2>{section.title}</h2>
      <div style={styles.actions} className="section-action">
        {/* <i className="text-center fas fa-expand-arrows cursor-pointer px-2" onClick={onEditSection}></i> */}
        <i className="text-center fas fa-pen-alt cursor-pointer px-2" onClick={onEditSection}></i>
        <i className="text-center fas fa-times cursor-pointer px-2" onClick={onDeleteSection}></i>
      </div>
      <div className="form-row mb-4">
        {section.form?.map((form, index) => (
          <InputItem item={form} onDelete={() => onDeleteItem(index)} />
        ))}
      </div>

      {emptyBox}

      <SectionModal
        visible={editSection}
        onCancel={onCancelSectionEdit}
        title={sectionTitle}
        setTitle={setSectionTitle}
        onOk={onUpdateSection}
      />

      <InputModal visible={visible} title="Section" onCancel={onCancel} onOk={onAddForm} initialState={initialState} />
    </div>
  );
};

const InputItem = ({ item, onDelete }) => {
  const [visible, setVisible] = useState(false);

  const onUpdate = (values) => {
    Object.assign(item, values);
    setVisible(false);
  };

  const onCancel = () => {
    setVisible(false);
  };

  // console.log(item)

  return (
    <Fragment>
      <div className={`form-group col-md-${item.col}`} id="InputItem">
        <div style={styles.actions} className="input-action">
          <i className="text-center fas fa-pen-alt cursor-pointer px-2" onClick={() => setVisible(true)}></i>
          <i className="text-center fas fa-times cursor-pointer px-2" onClick={onDelete}></i>
        </div>
        <input type="name" className="form-control" value={item.label} disabled={true} />
      </div>
      <InputModal visible={visible} title="Section" onCancel={onCancel} onOk={onUpdate} initialState={item} />
    </Fragment>
  );
};

const styles = {
  section: {
    position: "relative",
  },
  actions: {
    position: "absolute",
    top: -15,
    right: 0,
    background: "#fff",
    padding: 5,
  },
};
