const react_env = process.env.REACT_APP_NODE_ENV;

let ORIGIN = "localhost:5009/graphql";
let GRAPHQL_URL_CONSTANT = `http://${ORIGIN}`;

if (react_env === "development") {
  ORIGIN = "myost.app/graphql";
  GRAPHQL_URL_CONSTANT = `https://${ORIGIN}`;
} else if (react_env === "production") {
  ORIGIN = "f10microneptw.com/graphql";
  GRAPHQL_URL_CONSTANT = `https://${ORIGIN}`;
} else if (react_env === "SIT") {
  ORIGIN = "13.215.166.94:5009/graphql";
  GRAPHQL_URL_CONSTANT = `http://${ORIGIN}`;
} else if (react_env === "STAGING") {
  ORIGIN = "54.251.169.247:5009/graphql";
  GRAPHQL_URL_CONSTANT = `http://${ORIGIN}`;
}

export const GRAPHQL_URL = GRAPHQL_URL_CONSTANT;
export const GRAPHQL_URL_WS = `ws://${ORIGIN}`;

export const UserRole = {
  SuperAdmin: "SuperAdmin",
  SiteAdmin: "SiteAdmin",
  MTGroup: "MTGroup", // <-- All Micron Members should be in this role? No.
  ContractorPM: "ContractorPM",
  ContractorRequestor: "ContractorRequestor",
  MicronSupervisor: "MicronSupervisor", // remove?
  MicronManager: "MicronManager", // remove?
  User: "User", // <-- Will be the default role when they signed in using SSO
};

export const UserStatus = { Pending: "Pending", Approved: "Approved", Rejected: "Rejected" };
export const PermitStatus = {
  Pending: "Pending",
  Approved: "Approved",
  Rejected: "Rejected",
  PendingApproval: "PendingApproval",
  Completed: "Completed",
  Cancelled: "Cancelled",
  PendingClosure: "PendingClosure",
  PendingCancel: "PendingCancel",
  DraftPermit: "DraftPermit",
};

export const StatusColor = {
  Pending: "orange",
  Approved: "green",
  Rejected: "red",
  PendingApproval: "orange",
  Completed: "#108ee9",
  Cancelled: "#de0202",
  PendingClosure: "#FFA500",
  PendingCancel: "#FFD000",
};

export const PermitType = {
  G: "General",
  S: "SIPP",
  H: "Hotwork",
  W: "Work At height",
  C: "Confined Space",
  L: "Lifting",
  LSS: "Life Support System",
  LE: "Live Electrical",
  // A: "" // <-- For A Permits? Or Default Permit Type?
};

export const __data = [
  { fileName: "01-Risk Assessment_v2.pdf", lastUpdated: "26 July 2021" },
  { fileName: "02-Ladder Inspection Checklist.pdf", lastUpdated: "29 March 2021" },
  { fileName: "03-AirReceiver Compressor Generator Checklist.pdf", lastUpdated: "29 March 2021" },
  { fileName: "04-Excavation Checklist.pdf", lastUpdated: "29 March 2021" },
  { fileName: "05-Scaffold Checklist.pdf", lastUpdated: "07 Oct 2021" },
  { fileName: "06-Raised Metal Flooring Checklist_v2.pdf", lastUpdated: "26 July 2021" },
  { fileName: "07-SRDSLD Checklist.pdf", lastUpdated: "29 March 2021" },
];

export const S3_BASE_URL = "https://f10eptw-prod.s3.ap-southeast-1.amazonaws.com";

export const userGuides = [
  {
    fileName: "User Guide v2.1.pdf",
    lastUpdated: "18 Nov 2021",
    s3Url: "User Guide v2.1.pdf", // not yet in use
  },
];

export const youtubeLinks = [
  {
    fileName: "Introduction ePTW",
    lastUpdated: "26 April 2021",
    url: "https://youtu.be/PDocnuga8gg?list=PL5PDuXzubENCFFOjwvEvy5tKHRw_rGpyv",
  },
  {
    fileName: "ePTW User Account",
    lastUpdated: "26 April 2021",
    url: "https://youtu.be/GkbqgL9fSBY?list=PL5PDuXzubENCFFOjwvEvy5tKHRw_rGpyv",
  },
  {
    fileName: "User Guide",
    lastUpdated: "26 April 2021",
    url: "https://youtu.be/zMAupvLj618?list=PL5PDuXzubENCFFOjwvEvy5tKHRw_rGpyv",
  },
  {
    fileName: "Logging In",
    lastUpdated: "27 April 2021",
    url: "https://youtu.be/aCxOeesOYLk?list=PL5PDuXzubENCFFOjwvEvy5tKHRw_rGpyv",
  },
  {
    fileName: "Change Password and Update Name",
    lastUpdated: "27 April 2021",
    url: "https://youtu.be/REqE758OMG0?list=PL5PDuXzubENCFFOjwvEvy5tKHRw_rGpyv",
  },
  {
    fileName: "Apply Permit Parent Permit",
    lastUpdated: "28 April 2021",
    url: "https://youtu.be/xFmbxWZR-bU?list=PL5PDuXzubENCFFOjwvEvy5tKHRw_rGpyv",
  },
  {
    fileName: "Apply Children Permit",
    lastUpdated: "28 April 2021",
    url: "https://youtu.be/x6O8DcKXAgk?list=PL5PDuXzubENCFFOjwvEvy5tKHRw_rGpyv",
  },
  {
    fileName: "Permit Approval",
    lastUpdated: "28 April 2021",
    url: "https://youtu.be/XKoYItFzE8I?list=PL5PDuXzubENCFFOjwvEvy5tKHRw_rGpyv",
  },
  {
    fileName: "Micron ePTW Reviewer and Approval",
    lastUpdated: "26 April 2021",
    url: "https://youtu.be/T0EErJwVYMM?list=PL5PDuXzubENCFFOjwvEvy5tKHRw_rGpyv",
  },
  {
    fileName: "Create Child Permit",
    lastUpdated: "26 July 2021",
    url: "https://youtu.be/cQIIIb26XRU?list=PL5PDuXzubENCFFOjwvEvy5tKHRw_rGpyv",
  },
];

export const PermitStatusArray = [
  { text: "Pending Approval by PM", value: "Pending" },
  { text: "PendingApproval by Reviewers", value: "PendingApproval" },
  { text: "Approved", value: "Approved" },
  { text: "PendingClosure", value: "PendingClosure" },
  { text: "PendingCancel", value: "PendingCancel" },
  { text: "Completed", value: "Completed" },
  { text: "Cancelled", value: "Cancelled" },
  { text: "Rejected", value: "Rejected" },
];

export const InterlockDisclaimer = (
  <p>
    Dear Requester, as you have 3 or more Pending Closure permits, you are not allowed to create new permits. Please
    ensure your permits are all closed in order to create new permits.
  </p>
);

export const UnclosedPermitDisclaimer = (
  <p>
    Dear Requester, as you have 2 or more unclosed permits, you are not allowed to create new permits. Please ensure
    your permits are all closed in order to create new permits.
  </p>
);

export const UnfilledRequiredFieldsToolTip = (
  <p>Some required fields are empty. Please fill out the fields before you continue.</p>
);

export const deleteButtonToolTip = <span>Interlock Update: Deleting Required Uploaded File is not allowed...</span>;
