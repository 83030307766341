import { useMutation } from "@apollo/react-hooks";
import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CustomButton } from "../components/Shared";
import { TemplateSection, SectionModal } from "../components/Template";
import { PermitType } from "../config";
import { CREATE_TEMPLATE, UPDATE_TEMPLATE } from "../graphql/modules";
import { successNotify, warnNotify } from "../util";

export const CreateTemplate = ({ editData = null }) => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [sectionTitle, setSectionTitle] = useState("");
  const [template, setTemplate] = useState({
    type: "G",
    title: "",
    sections: [],
  });

  useEffect(() => {
    if (editData) {
      setTemplate({
        title: editData.title,
        sections: editData.sections,
        type: editData.type || "",
      });
    }
  }, [editData]);

  const onChange = (e) => setTemplate({ ...template, [e.target.name]: e.target.value });

  const onCancelModal = () => {
    setVisible(false);
    setSectionTitle("");
  };

  const onAddSection = () => {
    setSectionTitle("");
    template.sections.push({
      title: sectionTitle,
      form: [],
    });
    setVisible(false);
  };

  const onDeleteSection = (index) => {
    console.log(index);
    template.sections.splice(index, 1);
    setTemplate({
      ...template,
      sections: template.sections,
    });
  };

  const [UpdateTemplateMutation, { loading: updateLoading }] = useMutation(UPDATE_TEMPLATE, {
    variables: {
      id: editData?._id,
      data: template,
    },
  });

  const [CreateTemplateMutation, { loading }] = useMutation(CREATE_TEMPLATE, {
    variables: {
      data: template,
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    if (editData) {
      handleUpdate();
    } else {
      handleCreate();
    }
  };

  const handleCreate = async () => {
    try {
      const {
        data: { CreateTemplate },
      } = await CreateTemplateMutation();
      if (CreateTemplate.success) {
        successNotify(CreateTemplate.message);
        history.push("/templates");
      } else {
        warnNotify(CreateTemplate.message);
      }
    } catch (error) {
      console.log(error);
      warnNotify(error.message);
    }
  };

  const handleUpdate = async () => {
    try {
      const {
        data: { UpdateTemplate },
      } = await UpdateTemplateMutation();
      if (UpdateTemplate.success) {
        successNotify(UpdateTemplate.message);
        history.push("/templates");
      } else {
        warnNotify(UpdateTemplate.message);
      }
    } catch (error) {
      console.log(error);
      warnNotify(error.message);
    }
  };

  // const disabled = !template.title || template.sections.length === 0 || !template.type;
  const disabled = !template.title || template.sections.length === 0;

  return (
    <section>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="form-row">
              <div className="col-md-6">
                <input
                  type="name"
                  className="form-control"
                  placeholder="Enter form title"
                  name="title"
                  value={template.title}
                  onChange={onChange}
                />
              </div>
              <div className="col-md-6">
                <select name="col" className="form-control" name="type" value={template.type} onChange={onChange}>
                  <option value="">Select Permit Type</option>
                  {Object.keys(PermitType).map((key) => (
                    <option value={key}>{PermitType[key]}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <h3 className="text-center legend">
            <hr className="left" />
            Form
            <hr className="right" />
          </h3>

          {template.sections?.map((section, index) => (
            <TemplateSection
              key={index}
              section={section}
              index={index}
              onDeleteSection={() => onDeleteSection(index)}
            />
          ))}

          <div
            className="w-100 text-center cursor-pointer "
            style={{ fontSize: 20, border: "1px dashed #ddd", padding: 10 }}
            onClick={() => setVisible(true)}
          >
            <i className="fas fa-plus-circle mr-2"></i>
            Add Section
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <div className="text-right">
            <CustomButton
              className="btn btn-danger"
              loading={loading || updateLoading}
              disabled={disabled || loading || updateLoading}
              onClick={onSubmit}
            >
              {editData ? "Update Form" : "Create Form"}
            </CustomButton>
          </div>
        </div>
      </div>
      <SectionModal
        visible={visible}
        onCancel={onCancelModal}
        onOk={onAddSection}
        title={sectionTitle}
        setTitle={setSectionTitle}
      />
    </section>
  );
};
