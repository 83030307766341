import React, { useState, useEffect, Fragment } from "react";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

import { DatePicker, Button, Spin } from "antd";

import { GET_EPTW_INITIATIVES_DATA } from "../graphql/modules";

import treeImg from "../res/img/tree-5076.png";

const F10A1_BAR_COLOR = "#2980B9"; // January
const F10A2_BAR_COLOR = "#1ABC9C"; // February
const F10AN_BAR_COLOR = "#F1C40F"; // March
const F10NX_BAR_COLOR = "#F39C12"; // April
const F10W_BAR_COLOR = "##9B59B6"; // May
const F10X_BAR_COLOR = "#DAF7A6"; // June
const VIVID_SKY_BLUE = "#49C6E5"; // July
const SKY_BLUE_CRAYOLA = "#54DEFD"; // August
const MIDDLE_BLUE_GREEN = "#8BD7D2"; // September
const WILD_BLUE_YONDER = "#93A3BC"; // October
const BEAU_BLUE = "#CCDDE2"; // November
const DARK_ELECTRIC_BLUE = "#3E5C76"; // December

export const EptwInitiatives = () => {
  const [currentWeek, setWeek] = useState("");
  const [currentMonth, setMonth] = useState("");
  const [currentYear, setYear] = useState("");

  let siteList = ["F10A1", "F10A2", "F10N", "F10NX", "F10W", "F10X"];

  function onChangeYear(date, dateString) {
    console.log("onchange year", dateString);
    setYear(dateString);
  }

  function onClickFilterSiteButton() {
    getEptwInitiativesData({
      variables: {
        permitSite: "ALL",
        currentWeek: currentWeek,
        currentMonth: currentMonth,
        currentYear: currentYear,
      },
    });
  }

  const [getEptwInitiativesData, { data: GraphData, loading: GraphLoading }] = useLazyQuery(GET_EPTW_INITIATIVES_DATA, {
    variables: {
      permitSite: "ALL",
      currentWeek: "",
      currentMonth: "",
      currentYear: currentYear,
    },
    fetchPolicy: "cache-and-network",
  });

  const permitfilter = (site, month) => {
    let permitfilter;

    permitfilter = graphDataFiltering(month);

    console.log("permitfilter?.[0]:\n" + JSON.stringify(permitfilter?.[0], null, 2));

    let a;
    if (permitfilter !== undefined || GraphData?.GetEptwInitiativesData?.data !== undefined) {
      if (permitfilter?.[0]?.month === month) {
        if (site === "F10A1") {
          a = permitfilter?.[0]?.f10a1Permits;
        }

        if (site === "F10A2") {
          a = permitfilter?.[0]?.f10a2Permits;
        }

        if (site === "F10N") {
          a = permitfilter?.[0]?.f10nPermits;
        }

        if (site === "F10NX") {
          a = permitfilter?.[0]?.f10nxPermits;
        }

        if (site === "F10W") {
          a = permitfilter?.[0]?.f10wPermits;
        }

        if (site === "F10X") {
          a = permitfilter?.[0]?.f10xPermits;
        }
      }
    }
    console.log("a: \n" + JSON.stringify(a, null, 2));
    return a;
  };

  const graphDataFiltering = (month) => {
    let sitefilter;
    sitefilter = GraphData?.GetEptwInitiativesData?.data
      ?.map((o) => o)
      .filter((test) => {
        return test.month === month;
      });

    console.log("sitefilter: \n" + JSON.stringify(sitefilter, null, 2));
    return sitefilter;
  };

  const dataSetForAnalytics = {
    labels: siteList,
    datasets: [
      {
        //stack: "stack1",
        backgroundColor: F10A1_BAR_COLOR,
        borderColor: F10A1_BAR_COLOR,
        label: "January",
        borderWidth: 1,
        hoverBackgroundColor: F10A1_BAR_COLOR,
        hoverBorderColor: F10A1_BAR_COLOR,
        data: [
          permitfilter("F10A1", "January"),
          permitfilter("F10A2", "January"),
          permitfilter("F10N", "January"),
          permitfilter("F10NX", "January"),
          permitfilter("F10W", "January"),
          permitfilter("F10X", "January"),
        ],
      },
      {
        //stack: "stack1",
        backgroundColor: F10A2_BAR_COLOR,
        borderColor: F10A2_BAR_COLOR,
        label: "February",
        borderWidth: 1,
        hoverBackgroundColor: F10A2_BAR_COLOR,
        hoverBorderColor: F10A2_BAR_COLOR,
        data: [
          permitfilter("F10A1", "February"),
          permitfilter("F10A2", "February"),
          permitfilter("F10N", "February"),
          permitfilter("F10NX", "February"),
          permitfilter("F10W", "February"),
          permitfilter("F10X", "February"),
        ],
      },
      {
        //stack: "stack1",
        backgroundColor: F10AN_BAR_COLOR,
        borderColor: F10AN_BAR_COLOR,
        label: "March",
        borderWidth: 1,
        hoverBackgroundColor: F10AN_BAR_COLOR,
        hoverBorderColor: F10AN_BAR_COLOR,
        data: [
          permitfilter("F10A1", "March"),
          permitfilter("F10A2", "March"),
          permitfilter("F10N", "March"),
          permitfilter("F10NX", "March"),
          permitfilter("F10W", "March"),
          permitfilter("F10X", "March"),
        ],
      },
      {
        backgroundColor: F10NX_BAR_COLOR,
        borderColor: F10NX_BAR_COLOR,
        label: "April",
        borderWidth: 1,
        hoverBackgroundColor: F10NX_BAR_COLOR,
        hoverBorderColor: F10NX_BAR_COLOR,
        data: [
          permitfilter("F10A1", "April"),
          permitfilter("F10A2", "April"),
          permitfilter("F10N", "April"),
          permitfilter("F10NX", "April"),
          permitfilter("F10W", "April"),
          permitfilter("F10X", "April"),
        ],
      },
      {
        backgroundColor: F10W_BAR_COLOR,
        borderColor: F10W_BAR_COLOR,
        label: "May",
        borderWidth: 1,
        hoverBackgroundColor: F10W_BAR_COLOR,
        hoverBorderColor: F10W_BAR_COLOR,
        data: [
          permitfilter("F10A1", "May"),
          permitfilter("F10A2", "May"),
          permitfilter("F10N", "May"),
          permitfilter("F10NX", "May"),
          permitfilter("F10W", "May"),
          permitfilter("F10X", "May"),
        ],
      },
      {
        backgroundColor: F10X_BAR_COLOR,
        borderColor: F10X_BAR_COLOR,
        label: "June",
        borderWidth: 1,
        hoverBackgroundColor: F10X_BAR_COLOR,
        hoverBorderColor: F10X_BAR_COLOR,
        data: [
          permitfilter("F10A1", "June"),
          permitfilter("F10A2", "June"),
          permitfilter("F10N", "June"),
          permitfilter("F10NX", "June"),
          permitfilter("F10W", "June"),
          permitfilter("F10X", "June"),
        ],
      },
      {
        backgroundColor: VIVID_SKY_BLUE,
        borderColor: VIVID_SKY_BLUE,
        label: "July",
        borderWidth: 1,
        hoverBackgroundColor: VIVID_SKY_BLUE,
        hoverBorderColor: VIVID_SKY_BLUE,
        data: [
          permitfilter("F10A1", "July"),
          permitfilter("F10A2", "July"),
          permitfilter("F10N", "July"),
          permitfilter("F10NX", "July"),
          permitfilter("F10W", "July"),
          permitfilter("F10X", "July"),
        ],
      },
      {
        backgroundColor: SKY_BLUE_CRAYOLA,
        borderColor: SKY_BLUE_CRAYOLA,
        label: "August",
        borderWidth: 1,
        hoverBackgroundColor: SKY_BLUE_CRAYOLA,
        hoverBorderColor: SKY_BLUE_CRAYOLA,
        data: [
          permitfilter("F10A1", "August"),
          permitfilter("F10A2", "August"),
          permitfilter("F10N", "August"),
          permitfilter("F10NX", "August"),
          permitfilter("F10W", "August"),
          permitfilter("F10X", "August"),
        ],
      },
      {
        backgroundColor: MIDDLE_BLUE_GREEN,
        borderColor: MIDDLE_BLUE_GREEN,
        label: "September",
        borderWidth: 1,
        hoverBackgroundColor: MIDDLE_BLUE_GREEN,
        hoverBorderColor: MIDDLE_BLUE_GREEN,
        data: [
          permitfilter("F10A1", "September"),
          permitfilter("F10A2", "September"),
          permitfilter("F10N", "September"),
          permitfilter("F10NX", "September"),
          permitfilter("F10W", "September"),
          permitfilter("F10X", "September"),
        ],
      },
      {
        backgroundColor: WILD_BLUE_YONDER,
        borderColor: WILD_BLUE_YONDER,
        label: "October",
        borderWidth: 1,
        hoverBackgroundColor: WILD_BLUE_YONDER,
        hoverBorderColor: WILD_BLUE_YONDER,
        data: [
          permitfilter("F10A1", "October"),
          permitfilter("F10A2", "October"),
          permitfilter("F10N", "October"),
          permitfilter("F10NX", "October"),
          permitfilter("F10W", "October"),
          permitfilter("F10X", "October"),
        ],
      },
      {
        backgroundColor: BEAU_BLUE,
        borderColor: BEAU_BLUE,
        label: "November",
        borderWidth: 1,
        hoverBackgroundColor: BEAU_BLUE,
        hoverBorderColor: BEAU_BLUE,
        data: [
          permitfilter("F10A1", "November"),
          permitfilter("F10A2", "November"),
          permitfilter("F10N", "November"),
          permitfilter("F10NX", "November"),
          permitfilter("F10W", "November"),
          permitfilter("F10X", "November"),
        ],
      },
      {
        backgroundColor: DARK_ELECTRIC_BLUE,
        borderColor: DARK_ELECTRIC_BLUE,
        label: "December",
        borderWidth: 1,
        hoverBackgroundColor: DARK_ELECTRIC_BLUE,
        hoverBorderColor: DARK_ELECTRIC_BLUE,
        data: [
          permitfilter("F10A1", "December"),
          permitfilter("F10A2", "December"),
          permitfilter("F10N", "December"),
          permitfilter("F10NX", "December"),
          permitfilter("F10W", "December"),
          permitfilter("F10X", "December"),
        ],
      },
    ],
  };

  let options = {
    responsive: true,
    plugins: {
      datalabels: {
        display: true,
        color: "#303030",
      },
    },
    title: {
      display: true,
      position: "left",
      text: "Kilograms",
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].label || "";
          if (label) {
            label += ": ";
          }
          label += tooltipItem.yLabel + " kgs";
          return label;
        },
      },
    },
  };

  return (
    <>
      <h5 className="mapsectiontitle">ePTW Initiatives</h5>
      <div className="container-fluid">
        <React.Fragment>
          <div className="row dec-row">
            <div className="col-3 col-md-3">
              <DatePicker
                onChange={onChangeYear}
                picker="year"
                placeholder="Select Year"
                style={{ width: "100%" }}
                disabled={GraphLoading}
              />
            </div>
            <div className="col-3 col-md-3">
              <Button
                type="primary"
                style={{ width: "100%" }}
                onClick={onClickFilterSiteButton}
                disabled={GraphLoading}
              >
                Filter
              </Button>
            </div>
            <div className="col-3 col-md-3">
              <Spin tip="Loading data.. Please wait.." hidden={!GraphLoading} />
            </div>
          </div>
          <br />
          <div className="row dec-row">
            <div className="col-9 col-md-9">
              <div className="card">
                <Bar data={dataSetForAnalytics} plugins={[ChartDataLabels]} options={options} />
                <p style={{ textAlign: "center" }}> Site </p>
              </div>
            </div>
            <div className="col-3 col-md-3">
              <div className="col-12 col-md-12 mt-2">
                <div>
                  <br />
                  <br />
                  <h3> 1 Permit = 400G</h3>
                </div>
                <div>
                  <img src={treeImg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
    </>
  );
};
