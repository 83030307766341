import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";
import { SSO_USER_LOGIN } from "../graphql/modules";
import { loginUser } from "../store/modules/auth";
import { errorNotify, successNotify, warnNotify } from "../util";
import { useHistory } from "react-router-dom";

export const SSOLogin = () => {
  console.log("SSO Login");
  let history = useHistory();

  const dispatch = useDispatch();

  const ssoToken = localStorage.getItem("sso-token");
  const ssoObj = JSON.parse(ssoToken);

  //   console.log(ssoObj);

  const [successLogin, setSuccessLogin] = useState(ssoObj);
  const [redirect, setRedirect] = useState("/");

  const onSSOLogin = async () => {
    try {
      console.log("_SSOLogin: " + JSON.stringify(ssoObj, null, 2));

      if (ssoObj.success) {
        // successNotify(_SSOLogin.message);
        console.log("Before dispatch if sso success");
        dispatch(loginUser(ssoObj));
        console.log("After dispatch sso success, loginUser");
        history.push("/permits");
      } else {
        warnNotify(ssoObj.message);
      }
    } catch (err) {
      errorNotify(err.message);
      console.log("M ", err.message);
    }
  };

  useEffect(() => {
    console.log("sso-token:" + JSON.stringify(ssoObj));
    setSuccessLogin(ssoObj);
    onSSOLogin();
  }, [successLogin]);

  if (successLogin === null) {
    return "Loading...";
  }

  return (
    <div className="col-md-5 m-auto">
      Logging you in using your Micron Account Click <a href="/permits">here</a> if it doesn't automatically redirect
      you to your permits page.{" "}
    </div>
  );
};
