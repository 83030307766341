import React, { useCallback, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_USER } from "../graphql/modules";
import { successNotify, warnNotify } from "../util";
import { UserForm } from "../components/User";

export const CreateUser = ({}) => {
  //initial state
  const [state, setState] = useState({
    email: "",
    fullname: "",
    company: "",
    sites: [],
    role: "",
    image: "",
  });

  const [error, setError] = useState(null);
  // CREATE USER
  const [CreateUserMutation, { loading }] = useMutation(CREATE_USER, {
    variables: {
      data: state,
    },
  });

  // SUBMIT HANDLER
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const {
        data: { CreateUser },
      } = await CreateUserMutation();

      if (CreateUser.success) {
        successNotify(CreateUser.message);
        setState({
          email: "",
          fullname: "",
          company: "",
          sites: [],
          role: "",
          image: "",
        });
      } else {
        warnNotify(CreateUser.message);
      }
    } catch (error) {
      warnNotify(error.message);
      console.log(error.message);
    }
  };

  const handleChange = (value) => setState({ ...state, ...value });

  console.log(state)

  return (
    <UserForm
      title="Create New User"
      btnText="Create Account"
      btnPassword=""
      onSubmit={onSubmit}
      loading={loading}
      handleChange={handleChange}
      state={state}
    />
  );
};
