import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Timeline, Spin } from "antd";
import moment from "moment";
import { GET_ALL_CHANGE_LOGS } from "../graphql/modules";

export const ChangeLogs = ({ match }) => {
  const { data, loading } = useQuery(GET_ALL_CHANGE_LOGS);

  const changeLogs = JSON.parse(data?.GetAllChangeLogs?.changelogs || "[]");

  return (
    <>
      <Spin spinning={loading} tip="Getting your change logs list.">
        <div style={{ display: "flex", justifyContent: "left" }}>
          <h2 className="changelogsectiontitle">Change Logs</h2>
        </div>
        <br /> <br />
        <div style={{ display: "flex", justifyContent: "left" }}>
          <Timeline>
            {changeLogs &&
              changeLogs.map((cl) => (
                <Timeline.Item color="green">
                  <p>
                    <b>{moment(cl.dateDeployed).format("YYYY-MM-DD")}</b>
                  </p>
                  <hr />
                  {cl?.logs && cl?.logs.map((log) => <p>{log}</p>)}
                  <hr />
                </Timeline.Item>
              ))}
          </Timeline>
        </div>
      </Spin>
    </>
  );
};
