import { useMutation, useQuery } from "@apollo/react-hooks";
import React from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { UserProfile } from "../components/User";
import { GET_SINGLE_USER, DELETE_USER } from "../graphql/modules";
import { Popconfirm } from "antd";
import { successNotify, warnNotify } from "../util";

export const SingleUser = ({ match, location }) => {
  const { data, loading } = useQuery(GET_SINGLE_USER, {
    variables: {
      id: match.params.id,
    },
    fetchPolicy: "cache-and-network",
  });

  const user = data?.GetUser?.user;

  const [DeleteUserMutation] = useMutation(DELETE_USER);

  let history = useHistory();
  const handleDelete = async (key) => {
    console.log(key);

    try {
      const {
        data: { DeleteUser },
      } = await DeleteUserMutation({
        variables: {
          id: key,
        },
      });

      if (DeleteUser.success) {
        successNotify(DeleteUser.message);
        history.push("/users");
      } else {
        warnNotify(DeleteUser.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div>
      <UserProfile
        loading={loading}
        backTo={{
          path: "/users",
          title: "Back to view all users",
        }}
        user={user}
        actions={
          <div className="account-details-btn">
            <Link to={`/users/edit-user/${user?._id}`} className="btn btn-primary mr-3 rounded-0">
              Edit Profile
            </Link>
            <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(user?._id)}>
              <a className="btn btn-outline-primary rounded-0">Remove</a>
            </Popconfirm>
          </div>
        }
      />
    </div>
  );
};
