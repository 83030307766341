import { Spin, Tag } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

export const UserProfile = ({ loading = false, user, backTo, actions = "" }) => {
  const history = useHistory();
  const onBack = (e) => {
    e.preventDefault();
    history.push(backTo.path);
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          {backTo && (
            <div className="heading">
              <a href="#/" onClick={onBack} className="btn-link">
                <i className="far fa-arrow-left"></i> {backTo?.title}
              </a>
            </div>
          )}
          <Spin spinning={loading}>
            <div className="card">
              <div className="account-details">
                <div className="account-details-colum">
                  <h6>Full Name</h6>
                  <p>{user?.fullname}</p>
                </div>
                <div className="account-details-colum">
                  <h6>Company</h6>
                  <p>{user?.company || "-"}</p>
                </div>
                <div className="account-details-colum">
                  <h6>Email</h6>
                  <p>{user?.email}</p>
                </div>
                <div className="account-details-colum">
                  <h6>Sites</h6>
                  <p>
                    {user?.sites?.map((site) => (
                      <Tag style={{ borderRadius: 10, padding: "0px 10px", marginBottom: 5 }} color="purple">
                        {site?.code}
                      </Tag>
                    ))}
                  </p>
                </div>
                <div className="account-details-colum">
                  <h6>Roles</h6>
                  <p>{user?.role}</p>
                </div>
                {user?.group && (
                  <div className="account-details-colum">
                    <h6>Group</h6>
                    <p>{user?.group?.name || "-"}</p>
                  </div>
                )}
                <div className="account-details-colum">
                  <h6>Mobile Number</h6>
                  <p>{user?.phone || "-"}</p>
                </div>
              </div>
            </div>
          </Spin>
          {actions}
        </div>
      </div>
    </div>
  );
};
