import { USER_LOADING, GET_USERS, GET_USER, ADD_USER, UPDATE_USER, DELETE_USER } from "../../actions/types";

// initial state
const initialState = {
  users: [],
  user: {},
  loading: false,
};

export const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    case GET_USER:
      return {
        ...state,
        user: action.payload,
        loading: false,
      };
    case ADD_USER:
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    case UPDATE_USER:
      console.log("Updating user... Switch-Case");
      return {
        ...state,
        users: [action.payload, ...state.users],
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.payload),
      };
    default:
      return state;
  }
};

export const setUsers = (payload) => async (dispatch) => {
  // await dispatch({
  //   type: USER_LOADING
  // });

  await dispatch({
    type: GET_USERS,
    payload,
  });
  // window.location.reload();
};
