import { useMutation, useQuery } from "@apollo/react-hooks";
import { message, Select, Upload, Button, Spin } from "antd";
import React, { useState, useEffect } from "react";
import { CustomButton } from "../components/Shared";
import { GET_SITE_LIST, GET_SINGLE_MAP, UPDATE_MAP } from "../graphql/modules";
import { UploadOutlined } from "@ant-design/icons";
import { successNotify, warnNotify } from "../util";

export const EditMap = ({ match, history }) => {
  let id = match.params.id;
  const { data, loading } = useQuery(GET_SINGLE_MAP, {
    variables: {
      id: id,
    },
  });

  const { data: SiteList } = useQuery(GET_SITE_LIST, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  const [state, setState] = useState({
    name: "",
    site: "",
    mapImage: "",
  });

  const [UpdateMapMutation, { loading: updateLoading }] = useMutation(UPDATE_MAP, {
    variables: {
      id: id,
      data: state,
    },
  });

  useEffect(() => {
    const { name = "", site = "", mapImage = "" } = data?.GetMapToEdit?.results || {};
    setState({
      name: name || "",
      site: site || "",
      mapImage: mapImage || "",
    });
  }, [data]);

  const onChange = (e) => setState({ ...state, [e.target.name]: e.target.value });

  const onUpdate = async (e) => {
    e.preventDefault();
    try {
      const {
        data: { UpdateMap },
      } = await UpdateMapMutation();
      if (UpdateMap.success) {
        successNotify(UpdateMap.message);
        history.push("/maps");
      } else {
        warnNotify(UpdateMap.message);
      }
    } catch (error) {
      warnNotify(error.message);
    }
  };

  const disable = !state.name;

  return (
    <div>
      <div className="heading"></div>
      <h1 className="title">Edit Map</h1>
      <form onSubmit={onUpdate}>
        <div className="card">
          <div className="create-permit-details">
            <div className="form-row">
              <div className="form-group col-md-6">
                <select name="col" className="form-control" name="site" onChange={onChange} value={state.site}>
                  <option value="">Select Site</option>
                  {SiteList?.GetSites?.results?.map((_site) => (
                    <option key={_site._id} value={_site._id}>
                      {_site.code}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-6">
                <input
                  type="name"
                  className="form-control"
                  placeholder="Name"
                  name="name"
                  onChange={onChange}
                  value={state.name}
                />
              </div>
              {/*<div className="form-group col-md-12">
              <Upload {...props} maxCount={1}>
                  <Button icon={<UploadOutlined />} style={{width: "100%"}}>Upload</Button>
              </Upload>
              <div hidden={isSpinHidden} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Spin tip="Uploading..."></Spin>
              </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="account-details-btn ">
          <CustomButton type="submit" loading={loading} className="btn btn-primary ml-auto">
            Update Map
          </CustomButton>
        </div>
      </form>
    </div>
  );
};
