import { Select } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PermitStatus, StatusColor } from "../../config";
import { CustomButton } from "../Shared";
import { getNextReviewer, warnNotify } from "../../util";
import { Link } from "react-router-dom";
import { useLazyQuery } from "@apollo/react-hooks";
import GeneralPermitPDF from "../Pdf/generalPermit.pdf";
import {
  GET_PDFLOGFILE,
} from "../../graphql/modules";
import moment from "moment";

export const PermitUserInfo = ({ permit = {}, showAction, updateLoading, handleStatusUpdate }) => {
  const { user: authUser } = useSelector((state) => state.auth);
  const [pdfFile, setPdfFile] = useState("");
  const user = permit?.submittedBy;
  const status = permit?.status;
  const reason = permit?.reason;
  const permitCode = permit?.code;

  const lastApprovedBy = permit?.approvedBy?.length ? permit.approvedBy[permit?.approvedBy?.length - 1] : {};

  const rejectedBy = permit.rejectedBy;
  const historyLogs = permit?.logs;
  let showActionBtn = false;

  let showApprovedOrRejectedPermitDiv = false;

  if (status === PermitStatus.Approved || status === PermitStatus.Rejected) {
    showApprovedOrRejectedPermitDiv = true;
  }

  if (showAction !== undefined) {
    showActionBtn = showAction;
  }

  let color = "alert-warning";
  if (status === PermitStatus.Approved) color = "alert-success";
  else if (status === PermitStatus.Rejected) color = "alert-danger";
  else if (status === PermitStatus.Completed) color = StatusColor.Completed;
  else if (status === PermitStatus.Cancelled) color = StatusColor.Cancelled;
  else if (status === PermitStatus.PendingClosure) color = StatusColor.PendingClosure;
  else if (status === PermitStatus.PendingCancel) color = StatusColor.PendingCancel;

  let nextReviewer = getNextReviewer(permit);

  console.log(
    "\n=================================\n" + JSON.stringify(permitCode) + "\n=================================\n"
  );
  console.log("[43] nextReviewer: " + JSON.stringify(nextReviewer, null, 2));


  const [getPdfLog, { data, loading }] = useLazyQuery(GET_PDFLOGFILE, {
    variables: {
      pdfFileName: ""
    },
    fetchPolicy: 'network-only'
  });
  // useEffect(() => {
  //     getPdfLog();
  // }, []);

  useEffect(() => {
  if(data){
    if(data?.GetPdfLogs?.results === "null"){
      warnNotify("No Pdf to Generate!")
      console.log("No Records")
    }else{
      GeneralPermitPDF(JSON.parse(data?.GetPdfLogs?.results || "[]"))
    }

  }
}, [data, loading])


  return (
    <div className="card full-details">
      <div className="account-details">
        <div className="account-details-colum">
          <h6>Full Name</h6>
          <p>{user?.fullname}</p>
        </div>
        <div className="account-details-colum">
          <h6>Company</h6>
          <p>{user?.company || "-"}</p>
        </div>
        <div className="account-details-colum">
          <h6>Permit Number</h6>
          <p>{permitCode}</p>
        </div>
        <div className="account-details-colum">
          <h6>Email</h6>
          <p>{user?.email}</p>
        </div>
        <div className="account-details-colum">
          <h6>Site</h6>
          <p>{permit?.site?.code || "-"}</p>
        </div>
        <div className="account-details-colum">
          <h6>Status</h6>
          {showApprovedOrRejectedPermitDiv ? (
            <div className="pending-btn permit-btn">
              <button className={`btn ${color}`}>
                {status === PermitStatus.Approved
                  ? `Approved By ${lastApprovedBy?.group?.name ? lastApprovedBy?.group?.name : lastApprovedBy.role}`
                  : status === PermitStatus.Rejected
                  ? `Rejected By ${rejectedBy?.group?.name ? rejectedBy?.group?.name : rejectedBy?.role}`
                  : status}
              </button>
              <p>{status === PermitStatus.Rejected ? `Reason: ${reason}` : ""}</p>
            </div>
          ) : status === PermitStatus.PendingApproval ? (
            <div className="pending-btn permit-btn">
              <button className={`btn ${color}`}>Pending Approval by {nextReviewer}</button>
            </div>
          ) : status === PermitStatus.Pending ? (
            <div className="pending-btn permit-btn">
              <button className={`btn ${color}`}>Pending Approval by PM</button>
            </div>
          ) : (
            <div className="pending-btn permit-btn">
              <button className={`btn ${color}`}> {status}</button>
            </div>
          )}
        </div>
        <div className="account-details-colum">
          <h6>History Logs</h6>
          {
            <span style={{ fontSize: "10px" }}>
              {historyLogs &&
                historyLogs.map((result) => (
                  <React.Fragment>
                    *{result}
                    <Link
                      to="#"
                      hidden
                      onClick={(e) => {
                        e.preventDefault();
                        var dateTime = result.split('at ').pop().replace(/:[^:]*$/,'');
                        var userName = result.split(' ')[0];

                        getPdfLog({
                          variables:{
                            pdfFileName: permitCode + "_" + userName + "_" + dateTime
                          }
                        })
                      }}
                    >
                      PDF
                    </Link>
                    <br />
                  </React.Fragment>
                ))}
            </span>
          }
        </div>

        {showActionBtn && (
          <div className="account-details-colum">
            <div className="pending-btn permit-btn">
              <Select
                className={`btn ${color} p-0`}
                value={status}
                onChange={(value) => handleStatusUpdate({ status: value })}
              >
                <Select.Option value="Pending">Pending</Select.Option>
                <Select.Option value="Approved">Approved</Select.Option>
                <Select.Option value="Rejected">Rejected</Select.Option>
              </Select>
            </div>
          </div>
        )}
        {/* {showActionBtn && (
          <div className="account-details-colum pending-btn">
            <CustomButton
              loading={updateLoading && status === PermitStatus.Rejected}
              className="btn btn-danger mr-2"
              onClick={() => handleStatusUpdate({ status: "Rejected" })}
            >
              Reject
            </CustomButton>
            <CustomButton
              loading={updateLoading && status === PermitStatus.Approved}
              className="btn btn-success"
              onClick={() => handleStatusUpdate({ status: "Approved" })}
            >
              Approve
            </CustomButton>
          </div>
        )} */}
      </div>
    </div>
  );
};
