import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { Button, message, Modal, Select, Spin } from "antd";
import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { Divider } from "@material-ui/core";
import { warnNotify } from "../util";
import { Link } from "react-router-dom";
import { PermitSection } from "../components/Permit";
import {
  CREATE_CHILD_PERMIT,
  GET_MY_PERMIT_CODE,
  GET_PERMIT,
  GET_TEMPLATE_LIST,
  GET_GROUP_LIST,
  GET_ALL_MTGROUPS,
  GET_ALL_MICRON_USERS,
  CREATE_DRAFT_PERMIT,
  GET_ALL_DISCIPLINE,
} from "../graphql/modules";

import { validateFulfilledRequiredFields } from "../helpers";
import debounce from "lodash/debounce";
import _ from "lodash";
import { CustomButton } from "../components/Shared";
import { UserRole } from "../config";
const micronEmail = "@micron.com";

export const CreateChildPermit = ({ history }) => {
  const { user: authUser } = useSelector((_state) => _state.auth);

  const [template, setTemplate] = useState(null);
  const [selectedPermit, setGeneralPermit] = useState("");
  // const [userType, setUserType] = useState(null);
  const [showIndividualMtGroup, setShowIndividualMTGroup] = useState(false);
  const [mtgroup, setMTGroup] = useState([]);
  const [users, setUsers] = useState([]);
  const [childtemplate, setChildTemplate] = useState(null);
  const [visibleChildPermit, setVisibleChildPermit] = useState(null);
  const [state, setState] = useState({});
  const [childSubmissionState, setChildSubmissionState] = useState(null);
  const [isFormValid, setIsFormValid] = useState(true);
  const [formState, setFormState] = useState({});
  const [hostSystemOwner, setHostSystemOwner] = useState([]);
  const [discipline, setDiscipline] = useState([]);

  const { data: AllGeneralPermits } = useQuery(GET_MY_PERMIT_CODE, {
    variables: {
      limit: 20,
      offset: 0,
    },
    fetchPolicy: "cache-and-network",
  });
  let generalPermit = JSON.parse(AllGeneralPermits?.GetPermitCode?.results || "[]");

  const { data: GetGeneralPermit, loading: fetchingTemplate } = useQuery(GET_PERMIT, {
    variables: {
      id: selectedPermit,
    },
  });
  let getIndividualPermit = JSON.parse(GetGeneralPermit?.GetPermit?.permit || "[]");

  const { data: MTGroupsList } = useQuery(GET_ALL_MTGROUPS, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  const [getGroups, { data: GroupList, loading: fetchingGroup }] = useLazyQuery(GET_GROUP_LIST, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  const [getUsers, { data: UserList, loading: fetchingUser }] = useLazyQuery(GET_ALL_MICRON_USERS, {
    variables: {
      limit: 1000,
      offset: 0,
      searchText: micronEmail,
    },
  });

  const [getTemplates, { data: TemplateList, loading: fetchingTemplates }] = useLazyQuery(GET_TEMPLATE_LIST);

  const { data: DisciplineList } = useQuery(GET_ALL_DISCIPLINE);

  useEffect(() => {
    if (template === null) {
      getTemplates();
      setTemplate(
        TemplateList?.GetTemplates?.results?.filter((_template) => _template._id !== "60500a1f050c9442a394437c")
      );
    }
  }, [template]);

  // useEffect(() => {
  //   if (template === null) {
  //     getTemplates();
  //     setTemplate(
  //       TemplateList?.GetTemplates?.results?.filter((_template) => _template._id !== "60500a1f050c9442a394437c")
  //     );
  //   }
  // }, template);

  // useEffect(() => {
  //   if (selectedPermit) {
  //     getUsers();
  //     getGroups();
  //   }
  // }, [selectedPermit]);

  const { data, loading, error } = useQuery(GET_TEMPLATE_LIST);

  const handleChildPermit = (_childSubmissionState) => {
    setChildSubmissionState(_childSubmissionState);
  };

  const onSearchUser = React.useMemo(() => {
    const loadOptions = (value) => {
      getUsers({
        variables: {
          limit: 1000,
          offset: 0,
          searchText: value,
        },
      });
    };
    return debounce(loadOptions, 800);
  }, []);

  const onSearchGroup = React.useMemo(() => {
    const loadOptions = (value) => {
      getGroups({
        variables: {
          limit: 1000,
          offset: 0,
          searchText: value,
        },
      });
    };
    return debounce(loadOptions, 800);
  }, []);

  const onChange = (values) => {
    // console.log("values: " + JSON.stringify(values, null, 2));
    // console.log("formState: " + JSON.stringify(formState, null, 2));
    setState({ ...state, ...values });
  };

  const handleFormState = (value) => {
    setFormState(value);
  };

  const onChangeForm = (_v, obj) => {
    setTemplate(obj.template);
  };

  const onChangeChildPermit = (_v, obj) => {
    setChildTemplate(obj);
    // console.log("onChangeChildPermit.obj: " + JSON.stringify(obj[0]?.template?.sections, null, 2));
    obj[0]?.template?.sections?.map((section) => {
      section?.form?.map((frm) => {
        if (frm.required) {
          var obj = {
            name: frm.name,
            required: frm.required,
          };
          temporaryChildFormState.push(obj);
        }
      });
    });
  };

  const onOkChildPermit = () => {
    setVisibleChildPermit(false);
  };

  function getGroupObj(id, groupArr) {
    for (var i = 0; i < groupArr.length; i++) {
      if (groupArr[i]._id === id) {
        return groupArr[i];
      }
    }
  }

  const [CreateChildPermitMutation, { loading: createPermitLoading }] = useMutation(CREATE_CHILD_PERMIT);
  const onSubmit = async (e) => {
    e.preventDefault();
    getGroups();
    let mtGroups = MTGroupsList?.GetAllMTGroups?.results;

    let fState = state !== {} ? state : null;
    let childPermits = childtemplate?.length ? childtemplate?.length : null;
    let fChildState = childSubmissionState !== null ? childSubmissionState : null;

    if (fState !== null) {
      let mtGroupSelectedUsers = [];
      let userGroupArr = [];

      let isAllParentRequiredFieldsFilled = validateFulfilledRequiredFields(temporaryParentFormState, fState);

      if (isAllParentRequiredFieldsFilled) {
        try {
          console.log("mtgroup: " + JSON.stringify(mtgroup, null, 2));
          console.log("mtGroups.length: " + MTGroupsList?.GetAllMTGroups?.count);

          if (
            JSON.stringify(mtgroup) !== null ||
            JSON.stringify(mtgroup) !== [] ||
            JSON.stringify(mtgroup) !== undefined
          ) {
            for (var i = 0; i < MTGroupsList?.GetAllMTGroups?.count; i++) {
              if (mtgroup && mtgroup.some((pId) => pId.toString() === mtGroups[i]._id.toString())) {
                userGroupArr.push(mtGroups[i]);
              }
            }

            console.log("userGroupArr: " + JSON.stringify(userGroupArr, null, 2));

            userGroupArr &&
              userGroupArr.map((selectedGroups) => {
                if (
                  selectedGroups.members !== [] ||
                  selectedGroups.members !== null ||
                  selectedGroups.members !== undefined
                ) {
                  selectedGroups.members.map((member) => {
                    mtGroupSelectedUsers.push(member._id);
                  });
                }
              });
          }
        } catch (zError) {
          console.log(zError);
        }

        let allSelectedUsers = mtGroupSelectedUsers.concat(users);
        let _uniques = _.union(allSelectedUsers);

        var varData = {
          form: template?._id,
          parentPermit: selectedPermit,
          submission: JSON.stringify(state),
          selectedUser: _uniques,
          selectedMTGroupUsers: mtGroupSelectedUsers,
          selectedMTGroup: mtgroup,
          hostSystemOwner: hostSystemOwner,
          discipline: discipline,
        };

        console.log("varData: " + JSON.stringify(varData, null, 2));
        if (Object.keys(fState).length >= 10) {
          try {
            const { data: CreateChildPermit } = await CreateChildPermitMutation({
              variables: {
                data: varData,
              },
            });
            if (CreateChildPermit?.CreateChildPermit?.success) {
              message.success(CreateChildPermit?.CreateChildPermit?.message);
              if (authUser.role === UserRole.MTGroup) {
                history.push("/my-child-permit");
              } else {
                history.push("/childpermits");
              }
            } else {
              message.warn(CreateChildPermit?.CreateChildPermit?.message);
            }
          } catch (_error) {
            console.log(_error);
          }
        } else if (Object.keys(fState).length <= 0) {
          message.warn("Permit Information and Work Description are required!");
        }
      } else {
        message.warn("Please fill-in required fields before saving as draft or submitting the permit!");
      }
    } else {
      message.warn(
        "You cannot submit an empty permit! Please fill-in some fields before saving as draft or submitting the permit!"
      );
    }
  };

  const disabled = !template || !users?.length || !isFormValid || !mtgroup?.length;

  // console.log("template?.sections: " + JSON.stringify(template?.sections, null, 2));

  /** This array will be used to get all the required children permit fields, for validation */
  let temporaryChildFormState = [];

  /**
   *  This line will get the form field name and if the field is required
   *  Will be later used to check for required fields before submitting the permit.
   */
  let temporaryParentFormState = [];
  template?.sections?.map((section) => {
    section?.form?.map((frm) => {
      if (frm.required) {
        var obj = {
          name: frm.name,
        };
        temporaryParentFormState.push(obj);
      }
    });
  });

  /** END OF LINE */

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          {fetchingTemplates ? (
            <div className="col-12 text-center">
              <Spin size="large" />{" "}
            </div>
          ) : (
            <div className="create-permit-details">
              <div className="form-row">
                <div className="form-group col-md-4">
                  <div>
                    <br /> Child Permit <br />
                    <Select
                      style={{ width: "100%" }}
                      onChange={onChangeForm}
                      placeholder="Select template"
                      showSearch={false}
                      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                      {data?.GetTemplates?.results
                        ?.sort((a, b) => a.title.localeCompare(b.title))
                        .filter(
                          (_template) =>
                            _template._id !== "60500a1f050c9442a394437c" &&
                            _template.title.toLowerCase().startsWith("b")
                        )
                        .map((_template) => (
                          <Select.Option key={_template._id} value={_template._id} template={_template}>
                            {_template.title}
                          </Select.Option>
                        ))}
                    </Select>
                  </div>

                  {template && (
                    <div>
                      <br /> Parent Permit <br />
                      <Select
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          setGeneralPermit(value);
                          setShowIndividualMTGroup(true);
                        }}
                        placeholder="Select General Permit"
                      >
                        {generalPermit?.map((_parentpermit) => (
                          <Select.Option key={_parentpermit?._id} value={_parentpermit?._id}>
                            {_parentpermit?.code}
                          </Select.Option>
                        ))}
                      </Select>
                    </div>
                  )}
                </div>

                {/* start discipline, host system owner */}
                <div className="form-group col-md-4">
                  {selectedPermit && (
                    <div>
                      <br /> Host / System Owner <br />
                      <Select
                        style={{ width: "100%" }}
                        onChange={(value) => setHostSystemOwner(value)}
                        placeholder="Select Host/System Owner"
                        showSearch={true}
                        onSearch={onSearchUser}
                        filterOption={false}
                        notFoundContent={fetchingUser ? <Spin size="small" /> : null}
                        mode="single"
                        autoClearSearchValue
                        value={hostSystemOwner}
                      >
                        {UserList?.GetAllMicronUsers?.results
                          ?.sort((a, b) => a.email.localeCompare(b.email))
                          .filter((res) => res.email.toLowerCase().indexOf(micronEmail) !== -1)
                          .map((user) => (
                            <Select.Option key={user._id} value={user._id}>
                              <strong>{user.fullname}</strong>
                              <br />
                              <span>{user.email}</span>
                            </Select.Option>
                          ))}
                      </Select>
                    </div>
                  )}
                  {selectedPermit && (
                    <div>
                      <br /> Discipline <br />
                      <Select
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          setDiscipline(value);
                          // setShowIndividualMTGroup(true);
                        }}
                        placeholder="Select Discipline"
                      >
                        {DisciplineList?.GetAllDisciplines?.results
                          ?.sort((a, b) => a.code.localeCompare(b.code))
                          .map((_site) => (
                            <Select.Option key={_site._id} value={_site._id} site={_site}>
                              {_site.code}
                            </Select.Option>
                          ))}
                      </Select>
                    </div>
                  )}
                </div>
                {/* end discipline */}

                {/* start individual reviewer, mtgroup */}
                <div className="form-group col-md-4">
                  {selectedPermit && (
                    <div>
                      Individual Reviewer(s) - Micron supervisor/ Micron Manager <br />
                      <Select
                        style={{ width: "100%" }}
                        onChange={(value) => setUsers(value)}
                        placeholder="Select User"
                        showSearch={true}
                        onSearch={onSearchUser}
                        multiple
                        filterOption={false}
                        notFoundContent={fetchingUser ? <Spin size="small" /> : null}
                        mode="multiple"
                        autoClearSearchValue
                        onSelect={(value) => {
                          var hasDuplicateReviewer;

                          if (mtgroup !== null) {
                            let result_1 = MTGroupsList?.GetAllMTGroups?.results.filter((o1) =>
                              mtgroup?.some((o2) => o1._id === o2)
                            );
                            let combinedArray = result_1.map((res) => res.members);
                            let merged = [].concat.apply([], combinedArray);
                            hasDuplicateReviewer = merged.filter(
                              (ol) => ol._id.toLowerCase().indexOf(value.toLowerCase()) !== -1
                            );
                          }

                          if (hasDuplicateReviewer !== undefined && hasDuplicateReviewer.length !== 0) {
                            warnNotify("One of the User in MtGroup has same id in Individual Reviewer.");
                            setUsers([]);
                          }
                        }}
                        value={users}
                      >
                        {UserList?.GetAllMicronUsers?.results
                          ?.sort((a, b) => a.email.localeCompare(b.email))
                          .filter((res) => res.email.toLowerCase().indexOf(micronEmail) !== -1)
                          .map((user) => (
                            <Select.Option key={user._id} value={user._id}>
                              <strong>{user.fullname}</strong>
                              <br />
                              <span>{user.email}</span>
                            </Select.Option>
                          ))}
                      </Select>
                    </div>
                  )}

                  {selectedPermit && (
                    <div>
                      <br /> MTGroup(s) <br />
                      <Select
                        style={{ width: "100%" }}
                        onChange={(value) => setMTGroup(value)}
                        placeholder="Select MT Group"
                        showSearch={true}
                        onSearch={onSearchGroup}
                        multiple
                        filterOption={false}
                        notFoundContent={fetchingGroup ? <Spin size="small" /> : null}
                        mode="multiple"
                        autoClearSearchValue
                        onSelect={(value) => {
                          var hasDuplicate;
                          var hasDuplicateReviewer;
                          let result = MTGroupsList?.GetAllMTGroups?.results.filter(
                            (ol) => ol._id.toLowerCase().indexOf(value.toLowerCase()) !== -1
                          );
                          let getMembersToCheck = result.map((res) => res.members);

                          if (mtgroup !== null) {
                            let result_1 = MTGroupsList?.GetAllMTGroups?.results.filter((o1) =>
                              mtgroup?.some((o2) => o1._id === o2)
                            );
                            let combinedArray = result_1.map((res) => res.members);
                            let merged = [].concat.apply([], combinedArray);
                            hasDuplicate = merged.filter((o1) => getMembersToCheck[0].some((o2) => o1._id === o2._id));
                            hasDuplicateReviewer = users.filter((o1) =>
                              getMembersToCheck[0].some((o2) => o1 === o2._id)
                            );
                          }

                          if (users !== null) {
                            hasDuplicateReviewer = users.filter((o1) =>
                              getMembersToCheck[0].some((o2) => o1 === o2._id)
                            );
                          }

                          if (hasDuplicate !== undefined && hasDuplicate.length !== 0) {
                            warnNotify("One of the User has same id in other MTgroup.");
                            setMTGroup([]);
                          } else if (hasDuplicateReviewer !== undefined && hasDuplicateReviewer.length !== 0) {
                            warnNotify("One of the User in MtGroup has same id in Individual Reviewer.");
                            setMTGroup([]);
                          }
                        }}
                        value={mtgroup}
                      >
                        {GroupList?.GetUserGroups?.results
                          ?.sort((a, b) => a.name.localeCompare(b.name))
                          .map((group) => (
                            <Select.Option key={group._id} value={group._id}>
                              {group.name}
                            </Select.Option>
                          ))}
                      </Select>
                    </div>
                  )}
                </div>
                {/* start individual reviewer, mtgroup */}
              </div>
            </div>
          )}

          <div className="heading">
            <Link to="/childpermits" className="btn-link">
              <i className="far fa-arrow-left"></i> Back to view all child permit{" "}
              {template?.title && <span>[{template?.title}]</span>}
            </Link>
          </div>

          {/* Add Child Permit Button */}
          {template && (
            <form onSubmit={onSubmit}>
              {template?.sections?.map((section, key) => (
                <PermitSection
                  section={section}
                  key={key}
                  onChange={onChange}
                  state={state}
                  isParent={true}
                  handleFormState={handleFormState}
                />
              ))}

              <div className="account-details-btn ">
                {/* <CustomButton
                  disabled={disabled}
                  loading={createDrafPermitLoading}
                  className="btn btn-primary"
                  onClick={onSaveAsDraft}
                  style={{ marginRight: "1%" }}
                >
                  Save as Draft
                </CustomButton> */}

                <CustomButton
                  type="submit"
                  disabled={disabled}
                  loading={createPermitLoading}
                  className="btn btn-primary"
                >
                  Submit
                </CustomButton>
              </div>
            </form>
          )}
        </div>
      </div>

      <Modal
        title="Child permit"
        visible={visibleChildPermit}
        onCancel={() => setVisibleChildPermit(false)}
        onOk={onOkChildPermit}
      >
        <div className="form-row">
          <div className="form-group col-md-4">
            <Select mode="multiple" style={{ width: 400 }} onChange={onChangeChildPermit} placeholder="Select template">
              {data?.GetTemplates?.results?.map((tmp) =>
                tmp?._id === template?._id ? null : (
                  <Select.Option key={tmp._id} value={tmp._id} template={tmp}>
                    {tmp.title}
                  </Select.Option>
                )
              )}
            </Select>
          </div>
        </div>
      </Modal>
    </div>
  );
};
