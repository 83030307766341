import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function GeneralPermitPDF(generalpermit) {
  console.log(generalpermit);
  var permitno = generalpermit.code;
  var status = generalpermit.status;
  var userinfo = generalpermit.submittedBy;
  var body = [];
  let generalPermitData = generalpermit.submission;
  var discipline = generalpermit.discipline;
  var hostsystemowner = generalpermit.hostSystemOwner;
  var site = generalpermit.site;

  //TITLE
  generalpermit?.form?.sections?.map((res) => {
    body.push([{ text: "", border: [false, false, false, false] }]);
    var title = [{ text: res.title, style: "subheader" }];
    body.push(title);
    res.form.map((item) => {
      try {
        if (generalPermitData[item.name] === undefined) {
          if (item.type === "freetext") {
            body.push([{ text: "**" + item.label, style: "small", italics: true }]);
          } else {
            body.push([{ text: item.label + ": " + "", style: "small" }]);
          }
        } else {
          if (item.type === "file" || item.type === "map") {
            generalPermitData[item.name].map((res) => {
              body.push([
                {
                  text: item.label + ": " + res,
                  link: process.env.REACT_APP_IMAGE_URL + res,
                  style: "small",
                  italics: true,
                  color: "blue",
                },
              ]);
            });
          } else if (item.type === "esign") {
            if (generalPermitData[item.name]) {
              body.push([{ image: generalPermitData[item?.name], width: 100 }]);
            } else {
              body.push([{ text: "", style: "small" }]);
            }
          } else if (item.type === "freetext") {
            body.push([{ text: item.label, style: "small", italics: true }]);
          } else if (item.type === "checkbox") {
            var checkbox = generalPermitData[item.name] === true ? "Yes" : "No";
            body.push([{ text: item.label + ": " + checkbox, style: "small" }]);
          } else {
            body.push([{ text: item.label + ": " + generalPermitData[item.name], style: "small" }]);
          }
        }
      } catch (error) {
        console.log("Error Data Info!!");
      }
    });
    body.push([{ text: "", border: [false, false, false, false] }]);
  });

  var dd = {
    info: {
      title: "General Permit",
    },
    pageSize: "A4",
    content: [
      {
        table: {
          widths: ["33.33%", "33.33%", "33.33%"],
          headerRows: 1,
          body: [
            [
              { text: "Full Name\n" + userinfo?.fullname, style: "small" },
              { text: "Company\n" + userinfo?.company, style: "small" },
              { text: "Permit Number\n" + permitno, style: "small" },
            ],
            [
              { text: "Email\n" + userinfo?.email, style: "small" },
              { text: "Site\n" + site?.code, style: "small" },
              { text: "Status\n " + status, style: "small" },
            ],
            [
              { text: "Discipline\n" + discipline?.code, style: "small" },
              { text: "Host / System Owner\n" + hostsystemowner?.email, style: "small" },
              { text: "\n " + "", style: "small" },
            ],
          ],
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 2 : 1;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 2 : 1;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length ? "black" : "gray";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? "black" : "gray";
          },
        },
      },
      {
        table: {
          headerRows: 1,
          body: body,
        },
        layout: {
          hLineWidth: function (i, node) {
            return i === 0 || i === node.table.body.length ? 2 : 1;
          },
          vLineWidth: function (i, node) {
            return i === 0 || i === node.table.widths.length ? 2 : 1;
          },
          hLineColor: function (i, node) {
            return i === 0 || i === node.table.body.length ? "black" : "gray";
          },
          vLineColor: function (i, node) {
            return i === 0 || i === node.table.widths.length ? "black" : "gray";
          },
        },
      },
    ],
    styles: {
      header: {
        fontSize: 18,
        bold: true,
      },
      subheader: {
        fontSize: 10,
        bold: true,
      },
      quote: {
        italics: true,
      },
      small: {
        fontSize: 8,
      },
    },
  };
  pdfMake.createPdf(dd).open();
  var childrenData = generalpermit.children;

  childrenData ??
    childrenData?.map((childData, index) => {
      var childPermitBody = [];
      let childPermitKey = childData.submission;
      childData.form.sections.map((result) => {
        childPermitBody.push([{ text: "", border: [false, false, false, false] }]);
        var title = [{ text: result.title, style: "subheader" }];
        childPermitBody.push(title);
        result.form.map((item) => {
          if (childPermitKey[item.name] === undefined) {
            if (item.type === "freetext") {
              childPermitBody.push([{ text: "**" + item.label, style: "small", italics: true }]);
            } else {
              childPermitBody.push([{ text: item.label + ": " + "", style: "small" }]);
            }
          } else {
            if (item.type === "file" || item.type === "map") {
              childPermitKey[item.name].map((res) => {
                childPermitBody.push([
                  {
                    text: item.label + ": " + res,
                    link: process.env.REACT_APP_IMAGE_URL + res,
                    style: "small",
                    italics: true,
                    color: "blue",
                  },
                ]);
              });
            } else if (item.type === "esign") {
              if (childPermitKey[item.name]) {
                childPermitBody.push([{ image: childPermitKey[item?.name], width: 50 }]);
              } else {
                childPermitBody.push([{ text: "", style: "small" }]);
              }
            } else if (item.type === "freetext") {
              childPermitBody.push([{ text: item.label, style: "small", italics: true }]);
            } else if (item.type === "checkbox") {
              var checkbox = childPermitKey[item.name] === true ? "Yes" : "No";
              childPermitBody.push([{ text: item.label + ": " + checkbox, style: "small" }]);
            } else {
              childPermitBody.push([{ text: item.label + ": " + childPermitKey[item.name], style: "small" }]);
            }
          }
        });
      });

      var childPermit = {
        info: {
          title: childData.form.title,
        },
        pageSize: "A4",
        content: [
          {
            table: {
              widths: ["33.33%", "33.33%", "33.33%"],
              headerRows: 1,
              body: [
                [
                  { text: "Full Name\n" + userinfo.fullname, style: "small" },
                  { text: "Company\n" + userinfo.company, style: "small" },
                  { text: "Permit Number\n" + permitno, style: "small" },
                ],
                [
                  { text: "Email\n" + userinfo.email, style: "small" },
                  { text: "Site\n" + "-", style: "small" },
                  { text: "Status\n " + status, style: "small" },
                ],
              ],
            },
            layout: {
              hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 2 : 1;
              },
              vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 2 : 1;
              },
              hLineColor: function (i, node) {
                return i === 0 || i === node.table.body.length ? "black" : "gray";
              },
              vLineColor: function (i, node) {
                return i === 0 || i === node.table.widths.length ? "black" : "gray";
              },
            },
          },
          {
            table: {
              headerRows: 1,
              body: childPermitBody,
            },
            layout: {
              hLineWidth: function (i, node) {
                return i === 0 || i === node.table.body.length ? 2 : 1;
              },
              vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 2 : 1;
              },
              hLineColor: function (i, node) {
                return i === 0 || i === node.table.body.length ? "black" : "gray";
              },
              vLineColor: function (i, node) {
                return i === 0 || i === node.table.widths.length ? "black" : "gray";
              },
            },
          },
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
          },
          subheader: {
            fontSize: 10,
            bold: true,
          },
          quote: {
            italics: true,
          },
          small: {
            fontSize: 8,
          },
        },
      };
      pdfMake.createPdf(childPermit).open();
    });
}
