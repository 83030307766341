import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { UPDATE_USER, GET_SINGLE_USER } from "../graphql/modules";
import { successNotify, warnNotify } from "../util";
import { Spin } from "antd";
import { UserForm } from "../components/User";

export const EditUser = ({ match }) => {
  let id = match.params.id;

  const { data, loading } = useQuery(GET_SINGLE_USER, {
    variables: {
      id: id,
    },
  });

  const [state, setState] = useState({
    email: "",
    fullname: "",
    company: "",
    site: "",
    sites: [],
    role: "",
    group: null,
    phone: "",
  });

  useEffect(() => {
    const { email = "", fullname = "", company = "", sites = [], role = "", group, phone = "" } =
      data?.GetUser?.user || {};
    console.log(group);
    setState({
      email: email || "",
      fullname: fullname || "",
      company: company || "",
      sites: sites?.map((site) => site._id) || [],
      role,
      group: group?._id,
      phone: phone || "",
    });
  }, [data]);

  const [UpdateUserMutation, { loading: updateLoading }] = useMutation(UPDATE_USER, {
    variables: {
      id,
      data: state,
    },
  });

  const onUpdate = async (e) => {
    e.preventDefault();
    try {
      const {
        data: { UpdateUser },
      } = await UpdateUserMutation();

      console.log(UpdateUser);
      if (UpdateUser.success) {
        successNotify(UpdateUser.message);
      } else {
        warnNotify(UpdateUser.message);
      }
    } catch (error) {
      console.log(error.message);
      warnNotify(error.message);
    }
  };

  const handleChange = (value) => setState({ ...state, ...value });
  // console.log(state);
  return (
    <Spin spinning={loading}>
      <UserForm
        title="Edit User"
        btnText="Update Account"
        btnPassword="Reset Password"
        resetId={id}
        onSubmit={onUpdate}
        loading={updateLoading}
        handleChange={handleChange}
        state={state}
      />

    </Spin>
  );
};
