import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthLayout } from "../containers";

// auth component
export const AuthRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
  return isAuthenticated ? (
    <Redirect to="/permits" />
  ) : (
    <Route
      {...rest}
      component={(props) => (
        <AuthLayout>
          <Component {...props} />
        </AuthLayout>
      )}
    />
  );
};

// authenticated route
export const PrivateRoute = ({ component: Component, role: Role = [], ...rest }) => {
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);
  const role = useSelector((state) => state.auth?.user?.role);

  console.log(role);
  const AuthorizedPage = () => {
    if (Role.length === 0 || Role.includes(role)) {
      return <Route {...rest} component={(props) => <Component {...props} />} />;
    } else {
      return <Redirect to="/404" />;
    }
  };

  return isAuthenticated ? <AuthorizedPage /> : <Redirect to="/signin" from={rest.path} />;
};
