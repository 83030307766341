import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Link, useHistory } from "react-router-dom";
import { EllipsisOutlined } from "@ant-design/icons";
import { UserStatus } from "../config";
import { DELETE_TEMPLATE, GET_TEMPLATE_LIST } from "../graphql/modules";
import { Pagination, Tag, Popconfirm, Dropdown, Menu } from "antd";
import { DynamicList } from "../components/Shared";
import { successNotify, warnNotify, TABLE_LIST_LIMIT } from "../util";

export const AllTemplates = ({ match }) => {
  const history = useHistory();
  const [limit, setLimit] = useState(TABLE_LIST_LIMIT);
  const [page, setPage] = useState(1);

  const { data, loading, error, refetch } = useQuery(GET_TEMPLATE_LIST, {
    variables: {
      limit,
      offset: page - 1,
    },
    fetchPolicy: "cache-and-network",
  });

  const [DeleteTemplateMutation] = useMutation(DELETE_TEMPLATE);

  const total = data?.GetTemplates?.count;
  const results = data?.GetTemplates?.results;

  const onChangePagination = (p, pageSize) => {
    setPage(p);
    setLimit(pageSize);
  };


  const handleDelete = async (key) => {
    try {
      const {
        data: { DeleteTemplate },
      } = await DeleteTemplateMutation({
        variables: {
          id: key,
        },
      });
      if (DeleteTemplate.success) {
        successNotify(DeleteTemplate.message);
        refetch();
      } else {
        warnNotify(DeleteTemplate.message);
      }
    } catch (_error) {
      console.log(_error.message);
    }
  };

  const columns = [
    {
      title: "#",
      render: (_text, _row, index) => limit * (page - 1) + index + 1,
      width: "8%",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      sortDirections: ["descend"],
      defaultSortOrder: 'ascend',
    },
    {
      title: "Action",
      width: "15%",
      render: (text, record) => {
        const menu = (
          <Menu>
            <Menu.Item key="1">
              <Link style={{ marginRight: "15px" }} to={`${match.path}/edit-template/${record._id}`}>
                Edit
              </Link>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="3" style={{ pointerEvents: "none" }} disabled={record._id === "60500a1f050c9442a394437c"}>
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => handleDelete(record._id)}
                disabled={record._id === "60500a1f050c9442a394437c"}
              >
                Delete
              </Popconfirm>
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <EllipsisOutlined />
          </Dropdown>
        );
      },
    },
  ];

  if (error) {
    return <div className="card">Something went wrong</div>;
  }

  return (
    <DynamicList
      title="List of templates"
      loading={loading}
      columns={columns}
      data={results}
      actions={
        <Link to={match.path + "/create-template"} className="btn btn-primary ">
          Create Template <i className="ml-2 fas fa-plus-circle"></i>
        </Link>
      }
      total={total}
      pageSize={limit}
      onChange={onChangePagination}
      // showSearch={true}
    />
  );
};
