import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import React, { useState, useEffect, Fragment } from "react";
import { useSelector } from "react-redux";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import {
  GET_SITE_LIST,
  GET_DASHBOARD_CARDS_DATA,
  GET_ALL_G_DASHBOARD,
  GET_B1_PERMITS,
  GET_B2_PERMITS,
  GET_B3_PERMITS,
  GET_B4_PERMITS,
  GET_B5_PERMITS,
  GET_B6_PERMITS,
  GET_B7_PERMITS,
  GET_CRITICAL_ACTIVITIES,
  GET_ENHANCED_DASHBOARD_DATA,
  GET_ENHANCED_DASHBOARD_LOWER_DATA,
  GET_DISCIPLINE_ENHANCED_DASHBOARD_DATA,
  GET_DISCIPLINE_LOWER_GRAPH_DATA,
  GET_ALL_DISCIPLINE,
} from "../graphql/modules";
import { DynamicList } from "../components/Shared";
import moment from "moment";
import { DashboardCard } from "../components/Dashboard";
import { Column } from "@ant-design/plots";
import {
  Pagination,
  DatePicker,
  Select,
  Checkbox,
  Spin,
  Collapse,
  Alert,
  Button,
  Input,
  Space,
  Tag,
  Tabs,
  Row,
  Col,
  Table,
} from "antd";
import { EllipsisOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { Bar } from "react-chartjs-2";
import { Chart } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Divider } from "@material-ui/core";
import { formatDate, TABLE_LIST_LIMIT, getNextReviewer } from "../util";
import { UserRole, StatusColor, PermitStatus, PermitStatusArray } from "../config";
import { json } from "body-parser";
// import { excelHeader } from '../components/Excel';
moment.locale("en", {
  week: {
    dow: 4, // Thursday is the first day of the week
  },
});

const { Panel } = Collapse;

const { TabPane } = Tabs;

function callback(key) {
  console.log(key);
}

const F10A1_BAR_COLOR = "#2980B9";
const F10A2_BAR_COLOR = "#1ABC9C";
const F10AN_BAR_COLOR = "#F1C40F";
const F10NX_BAR_COLOR = "#F39C12";
const F10W_BAR_COLOR = "##9B59B6";
const F10X_BAR_COLOR = "#DAF7A6";

export const Dashboard = () => {
  const { user: authUser } = useSelector((_state) => _state.auth);
  const [limit, setLimit] = useState(TABLE_LIST_LIMIT);
  const [pageLimit] = useState(10);
  const [page, setPage] = useState(1);
  const { Option } = Select;

  const [disableWeekFilter, setDisableWeekFilter] = useState(false);
  const [disableMonthFilter, setDisableMonthFilter] = useState(false);
  const [disableYearFilter, setDisableYearFilter] = useState(false);

  const [permitId, setPermitId] = useState("");
  const [dateFrom, setdateFrom] = useState("");
  const [dateTo, setdateTo] = useState("");

  const [currentWeek, setWeek] = useState("");
  const [currentMonth, setMonth] = useState("");
  const [currentYear, setYear] = useState("");

  const [currentPermit, setPermit] = useState("");

  const [currentSite, setSite] = useState("");
  const [currentDiscipline, setDiscipline] = useState("");
  const [tabType, setTabType] = useState("SITE");
  // const [tabYear, setTabYear] = useState("");

  const [value, setValue] = useState("");
  const [statusCheck, setStatusCheck] = useState([]);
  const [siteName, setSiteName] = useState([]);

  const [disciplineGraph, setDisciplineGraph] = useState([]);
  const [disciplineData, setDisciplineData] = useState([]);
  /** START For Applier Name Filtering */
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  /** END For Applier Name Filtering */

  /** START For Start Date Filtering */
  const [searchStartDate, setSearchStartDate] = useState("");
  const [searchedStartDateColumn, setSearchedStartDateColumn] = useState("");
  /** END For Start Date Filtering */

  /** START For End Date Filtering */
  const [searchEndDate, setSearchDateEnd] = useState("");
  const [searchedEndDateColumn, setSearchedDateEndColumn] = useState("");
  /** END For End Date Filtering */

  /** START For End Date Filtering */
  const [searchCode, setSearchCode] = useState("");
  const [searchedCodeColumn, setSearchedCodeColumn] = useState("");
  /** END For End Date Filtering */

  /** START For End Date Filtering */
  const [searchPermitNumber, setSearchPermitNumber] = useState("");
  const [searchedPermitNumberColumn, setSearchedPermitNumberColumn] = useState("");
  /** END For End Date Filtering */

  /** START For Date Applied (createdAt) Filtering */
  const [searchDateApplied, setSearchDateApplied] = useState("");
  const [searchedDateAppliedColumn, setSearchedDateAppliedColumn] = useState("");
  /** END For Date Applied (createdAt) Filtering */

  /** START Graph Header */
  const [graphHeader, setGraphHeader] = useState("");
  const [disciplineGraphHeader, setDisciplineGraphHeader] = useState("");
  /** END Graph Header */

  /** Start Active Tab */
  const [activeTab, setActiveTab] = useState("1");

  const [disciplineSource, setDisciplineSource] = useState([]);
  const [siteSource, setSiteSource] = useState([]);

  const [totalPermits, setTotalPermits] = useState("");
  const [siteSetGraph, setSiteSetGraph] = useState([]);

  const [disciplineTotalPermits, setDisciplineTotalPermits] = useState("");
  const [disciplineSetGraph, setDisciplineSetGraph] = useState([]);

  const onChangePagination = (p) => {
    setPage(p);
  };

  const onChangeDisciplineGraph = (data) => {
    setDisciplineSetGraph(data);
  };

  const onChangeSiteGraph = (data) => {
    setSiteSetGraph(data);
  };

  const { data: CriticalActivities, loading: CriticalActLoading } = useQuery(GET_CRITICAL_ACTIVITIES, {
    variables: {
      permitId: permitId,
      dateFrom: dateFrom,
      dateTo: dateTo,
    },
    fetchPolicy: "cache-and-network",
  });
  const { data: DisciplineList, loading: DisciplineLoading } = useQuery(GET_ALL_DISCIPLINE);
  const { data: CardsData, loading: CardsLoading } = useQuery(GET_DASHBOARD_CARDS_DATA);
  const { data, loading, error, refetch } = useQuery(GET_ALL_G_DASHBOARD, {
    variables: {
      searchText: value,
      searchCode: searchCode,
      searchCreated: searchDateApplied,
      searchStartDate: searchStartDate,
      searchEndDate: searchEndDate,
      statusCheck: statusCheck,
      siteName: siteName,
      limit,
      offset: page - 1,
    },
    fetchPolicy: "cache-and-network",
  });
  const { data: b1Data, loading: b1Loading } = useQuery(GET_B1_PERMITS, {
    variables: {
      searchText: value,
      searchCode: searchCode,
      searchCreated: searchDateApplied,
      searchStartDate: searchStartDate,
      searchEndDate: searchEndDate,
      statusCheck: statusCheck,
      siteName: siteName,
      limit,
      offset: page - 1,
    },
    fetchPolicy: "cache-and-network",
  });
  const { data: b2Data, loading: b2Loading } = useQuery(GET_B2_PERMITS, {
    variables: {
      searchText: value,
      searchCode: searchCode,
      searchCreated: searchDateApplied,
      searchStartDate: searchStartDate,
      searchEndDate: searchEndDate,
      statusCheck: statusCheck,
      siteName: siteName,
      limit,
      offset: page - 1,
    },
    fetchPolicy: "cache-and-network",
  });
  const { data: b3Data, loading: b3Loading } = useQuery(GET_B3_PERMITS, {
    variables: {
      searchText: value,
      searchCode: searchCode,
      searchCreated: searchDateApplied,
      searchStartDate: searchStartDate,
      searchEndDate: searchEndDate,
      statusCheck: statusCheck,
      siteName: siteName,
      limit,
      offset: page - 1,
    },
    fetchPolicy: "cache-and-network",
  });
  const { data: b4Data, loading: b4Loading } = useQuery(GET_B4_PERMITS, {
    variables: {
      searchText: value,
      searchCode: searchCode,
      searchCreated: searchDateApplied,
      searchStartDate: searchStartDate,
      searchEndDate: searchEndDate,
      statusCheck: statusCheck,
      siteName: siteName,
      limit,
      offset: page - 1,
    },
    fetchPolicy: "cache-and-network",
  });
  const { data: b5Data, loading: b5Loading } = useQuery(GET_B5_PERMITS, {
    variables: {
      searchText: value,
      searchCode: searchCode,
      searchCreated: searchDateApplied,
      searchStartDate: searchStartDate,
      searchEndDate: searchEndDate,
      statusCheck: statusCheck,
      siteName: siteName,
      limit,
      offset: page - 1,
    },
    fetchPolicy: "cache-and-network",
  });
  const { data: b6Data, loading: b6Loading } = useQuery(GET_B6_PERMITS, {
    variables: {
      searchText: value,
      searchCode: searchCode,
      searchCreated: searchDateApplied,
      searchStartDate: searchStartDate,
      searchEndDate: searchEndDate,
      statusCheck: statusCheck,
      siteName: siteName,
      limit,
      offset: page - 1,
    },
    fetchPolicy: "cache-and-network",
  });
  const { data: b7Data, loading: b7Loading } = useQuery(GET_B7_PERMITS, {
    variables: {
      searchText: value,
      searchCode: searchCode,
      searchCreated: searchDateApplied,
      searchStartDate: searchStartDate,
      searchEndDate: searchEndDate,
      statusCheck: statusCheck,
      siteName: siteName,
      limit,
      offset: page - 1,
    },
    fetchPolicy: "cache-and-network",
  });

  const [getEnhancedDboardData, { data: GraphData, loading: GraphLoading }] = useLazyQuery(
    GET_ENHANCED_DASHBOARD_DATA,
    {
      variables: {
        permitSite: currentSite,
        currentWeek: currentWeek,
        currentMonth: currentMonth,
        currentYear: currentYear,
      },
      fetchPolicy: "cache-and-network",
    }
  );
  const [getSiteLowerGraph, { data: SiteGraph, loading: SiteGraphLoading }] = useLazyQuery(
    GET_ENHANCED_DASHBOARD_LOWER_DATA,
    {
      variables: {
        permitSite: currentSite,
        currentWeek: currentWeek,
        currentMonth: currentMonth,
        currentYear: currentYear,
      },
      fetchPolicy: "cache-and-network",
    }
  );
  const [getDisciplineGraph, { data: DisciplineGraphData, loading: DisciplineGraphDataLoading }] = useLazyQuery(
    GET_DISCIPLINE_ENHANCED_DASHBOARD_DATA,
    {
      variables: {
        discipline: currentDiscipline,
        currentWeek: currentWeek,
        currentMonth: currentMonth,
        currentYear: currentYear,
      },
      fetchPolicy: "cache-and-network",
    }
  );
  const [getDisciplineLowerGraph, { data: DisciplineLowerGraphData, loading: DisciplineLowerGraphLoading }] =
    useLazyQuery(GET_DISCIPLINE_LOWER_GRAPH_DATA, {
      variables: {
        discipline: currentDiscipline,
        currentWeek: currentWeek,
        currentMonth: currentMonth,
        currentYear: currentYear,
      },
      fetchPolicy: "cache-and-network",
    });
  const { data: SiteList } = useQuery(GET_SITE_LIST, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  useEffect(() => {
    let disciplineData = DisciplineLowerGraphData?.GetDisciplineLowerGraphData?.data;
    let sortByDiscipline = disciplineData?.slice().sort((a, b) => a.discipline.localeCompare(b.discipline));
    setDisciplineSource(sortByDiscipline);
  }, [DisciplineLowerGraphData?.GetDisciplineLowerGraphData?.data]);

  useEffect(() => {
    setSiteSource(SiteGraph?.GetEnhancedDashboardLowerData?.data);
    console.log(SiteGraph?.GetEnhancedDashboardLowerData);
  }, [SiteGraph?.GetEnhancedDashboardLowerData?.data]);

  let siteList = [];
  if (SiteList?.GetSites?.results?.length) {
    SiteList?.GetSites?.results?.map((site) => {
      siteList.push({
        text: site.code,
        value: site.code,
        _id: site._id,
      });
    });
  }

  var total = data?.GetAllGeneralPermitsDashboard?.count;
  var results = JSON.parse(data?.GetAllGeneralPermitsDashboard?.results || "[]");

  var b1total = b1Data?.GetB1Permits?.totalHotWorks;
  var b1count = b1Data?.GetB1Permits?.count;
  var b1result = JSON.parse(b1Data?.GetB1Permits?.results || "[]");

  var b2total = b2Data?.GetB2Permits?.count;
  var b2result = JSON.parse(b2Data?.GetB2Permits?.results || "[]");

  var b3total = b3Data?.GetB3Permits?.count;
  var b3result = JSON.parse(b3Data?.GetB3Permits?.results || "[]");

  var b4total = b4Data?.GetB4Permits?.totalWorkHeights;
  var b4count = b4Data?.GetB4Permits?.count;
  var b4result = JSON.parse(b4Data?.GetB4Permits?.results || "[]");

  var b5total = b5Data?.GetB5Permits?.count;
  var b5result = JSON.parse(b5Data?.GetB5Permits?.results || "[]");

  var b6total = b6Data?.GetB6Permits?.count;
  var b6result = JSON.parse(b6Data?.GetB6Permits?.results || "[]");

  var b7total = b7Data?.GetB7Permits?.count;
  var b7result = JSON.parse(b7Data?.GetB7Permits?.results || "[]");

  let cardsData = [];
  if (CardsData?.GetDashboardCardsData?.results) {
    cardsData = JSON.parse(CardsData?.GetDashboardCardsData?.results || []);
  }

  let permitType = ["SIPP", "General", "WAH", "Hot Work", "Confined Space", "Lifting", "LSS"];
  // var totalPermits;
  // var disciplineTotalPermits;
  let disciplineList = [];
  let disciplineType = [];
  // var disciplineDataSetGraph = [];

  if (DisciplineList?.GetAllDisciplines?.results?.length) {
    DisciplineList?.GetAllDisciplines?.results?.map((discipline) => {
      disciplineList.push({
        text: discipline.code,
        value: discipline.code,
      });
      disciplineType.push(discipline.code);
    });
  }
  // console.log("graphdatas", GraphData?.GetGraphData?.completed?.map(o => o.count).reduce((a, b) => a + b, 0))
  // if (GraphData?.GetEnhancedDashboardData) {
  //   totalPermits = GraphData?.GetEnhancedDashboardData?.totalPermits;
  // }

  // if (DisciplineGraphData?.GetDisciplineEnhancedDashboardData) {
  //   var permitsData = DisciplineGraphData?.GetDisciplineEnhancedDashboardData?.permitsData;
  //   var tmpArray = [];

  //   for (let x = 0; x < permitsData?.length; x++) {
  //     let obj = {
  //       discipline: permitsData[x].discipline,
  //       count: permitsData[x].disciplineTotal,
  //     };
  //     tmpArray.push(obj);
  //   }

  //   onChangeDisciplineGraph(tmpArray);
  // }

  // if (GraphData1?.GetEnhancedDashboardData1?.permitsData) {
  // }

  // let disciplineDataFilter = (discipline) => {
  //   let disciplineMonthFilter;

  //   disciplineMonthFilter = GraphData1?.GetEnhancedDashboardData1?.permitsData
  //     ?.map((o) => o)
  //     .filter((_disp) => {
  //       return _disp.discpline === discipline;
  //     });
  // };

  useEffect(() => {
    setDisciplineTotalPermits(DisciplineGraphData?.GetDisciplineEnhancedDashboardData?.totalPermits);

    if (Array.isArray(DisciplineGraphData?.GetDisciplineEnhancedDashboardData?.permitsData)) {
      var permitsData = DisciplineGraphData?.GetDisciplineEnhancedDashboardData?.permitsData;
      var tmpArray = [];

      for (let x = 0; x < permitsData?.length; x++) {
        let obj = {
          discipline: permitsData[x].discipline,
          count: permitsData[x].disciplineTotal,
        };
        tmpArray.push(obj);
      }

      let sortByDiscipline = tmpArray?.slice().sort((a, b) => a.discipline.localeCompare(b.discipline));

      onChangeDisciplineGraph(sortByDiscipline);
    }
  }, [DisciplineGraphData?.GetDisciplineEnhancedDashboardData]);

  useEffect(() => {
    setTotalPermits(GraphData?.GetEnhancedDashboardData?.totalPermits);
  }, [GraphData?.GetEnhancedDashboardData]);

  const permitfilter = (site, permit) => {
    let permitfilter;

    permitfilter = graphDataFiltering(site);
    let a;
    if (permitfilter?.[0]?.data !== undefined || GraphData?.GetEnhancedDashboardData?.permitsData !== undefined) {
      for (let x = 0; x < permitfilter?.[0]?.data?.length; x++) {
        if (permitfilter?.[0]?.data[x]?.permitType === permit) {
          a = permitfilter?.[0]?.data[x]?.count;
        }
      }
    }
    return a;
  };

  const graphDataFiltering = (site) => {
    let sitefilter;
    if (activeTab === "1") {
      sitefilter = GraphData?.GetEnhancedDashboardData?.permitsData
        ?.map((o) => o)
        .filter((test) => {
          return test.permitSite === site;
        });
    }
    return sitefilter;
  };

  function tabPaneCallback(key) {
    console.log(key);
    setWeek("");
    setMonth("");
    setYear("");
    setSite(null);
    setDiscipline("");
    setGraphHeader("ALL");
    setDisciplineGraphHeader("ALL");
    setDisableWeekFilter(false);
    setDisableMonthFilter(false);
    setDisableYearFilter(false);
    onResetSiteDataSource();
    onResetDisciplineDataSource();

    setTotalPermits("");
    setDisciplineTotalPermits("");

    onResetDisciplineDataGraph();
    onResetSiteDataGraph();

    if (key === "1") {
      setActiveTab("1");
      setTabType("SITE");
      window.location.reload(false);
    } else {
      setActiveTab("2");
      setTabType("DISCIPLINE");
    }
    toggle(false);
    // console.log(GraphData1, GraphData)
    // const graphDataFiltering = (site) => {
    //   let sitefilter;
    //   if (activeTab === "1") {
    //     sitefilter = GraphData?.GetEnhancedDashboardData?.permitsData
    //       ?.map((o) => o)
    //       .filter((test) => {
    //         return test.permitSite === site;
    //       });
    //   }
    //   return sitefilter;
    // };
  }

  //if tab is toggled and w/m/y are checked - uncheck it
  function toggle(ischecked) {
    // var elm = document.getElementsByName('checkbox');
    let elm = document.getElementsByName("site-wmy");
    let elm2 = document.getElementsByName("disc-wmy");
    for (let x = 0; x < elm.length - 1; x++) {
      if (elm.length > 0 && ischecked != elm[x].checked) {
        elm[x].click();
      }
      if (elm2.length > 0 && ischecked != elm2[x].checked) {
        elm2[x].click();
      }
    }
  }

  /** START For Permit Number Filtering */
  const handlePermitNumberSearch = (selectedKeys, confirm, dataIndex) => {
    setValue(selectedKeys[0]);
    confirm();
    setSearchPermitNumber(selectedKeys[0]);
    setSearchedPermitNumberColumn(dataIndex);
  };
  const handlePermitNumberReset = (clearFilters) => {
    setValue("");
    clearFilters();
    setSearchPermitNumber("");
  };
  const getColumnSearchPermitNumber = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handlePermitNumberSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handlePermitNumberSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handlePermitNumberReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (permitnumber, row) =>
      row?.code ? row?.code?.toString().toLowerCase().includes(permitnumber.toLowerCase()) : "",
    onFilterDropdownVisibleChange: (visible) => {
      try {
        setTimeout(() => this.searchInput.select(), 100);
      } catch (err) {
        console.error(err);
      }
    },
    render: (_text, _row) =>
      searchedPermitNumberColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchPermitNumber]}
          autoEscape
          textToHighlight={_row?.code ? _row?.code : ""}
        />
      ) : (
        _row?.code
      ),
  });
  /** END For Permit Number Filtering */

  /** START For Permit Code Filtering */
  const handleCodeSearch = (selectedKeys, confirm, dataIndex) => {
    setSearchCode(selectedKeys[0]);
    confirm();
    setSearchCode(selectedKeys[0]);
    setSearchedCodeColumn(dataIndex);
  };
  const handleCodeReset = (clearFilters) => {
    clearFilters();
    setSearchCode("");
    setValue("");
  };
  const getColumnSearchCode = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleCodeSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleCodeSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleCodeReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (code, row) => (row?.code ? row?.code?.toString().toLowerCase().includes(code.toLowerCase()) : ""),
    onFilterDropdownVisibleChange: (visible) => {
      try {
        setTimeout(() => this.searchInput.select(), 100);
      } catch (err) {
        console.error(err);
      }
    },
    render: (_text, _row) => {
      try {
        let permitNumber = _row?.code ? _row?.code : "SITE- -DATE-NUM";
        let splitPermitNumber = permitNumber.split("-");
        return splitPermitNumber[1];
      } catch (err) {
        // console.log(err);
        return "";
      }
    },
  });
  /** END For Permit Code Filtering */

  /** START HOST/SYSTEM OWNER FILTERING */
  const getHostOwnerSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (email, row) =>
      row?.hostSystemOwner ? row?.hostSystemOwner?.email?.toString().toLowerCase().includes(email.toLowerCase()) : "",
    render: (_text, _row) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={_row?.hostSystemOwner ? _row?.hostSystemOwner?.email.toString() : ""}
        />
      ) : (
        _row?.hostSystemOwner?.email
      ),
  });
  /** END HOST/SYSTEM OWNER FILTERING */

  /** START For Applier Name Filtering */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Jan 1, 2021`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (fullname, row) =>
      row?.submittedBy ? row?.submittedBy?.fullname?.toString().toLowerCase().includes(fullname.toLowerCase()) : "",
    onFilterDropdownVisibleChange: (visible) => {
      try {
        setTimeout(() => this.searchInput.select(), 100);
      } catch (err) {
        console.error(err);
      }
    },
    render: (_text, _row) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={_row?.submittedBy ? _row?.submittedBy?.fullname.toString() : ""}
        />
      ) : (
        _row?.submittedBy?.fullname
      ),
  });
  /** END For Applier Name Filtering */

  /** START For StartDate Filtering */
  const handleSearchStartDate = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchStartDate(selectedKeys[0]);
    setSearchedStartDateColumn(dataIndex);
  };
  const handleResetStartDate = (clearFilters) => {
    clearFilters();
    setSearchStartDate("");
  };
  const getStartDateColumnSearch = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Jan 1, 2021`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchStartDate(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchStartDate(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleResetStartDate(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    render: (_text, _row) => (_row?.submission?.startDate ? formatDate(_row?.submission?.startDate) : " "),
  });
  /** END For StartDate Filtering */

  const handleSearchDateEnd = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchDateEnd(selectedKeys[0]);
    setSearchedDateEndColumn(dataIndex);
  };
  const handleResetDateEnd = (clearFilters) => {
    clearFilters();
    setSearchDateEnd("");
    setSearchStartDate("");
  };
  const getEndDateColumnSearch = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Jan 1, 2021`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchDateEnd(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchDateEnd(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleResetDateEnd(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    render: (_text, _row) => (_row?.submission?.dateEnd ? formatDate(_row?.submission?.dateEnd) : " "),
  });
  /** END For DateEnd Filtering */

  /** START For Date Applied (createdAt) Filtering */
  const handleSearchDateApplied = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchDateApplied(selectedKeys[0]);
    setSearchedDateAppliedColumn(dataIndex);
  };
  const handleResetDateApplied = (clearFilters) => {
    clearFilters();
    setSearchDateApplied("");
  };
  const getDateAppliedColumnSearch = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Jan 1, 2021`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchDateApplied(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchDateApplied(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleResetDateApplied(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    render: (_text, _row) => (_row?.createdAt ? formatDate(_row?.createdAt) : " "),
  });
  const handleSearchStatusReset = () => {
    setStatusCheck([]);
  };
  const handleCheckboxSelect = (e) => {
    setStatusCheck(e);
  };
  const getStatusData = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Checkbox.Group style={{ width: "100%" }} onChange={handleCheckboxSelect} value={statusCheck}>
          {PermitStatusArray?.map((res) => {
            return (
              <Row>
                <Col span={24}>
                  <Checkbox value={res.value}>{res.text}</Checkbox>
                </Col>
              </Row>
            );
          })}
        </Checkbox.Group>
        <Space>
          <Button onClick={handleSearchStatusReset} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
  });

  const handleSiteNameReset = () => {
    setSiteName([]);
  };
  const handleSiteNameCheckbox = (e) => {
    setSiteName(e);
  };

  const getApplierSite = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Checkbox.Group style={{ width: "100%" }} onChange={handleSiteNameCheckbox} value={siteName}>
          {siteList?.map((res) => {
            return (
              <Row>
                <Col span={24}>
                  <Checkbox value={res.value}>{res.text}</Checkbox>
                </Col>
              </Row>
            );
          })}
        </Checkbox.Group>
        <Space>
          <Button onClick={handleSiteNameReset} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
  });

  const columnsGeneral = [
    {
      title: "#",
      width: "3%",
    },
    {
      title: "Permit Number",
      ...getColumnSearchPermitNumber("permitnumber"),
    },
    {
      title: "Permit Code",
      ...getColumnSearchCode("code"),
      width: "5%",
    },
    {
      title: "Discipline",
      filters: disciplineList,
      onFilter: (_text, _row) => _row?.discipline?.code?.indexOf(_text) === 0,
      render: (_text, _row) => (_row?.discipline?.code !== undefined ? _row?.discipline?.code : ""),
    },
    {
      title: "Host / System Owner",
      ...getHostOwnerSearchProps("hostSystemOwner"),
    },
    {
      title: "Work Description",
      render: (_text, _row) => _row?.submission?.workDetails,
      width: "25%",
    },
    {
      title: "Applier Site",
      filters: siteList,
      onFilter: (_text, _row) => _row?.site?.code?.indexOf(_text) === 0,
      render: (_text, _row) => (_row?.site?.code !== undefined ? _row?.site?.code : ""),
      // ...getApplierSite("applierSite"),
      // render: (_text, _row) => _row?.site?.code,
      width: "5%",
    },
    {
      title: "Date Applied",
      ...getDateAppliedColumnSearch("Date Applied"),
      width: "10%",
    },
    {
      title: "Start Date",
      ...getStartDateColumnSearch("startDate"),
      width: "10%",
    },
    {
      title: "End Date",
      ...getEndDateColumnSearch("dateEnd"),
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "15%",
      ...getStatusData("statusData"),
      render: (status, row) => {
        let color = "orange";
        if (status === PermitStatus.Rejected) color = StatusColor.Rejected;
        else if (status === PermitStatus.Approved) color = StatusColor.Approved;
        else if (status === PermitStatus.Completed) color = StatusColor.Completed;
        else if (status === PermitStatus.Cancelled) color = StatusColor.Cancelled;
        else if (status === PermitStatus.PendingClosure) color = StatusColor.PendingClosure;
        else if (status === PermitStatus.PendingCancel) color = StatusColor.PendingCancel;

        const approvedBy = row?.approvedBy?.length ? row.approvedBy[row?.approvedBy?.length - 1] : null;
        const rejectedBy = row?.rejectedBy;

        let nextReviewer = getNextReviewer(row);

        return (
          <div>
            {status === PermitStatus.Pending ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By PM
              </Tag>
            ) : status === PermitStatus.PendingApproval ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By {nextReviewer}
              </Tag>
            ) : status === PermitStatus.Rejected ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Rejected By {rejectedBy?.fullname}
              </Tag>
            ) : status === PermitStatus.Approved && approvedBy ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Final Approved By {approvedBy?.fullname}
              </Tag>
            ) : status === undefined || status === null ? (
              " "
            ) : (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                {status}
              </Tag>
            )}
          </div>
        );
      },
    },
  ];

  const columnsB1 = [
    {
      title: "#",
      width: "3%",
    },
    {
      title: "Permit Number",
      ...getColumnSearchPermitNumber("permitnumber"),
    },
    {
      title: "Permit Code",
      width: "5%",
      render: (_text, _row) => {
        try {
          let permitNumber = _row?.code ? _row?.code : "SITE- -DATE-NUM";
          let splitPermitNumber = permitNumber.split("-");
          return splitPermitNumber[1];
        } catch (err) {
          // console.log(err);
          return "";
        }
      },
    },
    {
      title: "Discipline",
      filters: disciplineList,
      onFilter: (_text, _row) => _row?.discipline?.code?.indexOf(_text) === 0,
      render: (_text, _row) => (_row?.discipline?.code !== undefined ? _row?.discipline?.code : ""),
    },
    {
      title: "Host / System Owner",
      ...getHostOwnerSearchProps("hostSystemOwner"),
    },
    {
      title: "Work Description",
      render: (_text, _row) => _row?.submission?.workDetails,
      width: "25%",
    },
    // {
    //   title: "Applier name",
    //   /** START For Applier Name Filtering */
    //   ...getColumnSearchProps("fullname"),
    //   /** END For Applier Name Filtering */
    // },
    {
      title: "Applier Site",
      ...getApplierSite("applierSite"),
      render: (_text, _row) => _row?.site?.code,
      width: "5%",
    },
    {
      title: "Date Applied",
      ...getDateAppliedColumnSearch("Date Applied"),
      width: "10%",
    },
    {
      title: "Start Date",
      ...getStartDateColumnSearch("startDate"),
      width: "10%",
    },
    {
      title: "End Date",
      ...getEndDateColumnSearch("dateEnd"),
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "15%",
      ...getStatusData("statusData"),
      render: (status, row) => {
        let color = "orange";
        if (status === PermitStatus.Rejected) color = StatusColor.Rejected;
        else if (status === PermitStatus.Approved) color = StatusColor.Approved;
        else if (status === PermitStatus.Completed) color = StatusColor.Completed;
        else if (status === PermitStatus.Cancelled) color = StatusColor.Cancelled;
        else if (status === PermitStatus.PendingClosure) color = StatusColor.PendingClosure;
        else if (status === PermitStatus.PendingCancel) color = StatusColor.PendingCancel;

        const approvedBy = row?.approvedBy?.length ? row.approvedBy[row?.approvedBy?.length - 1] : null;
        const rejectedBy = row?.rejectedBy;

        let nextReviewer = getNextReviewer(row);

        return (
          <div>
            {status === PermitStatus.Pending ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By PM
              </Tag>
            ) : status === PermitStatus.PendingApproval ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By {nextReviewer}
              </Tag>
            ) : status === PermitStatus.Rejected ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Rejected By {rejectedBy?.fullname}
              </Tag>
            ) : status === PermitStatus.Approved && approvedBy ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Final Approved By {approvedBy?.fullname}
              </Tag>
            ) : status === undefined || status === null ? (
              " "
            ) : (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                {status}
              </Tag>
            )}
          </div>
        );
      },
    },
  ];

  const columnsB2 = [
    {
      title: "#",
      width: "3%",
    },
    {
      title: "Permit Number",
      ...getColumnSearchPermitNumber("permitnumber"),
    },
    {
      title: "Permit Code",
      width: "5%",
      render: (_text, _row) => {
        try {
          let permitNumber = _row?.code ? _row?.code : "SITE- -DATE-NUM";
          let splitPermitNumber = permitNumber.split("-");
          return splitPermitNumber[1];
        } catch (err) {
          // console.log(err);
          return "";
        }
      },
    },
    {
      title: "Discipline",
      filters: disciplineList,
      onFilter: (_text, _row) => _row?.discipline?.code?.indexOf(_text) === 0,
      render: (_text, _row) => (_row?.discipline?.code !== undefined ? _row?.discipline?.code : ""),
    },
    {
      title: "Host / System Owner",
      ...getHostOwnerSearchProps("hostSystemOwner"),
    },
    {
      title: "Work Description",
      render: (_text, _row) => _row?.submission?.workDetails,
      width: "25%",
    },
    // {
    //   title: "Applier name",
    //   /** START For Applier Name Filtering */
    //   ...getColumnSearchProps("fullname"),
    //   /** END For Applier Name Filtering */
    // },
    {
      title: "Applier Site",
      ...getApplierSite("applierSite"),
      render: (_text, _row) => _row?.site?.code,
      width: "5%",
    },
    {
      title: "Date Applied",
      ...getDateAppliedColumnSearch("Date Applied"),
      width: "10%",
    },
    {
      title: "Start Date",
      ...getStartDateColumnSearch("startDate"),
      width: "10%",
    },
    {
      title: "End Date",
      ...getEndDateColumnSearch("dateEnd"),
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "15%",
      ...getStatusData("statusData"),
      render: (status, row) => {
        let color = "orange";
        if (status === PermitStatus.Rejected) color = StatusColor.Rejected;
        else if (status === PermitStatus.Approved) color = StatusColor.Approved;
        else if (status === PermitStatus.Completed) color = StatusColor.Completed;
        else if (status === PermitStatus.Cancelled) color = StatusColor.Cancelled;
        else if (status === PermitStatus.PendingClosure) color = StatusColor.PendingClosure;
        else if (status === PermitStatus.PendingCancel) color = StatusColor.PendingCancel;

        const approvedBy = row?.approvedBy?.length ? row.approvedBy[row?.approvedBy?.length - 1] : null;
        const rejectedBy = row?.rejectedBy;

        let nextReviewer = getNextReviewer(row);

        return (
          <div>
            {status === PermitStatus.Pending ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By PM
              </Tag>
            ) : status === PermitStatus.PendingApproval ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By {nextReviewer}
              </Tag>
            ) : status === PermitStatus.Rejected ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Rejected By {rejectedBy?.fullname}
              </Tag>
            ) : status === PermitStatus.Approved && approvedBy ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Final Approved By {approvedBy?.fullname}
              </Tag>
            ) : status === undefined || status === null ? (
              " "
            ) : (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                {status}
              </Tag>
            )}
          </div>
        );
      },
    },
  ];

  const columnsB3 = [
    {
      title: "#",
      width: "3%",
    },
    {
      title: "Permit Number",
      ...getColumnSearchPermitNumber("permitnumber"),
    },
    {
      title: "Permit Code",
      width: "5%",
      render: (_text, _row) => {
        try {
          let permitNumber = _row?.code ? _row?.code : "SITE- -DATE-NUM";
          let splitPermitNumber = permitNumber.split("-");
          return splitPermitNumber[1];
        } catch (err) {
          // console.log(err);
          return "";
        }
      },
    },
    {
      title: "Discipline",
      filters: disciplineList,
      onFilter: (_text, _row) => _row?.discipline?.code?.indexOf(_text) === 0,
      render: (_text, _row) => (_row?.discipline?.code !== undefined ? _row?.discipline?.code : ""),
    },
    {
      title: "Host / System Owner",
      ...getHostOwnerSearchProps("hostSystemOwner"),
    },
    {
      title: "Discipline",
      filters: disciplineList,
      onFilter: (_text, _row) => _row?.discipline?.code?.indexOf(_text) === 0,
      render: (_text, _row) => (_row?.discipline?.code !== undefined ? _row?.discipline?.code : ""),
    },
    {
      title: "Host / System Owner",
      ...getHostOwnerSearchProps("hostSystemOwner"),
    },
    {
      title: "Work Description",
      render: (_text, _row) => _row?.submission?.workDetails,
      width: "25%",
    },
    // {
    //   title: "Applier name",
    //   /** START For Applier Name Filtering */
    //   ...getColumnSearchProps("fullname"),
    //   /** END For Applier Name Filtering */
    // },
    {
      title: "Applier Site",
      ...getApplierSite("applierSite"),
      render: (_text, _row) => _row?.site?.code,
      width: "5%",
    },
    {
      title: "Date Applied",
      ...getDateAppliedColumnSearch("Date Applied"),
      width: "10%",
    },
    {
      title: "Start Date",
      ...getStartDateColumnSearch("startDate"),
      width: "10%",
    },
    {
      title: "End Date",
      ...getEndDateColumnSearch("dateEnd"),
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "15%",
      ...getStatusData("statusData"),
      render: (status, row) => {
        let color = "orange";
        if (status === PermitStatus.Rejected) color = StatusColor.Rejected;
        else if (status === PermitStatus.Approved) color = StatusColor.Approved;
        else if (status === PermitStatus.Completed) color = StatusColor.Completed;
        else if (status === PermitStatus.Cancelled) color = StatusColor.Cancelled;
        else if (status === PermitStatus.PendingClosure) color = StatusColor.PendingClosure;
        else if (status === PermitStatus.PendingCancel) color = StatusColor.PendingCancel;

        const approvedBy = row?.approvedBy?.length ? row.approvedBy[row?.approvedBy?.length - 1] : null;
        const rejectedBy = row?.rejectedBy;

        let nextReviewer = getNextReviewer(row);

        return (
          <div>
            {status === PermitStatus.Pending ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By PM
              </Tag>
            ) : status === PermitStatus.PendingApproval ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By {nextReviewer}
              </Tag>
            ) : status === PermitStatus.Rejected ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Rejected By {rejectedBy?.fullname}
              </Tag>
            ) : status === PermitStatus.Approved && approvedBy ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Final Approved By {approvedBy?.fullname}
              </Tag>
            ) : status === undefined || status === null ? (
              " "
            ) : (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                {status}
              </Tag>
            )}
          </div>
        );
      },
    },
  ];

  const columnsB4 = [
    {
      title: "#",
      width: "3%",
    },
    {
      title: "Permit Number",
      ...getColumnSearchPermitNumber("permitnumber"),
    },
    {
      title: "Permit Code",
      width: "5%",
      render: (_text, _row) => {
        try {
          let permitNumber = _row?.code ? _row?.code : "SITE- -DATE-NUM";
          let splitPermitNumber = permitNumber.split("-");
          return splitPermitNumber[1];
        } catch (err) {
          // console.log(err);
          return "";
        }
      },
    },
    {
      title: "Discipline",
      filters: disciplineList,
      onFilter: (_text, _row) => _row?.discipline?.code?.indexOf(_text) === 0,
      render: (_text, _row) => (_row?.discipline?.code !== undefined ? _row?.discipline?.code : ""),
    },
    {
      title: "Host / System Owner",
      ...getHostOwnerSearchProps("hostSystemOwner"),
    },
    {
      title: "Work Description",
      render: (_text, _row) => _row?.submission?.workDetails,
      width: "25%",
    },
    // {
    //   title: "Applier name",
    //   /** START For Applier Name Filtering */
    //   ...getColumnSearchProps("fullname"),
    //   /** END For Applier Name Filtering */
    // },
    {
      title: "Applier Site",
      ...getApplierSite("applierSite"),
      render: (_text, _row) => _row?.site?.code,
      width: "5%",
    },
    {
      title: "Date Applied",
      ...getDateAppliedColumnSearch("Date Applied"),
      width: "10%",
    },
    {
      title: "Start Date",
      ...getStartDateColumnSearch("startDate"),
      width: "10%",
    },
    {
      title: "End Date",
      ...getEndDateColumnSearch("dateEnd"),
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "15%",
      ...getStatusData("statusData"),
      render: (status, row) => {
        let color = "orange";
        if (status === PermitStatus.Rejected) color = StatusColor.Rejected;
        else if (status === PermitStatus.Approved) color = StatusColor.Approved;
        else if (status === PermitStatus.Completed) color = StatusColor.Completed;
        else if (status === PermitStatus.Cancelled) color = StatusColor.Cancelled;
        else if (status === PermitStatus.PendingClosure) color = StatusColor.PendingClosure;
        else if (status === PermitStatus.PendingCancel) color = StatusColor.PendingCancel;

        const approvedBy = row?.approvedBy?.length ? row.approvedBy[row?.approvedBy?.length - 1] : null;
        const rejectedBy = row?.rejectedBy;

        let nextReviewer = getNextReviewer(row);

        return (
          <div>
            {status === PermitStatus.Pending ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By PM
              </Tag>
            ) : status === PermitStatus.PendingApproval ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By {nextReviewer}
              </Tag>
            ) : status === PermitStatus.Rejected ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Rejected By {rejectedBy?.fullname}
              </Tag>
            ) : status === PermitStatus.Approved && approvedBy ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Final Approved By {approvedBy?.fullname}
              </Tag>
            ) : status === undefined || status === null ? (
              " "
            ) : (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                {status}
              </Tag>
            )}
          </div>
        );
      },
    },
  ];

  const columnsB5 = [
    {
      title: "#",
      width: "3%",
    },
    {
      title: "Permit Number",
      ...getColumnSearchPermitNumber("permitnumber"),
    },
    // {
    //   title: "Applier name",
    //   /** START For Applier Name Filtering */
    //   ...getColumnSearchProps("fullname"),
    //   /** END For Applier Name Filtering */
    // },
    {
      title: "Permit Code",
      width: "5%",
      render: (_text, _row) => {
        try {
          let permitNumber = _row?.code ? _row?.code : "SITE- -DATE-NUM";
          let splitPermitNumber = permitNumber.split("-");
          return splitPermitNumber[1];
        } catch (err) {
          // console.log(err);
          return "";
        }
      },
    },
    {
      title: "Discipline",
      filters: disciplineList,
      onFilter: (_text, _row) => _row?.discipline?.code?.indexOf(_text) === 0,
      render: (_text, _row) => (_row?.discipline?.code !== undefined ? _row?.discipline?.code : ""),
    },
    {
      title: "Host / System Owner",
      ...getHostOwnerSearchProps("hostSystemOwner"),
    },
    {
      title: "Work Description",
      render: (_text, _row) => _row?.submission?.workDetails,
      width: "25%",
    },
    // {
    //   title: "Applier name",
    //   /** START For Applier Name Filtering */
    //   ...getColumnSearchProps("fullname"),
    //   /** END For Applier Name Filtering */
    // },
    {
      title: "Applier Site",
      ...getApplierSite("applierSite"),
      render: (_text, _row) => _row?.site?.code,
      width: "5%",
    },
    {
      title: "Date Applied",
      ...getDateAppliedColumnSearch("Date Applied"),
      width: "10%",
    },
    {
      title: "Start Date",
      ...getStartDateColumnSearch("startDate"),
      width: "10%",
    },
    {
      title: "End Date",
      ...getEndDateColumnSearch("dateEnd"),
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "15%",
      ...getStatusData("statusData"),
      render: (status, row) => {
        let color = "orange";
        if (status === PermitStatus.Rejected) color = StatusColor.Rejected;
        else if (status === PermitStatus.Approved) color = StatusColor.Approved;
        else if (status === PermitStatus.Completed) color = StatusColor.Completed;
        else if (status === PermitStatus.Cancelled) color = StatusColor.Cancelled;
        else if (status === PermitStatus.PendingClosure) color = StatusColor.PendingClosure;
        else if (status === PermitStatus.PendingCancel) color = StatusColor.PendingCancel;

        const approvedBy = row?.approvedBy?.length ? row.approvedBy[row?.approvedBy?.length - 1] : null;
        const rejectedBy = row?.rejectedBy;

        let nextReviewer = getNextReviewer(row);

        return (
          <div>
            {status === PermitStatus.Pending ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By PM
              </Tag>
            ) : status === PermitStatus.PendingApproval ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By {nextReviewer}
              </Tag>
            ) : status === PermitStatus.Rejected ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Rejected By {rejectedBy?.fullname}
              </Tag>
            ) : status === PermitStatus.Approved && approvedBy ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Final Approved By {approvedBy?.fullname}
              </Tag>
            ) : status === undefined || status === null ? (
              " "
            ) : (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                {status}
              </Tag>
            )}
          </div>
        );
      },
    },
  ];

  const columnsB6 = [
    {
      title: "#",
      width: "3%",
    },
    {
      title: "Permit Number",
      ...getColumnSearchPermitNumber("permitnumber"),
    },
    {
      title: "Permit Code",
      width: "5%",
      render: (_text, _row) => {
        try {
          let permitNumber = _row?.code ? _row?.code : "SITE- -DATE-NUM";
          let splitPermitNumber = permitNumber.split("-");
          return splitPermitNumber[1];
        } catch (err) {
          // console.log(err);
          return "";
        }
      },
    },
    {
      title: "Discipline",
      filters: disciplineList,
      onFilter: (_text, _row) => _row?.discipline?.code?.indexOf(_text) === 0,
      render: (_text, _row) => (_row?.discipline?.code !== undefined ? _row?.discipline?.code : ""),
    },
    {
      title: "Host / System Owner",
      ...getHostOwnerSearchProps("hostSystemOwner"),
    },
    {
      title: "Permit Code",
      width: "5%",
      render: (_text, _row) => {
        try {
          let permitNumber = _row?.code ? _row?.code : "SITE- -DATE-NUM";
          let splitPermitNumber = permitNumber.split("-");
          return splitPermitNumber[1];
        } catch (err) {
          // console.log(err);
          return "";
        }
      },
    },
    {
      title: "Work Description",
      render: (_text, _row) => _row?.submission?.workDetails,
      width: "25%",
    },
    // {
    //   title: "Applier name",
    //   /** START For Applier Name Filtering */
    //   ...getColumnSearchProps("fullname"),
    //   /** END For Applier Name Filtering */
    // },
    {
      title: "Applier Site",
      ...getApplierSite("applierSite"),
      render: (_text, _row) => _row?.site?.code,
      width: "5%",
    },
    {
      title: "Date Applied",
      ...getDateAppliedColumnSearch("Date Applied"),
      width: "10%",
    },
    {
      title: "Start Date",
      ...getStartDateColumnSearch("startDate"),
      width: "10%",
    },
    {
      title: "End Date",
      ...getEndDateColumnSearch("dateEnd"),
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "15%",
      ...getStatusData("statusData"),
      render: (status, row) => {
        let color = "orange";
        if (status === PermitStatus.Rejected) color = StatusColor.Rejected;
        else if (status === PermitStatus.Approved) color = StatusColor.Approved;
        else if (status === PermitStatus.Completed) color = StatusColor.Completed;
        else if (status === PermitStatus.Cancelled) color = StatusColor.Cancelled;
        else if (status === PermitStatus.PendingClosure) color = StatusColor.PendingClosure;
        else if (status === PermitStatus.PendingCancel) color = StatusColor.PendingCancel;

        const approvedBy = row?.approvedBy?.length ? row.approvedBy[row?.approvedBy?.length - 1] : null;
        const rejectedBy = row?.rejectedBy;

        let nextReviewer = getNextReviewer(row);

        return (
          <div>
            {status === PermitStatus.Pending ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By PM
              </Tag>
            ) : status === PermitStatus.PendingApproval ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By {nextReviewer}
              </Tag>
            ) : status === PermitStatus.Rejected ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Rejected By {rejectedBy?.fullname}
              </Tag>
            ) : status === PermitStatus.Approved && approvedBy ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Final Approved By {approvedBy?.fullname}
              </Tag>
            ) : status === undefined || status === null ? (
              " "
            ) : (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                {status}
              </Tag>
            )}
          </div>
        );
      },
    },
  ];

  const columnsB7 = [
    {
      title: "#",
      width: "3%",
    },
    {
      title: "Permit Number",
      ...getColumnSearchPermitNumber("permitnumber"),
    },
    {
      title: "Permit Code",
      width: "5%",
      render: (_text, _row) => {
        try {
          let permitNumber = _row?.code ? _row?.code : "SITE- -DATE-NUM";
          let splitPermitNumber = permitNumber.split("-");
          return splitPermitNumber[1];
        } catch (err) {
          // console.log(err);
          return "";
        }
      },
    },
    {
      title: "Discipline",
      filters: disciplineList,
      onFilter: (_text, _row) => _row?.discipline?.code?.indexOf(_text) === 0,
      render: (_text, _row) => (_row?.discipline?.code !== undefined ? _row?.discipline?.code : ""),
    },
    {
      title: "Host / System Owner",
      ...getHostOwnerSearchProps("hostSystemOwner"),
    },
    {
      title: "Work Description",
      render: (_text, _row) => _row?.submission?.workDetails,
      width: "25%",
    },
    // {
    //   title: "Applier name",
    //   /** START For Applier Name Filtering */
    //   ...getColumnSearchProps("fullname"),
    //   /** END For Applier Name Filtering */
    // },
    {
      title: "Applier Site",
      ...getApplierSite("applierSite"),
      render: (_text, _row) => _row?.site?.code,
      width: "5%",
    },
    {
      title: "Date Applied",
      ...getDateAppliedColumnSearch("Date Applied"),
      width: "10%",
    },
    {
      title: "Start Date",
      ...getStartDateColumnSearch("startDate"),
      width: "10%",
    },
    {
      title: "End Date",
      ...getEndDateColumnSearch("dateEnd"),
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "15%",
      ...getStatusData("statusData"),
      render: (status, row) => {
        let color = "orange";
        if (status === PermitStatus.Rejected) color = StatusColor.Rejected;
        else if (status === PermitStatus.Approved) color = StatusColor.Approved;
        else if (status === PermitStatus.Completed) color = StatusColor.Completed;
        else if (status === PermitStatus.Cancelled) color = StatusColor.Cancelled;
        else if (status === PermitStatus.PendingClosure) color = StatusColor.PendingClosure;
        else if (status === PermitStatus.PendingCancel) color = StatusColor.PendingCancel;

        const approvedBy = row?.approvedBy?.length ? row.approvedBy[row?.approvedBy?.length - 1] : null;
        const rejectedBy = row?.rejectedBy;

        let nextReviewer = getNextReviewer(row);

        return (
          <div>
            {status === PermitStatus.Pending ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By PM
              </Tag>
            ) : status === PermitStatus.PendingApproval ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By {nextReviewer}
              </Tag>
            ) : status === PermitStatus.Rejected ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Rejected By {rejectedBy?.fullname}
              </Tag>
            ) : status === PermitStatus.Approved && approvedBy ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Final Approved By {approvedBy?.fullname}
              </Tag>
            ) : status === undefined || status === null ? (
              " "
            ) : (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                {status}
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Date Applied",
      ...getDateAppliedColumnSearch("Date Applied"),
      width: "10%",
    },
    {
      title: "Start Date",
      ...getStartDateColumnSearch("startDate"),
      width: "10%",
    },
    {
      title: "End Date",
      ...getEndDateColumnSearch("dateEnd"),
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "15%",
      ...getStatusData("statusData"),
      render: (status, row) => {
        let color = "orange";
        if (status === PermitStatus.Rejected) color = StatusColor.Rejected;
        else if (status === PermitStatus.Approved) color = StatusColor.Approved;
        else if (status === PermitStatus.Completed) color = StatusColor.Completed;
        else if (status === PermitStatus.Cancelled) color = StatusColor.Cancelled;
        else if (status === PermitStatus.PendingClosure) color = StatusColor.PendingClosure;
        else if (status === PermitStatus.PendingCancel) color = StatusColor.PendingCancel;

        const approvedBy = row?.approvedBy?.length ? row.approvedBy[row?.approvedBy?.length - 1] : null;
        const rejectedBy = row?.rejectedBy;

        let nextReviewer = getNextReviewer(row);

        return (
          <div>
            {status === PermitStatus.Pending ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By PM
              </Tag>
            ) : status === PermitStatus.PendingApproval ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By {nextReviewer}
              </Tag>
            ) : status === PermitStatus.Rejected ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Rejected By {rejectedBy?.fullname}
              </Tag>
            ) : status === PermitStatus.Approved && approvedBy ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Final Approved By {approvedBy?.fullname}
              </Tag>
            ) : status === undefined || status === null ? (
              " "
            ) : (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                {status}
              </Tag>
            )}
          </div>
        );
      },
    },
  ];

  const onClose = (e) => {
    console.log(e, "Alert was close.");
  };

  function onChangeSite(value, event) {
    setSite(value);
    console.log("change site", event.site.code);
    if (event.site.code === undefined) {
      setGraphHeader("ALL");
    } else {
      setGraphHeader(event.site.code);
    }
  }

  function onChangeDiscipline(value, event) {
    setDiscipline(value);

    console.log("change site", event);
    if (event.discipline === undefined) {
      setDisciplineGraphHeader("ALL");
    } else {
      setDisciplineGraphHeader(event.discipline.code);
    }
  }

  function onClickFilterSiteButton() {
    getEnhancedDboardData({
      variables: {
        permitSite: currentSite,
        currentWeek: currentWeek,
        currentMonth: currentMonth,
        currentYear: currentYear,
      },
    });

    getSiteLowerGraph({
      variables: {
        permitSite: currentSite,
        currentWeek: currentWeek,
        currentMonth: currentMonth,
        currentYear: currentYear,
      },
    });
  }

  function onClickFilterDisciplineButton() {
    getDisciplineGraph({
      variables: {
        discipline: currentDiscipline,
        currentWeek: currentWeek,
        currentMonth: currentMonth,
        currentYear: currentYear,
      },
    });

    getDisciplineLowerGraph({
      variables: {
        discipline: currentDiscipline,
        currentWeek: currentWeek,
        currentMonth: currentMonth,
        currentYear: currentYear,
      },
    });
  }

  function onChangePermit(value) {
    setPermit(value);
  }

  function onChangeWeek(date, dateString) {
    console.log("onchange week", date);
    setWeek(date);
  }
  function onChangeMonth(date, dateString) {
    console.log("onchange month", dateString);
    setMonth(date);
  }
  function onChangeYear(date, dateString) {
    console.log("onchange year", dateString);
    setYear(dateString);
  }
  const onResetDisciplineDataSource = () => {
    setDisciplineSource([]);
  };
  const onResetSiteDataSource = () => {
    setSiteSource([]);
  };
  const onResetSiteDataGraph = () => {
    setSiteSetGraph([]);
  };
  const onResetDisciplineDataGraph = () => {
    setDisciplineSetGraph([]);
  };
  const onToggledWeekFilter = (e) => {
    setDisableWeekFilter(e.target.checked);
    setDisableMonthFilter(false);
    setDisableYearFilter(false);
    if (!e.target.checked) {
      setWeek("");
      setMonth("");
      setYear("");
    }
  };
  const onToggledMonthFilter = (e) => {
    setDisableMonthFilter(e.target.checked);
    setDisableWeekFilter(false);
    setDisableYearFilter(false);
    if (!e.target.checked) {
      setWeek("");
      setMonth("");
      setYear("");
    }
  };
  const onToggledYearFilter = (e) => {
    setDisableYearFilter(e.target.checked);
    setDisableWeekFilter(false);
    setDisableMonthFilter(false);
    if (!e.target.checked) {
      setWeek("");
      setMonth("");
      setYear("");
    }
  };

  // const permitFilterParams = (index) => {
  //   const paramsArr = ["F10A1", "F10A2", "F10N", "F10NX", "F10W", "F10X"];
  //   const paramsArr2 = ["ABC", "UPW", "CDE", "EFG"];
  //   let data = [
  //     permitfilter(paramsArr[index], "SIPP"),
  //     permitfilter(paramsArr[index], "General"),
  //     permitfilter(paramsArr[index], "Work At Height"),
  //     permitfilter(paramsArr[index], "Hot Work"),
  //     permitfilter(paramsArr[index], "Confined Space"),
  //     permitfilter(paramsArr[index], "Lifting"),
  //     permitfilter(paramsArr[index], "LSS"),
  //   ];
  //   let data2 = [
  //     permitfilter(paramsArr2[index], "SIPP"),
  //     permitfilter(paramsArr2[index], "General"),
  //     permitfilter(paramsArr2[index], "Work At Height"),
  //     permitfilter(paramsArr2[index], "Hot Work"),
  //   ];
  //   if (activeTab === "1") {
  //     return data;
  //   } else {
  //   }
  // };

  // const dataSetForAnalytics2 = {
  //   labels: disciplineType,
  //   datasets: [
  //     {
  //       //stack: "stack1",
  //       backgroundColor: "#108ee9",
  //       borderColor: "#108ee9",
  //       label: "ABC",
  //       borderWidth: 1,
  //       hoverBackgroundColor: "#108ee9",
  //       hoverBorderColor: "#108ee9",
  //       data: [disciplineDataFilter("ABC")],
  //     },
  //     // [countSipp,countGen, countWah, countHotWork, countConfSpace, countLifting, countLss]
  //     {
  //       //stack: "stack1",
  //       backgroundColor: "#108ee9",
  //       borderColor: "#108ee9",
  //       label: "UPW",
  //       borderWidth: 1,
  //       hoverBackgroundColor: "#108ee9",
  //       hoverBorderColor: "#108ee9",
  //       data: [
  //         permitfilter("UPW", "SIPP"),
  //         permitfilter("UPW", "General"),
  //         permitfilter("UPW", "Work At Height"),
  //         permitfilter("UPW", "Hot Work"),
  //         permitfilter("UPW", "Confined Space"),
  //         permitfilter("UPW", "Lifting"),
  //         permitfilter("UPW", "LSS"),
  //       ],
  //     },
  //     {
  //       //stack: "stack1",
  //       backgroundColor: "#108ee9",
  //       borderColor: "#108ee9",
  //       label: "CDE",
  //       borderWidth: 1,
  //       hoverBackgroundColor: "#108ee9",
  //       hoverBorderColor: "#108ee9",
  //       data: [
  //         permitfilter("CDE", "SIPP"),
  //         permitfilter("CDE", "General"),
  //         permitfilter("CDE", "Work At Height"),
  //         permitfilter("CDE", "Hot Work"),
  //         permitfilter("CDE", "Confined Space"),
  //         permitfilter("CDE", "Lifting"),
  //         permitfilter("CDE", "LSS"),
  //       ],
  //     },
  //     {
  //       //stack: "stack1",
  //       backgroundColor: "#108ee9",
  //       borderColor: "#108ee9",
  //       label: "EFG",
  //       borderWidth: 1,
  //       hoverBackgroundColor: "#108ee9",
  //       hoverBorderColor: "#108ee9",
  //       data: [discplineMap("UPW")],
  //       data: [
  //         permitfilter("EFG", "SIPP"),
  //         permitfilter("EFG", "General"),
  //         permitfilter("EFG", "Work At Height"),
  //         permitfilter("EFG", "Hot Work"),
  //         permitfilter("EFG", "Confined Space"),
  //         permitfilter("EFG", "Lifting"),
  //         permitfilter("EFG", "LSS"),
  //       ],
  //     },
  //   ],
  // };

  const dataSetForAnalytics = {
    labels: permitType,
    datasets: [
      {
        //stack: "stack1",
        backgroundColor: F10A1_BAR_COLOR,
        borderColor: F10A1_BAR_COLOR,
        label: "F10A1",
        borderWidth: 1,
        hoverBackgroundColor: F10A1_BAR_COLOR,
        hoverBorderColor: F10A1_BAR_COLOR,
        data: [
          permitfilter("F10A1", "SIPP"),
          permitfilter("F10A1", "General"),
          permitfilter("F10A1", "Work At Height"),
          permitfilter("F10A1", "Hot Work"),
          permitfilter("F10A1", "Confined Space"),
          permitfilter("F10A1", "Lifting"),
          permitfilter("F10A1", "LSS"),
        ],
      },
      {
        //stack: "stack1",
        backgroundColor: F10A2_BAR_COLOR,
        borderColor: F10A2_BAR_COLOR,
        label: "F10A2",
        borderWidth: 1,
        hoverBackgroundColor: F10A2_BAR_COLOR,
        hoverBorderColor: F10A2_BAR_COLOR,
        data: [
          permitfilter("F10A2", "SIPP"),
          permitfilter("F10A2", "General"),
          permitfilter("F10A2", "Work At Height"),
          permitfilter("F10A2", "Hot Work"),
          permitfilter("F10A2", "Confined Space"),
          permitfilter("F10A2", "Lifting"),
          permitfilter("F10A2", "LSS"),
        ],
      },
      {
        //stack: "stack1",
        backgroundColor: F10AN_BAR_COLOR,
        borderColor: F10AN_BAR_COLOR,
        label: "F10AN",
        borderWidth: 1,
        hoverBackgroundColor: F10AN_BAR_COLOR,
        hoverBorderColor: F10AN_BAR_COLOR,
        data: [
          permitfilter("F10N", "SIPP"),
          permitfilter("F10N", "General"),
          permitfilter("F10N", "Work At Height"),
          permitfilter("F10N", "Hot Work"),
          permitfilter("F10N", "Confined Space"),
          permitfilter("F10N", "Lifting"),
          permitfilter("F10N", "LSS"),
        ],
      },
      {
        //stack: "stack1",
        backgroundColor: F10NX_BAR_COLOR,
        borderColor: F10NX_BAR_COLOR,
        label: "F10NX",
        borderWidth: 1,
        hoverBackgroundColor: F10NX_BAR_COLOR,
        hoverBorderColor: F10NX_BAR_COLOR,
        data: [
          permitfilter("F10NX", "SIPP"),
          permitfilter("F10NX", "General"),
          permitfilter("F10NX", "Work At Height"),
          permitfilter("F10NX", "Hot Work"),
          permitfilter("F10NX", "Confined Space"),
          permitfilter("F10NX", "Lifting"),
          permitfilter("F10NX", "LSS"),
        ],
      },
      {
        //stack: "stack1",
        backgroundColor: F10W_BAR_COLOR,
        borderColor: F10W_BAR_COLOR,
        label: "F10W",
        borderWidth: 1,
        hoverBackgroundColor: F10W_BAR_COLOR,
        hoverBorderColor: F10W_BAR_COLOR,
        data: [
          permitfilter("F10W", "SIPP"),
          permitfilter("F10W", "General"),
          permitfilter("F10W", "Work At Height"),
          permitfilter("F10W", "Hot Work"),
          permitfilter("F10W", "Confined Space"),
          permitfilter("F10W", "Lifting"),
          permitfilter("F10W", "LSS"),
        ],
      },
      {
        //stack: "stack1",
        backgroundColor: F10X_BAR_COLOR,
        borderColor: F10X_BAR_COLOR,
        label: "F10X",
        borderWidth: 1,
        hoverBackgroundColor: F10X_BAR_COLOR,
        hoverBorderColor: F10X_BAR_COLOR,
        data: [
          permitfilter("F10X", "SIPP"),
          permitfilter("F10X", "General"),
          permitfilter("F10X", "Work At Height"),
          permitfilter("F10X", "Hot Work"),
          permitfilter("F10X", "Confined Space"),
          permitfilter("F10X", "Lifting"),
          permitfilter("F10X", "LSS"),
        ],
      },
    ],
  };
  //CRITICAL ACTIVITIES EXPORT file
  function handleDateFrom(date) {
    setdateFrom(date);
  }
  function handleDateTo(date) {
    setdateTo(date);
  }
  function handlePermitId(_id) {
    setPermitId(_id);
  }
  const handleGenerateExcelFile = () => {
    // var Heading = [
    //   ["Created", "Site", "Critical Risk", "Start WW", "Start Date", "End Date", "FAC Owner", "EHS Owner", "Comments", "Leadeship Validation", "Pre-work Site Run-through", "Require Documents", "Equipment PPE Pre-Check", "Final Run-through", "Approval to Proceed", "Status"]
    // ];
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = moment(dateFrom).format("YYYY-MM-DD") + "_" + moment(dateTo).format("YYYY-MM-DD");
    const dataToExport = JSON.parse(CriticalActivities?.GetCriticalActivities?.results || {});
    console.log(dataToExport);
    const dataArray = dataToExport.map((res) => {
      return res.children.map((result) => {
        return {
          result: { ...result.submission, code: res.code, status: res.status, criticalRisks: result.form.title },
        };
      });
    });

    const merged = [].concat.apply([], dataArray);

    const submissionChild = merged.map((res) => res.result);
    const rewriteData = submissionChild.map((result) => {
      return {
        Site: result.workDoneAt,
        "Critical Risks": result.criticalRisks,
        Activity: result.workDescription,
        "WO/Permit": result.code,
        "Start WW": result.startDate,
        "End dateEnd (Max 7 Days)": result.SIPPApprovedEndDate,
        "FAC Owner": result.SippCoordName,
        "EHS Owner": "",
        "Comments for NA": "",
        "Contactor Owner": result.RequesterName,
        "Leadeship Validation": "",
        "Pre-work Site run through": "",
        "Required Documents": "",
        "Equipment PPE Pre-Check": "",
        "Final Run Through": "",
        "Approval To Proceed": "",
        Status: result.status,
      };
    });

    // const dataToGenerate = JSON.parse(CriticalActivities?.GetPermit?.permit || {});
    const ws = XLSX.utils.json_to_sheet(rewriteData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);

    console.log("EXPORT FILE");
  };
  //TABLE DATA
  const disciplineGraphConfig = {
    data: disciplineSetGraph,
    xField: "discipline",
    yField: "count",
    label: {
      position: "middle",
      // 'top', 'bottom', 'middle',
      style: {
        fill: "#FFFFFF",
        opacity: 0.6,
      },
    },
    // xAxis: {
    //   label: {
    //     autoHide: false,
    //     autoRotate: false,
    //   },
    // },
    // columnWidthRatio: 0.8,
    xAxis: {
      label: {
        autoRotate: false,
      },
    },
    appendPadding: 10,
    // scrollbar: {
    //   type: "horizontal",
    // },
    slider: {
      start: 0.1,
      end: 0.2,
    },
  };

  const disciplineLowerGraphColumns = [
    {
      title: "",
      dataIndex: "discipline",
      key: "discipline",
      render: (text) => <a>{text}</a>,
      fixed: "left",
    },
    {
      title: "January",
      render: (dispData) => (
        <>
          {dispData?.data?.map((o) => {
            if (o.month === "January") {
              if (o.count != 0) {
                return <>{o.count}</>;
              }
            }
          })}
        </>
      ),
    },
    {
      title: "February",
      render: (dispData) => (
        <>
          {dispData?.data?.map((o) => {
            if (o.month === "February") {
              if (o.count != 0) {
                return <>{o.count}</>;
              }
            }
          })}
        </>
      ),
    },
    {
      title: "March",
      render: (dispData) => (
        <>
          {dispData?.data?.map((o) => {
            if (o.month === "March") {
              if (o.count != 0) {
                return <>{o.count}</>;
              }
            }
          })}
        </>
      ),
    },
    {
      title: "April",
      render: (dispData) => (
        <>
          {dispData?.data?.map((o) => {
            if (o.month === "April") {
              if (o.count != 0) {
                return <>{o.count}</>;
              }
            }
          })}
        </>
      ),
    },
    {
      title: "May",
      render: (dispData) => (
        <>
          {dispData?.data?.map((o) => {
            if (o.month === "May") {
              if (o.count != 0) {
                if (o.count != 0) {
                  return <>{o.count}</>;
                }
              }
            }
          })}
        </>
      ),
    },
    {
      title: "June",
      render: (dispData) => (
        <>
          {dispData?.data?.map((o) => {
            if (o.month === "June") {
              if (o.count != 0) {
                return <>{o.count}</>;
              }
            }
          })}
        </>
      ),
    },
    {
      title: "July",
      render: (dispData) => (
        <>
          {dispData?.data?.map((o) => {
            if (o.month === "July") {
              if (o.count != 0) {
                return <>{o.count}</>;
              }
            }
          })}
        </>
      ),
    },
    {
      title: "August",
      render: (dispData) => (
        <>
          {dispData?.data?.map((o) => {
            if (o.month === "August") {
              if (o.count != 0) {
                return <>{o.count}</>;
              }
            }
          })}
        </>
      ),
    },
    {
      title: "September",
      render: (dispData) => (
        <>
          {dispData?.data?.map((o) => {
            if (o.month === "September") {
              if (o.count != 0) {
                return <>{o.count}</>;
              }
            }
          })}
        </>
      ),
    },
    {
      title: "October",
      render: (dispData) => (
        <>
          {dispData?.data?.map((o) => {
            if (o.month === "October") {
              if (o.count != 0) {
                return <>{o.count}</>;
              }
            }
          })}
        </>
      ),
    },
    {
      title: "November",
      render: (dispData) => (
        <>
          {dispData?.data?.map((o) => {
            if (o.month === "November") {
              if (o.count != 0) {
                return <>{o.count}</>;
              }
            }
          })}
        </>
      ),
    },
    {
      title: "December",
      render: (dispData) => (
        <>
          {dispData?.data?.map((o) => {
            if (o.month === "December") {
              if (o.count != 0) {
                return <>{o.count}</>;
              }
            }
          })}
        </>
      ),
    },
  ];

  const lowerGraphColumns = [
    {
      title: "",
      dataIndex: "month",
      key: "spacer",
      width: 120,
      fixed: "left",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "SIPP",
      dataIndex: "sippCount",
      key: "sippCount",
      width: 102.63,
      render: (_, { sippCount }) => <>{sippCount === 0 ? <> </> : <> {sippCount} </>}</>,
    },
    {
      title: "General",
      dataIndex: "generalCount",
      key: "generalCount",
      width: 102.63,
      render: (_, { generalCount }) => <>{generalCount === 0 ? <> </> : <> {generalCount} </>}</>,
    },
    {
      title: "WAH",
      dataIndex: "wahCount",
      key: "wahCount",
      width: 102.63,
      render: (_, { wahCount }) => <>{wahCount === 0 ? <> </> : <> {wahCount} </>}</>,
    },
    {
      title: "Hot Work",
      dataIndex: "hotWorkCount",
      key: "hotWorkCount",
      width: 102.63,
      render: (_, { hotWorkCount }) => <>{hotWorkCount === 0 ? <> </> : <> {hotWorkCount} </>}</>,
    },
    {
      title: "Confined Space",
      dataIndex: "confinedSpaceCount",
      key: "confinedSpaceCount",
      width: 102.63,
      render: (_, { confinedSpaceCount }) => <>{confinedSpaceCount === 0 ? <> </> : <> {confinedSpaceCount} </>}</>,
    },
    {
      title: "Lifting",
      dataIndex: "liftingCount",
      key: "liftingCount",
      width: 102.63,
      render: (_, { liftingCount }) => <>{liftingCount === 0 ? <> </> : <> {liftingCount} </>}</>,
    },
    {
      title: "LSS",
      dataIndex: "lssCount",
      key: "lssCount",
      width: 102.63,
      render: (_, { lssCount }) => <>{lssCount === 0 ? <> </> : <> {lssCount} </>}</>,
    },
  ];

  return (
    <div className="container-fluid">
      <Alert
        style={{ marginBottom: "2%" }}
        message="Informational Notes"
        description="Dear requester, please close all completed permit by clicking pending for closure and it will route to OPS to close permit completely . 
        Take note 📝 you are not allow to apply for more permits once quota is up."
        type="info"
        showIcon
        closable
        onClose={onClose}
      />

      {authUser.role === UserRole.ContractorRequestor || authUser.role === UserRole.ContractorPM ? (
        <div>
          <h2>Hi, {authUser.email} </h2>
        </div>
      ) : (
        <React.Fragment>
          <Collapse defaultActiveKey={["0"]} onChange={callback} style={{ width: "100%" }}>
            <Panel
              header={total === undefined ? <Spin /> : "_GENERAL PERMIT TO WORK" + "(" + total + ")"}
              key="_GENERAL PERMIT TO WORK"
            >
              <DynamicList
                loading={loading}
                columns={columnsGeneral}
                data={results}
                total={total}
                pageSize={limit}
                onChange={onChangePagination}
              />
            </Panel>
          </Collapse>

          <Collapse defaultActiveKey={["1"]} onChange={callback} style={{ width: "100%" }}>
            <Panel
              header={b1total === undefined ? <Spin /> : "B1 - HOT WORKS" + "(" + b1total + ")"}
              key="B1 - HOT WORKS"
            >
              <DynamicList
                loading={b1Loading}
                columns={columnsB1}
                data={b1result}
                total={b1count}
                pageSize={limit}
                onChange={onChangePagination}
              />
            </Panel>
          </Collapse>

          <Collapse defaultActiveKey={["2"]} onChange={callback} style={{ width: "100%" }}>
            <Panel
              header={b2total === undefined ? <Spin /> : "B2 - CONFINED SPACE" + "(" + b2total + ")"}
              key="B2 - CONFINED SPACE"
            >
              <DynamicList
                loading={b2Loading}
                columns={columnsB2}
                data={b2result}
                total={b2total}
                pageSize={limit}
                onChange={onChangePagination}
              />
            </Panel>
          </Collapse>

          <Collapse defaultActiveKey={["3"]} onChange={callback} style={{ width: "100%" }}>
            <Panel header={b3total === undefined ? <Spin /> : "B3 - LIFTINGE" + "(" + b3total + ")"} key="B3 - LIFTING">
              <DynamicList
                loading={b3Loading}
                columns={columnsB3}
                data={b3result}
                total={b3total}
                pageSize={limit}
                onChange={onChangePagination}
              />
            </Panel>
          </Collapse>

          <Collapse defaultActiveKey={["4"]} onChange={callback} style={{ width: "100%" }}>
            <Panel
              header={b4total === undefined ? <Spin /> : "B4 - WORK AT HEIGHT" + "(" + b4total + ")"}
              key="B4 - WORK AT HEIGH"
            >
              <DynamicList
                loading={b4Loading}
                columns={columnsB4}
                data={b4result}
                total={b4count}
                pageSize={limit}
                onChange={onChangePagination}
              />
            </Panel>
          </Collapse>

          <Collapse defaultActiveKey={["5"]} onChange={callback} style={{ width: "100%" }}>
            <Panel
              header={b5total === undefined ? <Spin /> : "B5 - LSS IMPAIRMENT PERMIT" + "(" + b3total + ")"}
              key="B5 - LSS IMPAIRMENT PERMIT"
            >
              <DynamicList
                loading={b5Loading}
                columns={columnsB5}
                data={b5result}
                total={b5total}
                pageSize={limit}
                onChange={onChangePagination}
              />
            </Panel>
          </Collapse>

          <Collapse defaultActiveKey={["6"]} onChange={callback} style={{ width: "100%" }}>
            <Panel
              header={b6total === undefined ? <Spin /> : "B6 - LIVE ELECTRICAL PERMIT" + "(" + b6total + ")"}
              key="B6 - LIVE ELECTRICAL PERMIT"
            >
              <DynamicList
                loading={b6Loading}
                columns={columnsB6}
                data={b6result}
                total={b6total}
                pageSize={limit}
                onChange={onChangePagination}
              />
            </Panel>
          </Collapse>

          <Collapse defaultActiveKey={["7"]} onChange={callback} style={{ width: "100%" }}>
            <Panel
              header={b7total === undefined ? <Spin /> : "B7 - SIPP PERMIT" + "(" + b7total + ")"}
              key="B7 - SIPP PERMIT"
            >
              <DynamicList
                loading={b7Loading}
                columns={columnsB7}
                data={b7result}
                total={b7total}
                pageSize={limit}
                onChange={onChangePagination}
              />
            </Panel>
          </Collapse>

          <hr />

          <div className="row dec-row">
            <div className="col-12 col-md-12">
              <Tabs defaultActiveKey="1" onChange={tabPaneCallback}>
                <TabPane tab="Site Graph" key="1">
                  <div className="card">
                    <Spin spinning={GraphLoading} tip="Loading Dashboard Analytics. Please wait..">
                      <div className="row dec-row mb-3">
                        <div className="col-6 col-md-2 text-left">
                          <h4 className="text-center">{graphHeader} PTW</h4>
                        </div>
                      </div>
                      {/* ENHANCED DASHBOARD */}
                      <div className="row mb-3">
                        <div className="col-3 col-md-3">
                          <div className="col-12 col-md-12 mt-2">
                            <div>
                              <Checkbox
                                name="site-wmy"
                                onChange={onToggledWeekFilter}
                                disabled={disableMonthFilter || disableYearFilter}
                              >
                                {" "}
                                Weekly{" "}
                              </Checkbox>
                            </div>
                            <div>
                              <Checkbox
                                name="site-wmy"
                                onChange={onToggledMonthFilter}
                                disabled={disableWeekFilter || disableYearFilter}
                              >
                                {" "}
                                Monthly{" "}
                              </Checkbox>
                            </div>
                            <div>
                              <Checkbox
                                name="site-wmy"
                                onChange={onToggledYearFilter}
                                disabled={disableWeekFilter || disableMonthFilter}
                              >
                                {" "}
                                Year{" "}
                              </Checkbox>
                            </div>
                          </div>
                          <div className="col-12 col-md-12 mt-5">
                            {disableWeekFilter || disableMonthFilter || disableYearFilter || (
                              <>
                                <input
                                  type="text"
                                  class="ant-picker"
                                  placeholder="Select Monthly, Weekly, or Yearly"
                                  style={{ width: "100%" }}
                                  disabled={true}
                                />
                              </>
                            )}
                            {disableWeekFilter && (
                              <>
                                <DatePicker
                                  onChange={onChangeWeek}
                                  picker="week"
                                  placeholder="Select Week"
                                  style={{ width: "100%" }}
                                  disabled={disableMonthFilter || disableYearFilter || !disableWeekFilter}
                                />
                              </>
                            )}

                            {disableMonthFilter && (
                              <DatePicker
                                onChange={onChangeMonth}
                                picker="month"
                                placeholder="Select Month"
                                style={{ width: "100%" }}
                                disabled={disableWeekFilter || disableYearFilter || !disableMonthFilter}
                              />
                            )}

                            {disableYearFilter && (
                              <DatePicker
                                onChange={onChangeYear}
                                picker="year"
                                placeholder="Select Year"
                                style={{ width: "100%" }}
                                disabled={disableWeekFilter || disableMonthFilter || !disableYearFilter}
                              />
                            )}
                          </div>

                          <div className="col-12 col-md-12 mt-3">
                            <Select style={{ width: "100%" }} placeholder="Select Site" onChange={onChangeSite}>
                              <Option key="ALL" value="ALL" site="ALL">
                                {"ALL"}
                              </Option>
                              {SiteList?.GetSites?.results
                                ?.sort((a, b) => a.code.localeCompare(b.code))
                                .map((_site) => (
                                  <Option key={_site._id} value={_site._id} site={_site}>
                                    {_site.code}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                          <div className="col-12 col-md-12 mt-3">
                            <Button type="primary" style={{ width: "100%" }} onClick={onClickFilterSiteButton}>
                              Filter
                            </Button>
                          </div>
                          <div className="col-12 col-md-12 mt-3" style={{ height: "100%" }}>
                            <div className="col-12 col-md-12 mt-3" style={{ height: "120px", background: "#7c36ac" }}>
                              <div className="dashboard-data-box" style={{ color: "#fff " }}>
                                <p style={{ color: "#fff" }}>PTW in total</p>
                                <h4 style={{ color: "#fff" }}>{totalPermits && totalPermits}</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-9 col-md-9">
                          <Bar
                            data={dataSetForAnalytics}
                            plugins={[ChartDataLabels]}
                            options={{
                              plugins: {
                                datalabels: {
                                  display: true,
                                  color: "#303030",
                                },
                              },
                            }}
                          />
                        </div>
                      </div>

                      <Divider />
                      <div className="row mb-3">
                        <div className="col-3 col-md-3"></div>
                        <div className="col-12 col-md-12">
                          <Spin
                            spinning={SiteGraphLoading}
                            tip="Preparing the data for you. This may take atleast one minute or less.. Please wait.."
                          >
                            <Table
                              dataSource={siteSource}
                              columns={lowerGraphColumns}
                              pagination={false}
                              bordered
                              scroll={{ x: 1500, y: 500 }}
                            />
                          </Spin>
                        </div>
                      </div>
                    </Spin>
                  </div>
                </TabPane>

                <TabPane tab="Discipline Graph" key="2">
                  <div className="card">
                    <Spin
                      spinning={DisciplineGraphDataLoading}
                      tip="Checking for all Disciplines on Permits. Please wait.."
                    >
                      <div className="row dec-row mb-3">
                        <div className="col-6 col-md-2 text-left">
                          <h4 className="text-center">{disciplineGraphHeader} Discipline</h4>
                        </div>
                      </div>
                      {/* DISCIPLINE DASHBOARD */}
                      <div className="row mb-3">
                        <div className="col-3 col-md-3">
                          <div className="col-12 col-md-12 mt-2">
                            <div>
                              <Checkbox
                                name="disc-wmy"
                                onChange={onToggledWeekFilter}
                                disabled={disableMonthFilter || disableYearFilter}
                              >
                                {" "}
                                Weekly{" "}
                              </Checkbox>
                            </div>
                            <div>
                              <Checkbox
                                name="disc-wmy"
                                onChange={onToggledMonthFilter}
                                disabled={disableWeekFilter || disableYearFilter}
                              >
                                {" "}
                                Monthly{" "}
                              </Checkbox>
                            </div>
                            <div>
                              <Checkbox
                                name="disc-wmy"
                                onChange={onToggledYearFilter}
                                disabled={disableWeekFilter || disableMonthFilter}
                              >
                                {" "}
                                Yearly{" "}
                              </Checkbox>
                            </div>
                          </div>
                          <div className="col-12 col-md-12 mt-5">
                            {disableWeekFilter || disableMonthFilter || disableYearFilter || (
                              <>
                                <input
                                  type="text"
                                  class="ant-picker"
                                  placeholder="Select Monthly, Weekly, or Yearly"
                                  style={{ width: "100%" }}
                                  disabled={true}
                                />
                              </>
                            )}
                            {disableWeekFilter && (
                              <>
                                <DatePicker
                                  onChange={onChangeWeek}
                                  picker="week"
                                  placeholder="Select Week"
                                  style={{ width: "100%" }}
                                  disabled={disableMonthFilter || disableYearFilter || !disableWeekFilter}
                                />
                              </>
                            )}

                            {disableMonthFilter && (
                              <DatePicker
                                onChange={onChangeMonth}
                                picker="month"
                                placeholder="Select Month"
                                style={{ width: "100%" }}
                                disabled={disableWeekFilter || disableYearFilter || !disableMonthFilter}
                              />
                            )}

                            {disableYearFilter && (
                              <DatePicker
                                onChange={onChangeYear}
                                picker="year"
                                placeholder="Select Year"
                                style={{ width: "100%" }}
                                disabled={disableWeekFilter || disableMonthFilter || !disableYearFilter}
                              />
                            )}
                          </div>

                          <div className="col-12 col-md-12 mt-3">
                            <Select
                              style={{ width: "100%" }}
                              placeholder="Select Discipline"
                              onChange={onChangeDiscipline}
                            >
                              <Option key="ALL" value="ALL" site="ALL">
                                {"ALL"}
                              </Option>
                              {DisciplineList?.GetAllDisciplines?.results
                                // ?.sort((a, b) => a.code.localeCompare(b.code))
                                ?.map((_discipline) => (
                                  <Option key={_discipline._id} value={_discipline._id} discipline={_discipline}>
                                    {_discipline.code}
                                  </Option>
                                ))}
                            </Select>
                          </div>
                          <div className="col-12 col-md-12 mt-3">
                            <Button type="primary" style={{ width: "100%" }} onClick={onClickFilterDisciplineButton}>
                              Filter
                            </Button>
                          </div>
                          <div className="col-12 col-md-12 mt-3" style={{ height: "100%" }}>
                            <div className="col-12 col-md-12 mt-3" style={{ height: "130px", background: "#7c36ac" }}>
                              <div className="dashboard-data-box" style={{ color: "#fff " }}>
                                <p style={{ color: "#fff" }}>PTW in total</p>
                                <h3 style={{ color: "#fff" }}>{disciplineTotalPermits && disciplineTotalPermits}</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-9 col-md-9">
                          <div></div>
                          <Column {...disciplineGraphConfig} />
                        </div>
                      </div>

                      <Divider />
                      <div className="row mb-3">
                        <div className="col-3 col-md-3"></div>
                        <div className="col-12 col-md-12">
                          <Spin
                            spinning={DisciplineLowerGraphLoading}
                            tip="Preparing the data for you. This may take atleast one minute or less.. Please wait.."
                          >
                            <Table
                              dataSource={disciplineSource}
                              columns={disciplineLowerGraphColumns}
                              pagination={false}
                              bordered
                              scroll={{ x: 1500, y: 500 }}
                            />
                          </Spin>
                        </div>
                      </div>
                    </Spin>
                  </div>
                </TabPane>
              </Tabs>
            </div>

            <div className="card">
              <div className="col-12 col-md-12">
                <div className="col-6 col-md-2 text-left">
                  <h4 className="text-center">Critical Activities</h4>
                </div>
                <div className="row dec-row">
                  <div className="col-2 col-md-2">
                    <DatePicker placeholder="Date From" style={{ width: "100%" }} onChange={handleDateFrom} />
                  </div>

                  <div className="col-2 col-md-2">
                    <DatePicker placeholder="Date To" style={{ width: "100%" }} onChange={handleDateTo} />
                  </div>

                  <div className="col-2 col-md-2">
                    <Select style={{ width: "100%" }} placeholder="Select Permit" onChange={handlePermitId}>
                      {cardsData
                        ?.sort((a, b) => a.title.localeCompare(b.title))
                        .map((card) => (
                          <Option key={card._id} value={card.title}>
                            {card.title}
                          </Option>
                        ))}
                    </Select>
                  </div>

                  <div className="col-2 col-md-2">
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        handleGenerateExcelFile();
                      }}
                    >
                      Export to Excel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
