import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";

import { GET_GROUP_LIST, GET_ALL_MTGROUPS } from "../graphql/modules";

export const IsPermitCloser = async (personId) => {
  const { data: GroupList } = useQuery(GET_ALL_MTGROUPS, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });
  let isUserPermitCloserMember = false;

  try {
    const groupData = GroupList?.GetAllMTGroups?.results;
    // console.log("IsPermitCloser : groupData: " + JSON.stringify(groupData, null, 2));
    // console.log("IsPermitCloser : groupData count: " + groupData.length);
    // console.log("IsPermitCloser : GroupList?.GetAllMTGroups?.count: " + GroupList?.GetAllMTGroups?.count);

    if (groupData !== null || groupData !== undefined || groupData !== []) {
      for (var i = 0; i < GroupList?.GetAllMTGroups?.count; i++) {
        if (groupData[i].members && groupData[i].members.some((usr) => usr._id.toString() === personId.toString())) {
          // console.log("user is member of " + groupData[i].name + " MTGroup");
          // console.log(groupData[i].name + " MTGroup can close permit? " + groupData[i].permitCloser);

          if (groupData[i].permitCloser === true) {
            isUserPermitCloserMember = true;
          }
          break;
        }
      }

      // resolve(isUserPermitCloserMember);
    } else {
      // console.log("No MTGroups available.");
      isUserPermitCloserMember = false;
      // resolve(isUserPermitCloserMember);
    }
  } catch (err) {
    console.log(err);
    isUserPermitCloserMember = false;
  }

  return isUserPermitCloserMember;
};

export const IsMTGroupMember = async (personId) => {
  const { data: GroupList } = useQuery(GET_ALL_MTGROUPS, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  let isUserMTGroupMember = false;

  try {
    const groupData = await GroupList?.GetAllMTGroups?.results;

    if (groupData !== null || groupData !== undefined) {
      for (var i = 0; i < GroupList?.GetAllMTGroups?.count; i++) {
        if (
          groupData[i]?.members &&
          groupData[i]?.members?.some((usr) => usr?._id.toString() === personId.toString())
        ) {
          // console.log("user is member of " + groupData[i]?.name + " MTGroup");
          isUserMTGroupMember = true;
          break;
        }
      }
    } else {
      // console.log("No MTGroups available.");
    }
  } catch (err) {
    console.log(err);
  }

  return isUserMTGroupMember;
};

export const IsUserMTGroupSiteSameWithPermitSite = async (personId, permitSite) => {
  const { data: GroupList } = useQuery(GET_ALL_MTGROUPS, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  let permitSiteSameWithMTGroupSite = false;

  try {
    const groupData = await GroupList?.GetAllMTGroups?.results;

    if (groupData !== null || groupData !== undefined) {
      for (var i = 0; i < GroupList?.GetAllMTGroups?.count; i++) {
        if (
          groupData[i]?.members &&
          groupData[i]?.members?.some((usr) => usr?._id.toString() === personId.toString())
        ) {
          // console.log("user is member of " + groupData[i]?.name + " MTGroup");
          if (groupData[i].site._id.toString() === permitSite.toString()) {
            console.log(
              "permit site " +
                permitSite.toString() +
                " is equal to MTGroup Site " +
                groupData[i]?.site?._id.toString() +
                "\n Permit can be cancelled or closed by the user."
            );

            permitSiteSameWithMTGroupSite = true;
          }
          break;
        }
      }
    } else {
      console.log("No MTGroups available.");
    }
  } catch (err) {
    console.error(err);
  }

  return permitSiteSameWithMTGroupSite;
};

// export const IndividualApproveCount = async (approvedBy, cleanSelectedUsers) => {
//   let promise = new Promise(function (resolve, reject) {
//     let indApproveCount = 0;

//     /** Count the number of individual approves */
//     approvedBy.map((usr) => {
//       if (cleanSelectedUsers && cleanSelectedUsers.some((o) => o._id === usr._id)) {
//         indApproveCount++;
//       }
//     });

//     resolve(indApproveCount);
//   });

//   let result = await promise;
//   return result;
// };

// export const MTGroupApproveCount = async (selectedMtGroup, approvedBy, mtGrpCount) => {
//   let promise = new Promise(function (resolve, reject) {
//     let mtUsrApproveCount = 0;

//     /** Count the number of MTGroup approves */
//     for (var i = 0; i < mtGrpCount; i++) {
//       selectedMtGroup[i]?.members &&
//         selectedMtGroup[i]?.members.map((usrs) => {
//           if (approvedBy && approvedBy?.some((o) => o?._id === usrs._id)) {
//             mtUsrApproveCount++;
//           }
//         });
//     }

//     resolve(mtUsrApproveCount);
//   });

//   let result = await promise;
//   return result;
// };

// export const getNextReviewer = async (permit) => {
//   const selectedUsers = permit?.selectedUser?.length ? permit?.selectedUser : null;
//   const approvedReviewers = permit?.approvedBy?.length ? permit?.approvedBy : null;

//   let nextReviewer = "";
//   let _individualReviewers = selectedUsers;

//   if (approvedReviewers === null) {
//     nextReviewer = selectedUsers[0]?.fullname;
//   } else {
//     //remove those who approved already
//     for (var i = 0; i < _individualReviewers?.length; i++) {
//       if (approvedReviewers.includes(selectedUsers[i]?._id)) {
//         _individualReviewers?.splice(i, 1);
//       }
//     }

//     if (IsMTGroupMember(_individualReviewers[0]?._id)) {
//       nextReviewer = "MTGroup";
//     } else {
//       nextReviewer = _individualReviewers[0]?.fullname;
//     }
//   }

//   return nextReviewer;
// };

export const userInApprovedBy = (id, allApproves) => {
  if (allApproves !== null) {
    let found = allApproves.find((usr) => usr?._id === id);

    if (found) {
      return true;
    }
    return false;
  } else {
    return false;
  }
};

export const getIndividualReviewersOnly = (selectedUsers, selectedMTGroupUsers) => {
  let individualReviewers = selectedUsers?.map((user) => {
    if (!selectedMTGroupUsers?.some((usr) => usr?._id === user?._id)) {
      return user;
    }
  });
  return _.compact(individualReviewers);
};

export const getMTGroupApproveCount = (permit) => {
  const approvedBy = permit?.approvedBy?.length ? permit?.approvedBy : null;
  const selectedMTGroups = permit?.selectedMTGroup?.length ? permit?.selectedMTGroup : null;

  let mtGrpCount = 0;
  let mtUsrApproveCount = 0;

  if (selectedMTGroups !== null) {
    mtGrpCount = selectedMTGroups?.length;

    /** Count the number of MTGroup approves */
    for (var i = 0; i < mtGrpCount; i++) {
      selectedMTGroups[i]?.members?.map((usrs) => {
        if (approvedBy?.some((o) => o._id === usrs._id)) {
          mtUsrApproveCount++;
        }
      });
    }
  }

  return mtUsrApproveCount;
};

export const getIndividualReviewerApproveCount = (permit) => {
  const approvedBy = permit?.approvedBy?.length ? permit?.approvedBy : null;
  const selectedUsers = permit?.selectedUser?.length ? permit?.selectedUser : null;
  const selectedMTGroupUsers = permit?.selectedMTGroupUsers?.length ? permit?.selectedMTGroupUsers : null;

  let cleanSelectedUsers = getIndividualReviewersOnly(selectedUsers, selectedMTGroupUsers);

  let indApproveCount = 0;

  /** Count the number of individual approves */
  approvedBy?.map((usr) => {
    if (cleanSelectedUsers?.some((o) => o?._id === usr?._id)) {
      indApproveCount++;
    }
  });

  return indApproveCount;
};

export const getApproveCountNeeded = (permit) => {
  const selectedUsers = permit?.selectedUser?.length ? permit?.selectedUser : null;
  const selectedMTGroupUsers = permit?.selectedMTGroupUsers?.length ? permit?.selectedMTGroupUsers : null;
  const selectedMTGroups = permit?.selectedMTGroup?.length ? permit?.selectedMTGroup : null;

  let mtGrpCount = 0;
  let cleanSelectedUsers = getIndividualReviewersOnly(selectedUsers, selectedMTGroupUsers);

  if (Array.isArray(selectedMTGroups)) {
    mtGrpCount = selectedMTGroups.length;
  }

  return mtGrpCount + cleanSelectedUsers.length;
};

export const getNumberOfApproves = (permit) => {
  const selectedUsers = permit?.selectedUser?.length ? permit?.selectedUser : null;
  const selectedMTGroupUsers = permit?.selectedMTGroupUsers?.length ? permit?.selectedMTGroupUsers : null;
  const selectedMTGroups = permit?.selectedMTGroups?.length ? permit?.selectedMTGroups : null;
  // const approvedBy = permit?.approvedBy?.length ? permit?.approvedBy : null;

  let individualApproveCount = 0;
  let mtGroupApproveCount = 0;

  let cleanSelectedUsers = getIndividualReviewersOnly(selectedUsers, selectedMTGroupUsers);

  if (Array.isArray(cleanSelectedUsers)) {
    individualApproveCount = getIndividualReviewerApproveCount(permit);
  }

  if (Array.isArray(selectedMTGroups)) {
    mtGroupApproveCount = getMTGroupApproveCount(permit);
  }

  return individualApproveCount + mtGroupApproveCount;
};

/**
 * arr1 is equals to all the required fields (na)
 * arr2 is equals to all the fields that are filled by user
 *
 * method will return boolean, check if all required fields
 * are filled-in by user
 */
export const validateFulfilledRequiredFields = (arr1, formState) => {
  let fulfilled = true;

  try {
    for (let i = 0; i < arr1?.length; i++) {
      if (formState.hasOwnProperty(arr1[i]?.name)) {
        if (!formState) {
          fulfilled = false;
          break;
        }
      } else {
        fulfilled = false;
        break;
      }
    }

    return fulfilled;
  } catch (error) {
    console.log(error);
    return false;
  }
};
