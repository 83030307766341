import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";

import { UPDATE_DISCIPLINE, GET_SINGLE_DISCIPLINE } from "../graphql/modules";
import { successNotify, warnNotify } from "../util";
import { Spin } from "antd";
import { UserForm } from "../components/User";
import { DisciplineForm } from "../components/Discipline";

export const EditDiscipline = ({ match }) => {
  const history = useHistory();
  let id = match.params.id;

  const { data, loading } = useQuery(GET_SINGLE_DISCIPLINE, {
    variables: {
      id: id,
    },
  });

  const [state, setState] = useState({
    code: "",
    description: "",
  });

  useEffect(() => {
    const { code = "", description = "" } =
      data?.GetDiscipline?.result || {};
    setState({
      code: code || "",
      description: description || "",
    });
  }, [data]);

  const [UpdateMutation, { loading: updateLoading }] = useMutation(UPDATE_DISCIPLINE, {
    variables: {
      id,
      data: state,
    },
  });

  const onUpdate = async (e) => {
    e.preventDefault();
    try {
      const {
        data: { UpdateDiscipline },
      } = await UpdateMutation();

      console.log(UpdateDiscipline);
      if (UpdateDiscipline?.success) {
        successNotify(UpdateDiscipline?.message);
        history.push("/disciplines");
      } else {
        warnNotify(UpdateDiscipline?.message);
      }
    } catch (error) {
      console.log(error.message);
      warnNotify(error.message);
    }
  };

  const handleChange = (value) => setState({ ...state, ...value });
  // console.log(state);
  return (
    <Spin spinning={loading}>
      <DisciplineForm
        title="Edit Discipline"
        btnText="Update Discipline"
        btnPassword="Discipline"
        resetId={id}
        onSubmit={onUpdate}
        loading={updateLoading}
        handleChange={handleChange}
        state={state}
      />

    </Spin>
  );
};
