import { useLazyQuery } from "@apollo/react-hooks";
import React, { useState, useEffect, Fragment } from "react";
import { DatePicker, Button, Spin, Collapse, Table, Typography } from "antd";
import moment from "moment";

import { GET_SIPP_MONTHLY } from "../graphql/modules";
import { formatDate, getWorkHours } from "../util";

const { Panel } = Collapse;
const { Title } = Typography;

export const SippWorks = () => {
  // const [currentWeek, setWeek] = useState("");
  const [currentMonth, setMonth] = useState("");
  // const [currentYear, setYear] = useState("");

  const [sippPermits, setSippPermits] = useState("");

  function onChangeMonth(date, dateString) {
    console.log("onchange month", dateString);
    setMonth(dateString);
  }

  const [getSippPermits, { data: GraphData, loading: GraphLoading }] = useLazyQuery(GET_SIPP_MONTHLY, {
    variables: {
      limit: 0,
      currentMonth: currentMonth,
    },
    fetchPolicy: "cache-and-network",
  });

  function onClickFilterSiteButton() {
    getSippPermits({
      variables: {
        currentMonth: currentMonth,
      },
    });
  }

  function totalApprovedAndPendingPermits(approved, pending) {
    var total = approved + pending;
    return total;
  }

  useEffect(() => {
    setSippPermits(GraphData?.GetSippMonthlyReports?.data);
  }, [GraphData?.GetSippMonthlyReports]);

  const cellTableColumns = [
    {
      title: "LID",
    },
    {
      title: "Area",
    },
    {
      title: "W5_Name",
    },
    {
      title: "W5_Group",
    },
  ];

  const columns = [
    {},
    {
      title: "SIPP no",
      fixed: "left",
      width: "250px",
      render: (_text, _row) => _row?.code,
    },
    {
      title: "SIPP type ",
      width: "150px",
      render: (_text, _row) => (_row?.children ? _row?.children[0]?.submission?.typeOfSIPPWork : ""),
    },
    {
      title: "Work description",
      width: "400px",
      render: (_text, _row) => (_row?.children ? _row?.children[0]?.submission?.workDescription : ""),
    },
    {
      title: "What tools/applications/processes impacted?",
      width: "500px",
      render: (_text, _row) => {
        return (
          <div>
            <p> {_row?.children ? _row?.children[0]?.submission?.SIPPToolsEquipment : ""} </p>

            {_row?.children[0]?.submission?.SIPPToolsEquipment ? (
              <Table
                dataSource={_row?.children ? _row?.children : []}
                columns={cellTableColumns}
                pagination={false}
                expandable={false}
              />
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      title: "Start Date",
      width: "200px",
      render: (_text, _row) => (_row?.children ? formatDate(_row?.children[0]?.submission?.startDate) : ""),
    },
    {
      title: "End Date",
      width: "200px",
      render: (_text, _row) => (_row?.children ? formatDate(_row?.children[0]?.submission?.preferredEndDate) : ""),
    },
    {
      title: "Work Duration",
      width: "150px",
      render: (_text, _row) =>
        _row?.children
          ? getWorkHours(
              _row?.children[0]?.submission?.startTime,
              _row?.children[0]?.submission?.endTime,
              _row?.children[0]?.submission?.startDate,
              _row?.children[0]?.submission?.preferredEndDate
            ) + " hours"
          : "",
    },
    {
      title: "Requestor",
      width: "150px",
      render: (_text, _row) => (_row?.children ? _row?.children[0]?.submission?.RequesterName : ""),
    },
    {
      title: "How will any system have affected?",
      width: "300px",
      render: (_text, _row) => (_row?.children ? _row?.children[0]?.submission?.SIPPImpactedSystem : ""),
    },
    {
      title: "Why does this work need to be done?",
      width: "400px",
      render: (_text, _row) => (_row?.children ? _row?.children[0]?.submission?.whyThisworksNeeds : ""),
    },
    {
      title: "Requestor Category",
      width: "150px",
      render: (_text, _row) => (_row?.children ? _row?.children[0]?.submission?.companyDepartment : ""),
    },
    {
      title: "Area affected",
      width: "150px",
      render: (_text, _row) => {
        return (
          <div>
            <p> {_row?.children ? _row?.children[0]?.submission?.workDoneAt : ""} </p>
            <p> {_row?.children ? _row?.children[0]?.submission?.workLocation : ""} </p>
            <p> {_row?.children ? _row?.children[0]?.submission?.buildingLevel : ""} </p>
          </div>
        );
      },
    },
    {
      title: "Requestor/System owner /Department coordinator comments",
      width: "200px",
    },
  ];

  return (
    <>
      <h5 className="mapsectiontitle">Daily SIPP Works</h5>
      <div className="container-fluid">
        <React.Fragment>
          <div className="row dec-row">
            <div className="col-3 col-md-3">
              <DatePicker
                onChange={onChangeMonth}
                picker="month"
                placeholder="Select Month"
                style={{ width: "100%" }}
                disabled={GraphLoading}
              />
            </div>
            <div className="col-3 col-md-3">
              <Button
                type="primary"
                style={{ width: "100%" }}
                onClick={onClickFilterSiteButton}
                disabled={GraphLoading}
              >
                Filter
              </Button>
            </div>
            <div className="col-3 col-md-3">
              <Spin tip="Loading data.. Please wait.." hidden={!GraphLoading}></Spin>
            </div>
          </div>
          <br />

          <div className="row dec-row">
            <div className="col-12 col-md-12">
              <div className="card">
                {!sippPermits ? (
                  <Title level={5}>No reports to show. Please select month.</Title>
                ) : (
                  <div>
                    {sippPermits &&
                      JSON.parse(sippPermits)?.map((sipp) => (
                        <Collapse accordion key={sipp?.startDate}>
                          <Panel
                            header={
                              "Start Date/Time: " +
                              formatDate(sipp?.startDate) +
                              "(" +
                              totalApprovedAndPendingPermits(
                                sipp?.pendingApprovalPermits?.length,
                                sipp?.approvedPermits?.length
                              ) +
                              ")"
                            }
                            key="1"
                          >
                            <Collapse accordion key={sipp?.startDate}>
                              <Panel
                                header={"Status: Pending Approval (" + sipp?.pendingApprovalPermits?.length + ")"}
                                key="1"
                              >
                                <Table
                                  dataSource={sipp?.pendingApprovalPermits}
                                  columns={columns}
                                  scroll={{ x: 1000 }}
                                  pagination={false}
                                  expandable={false}
                                />
                              </Panel>
                              <Panel header={"Status: Approved (" + sipp?.approvedPermits?.length + ")"} key="2">
                                <Table
                                  dataSource={sipp?.approvedPermits}
                                  columns={columns}
                                  scroll={{ x: 1000 }}
                                  pagination={false}
                                  expandable={false}
                                />
                              </Panel>
                            </Collapse>
                          </Panel>
                        </Collapse>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
    </>
  );
};
