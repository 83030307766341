import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_GROUP } from "../graphql/modules";
import { successNotify, warnNotify } from "../util";
import { GroupForm } from "../components/Group";

export const CreateNewGroup = ({}) => {
  //initial state
  const [state, setState] = useState({
    name: "",
    members: [],
    site: "",
    permitCloser: false,
  });

  const [error, setError] = useState(null);
  // CREATE USER
  const [CreateGroupMutation, { loading }] = useMutation(CREATE_GROUP, {
    variables: {
      data: state,
    },
  });

  // SUBMIT HANDLER
  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("state: " + JSON.stringify(state, null, 2));

    if (state.permitCloser === "true") {
      state.permitCloser = true;
    } else if (state.permitCloser === "") {
      state.permitCloser = false;
    } else {
      state.permitCloser = false;
    }

    try {
      const {
        data: { CreateGroup },
      } = await CreateGroupMutation();

      if (CreateGroup.success) {
        successNotify(CreateGroup.message);
        setState({
          name: "",
          site: "",
          permitCloser: false,
        });
      } else {
        warnNotify(CreateGroup.message);
      }
    } catch (error) {
      warnNotify(error.message);
      console.log(error.message);
    }
  };

  const handleChange = (value) => setState({ ...state, ...value });

  return (
    <GroupForm
      title="Create New Group"
      btnText="Create Group"
      onSubmit={onSubmit}
      loading={loading}
      handleChange={handleChange}
      state={state}
    />
  );
};
