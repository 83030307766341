import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
// import { Link } from "react-router-dom";
import { DeleteOutlined, EllipsisOutlined } from "@ant-design/icons";
import { GET_SITE_LIST, DELETE_SITE } from "../graphql/modules";
import { Pagination, Popconfirm } from "antd";
import { DynamicList } from "../components/Shared";
import { Link } from "react-router-dom";

export const AllSites = ({ match }) => {
  const [limit, setLimit] = useState(50);
  const [page, setPage] = useState(1);

  const { data, loading, error, refetch } = useQuery(GET_SITE_LIST, {
    variables: {
      limit,
      offset: page - 1,
    },
  });

  const onChangePagination = (p, size) => {
    setPage(p);
    setLimit(size)
  };


  const [DeleteSite] = useMutation(DELETE_SITE);
  const handleDelete = async (id) => {
    await DeleteSite({ variables: { id } });
    refetch();
  };

  const total = data?.GetSites?.count;
  const results = data?.GetSites?.results;

  const columns = [
    {
      title: "#",
      render: (_text, _row, index) => limit * (page - 1) + index + 1,
    },
    {
      title: "Site Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend"],
      defaultSortOrder: 'ascend',
    },
    {
      title: "Site Code",
      dataIndex: "code",
      key: "code",
      sorter: (a, b) => a.code.localeCompare(b.code),
      sortDirections: ["descend"],
      defaultSortOrder: 'ascend',
    },
    {
      title: "Site Address",
      dataIndex: "address",
      key: "address",
    },
    // {
    //   title: "Action",
    //   render: (_, record) => (
    //     <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record._id)}>
    //       <DeleteOutlined style={{ color: "#f01" }} />
    //     </Popconfirm>
    //   ),
    // },
  ];

  if (error) {
    return <div className="card">Something went wrong</div>;
  }

  return (
    <DynamicList
      title="List of sites"
      loading={loading}
      columns={columns}
      data={results}
      pagination={
        <Pagination
          className="pagination"
          total={total}
          pageSize={limit}
          current={page}
          showSizeChanger={false}
          onChange={onChangePagination}
        />
      }
    />
  );
};
