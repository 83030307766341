import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import { Link, useHistory } from "react-router-dom";
import { EllipsisOutlined } from "@ant-design/icons";
// import { UserStatus } from "../config";
import { ADD_USERS_TO_MTGROUP, GET_GROUP_LIST, GET_ALL_USERS_LIST, REMOVE_USERS_TO_MTGROUP } from "../graphql/modules";
import { Pagination, Modal, Popconfirm, Dropdown, Menu, Button, List, Input, Select, Spin } from "antd";
import { DynamicList } from "../components/Shared";
import { successNotify, warnNotify, TABLE_LIST_LIMIT } from "../util";
// import moment from "moment";
import debounce from "lodash/debounce";

export const Groups = ({ match }) => {
  const history = useHistory();
  const [limit, setLimit] = useState(TABLE_LIST_LIMIT);
  const [page, setPage] = useState(1);

  const [visible, setVisible] = React.useState(false);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  // const [modalText, setModalText] = React.useState("Content of the modal");`1

  const [modalGroupId, setModalGroupId] = React.useState("");
  const [modalRecordName, setModalRecordName] = React.useState("");
  const [modalRecordMembers, setModalRecordMembers] = React.useState("");
  const [users, setUsers] = useState(null);
  const [initialMemberCount, setInitialMemberCount] = useState(0);
  const [tempArr, setTempArr] = useState("");

  const [getUsers, { data: UserList, loading: fetchingUser }] = useLazyQuery(GET_ALL_USERS_LIST, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  const { data, loading, error, refetch } = useQuery(GET_GROUP_LIST, {
    variables: {
      limit,
      offset: page - 1,
    },
    fetchPolicy: "cache-and-network",
  });


  // const [UpdateUserMutation, { loading: updateLoading }] = useMutation(UPDATE_USER);
  const [AddUserToMTGroupMutation, { loading: addUserToMTGroupLoading }] = useMutation(ADD_USERS_TO_MTGROUP);
  const [RemoveUserToMTGroupMutation, { loading: removeUserToMTGroupLoading }] = useMutation(REMOVE_USERS_TO_MTGROUP);


  useEffect(() => {
    getUsers();
    console.log("Something happened modalRecordMembers");
    setInitialMemberCount(modalRecordMembers.length);
    // console.log(modalRecordMembers);
  }, []);

  useEffect(() => {
    setModalRecordMembers(tempArr);
    console.log(modalRecordMembers);
  }, [tempArr]);

  const onChangePagination = (p, pageSize) => {
    setPage(p);
    setLimit(pageSize);
  };

  function getUserEmail(id, userArr) {
    try {
      for (var i = 0; i < userArr?.length; i++) {
        if (userArr[i]?._id === id) {
          return userArr[i]?.email;
        }
      }
    } catch (error) {}
  }

  const onRemoveMember = async (arr, index, userid) => {
    arr.splice(index, 1);

    let members = [];

    arr.map((member) => {
      members.push(member._id);
    });

    try {
      const { data: removedUserToMTGrp } = await RemoveUserToMTGroupMutation({
        variables: {
          id: modalGroupId,
          userid: userid._id,
          data: {
            members: members,
          }
        },
      });

      let removeUserData = removedUserToMTGrp.RemoveUsersToMTGroup;
      if (removeUserData.success) {
        successNotify(removeUserData.message);
        refetch();
      } else {
        warnNotify(removeUserData.message);
      }
    } catch (_error) {
      console.log(_error);
      console.log(_error.message);
    }
  };

  const showModal = (record) => {
    console.log(record);
    setModalGroupId(record._id);
    setModalRecordName(record.name);
    setModalRecordMembers(record.members);
    setVisible(true);
  };

  const handleOk = async () => {
    let members = [];

    if (!users && initialMemberCount === modalRecordMembers.length) {
      setVisible(false);
    } else {
      console.log("users: " + JSON.stringify(users));
      if (modalRecordMembers.length !== 0) {
        console.log("Has existing members: " + modalRecordMembers.length);
        console.log(JSON.stringify(modalRecordMembers, null, 2));

        modalRecordMembers.map((member) => {
          members.push(member._id);
        });

        if (users) {
          users.map((usr) => {
            members.push(usr);
          });
        }
      } else {
        members = users;
      }

      try {
        const { data: AddedUserToMTGrp } = await AddUserToMTGroupMutation({
          variables: {
            id: modalGroupId,
            data: {
              members: members,
            },
          },
        });

        let adUserData = AddedUserToMTGrp.AddUsersToMTGroup;

        if (adUserData.success) {
          setConfirmLoading(true);
          setTimeout(() => {
            setVisible(false);
            setConfirmLoading(false);
            successNotify(adUserData.message);
          }, 2000);
          refetch();
        } else {
          warnNotify(adUserData.message);
        }
        setUsers(null);
      } catch (_error) {
        console.log(_error);
        console.log(_error.message);
      }
    }
  };

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setUsers(null);
    setTempArr("");
    setVisible(false);
  };

  const onSearchUser = React.useMemo(() => {
    const loadOptions = (value) => {
      getUsers({
        variables: {
          limit: 1000,
          offset: 0,
          searchText: value,
        },
      });
    };
    return debounce(loadOptions, 800);
  }, []);

  const total = data?.GetUserGroups?.count;
  const results = data?.GetUserGroups?.results;
  console.log(total);

  console.log("results: " + JSON.stringify(results?.members));

  const columns = [
    {
      title: "#",
      render: (_text, _row, index) => limit * (page - 1) + index + 1,
    },
    {
      title: "Group Name",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortDirections: ["descend"],
      defaultSortOrder: 'ascend',
    },
    {
      title: "Site",
      render: (text, row) => row.site?.code,
    },
    {
      title: "Can close permits?",
      width: "15%",
      render: (text, row) => {
        if (row.permitCloser) {
          return <div> Yes </div>;
        } else {
          return <div> No </div>;
        }
      },
    },
    {
      title: "Action",
      width: "15%",
      render: (text, record) => {
        const menu = (
          <Menu>
            <Menu.Item key="0">
              <Button
                type="text"
                onClick={(event) => {
                  event.preventDefault();
                  showModal(record);
                }}
              >
                View
              </Button>
            </Menu.Item>
            <Menu.Item key="1">
            <Link style={{ textAlign: "center" }} to={`${match.path}/edit-group/${record._id}`}>
              Edit
            </Link>
            </Menu.Item>
            {/* <Menu.Divider /> */}
            <Menu.Item key="2" disabled hidden>
              <Popconfirm
                title="Sure to delete?"
                // onConfirm={() => handleDelete(record._id)}
                disabled
                hidden
              >
                Delete
              </Popconfirm>
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <EllipsisOutlined />
          </Dropdown>
        );
      },
    },
  ];

  if (error) {
    return <div className="card">Something went wrong</div>;
  }

  return (
    <div>
      <DynamicList
        title="List of groups"
        loading={loading}
        columns={columns}
        data={results}
        actions={
          <Link to={match.path + "/create-group"} className="btn btn-primary mr-2">
            Create group <i className="fas fa-plus-circle"></i>
          </Link>
        }
        total={total}
        pageSize={limit}
        onChange={onChangePagination}
        pageNo={page}
        // showSearch={true}
      />

      <Modal
        title={modalRecordName + " Members"}
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <List
          itemLayout="horizontal"
          dataSource={modalRecordMembers}
          renderItem={(member, index) => (
            <List.Item
              actions={[
                <Button
                  type="primary"
                  danger
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("onRemoveMember: " + index);
                    onRemoveMember(modalRecordMembers, index, member);
                  }}
                  // disabled
                >
                  Delete
                </Button>,
              ]}
            >
              <List.Item.Meta title={member.email} />
            </List.Item>
          )}
        />
        <Select
          style={{ width: "100%" }}
          onChange={(value) => setUsers(value)}
          placeholder="Select User"
          showSearch={true}
          onSearch={onSearchUser}
          multiple
          filterOption={false}
          notFoundContent={fetchingUser ? <Spin size="small" /> : null}
          mode="multiple"
        >
          {UserList?.GetAllUsers?.results?.map((user) => (
            <Select.Option key={user._id} value={user._id}>
              <strong>{user.fullname}</strong>
              <br />
              <span>{user.email}</span>
            </Select.Option>
          ))}
        </Select>
        {/* <Input

        /> */}
      </Modal>
    </div>
  );
};
