import React, { useState, Fragment, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Link, useHistory } from "react-router-dom";
import { EllipsisOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import { PermitStatus, UserRole, StatusColor, InterlockDisclaimer } from "../config";
import { GET_MY_PERMITS, GET_SITE_LIST, DUPLICATE_PERMIT, GET_ALL_DISCIPLINE } from "../graphql/modules";
import { Pagination, Tag, Dropdown, Menu, Input, Button, Space, Checkbox, Row, Col, Alert } from "antd";

import { DynamicList } from "../components/Shared";
import { formatDate, getNextReviewer, TABLE_LIST_LIMIT, successNotify, warnNotify } from "../util";
import { useSelector } from "react-redux";
import GeneralPermitPDF from "../components/Pdf/generalPermit.pdf";

export const AllMyPermits = ({ match }) => {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const [limit, setLimit] = useState(TABLE_LIST_LIMIT);
  const [page, setPage] = useState(1);
  const [disciplineName, setDisciplineName] = useState([]);

  /** START For Applier Name Filtering */
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  /** END For Applier Name Filtering */

  const { data, loading, error, refetch } = useQuery(GET_MY_PERMITS, {
    variables: {
      limit,
      offset: page - 1,
      disciplineName: disciplineName,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: SiteList } = useQuery(GET_SITE_LIST, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  const [DuplicatePermit, { loading: duplicatePermitLoading }] = useMutation(DUPLICATE_PERMIT);

  const { data: DisciplineList } = useQuery(GET_ALL_DISCIPLINE);

  useEffect(() => {
    var getFilter = history.location?.state?.query;
    if (getFilter) {
      setDisciplineName(getFilter.disciplineName);
    }
  }, []);

  const onChangePagination = (p, size) => {
    setPage(p);
    setLimit(size);
  };

  /** START For Applier Name Filtering */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const handleDisciplineNameReset = () => {
    setDisciplineName([]);
  };

  const handleDisciplineNameCheckbox = (e) => {
    setDisciplineName(e);
  };

  let disciplineList = [];

  if (DisciplineList?.GetAllDisciplines?.results?.length) {
    DisciplineList?.GetAllDisciplines?.results?.map((discipline) => {
      disciplineList.push({
        text: discipline.code,
        value: discipline.code,
      });
    });
  }

  const getColumnSearchProps = (dataIndex) => ({
    // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    //   <div style={{ padding: 8 }}>
    //     <Input
    //       placeholder={`Search ${dataIndex}`}
    //       value={selectedKeys[0]}
    //       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    //       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
    //       style={{ marginBottom: 8, display: "block" }}
    //     />
    //     <Space>
    //       <Button
    //         type="primary"
    //         onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
    //         icon={<SearchOutlined />}
    //         size="small"
    //         style={{ width: 90 }}
    //       >
    //         Search
    //       </Button>
    //       <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
    //         Reset
    //       </Button>
    //     </Space>
    //   </div>
    // ),
    // filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    // onFilter: (fullname, row) =>
    //   row?.submittedBy ? row?.submittedBy?.fullname?.toString().toLowerCase().includes(fullname.toLowerCase()) : "",
    // onFilterDropdownVisibleChange: (visible) => {
    //   if (visible) {
    //     setTimeout(() => this.searchInput?.select(), 100);
    //   }
    // },
    render: (_text, _row) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={_row?.submittedBy ? _row?.submittedBy?.fullname.toString() : ""}
        />
      ) : (
        _row?.submittedBy?.fullname
      ),
  });
  /** END For Applier Name Filtering */

  /** START HOST/SYSTEM OWNER FILTERING */
  const getHostOwnerSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (email, row) =>
      row?.hostSystemOwner ? row?.hostSystemOwner?.email?.toString().toLowerCase().includes(email.toLowerCase()) : "",
    render: (_text, _row) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={_row?.hostSystemOwner ? _row?.hostSystemOwner?.email.toString() : ""}
        />
      ) : (
        _row?.hostSystemOwner?.email
      ),
  });
  /** END HOST/SYSTEM OWNER FILTERING */

  /** START Duplicate Permit Function */
  const onDuplicatePermit = async (id) => {
    console.log("Duplicating permit id: " + id);

    try {
      const { data } = await DuplicatePermit({
        variables: {
          id: id,
        },
      });

      if (data?.DuplicatePermit.success) {
        successNotify(data.DuplicatePermit.message);
        refetch();
      } else {
        warnNotify(data.DuplicatePermit.message);
      }
    } catch (err) {}
  };
  /** END Duplicate Permit Function */

  const total = data?.GetMyPermits?.count;
  const results = JSON.parse(data?.GetMyPermits?.results || "[]");
  const canCreatePermit = data?.GetMyPermits?.canCreatePermit;
  let siteList = [];

  console.log("canCreatePermit? " + canCreatePermit);

  if (SiteList?.GetSites?.results?.length) {
    SiteList?.GetSites?.results?.map((site) => {
      siteList.push({
        text: site.code,
        value: site.code,
      });
    });
  }

  const getDiscipline = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Checkbox.Group
          style={{ width: "100%" }}
          onChange={handleDisciplineNameCheckbox}
          value={disciplineName || disciplineName}
        >
          {disciplineList?.map((res) => {
            return (
              <Row>
                <Col span={24}>
                  <Checkbox value={res.value}>{res.text}</Checkbox>
                </Col>
              </Row>
            );
          })}
        </Checkbox.Group>
        <Space>
          <Button onClick={handleDisciplineNameReset} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
  });

  const columns = [
    {
      title: "#",
      // render: (_text, _row, index) => index + 1,
    },
    {
      title: "Permit Number",
      dataIndex: "code",
    },
    {
      title: "Applier name",
      /** START For Applier Name Filtering */
      ...getColumnSearchProps("fullname"),
      /** END For Applier Name Filtering */
    },
    {
      title: "Applier Site",
      filters: siteList,
      onFilter: (_text, _row) => _row?.site?.code?.indexOf(_text) === 0,
      render: (_text, _row) => _row?.site?.code,
    },
    {
      title: "Discipline",
      filters: disciplineList,
      onFilter: (_text, _row) => _row?.discipline?.code?.indexOf(_text) === 0,
      render: (_text, _row) => (_row?.discipline?.code !== undefined ? _row?.discipline?.code : ""),
    },
    {
      title: "Host/System Owner",
      ...getHostOwnerSearchProps("hostSystemOwner"),
    },
    {
      title: "Date applied",
      render: (_text, _row) => formatDate(_row.createdAt),
    },
    {
      title: "Start Date",
      render: (_text, _row) => formatDate(_row?.submission?.startDate),
    },
    {
      title: "End Date",
      render: (_text, _row) => formatDate(_row?.submission?.dateEnd),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, row) => {
        let color = "orange";
        if (status === PermitStatus.Rejected) color = StatusColor.Rejected;
        else if (status === PermitStatus.Approved) color = StatusColor.Approved;
        else if (status === PermitStatus.Completed) color = StatusColor.Completed;
        else if (status === PermitStatus.Cancelled) color = StatusColor.Cancelled;
        else if (status === PermitStatus.PendingClosure) color = StatusColor.PendingClosure;
        else if (status === PermitStatus.PendingCancel) color = StatusColor.PendingCancel;

        const approvedBy = row?.approvedBy?.length ? row.approvedBy[row?.approvedBy?.length - 1] : null;
        const rejectedBy = row?.rejectedBy;

        let nextReviewer = getNextReviewer(row);

        return (
          <div>
            {status === PermitStatus.Pending ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By PM
              </Tag>
            ) : status === PermitStatus.PendingApproval ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By {nextReviewer}
              </Tag>
            ) : status === PermitStatus.Rejected ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Rejected By {rejectedBy?.fullname}
              </Tag>
            ) : status === PermitStatus.Approved && approvedBy ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Final Approved By {approvedBy?.fullname}
              </Tag>
            ) : (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                {status}
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Child permit",
      render: (_text, _row) => _row?.form?.title,
    },
    {
      title: "Action",
      width: "15%",
      render: (text, record) => {
        const menu = (
          <Menu hidden={record.code === undefined}>
            <Menu.Item key="0" disabled={record.code === undefined}>
              <Link
                style={{ marginRight: "15px" }}
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`permits/${record._id}`, {
                    user: record,
                    query: {
                      disciplineName: disciplineName,
                    },
                  });
                }}
                hidden={record.code === undefined}
              >
                View
              </Link>
            </Menu.Item>

            <Menu.Item key="0" disabled={record.code === undefined}>
              <Link
                style={{ marginRight: "15px" }}
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  GeneralPermitPDF(record);
                }}
                hidden={record.code === undefined}
              >
                Export As PDF
              </Link>
            </Menu.Item>

            {(user.role === UserRole.ContractorRequestor ||
              user.role === UserRole.User ||
              user.role === UserRole.MTGroup) &&
              record.status === PermitStatus.Approved && (
                <Menu.Item key="5">
                  <Link
                    style={{ marginRight: "15px" }}
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`permits/review/${record._id}`, {
                        user: record,
                      });
                    }}
                  >
                    Review
                  </Link>
                </Menu.Item>
              )}
            {user.role === UserRole.ContractorRequestor && record.status === PermitStatus.Rejected && (
              <Menu.Item key="5">
                <Link
                  style={{ marginRight: "15px" }}
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(`permits/review/${record._id}`, {
                      user: record,
                    });
                  }}
                >
                  Resubmit
                </Link>
              </Menu.Item>
            )}

            {(user.role === UserRole.ContractorPM ||
              user.role === UserRole.MTGroup ||
              user.role === UserRole.MicronSupervisor ||
              user.role === UserRole.MicronManager ||
              user.role === UserRole.SiteAdmin) &&
              record.status !== PermitStatus.Approved && (
                <Fragment>
                  <Menu.Divider />
                  <Menu.Item key="3">
                    <Link
                      style={{ marginRight: "15px" }}
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`permits/review/${record._id}`, {
                          user: record,
                        });
                      }}
                    >
                      Review
                    </Link>
                  </Menu.Item>
                </Fragment>
              )}

            <Menu.Item key="4">
              <Link
                style={{ marginRight: "15px" }}
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  console.log("Duplicate Button Pressed");
                  onDuplicatePermit(record._id);
                }}
              >
                Duplicate
              </Link>
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <EllipsisOutlined />
          </Dropdown>
        );
      },
    },
  ];

  if (error) {
    return <div className="card">Something went wrong</div>;
  }

  let createBtn = null;

  if (
    user.role === UserRole.User ||
    user.role === UserRole.ContractorRequestor ||
    user.role === UserRole.SiteAdmin ||
    user.role === UserRole.MTGroup
  ) {
    if (canCreatePermit) {
      createBtn = (
        <Link
          to={`/permits/create-permit`}
          className="btn btn-primary mr-2"
          // style={{
          //   pointerEvents: canCreatePermit ? "" : "none",
          // }}
        >
          Create Permit <i className="fas fa-plus-circle"></i>
        </Link>
      );
    } else {
      var disclaimer = InterlockDisclaimer;
      var descriptionElement = (
        <div>
          {disclaimer}
          <br />
          <p>Please contact your micron host if you require further assistance.</p>
        </div>
      );
      createBtn = (
        <Alert
          style={{ marginBottom: "2%" }}
          message="Important Notes"
          description={descriptionElement}
          type="info"
          showIcon
          closable
          // onClose={onClose}
        />
      );
    }
  }

  return (
    <DynamicList
      title="List of permits"
      loading={loading}
      columns={columns}
      data={results}
      actions={createBtn}
      pagination={
        <Pagination
          className="pagination"
          total={total}
          pageSize={limit}
          current={page}
          showSizeChanger={false}
          onChange={onChangePagination}
        />
      }
      // showSearch={true}
      // onChangeSearch={onChangeSearch}
    />
  );
};
