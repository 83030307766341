import React, { useState, Fragment, useEffect, useRef } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import { Link, useHistory } from "react-router-dom";
import {
  PermitStatus,
  UserRole,
  StatusColor,
  PermitStatusArray,
  InterlockDisclaimer,
  UnclosedPermitDisclaimer,
} from "../config";
import {
  GET_PERMITS,
  GET_SITE_LIST,
  DUPLICATE_PERMIT,
  GET_PERMIT,
  GET_UNLCOSED_PERMIT,
  GET_ALL_DISCIPLINE,
  UPDATE_PERMIT_STATUS,
} from "../graphql/modules";
import { Pagination, Tag, Dropdown, Menu, Input, Button, Space, Table, Checkbox, Row, Col, Alert, Tooltip } from "antd";
import { EllipsisOutlined, SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { DynamicList } from "../components/Shared";
import { formatDate, getNextReviewer, TABLE_LIST_LIMIT, PERMITTOCLOSED, successNotify, warnNotify } from "../util";
import { useSelector } from "react-redux";
import GeneralPermitPDF from "../components/Pdf/generalPermit.pdf";

export const AllPermits = ({ match }) => {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const [limit, setLimit] = useState(TABLE_LIST_LIMIT);
  const [page, setPage] = useState(1);
  const [value, setValue] = useState("");
  const [statusCheck, setStatusCheck] = useState([]);
  const [siteName, setSiteName] = useState([]);
  const [disciplineName, setDisciplineName] = useState([]);

  const searchInput = useRef(null);
  const searchInput2 = useRef(null);

  /** START For Applier Name Filtering */
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  /** END For Applier Name Filtering */

  /** START For Start Date Filtering */
  const [searchStartDate, setSearchStartDate] = useState("");
  const [searchedStartDateColumn, setSearchedStartDateColumn] = useState("");
  /** END For Start Date Filtering */

  /** START For End Date Filtering */
  const [searchEndDate, setSearchDateEnd] = useState("");
  const [searchedEndDateColumn, setSearchedDateEndColumn] = useState("");
  /** END For End Date Filtering */

  /** START For End Date Filtering */
  const [searchCode, setSearchCode] = useState("");
  const [searchedCodeColumn, setSearchedCodeColumn] = useState("");
  /** END For End Date Filtering */

  /** START For End Date Filtering */
  const [searchPermitNumber, setSearchPermitNumber] = useState("");
  const [searchedPermitNumberColumn, setSearchedPermitNumberColumn] = useState("");
  /** END For End Date Filtering */

  /** START For Date Applied (createdAt) Filtering */
  const [searchDateApplied, setSearchDateApplied] = useState("");
  const [searchedDateAppliedColumn, setSearchedDateAppliedColumn] = useState("");
  /** END For Date Applied (createdAt) Filtering */

  const [DuplicatePermit, { loading: duplicatePermitLoading }] = useMutation(DUPLICATE_PERMIT);
  const [UpdatePermitStatus, { loading: updateLoading }] = useMutation(UPDATE_PERMIT_STATUS);

  const { data, loading, error, refetch } = useQuery(GET_PERMITS, {
    variables: {
      searchText: value,
      searchCode: searchCode,
      searchCreated: searchDateApplied,
      searchStartDate: searchStartDate,
      searchEndDate: searchEndDate,
      statusCheck: statusCheck,
      siteName: siteName,
      disciplineName: disciplineName,
      limit,
      offset: page - 1,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: SiteList } = useQuery(GET_SITE_LIST, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  const { data: DisciplineList } = useQuery(GET_ALL_DISCIPLINE);

  useEffect(() => {
    var getFilter = history.location?.state?.query;
    if (getFilter) {
      setValue(getFilter.searchText);
      setLimit(getFilter.limit);
      setPage(getFilter.offset);
      setSearchStartDate(getFilter.searchStartDate);
      setSearchDateEnd(getFilter.searchEndDate);
      setSearchDateApplied(getFilter.searchCreated);
      setSearchCode(getFilter.searchCode);
      setSiteName(getFilter.siteName);
      setStatusCheck(getFilter.statusCheck);
      setDisciplineName(getFilter.disciplineName);
    }
  }, []);

  const { data: unclosePermit, loading: uncloseLoading } = useQuery(GET_UNLCOSED_PERMIT);

  var total = data?.GetPermits?.count;
  var results = JSON.parse(data?.GetPermits?.results || "[]");
  var isEnableToCreate = unclosePermit?.CountUnClosedPermit?.isEnabled;
  var isFinalApprover = data?.GetPermits?.isFinalApprover;
  var canCreatePermit = data?.GetPermits?.canCreatePermit;

  console.log("isFinalApprover? " + isFinalApprover);
  console.log("canCreatePermit? " + canCreatePermit);

  // var unClosed = unclosePermit
  let siteList = [];
  if (SiteList?.GetSites?.results?.length) {
    SiteList?.GetSites?.results?.map((site) => {
      siteList.push({
        text: site.code,
        value: site.code,
      });
    });
  }

  let disciplineList = [];
  if (DisciplineList?.GetAllDisciplines?.results?.length) {
    DisciplineList?.GetAllDisciplines?.results?.map((discipline) => {
      disciplineList.push({
        text: discipline.code,
        value: discipline.code,
      });
    });
  }

  /** START For Permit Number Filtering */
  const handlePermitNumberSearch = (selectedKeys, confirm, dataIndex) => {
    setValue(selectedKeys[0]);
    confirm();
    setSearchPermitNumber(selectedKeys[0]);
    setSearchedPermitNumberColumn(dataIndex);
  };

  const handlePermitNumberReset = (clearFilters) => {
    setValue("");
    clearFilters();
    setSearchPermitNumber("");
  };

  const getColumnSearchPermitNumber = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search Permit Number`}
          ref={searchInput}
          value={selectedKeys[0] || value}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handlePermitNumberSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handlePermitNumberSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handlePermitNumberReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (permitnumber, row) =>
      row?.code ? row?.code?.toString().toLowerCase().includes(permitnumber.toLowerCase()) : "",
    onFilterDropdownVisibleChange: (visible) => {
      // try {
      //   setTimeout(() => this.searchInput.current.select(), 100);
      // } catch (err) {
      //   console.error(err);
      // }
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select());
        // null check above: as its initial value was null
      }
    },
    render: (_text, _row) =>
      searchedPermitNumberColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchPermitNumber]}
          autoEscape
          textToHighlight={_row?.code ? _row?.code : ""}
        />
      ) : (
        _row?.code
      ),
  });
  /** END For Permit Number Filtering */

  /** START For Permit Code Filtering */
  const handleCodeSearch = (selectedKeys, confirm, dataIndex) => {
    setSearchCode(selectedKeys[0]);
    confirm();
    setSearchCode(selectedKeys[0]);
    setSearchedCodeColumn(dataIndex);
  };

  const handleCodeReset = (clearFilters) => {
    clearFilters();
    setSearchCode("");
    setValue("");
  };

  const getColumnSearchCode = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          ref={searchInput}
          value={selectedKeys[0] || searchCode}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleCodeSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleCodeSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleCodeReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (code, row) => (row?.code ? row?.code?.toString().toLowerCase().includes(code.toLowerCase()) : ""),
    onFilterDropdownVisibleChange: (visible) => {
      // try {
      //   setTimeout(() => this.searchInput.select(), 100);
      // } catch (err) {
      //   console.error(err);
      // }
      if (visible) {
        setTimeout(() => searchInput2 && searchInput2.current && searchInput2.current.select());
        // null check above: as its initial value was null
      }
    },
    render: (_text, _row) => {
      try {
        let permitNumber = _row?.code ? _row?.code : "SITE- -DATE-NUM";
        let splitPermitNumber = permitNumber.split("-");
        return splitPermitNumber[1];
      } catch (err) {
        // console.log(err);
        return "";
      }
    },
  });
  /** END For Permit Code Filtering */

  /** START For Applier Name Filtering */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Jan 1, 2021`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (fullname, row) =>
      row?.submittedBy ? row?.submittedBy?.fullname?.toString().toLowerCase().includes(fullname.toLowerCase()) : "",
    onFilterDropdownVisibleChange: (visible) => {
      try {
        setTimeout(() => this.searchInput.select(), 100);
      } catch (err) {
        console.error(err);
      }
    },
    render: (_text, _row) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={_row?.submittedBy ? _row?.submittedBy?.fullname.toString() : ""}
        />
      ) : (
        _row?.submittedBy?.fullname
      ),
  });
  /** END For Applier Name Filtering */

  /** START For StartDate Filtering */
  const handleSearchStartDate = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchStartDate(selectedKeys[0]);
    setSearchedStartDateColumn(dataIndex);
  };

  const handleResetStartDate = (clearFilters) => {
    clearFilters();
    setSearchStartDate("");
  };

  const getStartDateColumnSearch = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Jan 1, 2021`}
          value={selectedKeys[0] || searchStartDate}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchStartDate(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchStartDate(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleResetStartDate(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    render: (_text, _row) => (_row?.submission?.startDate ? formatDate(_row?.submission?.startDate) : " "),
  });
  /** END For StartDate Filtering */

  /** START HOST/SYSTEM OWNER FILTERING */
  const getHostOwnerSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (email, row) =>
      row?.hostSystemOwner ? row?.hostSystemOwner?.email?.toString().toLowerCase().includes(email.toLowerCase()) : "",
    render: (_text, _row) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={_row?.hostSystemOwner ? _row?.hostSystemOwner?.email.toString() : ""}
        />
      ) : (
        _row?.hostSystemOwner?.email
      ),
  });

  /** END HOST/SYSTEM OWNER FILTERING */

  /** START CHILD PERMIT TITLE FILTERING */
  const getPermitTitleProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (code, row) =>
      row?.form?.title ? row?.form?.title?.toString().toLowerCase().includes(code.toLowerCase()) : "",
    render: (_text, _row) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={_row?.form?.title ? _row?.form?.title.toString() : ""}
        />
      ) : (
        _row?.form?.title
      ),
  });
  /** END CHILD PERMIT TITLE FILTERING */

  /** START For DateEnd Filtering */
  /** if reading dateEnd is a bit weird to read,
   * do note that I just aligned whatever variable
   * name we already have.. :(
   */
  const handleSearchDateEnd = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchDateEnd(selectedKeys[0]);
    setSearchedDateEndColumn(dataIndex);
  };

  const handleResetDateEnd = (clearFilters) => {
    clearFilters();
    setSearchDateEnd("");
    setSearchStartDate("");
  };

  const getEndDateColumnSearch = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Jan 1, 2021`}
          value={selectedKeys[0] || searchEndDate}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchDateEnd(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchDateEnd(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleResetDateEnd(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    render: (_text, _row) => (_row?.submission?.dateEnd ? formatDate(_row?.submission?.dateEnd) : " "),
  });
  /** END For DateEnd Filtering */

  /** START For Date Applied (createdAt) Filtering */
  const handleSearchDateApplied = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchDateApplied(selectedKeys[0]);
    setSearchedDateAppliedColumn(dataIndex);
  };

  const handleResetDateApplied = (clearFilters) => {
    clearFilters();
    setSearchDateApplied("");
  };

  const getDateAppliedColumnSearch = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Jan 1, 2021`}
          value={selectedKeys[0] || searchDateApplied}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchDateApplied(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchDateApplied(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleResetDateApplied(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    render: (_text, _row) => (_row?.createdAt ? formatDate(_row?.createdAt) : " "),
  });
  /** END For Date Applied (createdAt) Filtering */

  /** START Duplicate Permit Function */
  const onDuplicatePermit = async (id) => {
    console.log("Duplicating permit id: " + id);

    try {
      const { data } = await DuplicatePermit({
        variables: {
          id: id,
        },
      });

      if (data?.DuplicatePermit.success) {
        successNotify(data.DuplicatePermit.message);
        refetch();
      } else {
        warnNotify(data.DuplicatePermit.message);
      }
    } catch (err) {}
  };
  /** END Duplicate Permit Function */

  /** START Recall Permit Function */
  const onUpdateRecallPermit = async (values) => {
    try {
      const { data } = await UpdatePermitStatus({
        variables: {
          id: match.params.id,
          status: values.status,
        },
      });
      if (data.UpdatePermitStatus.success) {
        successNotify(data.UpdatePermitStatus.message);
        refetch();
      } else {
        warnNotify(data.UpdatePermitStatus.message);
      }
    } catch (error) {}
  };
  /** END Recall Permit Function */

  /** START STATUS Permit Function */
  const handleSearchStatusReset = () => {
    setStatusCheck([]);
  };
  const handleCheckboxSelect = (e) => {
    setStatusCheck(e);
  };
  const getStatusData = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Checkbox.Group style={{ width: "100%" }} onChange={handleCheckboxSelect} value={statusCheck}>
          {PermitStatusArray?.map((res) => {
            return (
              <Row>
                <Col span={24}>
                  <Checkbox value={res.value}>{res.text}</Checkbox>
                </Col>
              </Row>
            );
          })}
        </Checkbox.Group>
        <Space>
          <Button onClick={handleSearchStatusReset} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
  });
  /** END STATUS Permit Function */

  const handleSiteNameReset = () => {
    setSiteName([]);
  };

  const handleSiteNameCheckbox = (e) => {
    setSiteName(e);
  };

  const handleDisciplineNameReset = () => {
    setDisciplineName([]);
  };

  const handleDisciplineNameCheckbox = (e) => {
    setDisciplineName(e);
  };

  const onClose = (e) => {
    console.log(e, "Alert was close.");
  };

  const getApplierSite = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Checkbox.Group style={{ width: "100%" }} onChange={handleSiteNameCheckbox} value={siteName || siteName}>
          {siteList?.map((res) => {
            return (
              <Row>
                <Col span={24}>
                  <Checkbox value={res.value}>{res.text}</Checkbox>
                </Col>
              </Row>
            );
          })}
        </Checkbox.Group>
        <Space>
          <Button onClick={handleSiteNameReset} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
  });

  const getDiscipline = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Checkbox.Group
          style={{ width: "100%" }}
          onChange={handleDisciplineNameCheckbox}
          value={disciplineName || disciplineName}
        >
          {disciplineList?.map((res) => {
            return (
              <Row>
                <Col span={24}>
                  <Checkbox value={res.value}>{res.text}</Checkbox>
                </Col>
              </Row>
            );
          })}
        </Checkbox.Group>
        <Space>
          <Button onClick={handleDisciplineNameReset} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
  });

  const columns = [
    {
      title: "#",
      width: "3%",
    },
    {
      title: "Permit Number",
      ...getColumnSearchPermitNumber("permitnumber"),
    },
    {
      title: "Permit Code",
      ...getColumnSearchCode("code"),
      width: "5%",
    },
    {
      title: "Permit Type",
      // render: (_text, _row) => _row?.form?.title,
      ...getPermitTitleProps("Permit Title"),
    },
    {
      title: "Discipline",
      filters: disciplineList,
      onFilter: (_text, _row) => _row?.discipline?.code?.indexOf(_text) === 0,
      render: (_text, _row) => (_row?.discipline?.code !== undefined ? _row?.discipline?.code : ""),
    },
    {
      title: "Host/System Owner",
      ...getHostOwnerSearchProps("hostSystemOwner"),
      // render: (_text, _row) => (_row?.hostSystemOwner?.email !== undefined)? _row?.hostSystemOwner?.email : "",
    },
    {
      title: "Work Description",
      render: (_text, _row) => _row?.submission?.workDetails,
      width: "25%",
    },
    // {
    //   title: "Applier name",
    //   /** START For Applier Name Filtering */
    //   ...getColumnSearchProps("fullname"),
    //   /** END For Applier Name Filtering */
    // },
    {
      title: "Applier Site",
      ...getApplierSite("applierSite"),
      render: (_text, _row) => _row?.site?.code,
      width: "5%",
    },
    {
      title: "Date Applied",
      ...getDateAppliedColumnSearch("Date Applied"),
      width: "10%",
    },
    {
      title: "Start Date",
      ...getStartDateColumnSearch("startDate"),
      width: "10%",
    },
    {
      title: "End Date",
      ...getEndDateColumnSearch("dateEnd"),
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "15%",
      ...getStatusData("statusData"),
      render: (status, row) => {
        let color = "orange";
        if (status === PermitStatus.Rejected) color = StatusColor.Rejected;
        else if (status === PermitStatus.Approved) color = StatusColor.Approved;
        else if (status === PermitStatus.Completed) color = StatusColor.Completed;
        else if (status === PermitStatus.Cancelled) color = StatusColor.Cancelled;
        else if (status === PermitStatus.PendingClosure) color = StatusColor.PendingClosure;
        else if (status === PermitStatus.PendingCancel) color = StatusColor.PendingCancel;

        const approvedBy = row?.approvedBy?.length ? row.approvedBy[row?.approvedBy?.length - 1] : null;
        const rejectedBy = row?.rejectedBy;

        let nextReviewer = getNextReviewer(row);

        return (
          <div>
            {status === PermitStatus.Pending ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By PM
              </Tag>
            ) : status === PermitStatus.PendingApproval ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By {nextReviewer}
              </Tag>
            ) : status === PermitStatus.Rejected ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Rejected By {rejectedBy?.fullname}
              </Tag>
            ) : status === PermitStatus.Approved && approvedBy ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Final Approved By {approvedBy?.fullname}
              </Tag>
            ) : status === undefined || status === null ? (
              " "
            ) : (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                {status}
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Action",
      width: "5%",
      render: (text, record) => {
        const menu = (
          <Menu hidden={record.code === undefined}>
            <Menu.Item key="0" disabled={record.code === undefined}>
              <Link
                style={{ marginRight: "15px" }}
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`${match.path}/${record._id}`, {
                    user: record,
                    query: {
                      searchText: value,
                      searchCode: searchCode,
                      searchCreated: searchDateApplied,
                      searchStartDate: searchStartDate,
                      searchEndDate: searchEndDate,
                      statusCheck: statusCheck,
                      siteName: siteName,
                      disciplineName: disciplineName,
                      limit,
                      offset: page,
                    },
                  });
                }}
                hidden={record.code === undefined}
              >
                View
              </Link>
            </Menu.Item>

            <Menu.Item key="0" disabled={record.code === undefined}>
              <Link
                style={{ marginRight: "15px" }}
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  GeneralPermitPDF(record);
                }}
                hidden={record.code === undefined}
              >
                Export As PDF
              </Link>
            </Menu.Item>

            {(user.role === UserRole.ContractorRequestor ||
              user.role === UserRole.User ||
              user.role === UserRole.MTGroup) &&
              record.status === PermitStatus.Approved && (
                <Menu.Item key="5">
                  <Link
                    style={{ marginRight: "15px" }}
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`${match.path}/review/${record._id}`, {
                        user: record,
                        query: {
                          searchText: value,
                          searchCode: searchCode,
                          searchCreated: searchDateApplied,
                          searchStartDate: searchStartDate,
                          searchEndDate: searchEndDate,
                          statusCheck: statusCheck,
                          siteName: siteName,
                          disciplineName: disciplineName,
                          limit,
                          offset: page,
                        },
                      });
                    }}
                  >
                    Review
                  </Link>
                </Menu.Item>
              )}
            {user.role === UserRole.ContractorRequestor && record.status === PermitStatus.Rejected && (
              <Menu.Item key="5">
                <Link
                  style={{ marginRight: "15px" }}
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(`${match.path}/review/${record._id}`, {
                      user: record,
                    });
                  }}
                >
                  Resubmit
                </Link>
              </Menu.Item>
            )}

            {(user.role === UserRole.ContractorPM ||
              user.role === UserRole.MTGroup ||
              user.role === UserRole.MicronSupervisor ||
              user.role === UserRole.MicronManager ||
              user.role === UserRole.SiteAdmin) &&
              record.status !== PermitStatus.Approved && (
                <Fragment>
                  <Menu.Divider />
                  <Menu.Item key="3">
                    <Link
                      style={{ marginRight: "15px" }}
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`${match.path}/review/${record._id}`, {
                          user: record,
                          query: {
                            searchText: value,
                            searchCode: searchCode,
                            searchCreated: searchDateApplied,
                            searchStartDate: searchStartDate,
                            searchEndDate: searchEndDate,
                            statusCheck: statusCheck,
                            siteName: siteName,
                            disciplineName: disciplineName,
                            limit,
                            offset: page,
                          },
                        });
                      }}
                    >
                      Review
                    </Link>
                  </Menu.Item>
                </Fragment>
              )}

            {record.submittedBy?._id === user?._id ? (
              <Menu.Item key="4" disabled={user.role === UserRole.SuperAdmin}>
                <Link
                  style={{ marginRight: "15px" }}
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("Duplicate Button Pressed");
                    onDuplicatePermit(record?._id);
                  }}
                >
                  Duplicate
                </Link>
              </Menu.Item>
            ) : (
              <Fragment />
            )}

            {/* {record.status === "Approved" && isFinalApprover ? (
              <Menu.Item key="4" disabled={user.role === UserRole.SuperAdmin}>
                <Link
                  style={{ marginRight: "15px" }}
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    console.log("Recall Approved Status Button Pressed");
                    onUpdateRecallPermit({
                      status: PermitStatus.PendingApproval,
                    });
                  }}
                >
                  Recall Approved Status
                </Link>
              </Menu.Item>
            ) : (
              <Fragment />
            )} */}
          </Menu>
        );

        return record.code === undefined ? (
          " "
        ) : (
          <Dropdown overlay={menu} trigger={["click"]}>
            <EllipsisOutlined />
          </Dropdown>
        );
      },
    },
  ];

  if (error) {
    return <div className="card">Something went wrong</div>;
  }

  let createBtn = null;

  if (
    user.role === UserRole.User ||
    user.role === UserRole.ContractorRequestor ||
    user.role === UserRole.SiteAdmin ||
    user.role === UserRole.MTGroup
  ) {
    // createBtn = (
    //   <Link to={match.path + "/create-permit"} className="btn btn-primary mr-2" disabled={loading}>
    //     Create Permit <i className="fas fa-plus-circle"></i>
    //   </Link>
    // );

    if (isEnableToCreate && canCreatePermit) {
      createBtn = (
        <Link
          to={match.path + "/create-permit"}
          className="btn btn-primary mr-2"
          disabled={uncloseLoading}
          // style={{ pointerEvents: canCreatePermit ? "" : "none" }}
        >
          Create Permit <i className="fas fa-plus-circle"></i>
        </Link>
      );
    } else {
      var disclaimer = UnclosedPermitDisclaimer;
      if (canCreatePermit == false) {
        disclaimer = InterlockDisclaimer;
      }
      var descriptionElement = (
        <div>
          {disclaimer}
          <br />
          <p>Please contact your micron host if you require further assistance.</p>
        </div>
      );
      createBtn = (
        <Alert
          style={{ marginBottom: "2%" }}
          message="Important Notes"
          description={descriptionElement}
          type="info"
          showIcon
          closable
          onClose={onClose}
        />
      );
    }
  }

  const onChangePagination = (p, pageSize) => {
    setPage(p);
    setLimit(pageSize);
  };

  const onChangeSearch = (datavalue) => {
    setValue(datavalue);
  };

  return (
    <Fragment>
      <DynamicList
        title="List of permits"
        loading={loading}
        columns={columns}
        data={results}
        actions={createBtn}
        total={total}
        pageSize={limit}
        onChange={onChangePagination}
        pageNo={page}
      />
    </Fragment>
  );
};
