import React, { useState, Fragment, useRef } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Link, useHistory } from "react-router-dom";
import { EllipsisOutlined, SearchOutlined } from "@ant-design/icons";
import { PermitStatus, UserRole, StatusColor, PermitStatusArray } from "../config";
import { GET_SITE_LIST, GET_ALL_DISCIPLINE, GET_CHILD_NEXT_APPROVAL_PERMITS } from "../graphql/modules";
import { Pagination, Tag, Dropdown, Menu, Input, Button, Space, Checkbox, Row, Col, Tabs } from "antd";
import { DynamicList } from "../components/Shared";
import { formatDate, getNextReviewer, TABLE_LIST_LIMIT } from "../util";
import { useSelector } from "react-redux";
import Highlighter from "react-highlight-words";
import moment from "moment";

export const AllMyPendingApprovalChildPermits = ({ match }) => {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const [limit, setLimit] = useState(TABLE_LIST_LIMIT);
  const [page, setPage] = useState(1);
  const [value, setValue] = useState("");

  const dateToday = moment(new Date());

  const [showOnlyDuePermits, setShowOnlyDuePermits] = useState(false);

  /** START For Applier Name Filtering */
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  /** END For Applier Name Filtering */

  /** START For End Date Filtering */
  const [searchCode, setSearchCode] = useState("");
  const [searchedCodeColumn, setSearchedCodeColumn] = useState("");
  /** END For End Date Filtering */

  /** START For End Date Filtering */
  const [searchPermitNumber, setSearchPermitNumber] = useState("");
  const [searchedPermitNumberColumn, setSearchedPermitNumberColumn] = useState("");
  /** END For End Date Filtering */

  const searchInput = useRef(null);
  const searchInput2 = useRef(null);

  const [disciplineName, setDisciplineName] = useState([]);

  const [childValue, setChildValue] = useState("");

  const { TabPane } = Tabs;

  const onChangeTab = (key) => {
    console.log(key);
  };

  const { data, loading, error } = useQuery(GET_CHILD_NEXT_APPROVAL_PERMITS, {
    variables: {
      searchText: value,
      limit,
      offset: page - 1,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: SiteList } = useQuery(GET_SITE_LIST, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  const { data: DisciplineList } = useQuery(GET_ALL_DISCIPLINE);

  let total = data?.GetMyNextPendingApprovalChildPermits?.count;
  let results = JSON.parse(data?.GetMyNextPendingApprovalChildPermits?.results || "[]");
  let siteList = [];

  let filteredResults = [];

  console.log("results: " + JSON.stringify(results.submission, null, 2));

  if (SiteList?.GetSites?.results?.length) {
    SiteList?.GetSites?.results?.map((site) => {
      siteList.push({
        text: site.code,
        value: site.code,
      });
    });
  }

  const handleDisciplineNameReset = () => {
    setDisciplineName([]);
  };

  const handleDisciplineNameCheckbox = (e) => {
    setDisciplineName(e);
  };

  let disciplineList = [];

  if (DisciplineList?.GetAllDisciplines?.results?.length) {
    DisciplineList?.GetAllDisciplines?.results?.map((discipline) => {
      disciplineList.push({
        text: discipline.code,
        value: discipline.code,
      });
    });
  }

  /** START For Applier Name Filtering */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    // filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    //   <div style={{ padding: 8 }}>
    //     <Input
    //       placeholder={`Search ${dataIndex}`}
    //       value={selectedKeys[0]}
    //       onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
    //       onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
    //       style={{ marginBottom: 8, display: "block" }}
    //     />
    //     <Space>
    //       <Button
    //         type="primary"
    //         onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
    //         icon={<SearchOutlined />}
    //         size="small"
    //         style={{ width: 90 }}
    //       >
    //         Search
    //       </Button>
    //       <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
    //         Reset
    //       </Button>
    //     </Space>
    //   </div>
    // ),
    // filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    // onFilter: (fullname, row) =>
    //   row?.submittedBy ? row?.submittedBy?.fullname?.toString().toLowerCase().includes(fullname.toLowerCase()) : "",
    // onFilterDropdownVisibleChange: (visible) => {
    //   if (visible) {
    //     setTimeout(() => this.searchInput.select(), 100);
    //   }
    // },
    render: (_text, _row) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={_row?.submittedBy ? _row?.submittedBy?.fullname.toString() : ""}
        />
      ) : (
        _row?.submittedBy?.fullname
      ),
  });
  /** END For Applier Name Filtering */

  /** START HOST/SYSTEM OWNER FILTERING */
  const getApplierNameSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (text, row) =>
      row?.submittedBy ? row?.submittedBy?.fullname?.toString().toLowerCase().includes(text.toLowerCase()) : "",
    render: (_text, _row) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={_row?.submittedBy ? _row?.submittedBy?.fullname.toString() : ""}
        />
      ) : (
        _row?.submittedBy?.fullname
      ),
  });

  /** START For Permit Code Filtering */
  const handleCodeSearch = (selectedKeys, confirm, dataIndex) => {
    setSearchCode(selectedKeys[0]);
    confirm();
    setSearchCode(selectedKeys[0]);
    setSearchedCodeColumn(dataIndex);
  };

  const handleCodeReset = (clearFilters) => {
    clearFilters();
    setSearchCode("");
    setValue("");
  };

  const getColumnSearchCode = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          ref={searchInput}
          value={selectedKeys[0] || searchCode}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleCodeSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleCodeSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleCodeReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (code, row) => (row?.code ? row?.code?.toString().toLowerCase().includes(code.toLowerCase()) : ""),
    onFilterDropdownVisibleChange: (visible) => {
      // try {
      //   setTimeout(() => this.searchInput.select(), 100);
      // } catch (err) {
      //   console.error(err);
      // }
      if (visible) {
        setTimeout(() => searchInput2 && searchInput2.current && searchInput2.current.select());
        // null check above: as its initial value was null
      }
    },
    render: (_text, _row) => {
      try {
        let permitNumber = _row?.code ? _row?.code : "SITE- -DATE-NUM";
        let splitPermitNumber = permitNumber.split("-");
        return splitPermitNumber[1];
      } catch (err) {
        // console.log(err);
        return "";
      }
    },
  });
  /** END For Permit Code Filtering */

  /** START HOST/SYSTEM OWNER FILTERING */
  const getHostOwnerSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (email, row) =>
      row?.hostSystemOwner ? row?.hostSystemOwner?.email?.toString().toLowerCase().includes(email.toLowerCase()) : "",
    render: (_text, _row) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={_row?.hostSystemOwner ? _row?.hostSystemOwner?.email.toString() : ""}
        />
      ) : (
        _row?.hostSystemOwner?.email
      ),
  });
  /** END HOST/SYSTEM OWNER FILTERING */

  /** START DATE APPLIED FILTERING */
  const getDateAppliedSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (sDate, row) =>
      row?.createdAt ? formatDate(row?.createdAt)?.toString().toLowerCase().includes(sDate.toLowerCase()) : "",
    render: (_text, _row) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={formatDate(_row?.createdAt) ? formatDate(_row?.createdAt).toString() : ""}
        />
      ) : (
        formatDate(_row?.createdAt)
      ),
  });
  /** END DATE APPLIED FILTERING */

  /** START START DATE FILTERING */
  const getStartDateSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (text, row) => filterStartDate(text, row),
    render: (_text, _row) => getChildPermitStartDate(_row) ?? "",
  });

  const filterStartDate = (text, permit) => {
    if (permit?.form?.title === "B2 - CONFINED SPACE") {
      return formatDate(permit?.submission?.C11StartDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B3 - LIFTING") {
      return formatDate(permit?.submission?.G11StartDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B4 - WORK AT HEIGHT WEEK 1") {
      return formatDate(permit?.submission?.GW211StartDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B5 - LSS IMPAIRMENT PERMIT") {
      return formatDate(permit?.submission?.startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B7 - SIPP PERMIT") {
      return formatDate(permit?.submission?.startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D01 - HOT WORKS DAY 01") {
      return formatDate(permit?.submission?.D1startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D02 - HOT WORKS DAY 02") {
      return formatDate(permit?.submission?.D2startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D03 - HOT WORKS DAY 03") {
      return formatDate(permit?.submission?.D03startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D04 - HOT WORKS DAY 04") {
      return formatDate(permit?.submission?.D04startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D05 - HOT WORKS DAY 05") {
      return formatDate(permit?.submission?.D05startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D06 - HOT WORKS DAY 06") {
      return formatDate(permit?.submission?.D06startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D07 - HOT WORKS DAY 07") {
      return formatDate(permit?.submission?.D07startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D08 - HOT WORKS DAY 08") {
      return formatDate(permit?.submission?.D08startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D09 - HOT WORKS DAY 09") {
      return formatDate(permit?.submission?.D09startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D10 - HOT WORKS DAY 10") {
      return formatDate(permit?.submission?.D10startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D11 - HOT WORKS DAY 11") {
      return formatDate(permit?.submission?.D11startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D12 - HOT WORKS DAY 12") {
      return formatDate(permit?.submission?.D12startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D13 - HOT WORKS DAY 13") {
      return formatDate(permit?.submission?.D13startDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D14 - HOT WORKS DAY 14") {
      return formatDate(permit?.submission?.D14startDate).toLowerCase().includes(text.toLowerCase());
    }
  };

  const getChildPermitStartDate = (permit) => {
    if (permit?.form?.title === "B2 - CONFINED SPACE") {
      return formatDate(permit?.submission?.C11StartDate) ?? "";
    }

    if (permit?.form?.title === "B3 - LIFTING") {
      return formatDate(permit?.submission?.G11StartDate) ?? "";
    }

    if (permit?.form?.title === "B4 - WORK AT HEIGHT WEEK 1") {
      return formatDate(permit?.submission?.GW211StartDate) ?? "";
    }

    if (permit?.form?.title === "B4 - WORK AT HEIGHT WEEK 2") {
      return formatDate(permit?.submission?.startDate) ?? "";
    }

    if (permit?.form?.title === "B5 - LSS IMPAIRMENT PERMIT") {
      return formatDate(permit?.submission?.startDate) ?? "";
    }

    if (permit?.form?.title === "B7 - SIPP PERMIT") {
      return formatDate(permit?.submission?.startDate) ?? "";
    }

    if (permit?.form?.title === "B1D01 - HOT WORKS DAY 01") {
      return formatDate(permit?.submission?.D1startDate) ?? "";
    }

    if (permit?.form?.title === "B1D02 - HOT WORKS DAY 02") {
      return formatDate(permit?.submission?.D2startDate) ?? "";
    }

    if (permit?.form?.title === "B1D03 - HOT WORKS DAY 03") {
      return formatDate(permit?.submission?.D03startDate) ?? "";
    }

    if (permit?.form?.title === "B1D04 - HOT WORKS DAY 04") {
      return formatDate(permit?.submission?.D04startDate) ?? "";
    }

    if (permit?.form?.title === "B1D05 - HOT WORKS DAY 05") {
      return formatDate(permit?.submission?.D05startDate) ?? "";
    }

    if (permit?.form?.title === "B1D06 - HOT WORKS DAY 06") {
      return formatDate(permit?.submission?.D06startDate) ?? "";
    }

    if (permit?.form?.title === "B1D07 - HOT WORKS DAY 07") {
      return formatDate(permit?.submission?.D07startDate) ?? "";
    }

    if (permit?.form?.title === "B1D08 - HOT WORKS DAY 08") {
      return formatDate(permit?.submission?.D08startDate) ?? "";
    }

    if (permit?.form?.title === "B1D09 - HOT WORKS DAY 09") {
      return formatDate(permit?.submission?.D09startDate) ?? "";
    }

    if (permit?.form?.title === "B1D10 - HOT WORKS DAY 10") {
      return formatDate(permit?.submission?.D10startDate) ?? "";
    }

    if (permit?.form?.title === "B1D11 - HOT WORKS DAY 11") {
      return formatDate(permit?.submission?.D11startDate) ?? "";
    }

    if (permit?.form?.title === "B1D12 - HOT WORKS DAY 12") {
      return formatDate(permit?.submission?.D12startDate) ?? "";
    }

    if (permit?.form?.title === "B1D13 - HOT WORKS DAY 13") {
      return formatDate(permit?.submission?.D13startDate) ?? "";
    }

    if (permit?.form?.title === "B1D14 - HOT WORKS DAY 14") {
      return formatDate(permit?.submission?.D14startDate) ?? "";
    }
  };
  /** END START DATE FILTERING */

  /** START END DATE FILTERING */
  const getEndDateSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (text, row) => filterEndDate(text, row),
    render: (_text, _row) => getChildPermitEndDate(_row) ?? "",
  });

  const filterEndDate = (text, permit) => {
    if (permit?.form?.title === "B2 - CONFINED SPACE") {
      return formatDate(permit?.submission?.C11EndDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B3 - LIFTING") {
      return formatDate(permit?.submission?.G11EndDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B4 - WORK AT HEIGHT WEEK 1") {
      return formatDate(permit?.submission?.GW211EndDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B4 - WORK AT HEIGHT WEEK 2") {
      return formatDate(permit?.submission?.dateEnd).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B5 - LSS IMPAIRMENT PERMIT") {
      return formatDate(permit?.submission?.dateEnd).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B7 - SIPP PERMIT") {
      return formatDate(permit?.submission?.preferredEndDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D01 - HOT WORKS DAY 01") {
      return formatDate(permit?.submission?.D1endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D02 - HOT WORKS DAY 02") {
      return formatDate(permit?.submission?.D2endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D03 - HOT WORKS DAY 03") {
      return formatDate(permit?.submission?.D03endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D04 - HOT WORKS DAY 04") {
      return formatDate(permit?.submission?.D04endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D05 - HOT WORKS DAY 05") {
      return formatDate(permit?.submission?.D05endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D06 - HOT WORKS DAY 06") {
      return formatDate(permit?.submission?.D06endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D07 - HOT WORKS DAY 07") {
      return formatDate(permit?.submission?.D07endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D08 - HOT WORKS DAY 08") {
      return formatDate(permit?.submission?.D08endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D09 - HOT WORKS DAY 09") {
      return formatDate(permit?.submission?.D09endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D10 - HOT WORKS DAY 10") {
      return formatDate(permit?.submission?.D10endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D11 - HOT WORKS DAY 11") {
      return formatDate(permit?.submission?.D11endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D12 - HOT WORKS DAY 12") {
      return formatDate(permit?.submission?.D12endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D13 - HOT WORKS DAY 13") {
      return formatDate(permit?.submission?.D13endDate).toLowerCase().includes(text.toLowerCase());
    }

    if (permit?.form?.title === "B1D14 - HOT WORKS DAY 14") {
      return formatDate(permit?.submission?.D14endDate).toLowerCase().includes(text.toLowerCase());
    }
  };
  const getChildPermitEndDate = (permit) => {
    if (permit?.form?.title === "B2 - CONFINED SPACE") {
      return formatDate(permit?.submission?.C11EndDate) ?? "";
    }

    if (permit?.form?.title === "B3 - LIFTING") {
      return formatDate(permit?.submission?.G11EndDate) ?? "";
    }

    if (permit?.form?.title === "B4 - WORK AT HEIGHT WEEK 1") {
      return formatDate(permit?.submission?.GW211EndDate) ?? "";
    }

    if (permit?.form?.title === "B4 - WORK AT HEIGHT WEEK 2") {
      return formatDate(permit?.submission?.dateEnd) ?? "";
    }

    if (permit?.form?.title === "B5 - LSS IMPAIRMENT PERMIT") {
      return formatDate(permit?.submission?.dateEnd) ?? "";
    }

    if (permit?.form?.title === "B7 - SIPP PERMIT") {
      return formatDate(permit?.submission?.preferredEndDate) ?? "";
    }

    if (permit?.form?.title === "B1D01 - HOT WORKS DAY 01") {
      return formatDate(permit?.submission?.D1endDate) ?? "";
    }

    if (permit?.form?.title === "B1D02 - HOT WORKS DAY 02") {
      return formatDate(permit?.submission?.D2endDate) ?? "";
    }

    if (permit?.form?.title === "B1D03 - HOT WORKS DAY 03") {
      return formatDate(permit?.submission?.D03endDate) ?? "";
    }

    if (permit?.form?.title === "B1D04 - HOT WORKS DAY 04") {
      return formatDate(permit?.submission?.D04endDate) ?? "";
    }

    if (permit?.form?.title === "B1D05 - HOT WORKS DAY 05") {
      return formatDate(permit?.submission?.D05endDate) ?? "";
    }

    if (permit?.form?.title === "B1D06 - HOT WORKS DAY 06") {
      return formatDate(permit?.submission?.D06endDate) ?? "";
    }

    if (permit?.form?.title === "B1D07 - HOT WORKS DAY 07") {
      return formatDate(permit?.submission?.D07endDate) ?? "";
    }

    if (permit?.form?.title === "B1D08 - HOT WORKS DAY 08") {
      return formatDate(permit?.submission?.D08endDate) ?? "";
    }

    if (permit?.form?.title === "B1D09 - HOT WORKS DAY 09") {
      return formatDate(permit?.submission?.D09endDate) ?? "";
    }

    if (permit?.form?.title === "B1D10 - HOT WORKS DAY 10") {
      return formatDate(permit?.submission?.D10endDate) ?? "";
    }

    if (permit?.form?.title === "B1D11 - HOT WORKS DAY 11") {
      return formatDate(permit?.submission?.D11endDate) ?? "";
    }

    if (permit?.form?.title === "B1D12 - HOT WORKS DAY 12") {
      return formatDate(permit?.submission?.D12endDate) ?? "";
    }

    if (permit?.form?.title === "B1D13 - HOT WORKS DAY 13") {
      return formatDate(permit?.submission?.D13endDate) ?? "";
    }

    if (permit?.form?.title === "B1D14 - HOT WORKS DAY 14") {
      return formatDate(permit?.submission?.D14endDate) ?? "";
    }
  };
  /** END END DATE FILTERING */

  const getDiscipline = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Checkbox.Group
          style={{ width: "100%" }}
          onChange={handleDisciplineNameCheckbox}
          value={disciplineName || disciplineName}
        >
          {disciplineList?.map((res) => {
            return (
              <Row>
                <Col span={24}>
                  <Checkbox value={res.value}>{res.text}</Checkbox>
                </Col>
              </Row>
            );
          })}
        </Checkbox.Group>
        <Space>
          <Button onClick={handleDisciplineNameReset} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
  });

  /** START For Permit Number Filtering */
  const handlePermitNumberSearch = (selectedKeys, confirm, dataIndex) => {
    setValue(selectedKeys[0]);
    confirm();
    setSearchPermitNumber(selectedKeys[0]);
    setSearchedPermitNumberColumn(dataIndex);
  };

  const handlePermitNumberReset = (clearFilters) => {
    setValue("");
    clearFilters();
    setSearchPermitNumber("");
  };

  const getColumnSearchPermitNumber = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search Permit Number`}
          ref={searchInput}
          value={selectedKeys[0] || value}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handlePermitNumberSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handlePermitNumberSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handlePermitNumberReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (permitnumber, row) =>
      row?.code ? row?.code?.toString().toLowerCase().includes(permitnumber.toLowerCase()) : "",
    onFilterDropdownVisibleChange: (visible) => {
      // try {
      //   setTimeout(() => this.searchInput.current.select(), 100);
      // } catch (err) {
      //   console.error(err);
      // }
      if (visible) {
        setTimeout(() => searchInput && searchInput.current && searchInput.current.select());
        // null check above: as its initial value was null
      }
    },
    render: (_text, _row) =>
      searchedPermitNumberColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchPermitNumber]}
          autoEscape
          textToHighlight={_row?.code ? _row?.code : ""}
        />
      ) : (
        _row?.code
      ),
  });
  /** END For Permit Number Filtering */

  /** START PARENT PERMIT NUMBER FILTERING */
  const getParentPermitCodeProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (code, row) =>
      row?.parentPermit?.code ? row?.parentPermit?.code?.toString().toLowerCase().includes(code.toLowerCase()) : "",
    render: (_text, _row) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={_row?.parentPermit?.code ? _row?.parentPermit?.code.toString() : ""}
        />
      ) : (
        _row?.parentPermit?.code
      ),
  });
  /** END PARENT PERMIT FILTERING */

  const columns = [
    // {
    //   title: "#",
    //   width: "3%",
    // },
    {
      title: "Child permit",
      render: (_text, _row) => _row?.form?.title,
    },
    {
      title: "Permit Number",
      ...getColumnSearchPermitNumber("permitnumber"),
    },
    {
      title: "Permit Code",
      ...getColumnSearchCode("code"),
      width: "5%",
    },
    {
      title: "Parent Permit ID",
      // render: (_text, _row) => _row?.parentPermit?.code,
      ...getParentPermitCodeProps("parentPermit"),
      width: "20%",
    },
    {
      title: "Applier name",
      /** START For Applier Name Filtering */
      ...getApplierNameSearchProps("fullname"),
      /** END For Applier Name Filtering */
    },
    // {
    //   title: "Applier Site",
    //   filters: siteList,
    //   onFilter: (_text, _row) => _row?.site?.code?.indexOf(_text) === 0,
    //   render: (_text, _row) => _row?.site?.code,
    // },
    {
      title: "Discipline",
      filters: disciplineList,
      onFilter: (_text, _row) => _row?.discipline?.code?.indexOf(_text) === 0,
      render: (_text, _row) => (_row?.discipline?.code !== undefined ? _row?.discipline?.code : ""),
    },
    {
      title: "Host/System Owner",
      ...getHostOwnerSearchProps("hostSystemOwner"),
      // render: (_text, _row) => (_row?.hostSystemOwner?.email !== undefined)? _row?.hostSystemOwner?.email : "",
    },
    {
      title: "Date Applied",
      // render: (_text, _row) => formatDate(_row.createdAt),
      ...getDateAppliedSearchProps("dateApplied"),
    },
    {
      title: "Start Date",
      ...getStartDateSearchProps("startDate"),
      // render: (_text, _row) => formatDate(_row?.submission?.startDate),
    },
    {
      title: "End Date",
      ...getEndDateSearchProps("dateEnd"),
      // render: (_text, _row) => formatDate(_row?.submission?.dateEnd),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "15%",
      filters: PermitStatusArray,
      onFilter: (status, row) => row?.status?.indexOf(status) === 0,
      render: (status, row) => {
        let color = "orange";
        if (status === PermitStatus.Rejected) color = StatusColor.Rejected;
        else if (status === PermitStatus.Approved) color = StatusColor.Approved;
        else if (status === PermitStatus.Completed) color = StatusColor.Completed;
        else if (status === PermitStatus.Cancelled) color = StatusColor.Cancelled;
        else if (status === PermitStatus.PendingClosure) color = StatusColor.PendingClosure;
        else if (status === PermitStatus.PendingCancel) color = StatusColor.PendingCancel;

        const approvedBy = row?.approvedBy?.length ? row.approvedBy[row?.approvedBy?.length - 1] : null;
        const rejectedBy = row?.rejectedBy;

        let nextReviewer = getNextReviewer(row);

        return (
          <div>
            {status === PermitStatus.Pending ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By PM
              </Tag>
            ) : status === PermitStatus.PendingApproval ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Pending Approval By {nextReviewer}
              </Tag>
            ) : status === PermitStatus.Rejected && rejectedBy?.group?.name ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Rejected By {rejectedBy?.fullname}
              </Tag>
            ) : status === PermitStatus.Approved && approvedBy ? (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                Final Approved By {approvedBy?.fullname}
              </Tag>
            ) : (
              <Tag
                style={{
                  borderRadius: 10,
                  padding: "0px 10px",
                  width: "100%",
                  justifyContent: "space-around",
                  display: "flex",
                }}
                color={color}
              >
                {status}
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Action",
      width: "15%",
      render: (text, record) => {
        const menu = (
          <Menu hidden={record.code === undefined}>
            <Menu.Item key="0" disabled={record.code === undefined}>
              <Link
                style={{ marginRight: "15px" }}
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`childpermits/${record._id}`, {
                    user: record,
                  });
                }}
                hidden={record.code === undefined}
              >
                View
              </Link>
            </Menu.Item>
            {(user.role === UserRole.ContractorRequestor ||
              user.role === UserRole.User ||
              user.role === UserRole.MTGroup) &&
              record.status === PermitStatus.Approved && (
                <Menu.Item key="5">
                  <Link
                    style={{ marginRight: "15px" }}
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`childpermits/review/${record._id}`, {
                        user: record,
                      });
                    }}
                  >
                    Review
                  </Link>
                </Menu.Item>
              )}
            {user.role === UserRole.ContractorRequestor && record.status === PermitStatus.Rejected && (
              <Menu.Item key="5">
                <Link
                  style={{ marginRight: "15px" }}
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(`childpermits/review/${record._id}`, {
                      user: record,
                    });
                  }}
                >
                  Resubmit
                </Link>
              </Menu.Item>
            )}

            {(user.role === UserRole.ContractorPM ||
              user.role === UserRole.MTGroup ||
              user.role === UserRole.MicronSupervisor ||
              user.role === UserRole.MicronManager ||
              user.role === UserRole.SiteAdmin) &&
              record.status !== PermitStatus.Approved && (
                <Fragment>
                  <Menu.Divider />
                  <Menu.Item key="3">
                    <Link
                      style={{ marginRight: "15px" }}
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`childpermits/review/${record._id}`, {
                          user: record,
                        });
                      }}
                    >
                      Review
                    </Link>
                  </Menu.Item>
                </Fragment>
              )}
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <EllipsisOutlined />
          </Dropdown>
        );
      },
    },
  ];

  if (error) {
    return <div className="card">Something went wrong</div>;
  }

  //   let createBtn = null;
  //   if (
  //     user.role === UserRole.User ||
  //     user.role === UserRole.ContractorRequestor ||
  //     user.role === UserRole.SiteAdmin ||
  //     user.role === UserRole.MTGroup
  //   ) {
  //     createBtn = (
  //       <Link to={match.path + "/create-permit"} className="btn btn-primary mr-2" hidden>
  //         Create Permit <i className="fas fa-plus-circle"></i>
  //       </Link>
  //     );
  //   }

  if (showOnlyDuePermits) {
    for (var i = 0; i < results?.length; i++) {
      if (results[i]?.status === PermitStatus.Pending || results[i]?.status === PermitStatus.PendingApproval) {
        let sDate = formatDate(results[i]?.submission?.startDate);
        if (moment(sDate).isBefore(dateToday)) {
          filteredResults.push(results[i]);
        }
      }
    }
  } else {
    filteredResults = results;
  }

  const onChangePagination = (p, size) => {
    setPage(p);
    setLimit(size);
  };

  const onChangeSearch = (datavalue) => {
    setValue(datavalue);
  };

  const onChangeCheckBox = (e) => {
    setShowOnlyDuePermits(e.target.checked);
  };

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="form-row">
              <div className="form-group col-md-3" />
              <div className="form-group col-md-3" />
              <div className="form-group col-md-3" />
              {/* <div className="form-group col-md-3">
                <Checkbox
                  onChange={(e) => {
                    e.preventDefault();
                    onChangeCheckBox(e);
                  }}
                >
                  Show Only Due Permits
                </Checkbox>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <DynamicList
        title="Child Permits Pending My Approval"
        loading={loading}
        columns={columns}
        data={filteredResults}
        total={total}
        pageSize={limit}
        onChange={onChangePagination}
        pageNo={page}
        showSearch={true}
        onChangeSearch={onChangeSearch}
      />
    </Fragment>
  );
};
