import gql from "graphql-tag";

export const GET_ALL_CHANGE_LOGS = gql`
  query {
    GetAllChangeLogs {
      changelogs
      message
      success
      code
    }
  }
`;
