import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
// import { _ } from "@ungap/global-this";
import { Spin, Select, message, Button, Checkbox, Typography, Modal, Alert, Tooltip } from "antd";
import React, { useEffect, useState, Fragment } from "react";
import { Divider } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import debounce from "lodash/debounce";
import _ from "lodash";

import { PermitSection } from "../components/ReviewPermit";
import { PermitUserInfo } from "../components/Permit/PermitUserInfo";
import { CustomButton } from "../components/Shared";
import { PermitStatus, UserRole } from "../config";
import { GENERAL_PERMIT_ID } from "../util";
import {
  GET_DRAFT_PERMIT_BY_ID,
  UPDATE_DRAFT_PERMIT,
  UPDATE_DRAFT_PERMIT_REVIEWERS,
  GET_GROUP_LIST,
  GET_ALL_USERS_LIST,
  CREATE_PERMIT,
  GET_ALL_MTGROUPS,
  DELETE_DRAFT_PERMIT,
  GET_TEMPLATE_LIST,
  SAVE_DRAFT_CHILD_PERMIT,
  GET_SPECIAL_TEMPLATE_LIST,
  GET_ALL_DISCIPLINE,
  GET_ALL_MICRON_USERS,
} from "../graphql/modules";
import { successNotify, warnNotify } from "../util";

const { Title } = Typography;
const micronEmail = "@micron.com";

export const ReviewDraftPermit = ({ match, history }) => {
  const [visibleChildPermit, setVisibleChildPermit] = useState(null);
  const [template, setTemplate] = useState(null);
  const [state, setState] = useState({});
  const [childrenState, setChildrenState] = useState([]);
  const [mtgroup, setMTGroup] = useState(null);
  const [users, setUsers] = useState(null);
  const [isFormValid, setIsFormValid] = useState(true);

  const [isR1Checked, setIsR1Checked] = useState(false);
  const [isMTChecked, setIsMTChecked] = useState(false);

  const [childtemplate, setChildTemplate] = useState(null);
  const [childSubmissionState, setChildSubmissionState] = useState(null);

  const [discipline, setDiscipline] = useState(null);
  const [hostSystemOwner, setHostSystemOwner] = useState(null);

  const { user: authUser } = useSelector((_state) => _state.auth);

  const { data: DisciplineList } = useQuery(GET_ALL_DISCIPLINE);

  const { data, loading, refetch } = useQuery(GET_DRAFT_PERMIT_BY_ID, {
    variables: {
      id: match.params.id,
    },
  });

  const { data: MTGroupsList } = useQuery(GET_ALL_MTGROUPS, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  const [getGroups, { data: GroupList, loading: fetchingGroup }] = useLazyQuery(GET_GROUP_LIST, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  const [getUsers, { data: UserList, loading: fetchingUser }] = useLazyQuery(GET_ALL_USERS_LIST, {
    variables: {
      limit: 1000,
      offset: 0,
      searchText: micronEmail,
    },
  });

  const [getHostOwner, { data: HostOwnerList, loading: fetchingHostOwner }] = useLazyQuery(GET_ALL_MICRON_USERS, {
    variables: {
      limit: 1000,
      offset: 0,
      searchText: micronEmail,
    },
  });

  const { data: SpecialChildTemplates } = useQuery(GET_SPECIAL_TEMPLATE_LIST, {
    variables: {
      showInCreatePermit: true,
    },
  });

  const [UpdateDraftPermit, { loading: updateDraftLoading }] = useMutation(UPDATE_DRAFT_PERMIT);
  const [UpdateDraftPermitReviewers] = useMutation(UPDATE_DRAFT_PERMIT_REVIEWERS);

  const [SaveDraftChildPermit, { loading: saveDraftChildPermitLoading }] = useMutation(SAVE_DRAFT_CHILD_PERMIT);

  useEffect(() => {
    setState(permit?.submission || {});
    setChildrenState(permit?.children || []);
  }, [data]);

  useEffect(() => {
    if (data) {
      getUsers();
      getGroups();
      getHostOwner();
    }
  }, [data]);

  //MODAL FOR ADDING CHILD PERMIT
  const onOkChildPermit = () => {
    setVisibleChildPermit(false);
  };

  const onChangeDiscipline = (value) => {
    // console.log("onChangeDiscipline event: ", JSON.stringify(value));

    console.log("onChangeDiscipline value: ", value.value);
    setDiscipline(value);
  };

  const onChangeHostSystemOwner = (value) => {
    console.log("onChangeHostSystemOwner event: " + value);
    setHostSystemOwner(value);
  };

  const onChangeChildPermit = (_v, obj) => {
    setChildTemplate(obj);
    // console.log("onChangeChildPermit.obj: " + JSON.stringify(obj[0]?.template?.sections, null, 2));
    obj[0]?.template?.sections?.map((section) => {
      section?.form?.map((frm) => {
        if (frm.required) {
          var obj = {
            name: frm.name,
            required: frm.required,
          };
          temporaryChildFormState.push(obj);
        }
      });
    });
  };

  const handleChildPermit = (_childSubmissionState) => {
    setChildSubmissionState(_childSubmissionState);
  };

  function onChangeR1Checkbox(e) {
    console.log(`checked = ${e.target.checked}`);
    if (e.target.checked) {
      setIsR1Checked(true);
    } else {
      setIsR1Checked(false);
    }
  }

  function onChangeMTCheckbox(e) {
    console.log(`checked = ${e.target.checked}`);

    if (e.target.checked) {
      setIsMTChecked(true);
    } else {
      setIsMTChecked(false);
    }
  }

  // const [childState, setChildState] = useState({});
  const onSearchUser = React.useMemo(() => {
    const loadOptions = (value) => {
      getUsers({
        variables: {
          limit: 100,
          offset: 0,
          searchText: value,
        },
      });
    };
    return debounce(loadOptions, 800);
  }, []);

  const onSearchGroup = React.useMemo(() => {
    const loadOptions = (value) => {
      getGroups({
        variables: {
          limit: 10,
          offset: 0,
          searchText: value,
        },
      });
    };
    return debounce(loadOptions, 800);
  }, []);

  const onPermitChange = (values) => {
    setState({ ...state, ...values });
  };

  const onUpdateDraftPermit = async (values) => {
    console.log("++++++++++++++++");
    console.log("discipline: ", discipline?.value);
    console.log("hostSystemOwner: ", hostSystemOwner?.value);
    console.log("++++++++++++++++");

    try {
      const { data: UpdateDraftData } = await UpdateDraftPermit({
        variables: {
          id: match.params.id,
          status: values.status,
          submission: JSON.stringify(state),
          childrenState: JSON.stringify(childrenState),
          discipline: discipline ? discipline?.value : selectDisciplineValue?._id,
          hostSystemOwner: hostSystemOwner ? hostSystemOwner?.value : selectHostOwnerValue?._id,
        },
      });

      if (UpdateDraftData.UpdateDraftPermit.success) {
        successNotify(UpdateDraftData.UpdateDraftPermit.message);
        history.push(`/draftpermit/${permit?._id}`);
      } else {
        console.error("UPDATE DRAFT PERMIT FAILED!!");
        if (UpdateDraftData.UpdateDraftData.errors) {
          warnNotify("Please fill in required information above. (e.g. Discipline, Host / System Owner, etc");
        } else {
          warnNotify(UpdateDraftData.UpdateDraftPermit.message);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onChangeHandler = (values, child) => {
    console.log("\n");
    const selectedChild = childrenState?.find((d) => d._id === child._id);
    if (selectedChild) {
      console.log("selectedChild is true.");
      Object.assign(selectedChild.submission, values);
      try {
        setChildrenState([...childrenState, ...selectedChild]);
      } catch (error) {
        console.log("Error!!");
      }
    } else {
      if (childrenState?.length > 0) {
        console.log("childrenState?.length > 0");
        childrenState.push({
          form: child?._id,
          submission: values,
        });
      } else {
        console.log("!!!!childrenState?.length > 0");
        setChildrenState([
          {
            form: child?._id,
            submission: values,
          },
        ]);
      }
    }
  };
  const onUpdateReviewers = async () => {
    console.log("users: " + JSON.stringify(users, null, 2));
    console.log("mtgroup: " + JSON.stringify(mtgroup, null, 2));

    try {
      let newSelectedUsers = [];
      let newMTGroups = [];
      let reviewersOkToSubmit = false;
      let mtGrpsOkToSubmit = false;

      if (isR1Checked === false && isMTChecked === false) {
        warnNotify("Please tick any checkbox and select reviewer or MTGroup first.");
      } else {
        if ((users === null || users === []) && (mtgroup === null || mtgroup === [])) {
          warnNotify("You cannot submit empty fields when updating reviewers.");
          reviewersOkToSubmit = false;
          mtGrpsOkToSubmit = false;
        } else {
          if (isR1Checked) {
            if (users) {
              let hasSameReviewer = permit?.selectedUser.map((usr) => {
                let res = users && users.some((usrId) => usrId.toString() === usr._id.toString());
                return res;
              });

              if (hasSameReviewer.includes(true)) {
                warnNotify("You cannot add the same Individual Reviewer or someone from the selected MTGroup.");
                reviewersOkToSubmit = false;
              } else {
                newSelectedUsers = users;
                reviewersOkToSubmit = true;
              }
            } else {
              warnNotify("You cannot submit empty fields when updating reviewers.");
              reviewersOkToSubmit = false;
            }
          } else {
            console.log("No Change in Individual Reviewer");
            reviewersOkToSubmit = true;
          }

          if (isMTChecked) {
            if (mtgroup) {
              if (permit?.selectedMTGroup) {
                let hasSameMTGroup =
                  permit?.selectedMTGroup &&
                  permit?.selectedMTGroup.map((grp) => {
                    let res = mtgroup && mtgroup.some((sGrp) => sGrp.toString() === grp._id.toString());
                    return res;
                  });
                if (hasSameMTGroup.includes(true)) {
                  warnNotify("You cannot add the same MTGroup.");
                  mtGrpsOkToSubmit = false;
                } else {
                  newMTGroups = mtgroup;
                  mtGrpsOkToSubmit = true;
                }
              } else {
                newMTGroups = mtgroup;
                mtGrpsOkToSubmit = true;
              }
            } else {
              warnNotify("You cannot submit empty fields when updating reviewers.");
              mtGrpsOkToSubmit = false;
            }
          } else {
            console.log("No Change in MT Groups");
            mtGrpsOkToSubmit = true;
          }
        }

        // mutation here, add new selected users to selectedUsers in backend
        // and new MTGroup Members to selectedUsers and selectedMTGroupUsers
        if (reviewersOkToSubmit && mtGrpsOkToSubmit) {
          try {
            console.log("\n===================");
            console.log("permit id: " + permit._id);
            console.log("\nnewSelectedUsers: " + JSON.stringify(newSelectedUsers, null, 2));
            console.log("\nnewMTGroups: " + JSON.stringify(newMTGroups, null, 2));
            console.log("\n===================");

            const { data } = await UpdateDraftPermitReviewers({
              variables: {
                id: permit._id,
                newIndividualReviewers: newSelectedUsers,
                newMTGroups: newMTGroups,
              },
            });

            if (data.UpdateDraftPermitReviewers.success) {
              successNotify(data.UpdateDraftPermitReviewers.message);
              refetch();
            } else {
              warnNotify(data.UpdateDraftPermitReviewers.message);
            }
          } catch (updErr) {
            console.log(updErr);
          }
        } else {
          console.log("Not submitting updates to backend.");
        }
      }
    } catch (err) {
      console.log("Something went wrong updating reviewers");
      console.log(err);
    }
  };

  const handleDraftUpdate = (e) => {
    e.preventDefault();

    if (!discipline) {
      setDiscipline(selectDisciplineValue?._id);
    }
    // else {
    //   let dispId = discipline.value;
    //   console.log("dispId: " + dispId);
    //   setDiscipline(dispId);
    // }

    if (!hostSystemOwner) {
      setHostSystemOwner(selectHostOwnerValue?._id);
    }
    // else {
    //   let hostId = hostSystemOwner.value;
    //   console.log("hostId: " + hostId);
    //   setHostSystemOwner(hostId);
    // }

    continueSaveDraft();
  };

  const continueSaveDraft = () => {
    onUpdateDraftPermit({ status: PermitStatus.DraftPermit });
  };

  const onSaveAddChildPermit = async () => {
    console.log("permit?: " + JSON.stringify(permit, null, 2));
    console.log("match.params.id: " + JSON.stringify(match.params.id, null, 2));
    // console.log("childtemplate: " + JSON.stringify(childtemplate, null, 2));
    // console.log("childSubmissionState: " + JSON.stringify(childSubmissionState, null, 2));
    console.log(
      "\n===================================\n SAVING DRAFT CHILD PERMIT \n===================================\n"
    );

    try {
      const { data } = await SaveDraftChildPermit({
        variables: {
          id: match.params.id,
          childrenState: JSON.stringify(childSubmissionState),
        },
      });

      if (data.SaveDraftChildPermit.success) {
        successNotify(data.SaveDraftChildPermit.message);
        setChildTemplate(null);
        setChildSubmissionState(null);
        temporaryChildFormState = [];
        refetch();
      } else {
        warnNotify(data.SaveDraftChildPermit.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {}, [discipline, hostSystemOwner]);

  let permit = {};
  if (data?.GetDraftPermitById?.permit) {
    permit = JSON.parse(data?.GetDraftPermitById?.permit || {});
  }

  let selectHostOwnerValue = permit?.hostSystemOwner;
  let selectDisciplineValue = permit?.discipline;

  // console.log("selectHostOwnerValue: " + JSON.stringify(selectHostOwnerValue));
  // console.log("selectDisciplineValue: " + JSON.stringify(selectDisciplineValue));

  const selectedHostOwnerProps = {
    style: { width: "100%" },
    labelInValue: true,
    value: hostSystemOwner
      ? hostSystemOwner
      : { key: selectHostOwnerValue?._id, label: selectHostOwnerValue?.fullname },
  };

  const selectedDisciplineProps = {
    style: { width: "100%" },
    labelInValue: true,
    value: discipline ? discipline : { key: selectDisciplineValue?._id, label: selectDisciplineValue?.code },
  };

  console.log("permit?.site: " + permit?.site);

  const [CreatePermitMutation, { loading: createPermitLoading }] = useMutation(CREATE_PERMIT);
  const [DeleteDraftPermit, { loading: deleteDraftLoading }] = useMutation(DELETE_DRAFT_PERMIT);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (isFormValid) {
      let selectedUserIds = [];
      let selectedMTGroupUserIds = [];
      let selectedMTGroupIds = [];

      permit.selectedUser &&
        permit.selectedUser.map((usr) => {
          selectedUserIds.push(usr._id);
        });

      permit.selectedMTGroupUsers &&
        permit.selectedMTGroupUsers.map((mtGrpMmbrs) => {
          selectedMTGroupUserIds.push(mtGrpMmbrs._id);
        });

      permit.selectedMTGroup &&
        permit.selectedMTGroup.map((mtgrp) => {
          selectedMTGroupIds.push(mtgrp._id);
        });

      var varData = {
        form: permit?.form?._id,
        submission: JSON.stringify(state),
        childrenState: JSON.stringify(childrenState),
        site: permit?.site,
        selectedUser: selectedUserIds,
        selectedMTGroupUsers: selectedMTGroupUserIds,
        selectedMTGroup: selectedMTGroupIds,
        discipline: discipline,
        hostSystemOwner: hostSystemOwner,
      };

      console.log("varData: " + JSON.stringify(varData, null, 2));

      /*
      try {
        console.log("trying to create permit...");
        const { data: CreatePermitData } = await CreatePermitMutation({
          variables: {
            data: varData,
          },
        });
        if (CreatePermitData?.CreatePermit?.success) {
          message.success(CreatePermitData?.CreatePermit?.message);
          await DeleteDraftPermit({
            variables: {
              id: permit?._id,
            },
          });

          if (authUser.role === UserRole.MTGroup) {
            history.push("/all-my-permits");
          } else {
            history.push("/permits");
          }
        } else {
          message.warn(CreatePermitData?.CreatePermit?.message);
        }
      } catch (_error) {}
      */
    } else {
      message.warn("Some required fields are not filled in.");
    }
  };

  let hideApproveButton = true;
  let updateReviewersComponents = false;

  if (data?.GetDraftPermitById?.success === false) {
    return <div className="card">Not found</div>;
  }

  let _selectedMtGroup = permit.selectedMTGroup;

  if (_selectedMtGroup === undefined || _selectedMtGroup === null || _selectedMtGroup === []) {
    console.log("No MTGroup Selected");
    hideApproveButton = false;
  } else {
    let showButton = true;

    for (var i = 0; i < _selectedMtGroup.length; i++) {
      if (_selectedMtGroup[i].members.some((p) => p._id === authUser._id)) {
        console.log(
          "User is a member of a selected MTGroup: " +
            _selectedMtGroup[i].name +
            "? " +
            _selectedMtGroup[i].members.some((p) => p._id === authUser._id)
        );

        const hasMemberApproved = permit.approvedBy.map((pApproved) => {
          let res = _selectedMtGroup[i].members.some((mmbr) => mmbr._id == pApproved._id);
          return res;
        });

        if (hasMemberApproved.includes(true)) {
          showButton = true;
          console.log(
            "One members on same MTGroup Approved already? " +
              hasMemberApproved.includes(true) +
              "\nHide approve button? " +
              showButton
          );
          break;
        } else {
          showButton = false;
          console.log(
            "One members on same MTGroup Approved already? " +
              hasMemberApproved.includes(true) +
              "\nHide approve button? " +
              showButton
          );
          break;
        }
      } else {
        console.log("User is not a member of any selected MTGroup");
        showButton = false;
      }
    }

    hideApproveButton = showButton;
  }

  //MODAL FOR COMMENT REJECT

  let selectDefaultValue = permit?.selectedUser?.map((user) => {
    if (!permit?.selectedMTGroupUsers?.find((o2) => user.email === o2.email)) {
      return user?.email;
    }
  });

  let selectedMTGroup = permit?.selectedMTGroup?.map((grp) => {
    return grp?.name;
  });

  let showChildPermitSection = permit?.children?.length ? true : false;
  let temporaryChildFormState = [];

  let disableChildPermitSaveButton = childtemplate === null || childtemplate.length <= 0 ? true : false;

  const selectedProps = {
    mode: "multiple",
    style: { width: "100%" },
    value: _.compact(selectDefaultValue),
  };

  const selectedMtProps = {
    mode: "multiple",
    style: { width: "100%" },
    value: selectedMTGroup,
  };

  // console.log("fromParentPermit: " + JSON.stringify(fromParentPermit, null, 2));

  return (
    <Spin spinning={loading}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="create-permit-details">
              <div className="form-row">
                <div className="form-group col-md-3" />
                <div className="form-group col-md-3">
                  <Title level={5}>Selected Individual Reviewers</Title>
                  <Select {...selectedProps} disabled></Select>
                </div>
                <div className="form-group col-md-3">
                  <Title level={5}>Selected MTGroups</Title>
                  <Select {...selectedMtProps} disabled></Select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="create-permit-details">
              <div className="form-row">
                <div className="form-group col-md-3" />
                <div className="form-group col-md-3">
                  <Checkbox onChange={onChangeR1Checkbox} disabled={updateReviewersComponents}>
                    {" "}
                    Include to update{" "}
                  </Checkbox>
                  <Select
                    // disabled={isR1Checked}
                    style={{ width: "100%" }}
                    onChange={(value) => setUsers(value)}
                    placeholder="Select User"
                    showSearch={true}
                    onSearch={onSearchUser}
                    multiple
                    filterOption={false}
                    notFoundContent={fetchingUser ? <Spin size="small" /> : null}
                    mode="multiple"
                    disabled={updateReviewersComponents || !isR1Checked}
                  >
                    {UserList?.GetAllUsers?.results
                      ?.sort((a, b) => a.email.localeCompare(b.email))
                      .filter((res) => res.email.toLowerCase().indexOf(micronEmail) !== -1)
                      .map((user) => (
                        <Select.Option key={user._id} value={user._id}>
                          <strong>{user.fullname}</strong>
                          <br />
                          <span>{user.email}</span>
                        </Select.Option>
                      ))}
                  </Select>
                </div>
                <div className="form-group col-md-3">
                  <Checkbox onChange={onChangeMTCheckbox} disabled={updateReviewersComponents}>
                    {" "}
                    Include to update{" "}
                  </Checkbox>
                  <Select
                    style={{ width: "100%" }}
                    onChange={(value) => setMTGroup(value)}
                    placeholder="Select MT Group"
                    showSearch={true}
                    onSearch={onSearchGroup}
                    multiple
                    filterOption={false}
                    notFoundContent={fetchingGroup ? <Spin size="small" /> : null}
                    mode="multiple"
                    disabled={updateReviewersComponents || !isMTChecked}
                  >
                    {GroupList?.GetUserGroups?.results
                      ?.sort((a, b) => a.name.localeCompare(b.name))
                      .map((group) => (
                        <Select.Option key={group._id} value={group._id}>
                          {group.name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
                <div className="form-group col-md-3">
                  <Button
                    type="primary"
                    onClick={(e) => {
                      e.preventDefault();
                      onUpdateReviewers();
                    }}
                    disabled={updateReviewersComponents}
                  >
                    Update Reviewers
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Divider />
      <br />

      <div className="row">
        <div className="col-12">
          <div className="create-permit-details">
            <div className="form-row">
              {permit && (
                <div className="form-group col-md-3">
                  Host/System Owner <br />
                  <Select {...selectedHostOwnerProps} onChange={onChangeHostSystemOwner}>
                    {HostOwnerList?.GetAllMicronUsers?.results
                      ?.sort((a, b) => a.email.localeCompare(b.email))
                      .filter((res) => res.email.toLowerCase().indexOf(micronEmail) !== -1)
                      .map((user) => (
                        <Select.Option key={user._id} value={user._id}>
                          <strong>{user.fullname}</strong>
                          <br />
                          <span>{user.email}</span>
                        </Select.Option>
                      ))}
                  </Select>
                  <br />
                </div>
              )}

              {permit && (
                <div className="form-group col-md-3">
                  Discipline <br />
                  <Select {...selectedDisciplineProps} onChange={onChangeDiscipline}>
                    {DisciplineList?.GetAllDisciplines?.results
                      ?.sort((a, b) => a.code.localeCompare(b.code))
                      .map((_discipline) => (
                        <Select.Option key={_discipline._id} value={_discipline._id}>
                          {_discipline.code}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              )}
              <div className="form-group col-md-3" />
              <div className="form-group col-md-3" />
            </div>
          </div>
        </div>
      </div>

      <div className="heading">
        <Link to="/my-draft-permits" className="btn-link">
          <i className="far fa-arrow-left"></i> Back to view all draft permits
        </Link>
      </div>

      <PermitUserInfo permit={permit} showAction={false} />

      <form>
        {permit?.form?.sections?.map((section, key) => (
          <PermitSection
            section={section}
            key={key}
            onChange={onPermitChange}
            state={permit?.submission}
            isParent={true}
            siteID={permit?.site}
            formState={state}
          />
        ))}

        {showChildPermitSection ? (
          <Fragment>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: "2%" }}>
              <br />
              <h2>Child permit(s)</h2>
            </div>
            {permit?.children?.map((child) => (
              <React.Fragment>
                <Divider />
                <div>
                  <h3>{child?.form?.title}</h3>
                </div>

                {child?.form?.sections?.map((_section, _key) => (
                  <div>
                    <PermitSection
                      templateKey={_key}
                      section={_section}
                      onChange={(values) => onChangeHandler(values, child)}
                      // onChange={onPermitChange}
                      state={child?.submission}
                      isParent={false}
                    />
                  </div>
                ))}
                <br />
              </React.Fragment>
            ))}
          </Fragment>
        ) : (
          <Fragment />
        )}

        {childtemplate?.length > 0 && (
          <ChildPermit
            templates={childtemplate}
            onChange={onPermitChange}
            state={state}
            onHandleSubmission={handleChildPermit}
            isParent={false}
          />
        )}

        <div className="text-right w-100">
          <Button type="dashed" size="large" right onClick={() => setVisibleChildPermit(true)}>
            Add child permit
          </Button>
          <Button
            type="primary"
            ghost
            size="large"
            right
            style={{ marginLeft: "1%" }}
            disabled={disableChildPermitSaveButton}
            onClick={(e) => {
              e.preventDefault();
              onSaveAddChildPermit();
            }}
          >
            Save
          </Button>
        </div>

        <Modal
          title="Child permit"
          visible={visibleChildPermit}
          onCancel={() => setVisibleChildPermit(false)}
          onOk={onOkChildPermit}
        >
          <div className="form-col">
            <Alert
              style={{ marginBottom: "5%" }}
              message="Looking for a specific child permit template?"
              description="Please create a separate child permit in My Child Permits Page after submitting this General Permit."
              type="info"
              showIcon
            />

            <div className="form-group">
              <p>Select From Available Child Permit Below: </p>
            </div>

            <div className="form-group col-md-4">
              <Select
                mode="multiple"
                style={{ width: 400 }}
                onChange={onChangeChildPermit}
                placeholder="Select template"
              >
                {SpecialChildTemplates?.GetSpecialTemplates?.results?.map((tmp) =>
                  tmp?._id === template?._id ? null : (
                    <Select.Option key={tmp._id} value={tmp._id} template={tmp}>
                      {tmp.title}
                    </Select.Option>
                  )
                )}
              </Select>
            </div>
          </div>
        </Modal>

        <div className="account-details-btn ">
          <Tooltip title="Saving Draft Permit Requires to have Discipline and Host / System Owner Selected.">
            <div hidden={hideApproveButton}>
              <CustomButton
                type="submit"
                loading={updateDraftLoading}
                className="btn btn-primary"
                style={{ maxWidth: 300 }}
                onClick={handleDraftUpdate}
                // disabled={!discipline || !hostSystemOwner}
              >
                Save Draft Permit
              </CustomButton>
            </div>
          </Tooltip>
          {/* <CustomButton
            onClick={onSubmit}
            type="submit"
            loading={createPermitLoading}
            className="btn ml-3"
            style={{ color: "white", background: "green", marginLeft: "1%" }}
          >
            Submit Permit
          </CustomButton> */}
        </div>
      </form>
    </Spin>
  );
};

const ChildPermit = ({ templates, onChange, state, onHandleSubmission }) => {
  const onChangeHandler = (values, children) => {
    // console.log("ChildPermit values: " + JSON.stringify(values));
    // console.log("ChildPermit children: " + JSON.stringify(children, null, 2));
    const child = state?.children?.find((d) => d.form === children?._id);
    if (child) {
      Object.assign(child.submission, values);
    } else {
      if (state?.children?.length > 0) {
        state.children.push({
          form: children?._id,
          submission: values,
        });
      } else {
        state.children = [
          {
            form: children?._id,
            submission: values,
          },
        ];
      }
    }

    // console.log("after if-else block, onChangeHandler" + JSON.stringify(state.children));
    onHandleSubmission(state.children);
  };

  return (
    <Fragment>
      <div style={{ display: "flex", justifyContent: "center", marginBottom: "2%" }}>
        <br />
        <h2>New Child permit(s)</h2>
      </div>

      {templates?.map(({ template: children }, key) => (
        <React.Fragment>
          <Divider />
          <div>
            <h3>{children?.title}</h3>
          </div>
          {children?.sections?.map((section, _key) => (
            <PermitSection
              templateKey={_key}
              section={section}
              onChange={(values) => onChangeHandler(values, children)}
              state={state}
              childrenId={children?._id}
              isParent={false}
            />
          ))}
          <br />
        </React.Fragment>
      ))}
    </Fragment>
  );
};
