import React from "react";
import { Table } from "antd";

export const DynamicList = ({
  title = "",
  actions,
  // pagination,
  data = [],
  columns = [],
  loading,
  showSearch = false,
  onChangeSearch,
  onChange,
  total,
  pageSize,
  pageNo
 

}) => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="heading">
            <div className="create-user-btn d-flex">{actions}</div>
          </div>
          <div className="card">
            <div className="list-user-part">
              <div className="card-title">
                <h1>{title}</h1>
                {showSearch && (
                  <div className="search">
                    <form>
                      <input
                        type="text"
                        placeholder="Search"
                        name=""
                        className="form-control"
                        onChange={(e) => onChangeSearch(e.target.value)}
                        style={{ width: "350px" }}
                      />
                      <button type="button">
                        <i className="far fa-search"></i>
                      </button>
                    </form>
                  </div>
                )}
              </div>
              <div className="table-responsive">
                <Table
                  rowKey={(row) => row?._id}
                  dataSource={data}
                  columns={columns}
                  pagination={{
                    total: total,
                    defaultPageSize: pageSize,
                    pageSizeOptions: [ 20, 50, 100],
                    showSizeChanger: true,
                    showQuickJumper: true,
                    onChange: onChange,
                    current: pageNo
                  }}
                  loading={loading}
                />
              </div>
            </div>
          </div>
          {/* {pagination} */}
        </div>
      </div>
    </div>
  );
};
