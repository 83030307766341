import gql from "graphql-tag";

export const GET_PDFLOGFILE = gql`
  query ($pdfFileName: String) {
    GetPdfLogs(pdfFileName: $pdfFileName) {
      code
      success
      message
      results
      count
    }
  }
`;
