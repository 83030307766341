import gql from "graphql-tag";
import { TEMPLATE_FORM_FRAGMENT } from "../fragments";

export const GET_SINGLE_TEMPLATE = gql`
  query ($id: ID!) {
    GetTemplate(id: $id) {
      code
      success
      message
      template {
        _id
        title
        type
        sections {
          title
          form {
            ...FormType
          }
        }
      }
    }
  }
  ${TEMPLATE_FORM_FRAGMENT}
`;

export const GET_TEMPLATE_LIST = gql`
  query ($limit: Int, $offset: Int, $showInCreatePermit: Boolean) {
    GetTemplates(limit: $limit, offset: $offset, showInCreatePermit: $showInCreatePermit) {
      code
      success
      message
      count
      results {
        _id
        title
        asParentPermit
        sections {
          title
          form {
            label
            name
            placeholder
            type
            required
            col
            options {
              value
              label
            }
            tooltip
            tooltipText
          }
        }
      }
    }
  }
`;

export const GET_SPECIAL_TEMPLATE_LIST = gql`
  query ($limit: Int, $offset: Int, $showInCreatePermit: Boolean) {
    GetSpecialTemplates(limit: $limit, offset: $offset, showInCreatePermit: $showInCreatePermit) {
      code
      success
      message
      count
      results {
        _id
        title
        sections {
          title
          form {
            label
            name
            placeholder
            type
            required
            col
            options {
              value
              label
            }
          }
        }
      }
    }
  }
`;

export const CREATE_TEMPLATE = gql`
  mutation ($data: TemplateInput) {
    CreateTemplate(data: $data) {
      code
      success
      message
    }
  }
`;

export const UPDATE_TEMPLATE = gql`
  mutation ($id: ID!, $data: TemplateInput) {
    UpdateTemplate(id: $id, data: $data) {
      code
      success
      message
    }
  }
`;

export const DELETE_TEMPLATE = gql`
  mutation ($id: ID!) {
    DeleteTemplate(id: $id) {
      code
      success
      message
    }
  }
`;
