import React from "react";
import { PermitFormItem } from "./PermitFormItem";

export const PermitSection = ({
  permitStatus,
  templateKey,
  section,
  state,
  onChange,
  formState,
  isParent,
  siteID,
  handleCheckForUploadFile,
  formRef,
}) => {
  return (
    <div className="card full-details">
      <h2>{section.title}</h2>
      <div className="form-row">
        {section?.form?.map((item, key) => (
          <PermitFormItem
            permitStatus={permitStatus}
            templateKey={templateKey}
            item={item}
            onChange={onChange}
            formState={formState}
            state={state}
            isParent={isParent}
            siteID={siteID}
            handleCheckForUploadFile={handleCheckForUploadFile}
            formRef={formRef}
          />
        ))}
      </div>
    </div>
  );
};
