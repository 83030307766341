import { useQuery } from "@apollo/react-hooks";
import { Spin } from "antd";
import React from "react";
import { GET_SINGLE_TEMPLATE } from "../graphql/modules";
import { CreateTemplate } from "./CreateTemplate";

export const EditTemplate = ({ match: { params } }) => {
  const { data, loading } = useQuery(GET_SINGLE_TEMPLATE, {
    variables: {
      id: params.id,
    },
  });

  const editData = data?.GetTemplate?.template;

  return (
    <Spin spinning={loading}>
      <h4>Edit Template</h4>
      <CreateTemplate editData={editData} />
    </Spin>
  );
};
