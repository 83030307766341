import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { UPDATE_PROFILE } from "../graphql/modules";
import { successNotify, warnNotify } from "../util";
import { Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { updateProfileAction } from "../store/modules";
import { UserForm } from "../components/User";

export const UpdateProfile = ({ match }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  // const { data: SiteList } = useQuery(GET_SITE_LIST, {
  //   variables: {
  //     offset: 0,
  //     limit: 100,
  //   },
  // });

  const [state, setState] = useState({
    email: user?.email,
    fullname: user?.fullname,
    company: user?.company,
    phone: user?.phone,
  });

  const [UpdateProfileMutation, { loading }] = useMutation(UPDATE_PROFILE, {
    variables: {
      data: {
        ...state,
        site: state?.site?._id,
      },
    },
  });

  const onUpdate = async (e) => {
    e.preventDefault();
    try {
      const {
        data: { UpdateProfile },
      } = await UpdateProfileMutation();

      console.log(UpdateProfile);
      if (UpdateProfile.success) {
        successNotify(UpdateProfile.message);
        dispatch(updateProfileAction(state));
      } else {
        warnNotify(UpdateProfile.message);
      }
    } catch (error) {
      console.log(error.message);
      warnNotify(error.message);
    }
  };

  // const onChange = (e) => setState({ ...state, [e.target.name]: e.target.value });

  // const onChangeSite = (e, option) => {
  //   setState({ ...state, site: option.site });
  // };

  const handleChange = (value) => setState({ ...state, ...value });

  const disable = !state.email || !state.fullname;

  return (
    <Spin spinning={false}>
      <UserForm
        title="Update Profile"
        btnText="Update"
        onSubmit={onUpdate}
        loading={loading}
        handleChange={handleChange}
        state={state}
        userId={user._id}
      />
    </Spin>
  );
};

// const test = (
//   <div>
//     <div className="heading"></div>
//     <h1 className="title">Update Profile</h1>
//     <form onSubmit={onUpdate}>
//       <div className="card">
//         <div className="create-permit-details">
//           <div className="form-row">
//             <div className="form-group col-md-4">
//               <input
//                 type="name"
//                 className="form-control"
//                 placeholder="Fullname"
//                 name="fullname"
//                 value={state.fullname}
//                 onChange={onChange}
//               />
//             </div>
//             <div className="form-group col-md-4">
//               <input
//                 type="email"
//                 className="form-control"
//                 placeholder="Email"
//                 name="email"
//                 value={state.email}
//                 onChange={onChange}
//               />
//             </div>
//             <div className="form-group col-md-4">
//               <Select
//                 id="inputState"
//                 size="large"
//                 style={{ width: "100%" }}
//                 className="form-control-select"
//                 name="site"
//                 value={state?.site?._id}
//                 onChange={onChangeSite}
//                 placeholder="Select site"
//               >
//                 {SiteList?.GetSites?.results?.map((site) => (
//                   <Select.Option key={site._id} value={site._id} site={site}>
//                     {site.name}
//                   </Select.Option>
//                 ))}
//               </Select>
//             </div>
//           </div>
//           <div className="form-row">
//             <div className="form-group col-md-4">
//               <input type="name" className="form-control" placeholder="user role" value={user.role} disabled={true} />
//             </div>
//             <div className="form-group col-md-4">
//               <input
//                 type="name"
//                 className="form-control"
//                 placeholder="Company Name"
//                 name="company"
//                 value={state.company}
//                 onChange={onChange}
//               />
//             </div>
//             <div className="form-group col-md-4">
//               <input
//                 type="name"
//                 className="form-control"
//                 placeholder="Phone Number"
//                 name="phone"
//                 value={state.phone}
//                 onChange={onChange}
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="account-details-btn">
//         <CustomButton type="submit" loading={loading} disabled={disable} className="btn btn-primary">
//           Update
//         </CustomButton>
//       </div>
//     </form>
//   </div>
// );
