import gql from "graphql-tag";
// import { SITE_FRAGMENT } from "../fragments";

export const GET_GROUP_LIST = gql`
  query ($limit: Int, $offset: Int, $searchText: String, $sites: [ID]) {
    GetUserGroups(limit: $limit, offset: $offset, searchText: $searchText, sites: $sites) {
      code
      success
      message
      count
      results {
        _id
        name
        createdBy {
          _id
          fullname
          email
        }
        createdAt
        site {
          _id
          name
          code
          address
        }
        members {
          _id
          fullname
          email
        }
        permitCloser
      }
    }
  }
`;

export const GET_ALL_MTGROUPS = gql`
  query ($limit: Int, $offset: Int) {
    GetAllMTGroups(limit: $limit, offset: $offset) {
      code
      success
      message
      count
      results {
        _id
        name
        createdBy {
          _id
          fullname
          email
        }
        createdAt
        site {
          _id
          name
          code
          address
        }
        members {
          _id
          fullname
          email
        }
        permitCloser
      }
    }
  }
`;

export const CREATE_GROUP = gql`
  mutation CreateGroup($data: GroupInput!) {
    CreateGroup(data: $data) {
      code
      success
      message
    }
  }
`;

export const ADD_USERS_TO_MTGROUP = gql`
  mutation AddUsersToMTGroup($id: ID, $data: GroupInput!) {
    AddUsersToMTGroup(id: $id, data: $data) {
      code
      success
      message
    }
  }
`;

export const REMOVE_USERS_TO_MTGROUP = gql`
  mutation AddUsersToMTGroup($id: ID, $userid: ID!, $data: GroupInput!) {
    RemoveUsersToMTGroup(id: $id, userid: $userid, data: $data) {
      code
      success
      message
    }
  }
`;

export const GET_MTGROUP_BY_ID = gql`
  query ($id: ID) {
    GetMTGroupById(id: $id) {
      code
      success
      results {
        _id
        name
        permitCloser
        site {
          _id
          name
        }
        members {
          _id
          fullname
          email
        }
      }
    }
  }
`;

export const UPDATE_MTGROUP = gql`
  mutation UpdateGroup($id: ID!, $data: GroupInput!) {
    UpdateGroup(id: $id, data: $data) {
      code
      success
      message
    }
  }
`;
