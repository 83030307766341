import { notification } from "antd";
import _ from "lodash";

const endpoint = process.env.REACT_APP_IMAGE_URL;
const noImage = "/img/noimage.png";
export const getImage = (image) => {
  if (!image) return noImage;
  return endpoint + image;
};

export const showNotification = (data) => {
  if (data.success) {
    notification.success({
      message: data.message,
      placement: "bottomLeft",
    });
  } else {
    notification.error({
      message: data.message,
      placement: "bottomLeft",
    });
  }
};

export const Reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// returns full name
export const getNextReviewer = (permit) => {
  const selectedUsers = permit?.selectedUser?.length ? permit?.selectedUser : null;
  const selectedMTGroups = permit?.selectedMTGroup?.length ? permit?.selectedMTGroup : null;
  const approvedReviewers = permit?.approvedBy?.length ? permit?.approvedBy : null;
  const selectedMTGroupUsers = permit?.selectedMTGroupUsers?.length ? permit?.selectedMTGroupUsers : null;

  let nextReviewer = "";

  try {
      if (approvedReviewers !== null) {
        //remove those who approved already
        let getReviewersWhoDidNotApprovedYet = selectedUsers?.filter((x) => {
          return !approvedReviewers?.find((usr) => usr?._id === x?._id);
        });

        // check if there is mtgroup members (mtgroup selected)
        if (selectedMTGroups === null) {
          nextReviewer = getReviewersWhoDidNotApprovedYet[0]?.fullname;
        } else {
          // get original selected users (individual reviewers)
          let ogSelectedUsers = getReviewersWhoDidNotApprovedYet?.filter((x) => {
            return !selectedMTGroupUsers?.find((usr) => usr?._id === x?._id);
          });
          if (ogSelectedUsers?.length !== 0) {
            nextReviewer = ogSelectedUsers[0]?.fullname;
          } else {
            for (let i = 0; i < selectedMTGroups.length; i++) {
              const hasMemberApproved = approvedReviewers?.map((pApproved) => {
                return selectedMTGroups[i]?.members?.some((mmbr) => mmbr?._id == pApproved?._id);
              });

              if (hasMemberApproved.includes(true)) {
                // console.log("a member of mtgroup " + selectedMTGroups[i]?.name + " already approved..");
              } else {
                nextReviewer = selectedMTGroups[i]?.name;
                break;
              }
            }
          }
        }
      } else {
        // check if there is mtgroup members (mtgroup selected)
        if (selectedMTGroupUsers === null) {
          // console.log("[102] selectedMTGroupUsers === null");
          nextReviewer = selectedUsers && selectedUsers[0]?.fullname;
        } else {
          // get original selected users (individual reviewers)
          let ogSelectedUsers = selectedUsers.filter((x) => {
            return !selectedMTGroupUsers.find((usr) => usr._id === x._id);
          });

          if (
            ogSelectedUsers === [] ||
            ogSelectedUsers === null ||
            ogSelectedUsers === undefined ||
            ogSelectedUsers?.length === 0
          ) {
            nextReviewer = " ";
          } else {
            nextReviewer = ogSelectedUsers[0]?.fullname;
          }
        }
      }
      return nextReviewer;
    } catch (_error) {
      console.log(_error);
      nextReviewer = { fullname: " " };
      return nextReviewer;
    }

};

/**
 *
 * @param {*} permit
 * @returns
 * {
 *  isIndividualReviewer: Boolean
 *  reviewerId: id
 *  reviewerName: String
 *  members: [String]
 * }
 */
export const getNextApprover = (permit) => {
  const selectedUsers = permit?.selectedUser?.length ? permit?.selectedUser : null;
  const selectedMTGroups = permit?.selectedMTGroup?.length ? permit?.selectedMTGroup : null;
  const approvedReviewers = permit?.approvedBy?.length ? permit?.approvedBy : null;
  const selectedMTGroupUsers = permit?.selectedMTGroupUsers?.length ? permit?.selectedMTGroupUsers : null;

  let nextReviewer = {};

  try {
    if (approvedReviewers !== null) {
      //remove those who approved already
      let getReviewersWhoDidNotApprovedYet = selectedUsers?.filter((x) => {
        return !approvedReviewers?.find((usr) => usr?._id === x?._id);
      });

      // check if there is mtgroup members (mtgroup selected)
      if (selectedMTGroups === null) {
        nextReviewer = {
          isIndividualReviewer: true,
          reviewerId: getReviewersWhoDidNotApprovedYet[0]?._id,
          reviewerName: getReviewersWhoDidNotApprovedYet[0]?.fullname,
          members: [],
        };
      } else {
        // get original selected users (individual reviewers)
        let ogSelectedUsers = getReviewersWhoDidNotApprovedYet?.filter((x) => {
          return !selectedMTGroupUsers?.find((usr) => usr?._id === x?._id);
        });
        if (ogSelectedUsers?.length !== 0) {
          // nextReviewer = ogSelectedUsers[0]?.fullname;
          nextReviewer = {
            isIndividualReviewer: true,
            reviewerId: ogSelectedUsers[0]?._id,
            reviewerName: ogSelectedUsers[0]?.fullname,
            members: [],
          };
        } else {
          for (let i = 0; i < selectedMTGroups.length; i++) {
            const hasMemberApproved = approvedReviewers?.map((pApproved) => {
              return selectedMTGroups[i]?.members?.some((mmbr) => mmbr?._id == pApproved?._id);
            });

            if (hasMemberApproved.includes(true)) {
              // console.log("a member of mtgroup " + selectedMTGroups[i]?.name + " already approved..");
            } else {
              // nextReviewer = selectedMTGroups[i]?.name;
              nextReviewer = {
                isIndividualReviewer: false,
                reviewerId: selectedMTGroups[i]?._id,
                reviewerName: selectedMTGroups[i]?.name,
                members: selectedMTGroups[i]?.members,
              };
              break;
            }
          }
        }
      }
    } else {
      // check if there is mtgroup members (mtgroup selected)
      if (selectedMTGroupUsers === null) {
        // console.log("[102] selectedMTGroupUsers === null");
        // nextReviewer = selectedUsers && selectedUsers[0]?.fullname;
        nextReviewer = {
          isIndividualReviewer: true,
          reviewerId: selectedUsers[0]?._id,
          reviewerName: selectedUsers[0]?.fullname,
          members: [],
        };
      } else {
        // get original selected users (individual reviewers)
        let ogSelectedUsers = selectedUsers.filter((x) => {
          return !selectedMTGroupUsers.find((usr) => usr._id === x._id);
        });

        if (
          ogSelectedUsers === [] ||
          ogSelectedUsers === null ||
          ogSelectedUsers === undefined ||
          ogSelectedUsers?.length === 0
        ) {
          nextReviewer = {
            isIndividualReviewer: false,
            reviewerId: null,
            reviewerName: " ",
            members: [],
          };
        } else {
          // nextReviewer = ogSelectedUsers[0]?.fullname;
          nextReviewer = {
            isIndividualReviewer: true,
            reviewerId: ogSelectedUsers[0]?._id,
            reviewerName: ogSelectedUsers[0]?.fullname,
            members: [],
          };
        }
      }
    }
    return nextReviewer;
  } catch (_error) {
    console.log(_error);
    // nextReviewer = { fullname: " " };
    nextReviewer = {
      isIndividualReviewer: false,
      reviewerId: null,
      reviewerName: " ",
      members: [],
    };
    return nextReviewer;
  }
};
