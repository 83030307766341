import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { Link, useHistory } from "react-router-dom";
import { EllipsisOutlined, SearchOutlined } from "@ant-design/icons";
import { UserStatus } from "../config";
import { GET_USER_LIST, DELETE_USER, UPDATE_USER } from "../graphql/modules";
import { Pagination, Tag, Popconfirm, Dropdown, Menu, Input, Button, Space } from "antd";
import Highlighter from "react-highlight-words";

import { DynamicList } from "../components/Shared";
import { successNotify, warnNotify, TABLE_LIST_LIMIT } from "../util";

export const AllUsers = ({ match }) => {
  const history = useHistory();
  const [limit, setLimit] = useState(TABLE_LIST_LIMIT);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [value, setValue] = useState("");
  const { data, loading, error, refetch } = useQuery(GET_USER_LIST, {
    variables: {
      searchText: value,
      limit,
      offset: page - 1,
    },
    fetchPolicy: "cache-and-network",
  });

  const onChangePagination = (p, pageSize) => {
    setPage(p);
    setLimit(pageSize);
  };


  const [DeleteUserMutation] = useMutation(DELETE_USER);

  const handleDelete = async (key) => {
    try {
      const {
        data: { DeleteUser },
      } = await DeleteUserMutation({
        variables: {
          id: key,
        },
      });

      if (DeleteUser.success) {
        successNotify(DeleteUser.message);
        refetch();
      } else {
        warnNotify(DeleteUser.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [UpdateUserMutation, { loading: updateLoading }] = useMutation(UPDATE_USER);

  const onApproveUser = async (key) => {
    try {
      const {
        data: { UpdateUser },
      } = await UpdateUserMutation({
        variables: {
          id: key,
          data: { status: UserStatus.Approved },
        },
      });

      if (UpdateUser.success) {
        successNotify(UpdateUser.message);
        refetch();
      } else {
        warnNotify(UpdateUser.message);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const total = data?.GetUsers?.count;
  const results = data?.GetUsers?.results;

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            setSearchInput(node);
            // this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={searchText}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  function handleSearchUser(datavalue) {
    setValue(datavalue);
    setPage(1);
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns = [
    {
      title: "#",
      render: (_text, _row, index) => limit * (page - 1) + index + 1,
    },
    {
      title: "User's Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
      sortDirections: ["ascend"],
    },
    {
      title: "Sites",
      dataIndex: "sites",
      key: "sites",
      render: (sites, row) => {
        return sites?.map((site) => (
          <Tag style={{ borderRadius: 10, padding: "0px 10px" }} color="purple">
            {site?.code}
          </Tag>
        ));
      },
    },
    {
      title: "Role",
      // dataIndex: "role",
      // key: "role",
      render: (_v, row) => (
        <div>
          <span className="d-block">{row?.role}</span>
          {row?.group && <span className="badge-success badge badge-pill"> {row?.group?.name}</span>}
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color = "orange";
        if (status === UserStatus.Rejected) color = "red";
        else if (status === UserStatus.Approved) color = "green";

        return (
          <Tag style={{ borderRadius: 10, padding: "0px 10px" }} color={color}>
            {status}
          </Tag>
        );
      },
    },
    {
      title: "Action",
      width: "15%",
      render: (text, record) => {
        const menu = (
          <Menu>
            <Menu.Item key="0">
              <Link
                style={{ marginRight: "15px" }}
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`${match.path}/${record._id}`, {
                    user: record,
                  });
                }}
              >
                View
              </Link>
            </Menu.Item>
            <Menu.Item key="1">
              <Link style={{ marginRight: "15px" }} to={`${match.path}/edit-user/${record._id}`}>
                Edit
              </Link>
            </Menu.Item>
            <Menu.Item key="1">
              <span type="link" onClick={() => onApproveUser(record._id)}>
                Approve
              </span>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="3">
              <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record._id)}>
                Delete
              </Popconfirm>
            </Menu.Item>
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <EllipsisOutlined />
          </Dropdown>
        );
      },
    },
  ];

  if (error) {
    return <div className="card">Something went wrong</div>;
  }

  return (
    <DynamicList
      title="List of users"
      loading={loading}
      columns={columns}
      data={results}
      actions={
        <Link to={match.path + "/create-user"} className="btn btn-primary mr-2">
          Create user <i className="fas fa-plus-circle"></i>
        </Link>
      }
      total={total}
      pageSize={limit}
      onChange={onChangePagination}
      pageNo={page}
      showSearch={true}
      onChangeSearch={handleSearchUser}
    />
  );
};
