import gql from "graphql-tag";

export const CREATE_DRAFT_PERMIT = gql`
  mutation ($data: PermitInput!) {
    CreateDraftPermit(data: $data) {
      code
      success
      message
    }
  }
`;

export const GET_DRAFT_PERMIT_BY_ID = gql`
  query ($id: ID!) {
    GetDraftPermitById(id: $id) {
      code
      success
      message
      permit
    }
  }
`;

export const GET_MY_DRAFT_PERMITS = gql`
  query ($limit: Int, $offset: Int, $searchText: String) {
    GetMyDraftPermits(limit: $limit, offset: $offset, searchText: $searchText) {
      code
      success
      message
      results
      count
    }
  }
`;

export const UPDATE_DRAFT_PERMIT = gql`
  mutation ($id: ID!, $submission: String, $childrenState: String, $discipline: ID!, $hostSystemOwner: ID!) {
    UpdateDraftPermit(
      id: $id
      submission: $submission
      childrenState: $childrenState
      discipline: $discipline
      hostSystemOwner: $hostSystemOwner
    ) {
      code
      success
      message
    }
  }
`;

export const UPDATE_DRAFT_PERMIT_REVIEWERS = gql`
  mutation ($id: ID!, $newIndividualReviewers: [String], $newMTGroups: [String]) {
    UpdateDraftPermitReviewers(id: $id, newIndividualReviewers: $newIndividualReviewers, newMTGroups: $newMTGroups) {
      code
      success
      message
    }
  }
`;

export const DELETE_DRAFT_PERMIT = gql`
  mutation ($id: ID!) {
    DeleteDraftPermit(id: $id) {
      code
      success
      message
    }
  }
`;

export const SAVE_DRAFT_CHILD_PERMIT = gql`
  mutation ($id: ID!, $childrenState: String) {
    SaveDraftChildPermit(id: $id, childrenState: $childrenState) {
      code
      success
      message
    }
  }
`;
