import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { UPDATE_MTGROUP, GET_MTGROUP_BY_ID } from "../graphql/modules";
import { successNotify, warnNotify } from "../util";
import { GroupForm } from "../components/Group";

export const EditGroup = ({ match }) => {
  let id = match.params.id;

  const { data, loading } = useQuery(GET_MTGROUP_BY_ID, {
    variables: {
      id: id,
    },
  });


  //initial state
  const [state, setState] = useState({
    name: "",
    site: "",
    permitCloser: "",
  });

  useEffect(() => {
    const { name = "", site = "", permitCloser = ""} = data?.GetMTGroupById?.results || {};
    setState({
      name: name || "",
      site: site._id,
      permitCloser: permitCloser
    });
  }, [data]);

  // UPDATE MTGROUP
  const [UpdateMTGroup, { loading: load }] = useMutation(UPDATE_MTGROUP, {
    variables: {
      id,
      data: state,
    },
  });

  // SUBMIT HANDLER
  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("state: " + JSON.stringify(state, null, 2));

    if (state.permitCloser === "true") {
      state.permitCloser = true;
    } else if (state.permitCloser === "") {
      state.permitCloser = false;
    } else {
      state.permitCloser = false;
    }

    try {
      const { data: { UpdateGroup }} = await UpdateMTGroup();

      if (UpdateGroup.success) {
        successNotify(UpdateGroup.message);
      } else {
        warnNotify(UpdateGroup.message);
      }
    } catch (error) {
      warnNotify(error.message);
      console.log(error.message);
    }
  };

  const handleChange = (value) => setState({ ...state, ...value });
//  console.log(state)
  return (
    <GroupForm
      title="Edit MtGroup"
      btnText="Update Group"
      onSubmit={onSubmit}
      loading={loading}
      handleChange={handleChange}
      state={state}
    />
  );
};
