import { Checkbox, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { Fragment } from "react";

export const InputModal = ({ visible, onCancel, onOk, initialState }) => {
  const [state, setState] = useState(initialState);
  const [enableOption, setEnableOption] = useState(false);
  const onChange = (e) => setState({ ...state, [e.target.name]: e.target.value });

  const handleOk = () => {
    onOk(state);
    setState(initialState);
  };

  const handleCancel = () => {
    setState(initialState);
    onCancel();
  };

  useEffect(() => {
    if (state.type === "select" || state.type === "radio" || state.type === "multicheck") {
      setEnableOption(true);
      if (state.options?.length === 0 || !state.options) {
        state.options = [];
      }
    } else {
      setEnableOption(false);
      delete state.options;
    }

    if (state.type === "freetext") {
      setState({ ...state, name: "freeText" });
    }
  }, [state.type]);

  const handleOptionAdd = () => {
    state.options.push({
      label: "",
      value: "",
    });
    setState({
      ...state,
      options: state.options,
    });
  };

  const onDeleteOption = (index) => {
    state.options.splice(index, 1);
    setState({
      ...state,
      options: state.options,
    });
  };
  // console.log(state);
  let disabled = !state.label || !state.name || !state.type || state.name.includes(" ");
  if (state.type === "freetext" && state.label) {
    disabled = false;
  }
  return (
    <Modal
      visible={visible}
      title="Section"
      onCancel={handleCancel}
      onOk={handleOk}
      okButtonProps={{ disabled: disabled }}
    >
      <div className="form-row">
        <div className="form-group col-md-12">
          <select name="type" className="form-control" value={state.type} onChange={onChange}>
            <option value="">Select Input Type</option>
            <option value="text">Text</option>
            <option value="textarea">Textarea</option>
            <option value="radio">Radio</option>
            <option value="checkbox">Checkbox</option>
            <option value="multicheck">Multiple Checkbox</option>
            <option value="date">Date</option>
            <option value="time">Time</option>
            <option value="daterange">Date Range</option>
            <option value="select">Select</option>
            <option value="file">File</option>
            <option value="esign">eSignature</option>
            <option value="freetext">Free Text</option>
            <option value="map">Map</option>
            <option value="reviewerlist">Reviewer List</option>
          </select>
        </div>

        {state.type === "freetext" ? (
          <div className="form-group col-md-12">
            <textarea
              type="name"
              className="form-control"
              placeholder="Enter text"
              name="label"
              value={state.label}
              onChange={onChange}
              rows={3}
            ></textarea>
          </div>
        ) : (
          <Fragment>
            <div className="form-group col-md-12">
              <input
                type="name"
                className="form-control"
                placeholder="Label"
                name="label"
                value={state.label}
                onChange={onChange}
              />
            </div>
            <div className="form-group col-md-12">
              <input
                type="name"
                className="form-control"
                placeholder="Name"
                name="name"
                value={state.name}
                onChange={onChange}
              />

              {state.name.includes(" ") && <small className="text-danger">Space not allowed</small>}
            </div>
            <div className="form-group col-md-12">
              <input
                type="name"
                className="form-control"
                placeholder="Placeholder"
                name="placeholder"
                value={state.placeholder}
                onChange={onChange}
              />
            </div>
            <div className="form-group col-md-12">
              <input
                type="name"
                className="form-control"
                placeholder="Default Value"
                name="defaultValue"
                value={state.defaultValue || ""}
                onChange={onChange}
              />
            </div>
            <div className="form-group col-md-12">
              <select name="col" className="form-control" value={state.col} onChange={onChange}>
                <option value="">Select Column</option>
                <option value="2">2</option>
                <option value="4">4</option>
                <option value="6">6</option>
                <option value="8">8</option>
                <option value="10">10</option>
                <option value="12">12</option>
              </select>
            </div>

            {enableOption && (
              <div className="card p-3">
                {state?.options?.map((opt, key) => (
                  <FormOption
                    option={opt}
                    key={key}
                    onDelete={() => onDeleteOption(key)}
                    onStateChange={(values) => {
                      Object.assign(opt, values);
                      setState({ ...state });
                    }}
                  />
                ))}
                <div
                  className="w-100 text-center cursor-pointer "
                  style={{ fontSize: 12, border: "1px dashed #ddd", padding: 5 }}
                  onClick={handleOptionAdd}
                >
                  <i className="fas fa-plus-circle mr-2"></i>
                  Add Option
                </div>
              </div>
            )}
            
            <div className="form-group  col-md-12">
              <Checkbox
                id="tooltip"
                onChange={(e) => setState({ ...state, tooltip: e.target.checked })}
                checked={state.tooltip}
              />
              <label htmlFor="tooltip" className="cursor-pointer ml-2">
                Tooltip
              </label>

              <div className="form-group col-md-12">
                <input
                  type="name"
                  className="form-control"
                  placeholder="Tooltip Text"
                  name="tooltipText"
                  value={state.tooltipText}
                  onChange={onChange}
                  disabled={!state.tooltip}
                />
              </div>
            </div>

            <div className="form-group">
              <Checkbox
                id="required"
                onChange={(e) => setState({ ...state, required: e.target.checked })}
                checked={state.required}
              />
              <label htmlFor="required" className="cursor-pointer ml-2">
                Required
              </label>
            </div>
          </Fragment>
        )}
      </div>
    </Modal>
  );
};

const FormOption = ({ option, onDelete, onStateChange }) => {
  const [state, setState] = useState(option);

  const onChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    onStateChange(state);
  }, [state]);

  return (
    <div className="form-row" id="TemplateOption">
      <div className="input-action">
        <i className="text-center fas fa-times cursor-pointer px-2" onClick={onDelete}></i>
      </div>
      <div className="form-group col-md-6">
        <input
          type="name"
          className="form-control"
          placeholder="Label"
          name="label"
          value={state.label}
          onChange={onChange}
        />
      </div>
      <div className="form-group col-md-6">
        <input
          type="name"
          className="form-control"
          placeholder="Value"
          name="value"
          value={state.value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
