import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Link, useHistory } from "react-router-dom";
import { EllipsisOutlined, SearchOutlined } from "@ant-design/icons";
import { PermitStatus, UserRole, StatusColor } from "../config";
import { GET_PENDING_PERMITS, GET_SITE_LIST } from "../graphql/modules";
import { Pagination, Tag, Dropdown, Menu, Input, Button, Space, Checkbox, Row, Col } from "antd";
import { DynamicList } from "../components/Shared";
import { formatDate, TABLE_LIST_LIMIT } from "../util";
import { useSelector } from "react-redux";
import Highlighter from "react-highlight-words";

export const AllPendingClosureCancelPermits = ({ match }) => {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const [limit, setLimit] = useState(TABLE_LIST_LIMIT);
  const [page, setPage] = useState(1);
  const [value, setValue] = useState("");
  const [statusCheck, setStatusCheck] = useState([]);
  const [siteName, setSiteName] = useState([]);

  /** START For Applier Name Filtering */

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  /** END For Applier Name Filtering */

  /** START For Start Date Filtering */
  const [searchStartDate, setSearchStartDate] = useState("");
  const [searchedStartDateColumn, setSearchedStartDateColumn] = useState("");
  /** END For Start Date Filtering */

  /** START For End Date Filtering */
  const [searchEndDate, setSearchDateEnd] = useState("");
  const [searchedEndDateColumn, setSearchedDateEndColumn] = useState("");
  /** END For End Date Filtering */

  /** START For End Date Filtering */
  const [searchCode, setSearchCode] = useState("");
  const [searchedCodeColumn, setSearchedCodeColumn] = useState("");
  /** END For End Date Filtering */

  /** START For End Date Filtering */
  const [searchPermitNumber, setSearchPermitNumber] = useState("");
  const [searchedPermitNumberColumn, setSearchedPermitNumberColumn] = useState("");
  /** END For End Date Filtering */

  /** START For Date Applied (createdAt) Filtering */
  const [searchDateApplied, setSearchDateApplied] = useState("");
  const [searchedDateAppliedColumn, setSearchedDateAppliedColumn] = useState("");
  /** END For Date Applied (createdAt) Filtering */

  const { data, loading, error, refetch } = useQuery(GET_PENDING_PERMITS, {
    variables: {
      searchText: value,
      searchCode: searchCode,
      searchCreated: searchDateApplied,
      searchStartDate: searchStartDate,
      searchEndDate: searchEndDate,
      statusCheck: statusCheck,
      siteName: siteName,
      limit,
      offset: page - 1,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: SiteList } = useQuery(GET_SITE_LIST, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  const total = data?.GetPendingPermits?.count;
  const results = JSON.parse(data?.GetPendingPermits?.results || "[]");
  let siteList = [];

  if (SiteList?.GetSites?.results?.length) {
    SiteList?.GetSites?.results?.map((site) => {
      siteList.push({
        text: site.code,
        value: site.code,
      });
    });
  }

  /** START For Applier Name Filtering */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (fullname, row) =>
      row?.submittedBy ? row?.submittedBy?.fullname?.toString().toLowerCase().includes(fullname.toLowerCase()) : "",
    render: (_text, _row) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={_row?.submittedBy ? _row?.submittedBy?.fullname.toString() : ""}
        />
      ) : (
        _row?.submittedBy?.fullname
      ),
  });
  /** END For Applier Name Filtering */

  /** START For Permit Number Filtering */
  const handlePermitNumberSearch = (selectedKeys, confirm, dataIndex) => {
    setValue(selectedKeys[0]);
    confirm();
    setSearchPermitNumber(selectedKeys[0]);
    setSearchedPermitNumberColumn(dataIndex);
  };

  const handlePermitNumberReset = (clearFilters) => {
    setValue("");
    clearFilters();
    setSearchPermitNumber("");
  };

  const getColumnSearchPermitNumber = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handlePermitNumberSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handlePermitNumberSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handlePermitNumberReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (permitnumber, row) =>
      row?.code ? row?.code?.toString().toLowerCase().includes(permitnumber.toLowerCase()) : "",
    // onFilterDropdownVisibleChange: (visible) => {
    //   try {
    //     setTimeout(() => this.searchInput.select(), 100);
    //   } catch (err) {
    //     console.error(err);
    //   }
    // },
    render: (_text, _row) =>
      searchedPermitNumberColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchPermitNumber]}
          autoEscape
          textToHighlight={_row?.code ? _row?.code : ""}
        />
      ) : (
        _row?.code
      ),
  });
  /** END For Permit Number Filtering */

  const handleSiteNameReset = () => {
    setSiteName([]);
  };

  const handleSiteNameCheckbox = (e) => {
    setSiteName(e);
  };

  /** START For Date Applied (createdAt) Filtering */
  const handleSearchDateApplied = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchDateApplied(selectedKeys[0]);
    setSearchedDateAppliedColumn(dataIndex);
  };

  const handleResetDateApplied = (clearFilters) => {
    clearFilters();
    setSearchDateApplied("");
  };

  const getDateAppliedColumnSearch = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Jan 1, 2021`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchDateApplied(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchDateApplied(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleResetDateApplied(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    render: (_text, _row) => (_row?.createdAt ? formatDate(_row?.createdAt) : " "),
  });
  /** END For Date Applied (createdAt) Filtering */

  /** START For DateEnd Filtering */
  const handleSearchDateEnd = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchDateEnd(selectedKeys[0]);
    setSearchedDateEndColumn(dataIndex);
  };

  const handleResetDateEnd = (clearFilters) => {
    clearFilters();
    setSearchDateEnd("");
    setSearchStartDate("");
  };

  const getEndDateColumnSearch = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Jan 1, 2021`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchDateEnd(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchDateEnd(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleResetDateEnd(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    render: (_text, _row) => (_row?.submission?.dateEnd ? formatDate(_row?.submission?.dateEnd) : " "),
  });
  /** END For DateEnd Filtering */

  /** START For StartDate Filtering */
  const handleSearchStartDate = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchStartDate(selectedKeys[0]);
    setSearchedStartDateColumn(dataIndex);
  };
  const handleResetStartDate = (clearFilters) => {
    clearFilters();
    setSearchStartDate("");
  };
  const getStartDateColumnSearch = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Jan 1, 2021`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearchStartDate(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearchStartDate(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleResetStartDate(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    render: (_text, _row) => (_row?.submission?.startDate ? formatDate(_row?.submission?.startDate) : " "),
  });
  /** END For StartDate Filtering */

  const getApplierSite = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Checkbox.Group style={{ width: "100%" }} onChange={handleSiteNameCheckbox} value={siteName}>
          {siteList?.map((res) => {
            return (
              <Row>
                <Col span={24}>
                  <Checkbox value={res.value}>{res.text}</Checkbox>
                </Col>
              </Row>
            );
          })}
        </Checkbox.Group>
        <Space>
          <Button onClick={handleSiteNameReset} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
  });

  const columns = [
    {
      title: "#",
      // render: (_text, _row, index) => index + 1,
    },
    {
      title: "Permit Number",
      ...getColumnSearchPermitNumber("permitnumber"),
    },
    {
      title: "Applier name",
      /** START For Applier Name Filtering */
      render: (_text, _row) => _row?.submittedBy?.fullname,
      /** END For Applier Name Filtering */
    },
    {
      title: "Applier Site",
      ...getApplierSite("applierSite"),
      render: (_text, _row) => _row?.site?.code,
      width: "5%",
    },
    {
      title: "Date Applied",
      ...getDateAppliedColumnSearch("Date Applied"),
      width: "10%",
    },
    {
      title: "Start Date",
      ...getStartDateColumnSearch("startDate"),
      width: "10%",
    },
    {
      title: "End Date",
      ...getEndDateColumnSearch("dateEnd"),
      width: "10%",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, row) => {
        let color = "orange";
        if (status === PermitStatus.PendingCancel) color = StatusColor.PendingCancel;
        else if (status === PermitStatus.PendingClosure) color = StatusColor.PendingClosure;

        return (
          <div>
            <Tag style={{ borderRadius: 10, padding: "0px 10px" }} color={color}>
              {status}
            </Tag>
          </div>
        );
      },
    },
    {
      title: "Child permit",
      render: (_text, _row) => _row?.form?.title,
    },
    {
      title: "Action",
      width: "15%",
      render: (text, record) => {
        const menu = (
          <Menu hidden={record.code === undefined}>
            <Menu.Item key="0" disabled={record.code === undefined}>
              <Link
                style={{ marginRight: "15px" }}
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`permits/${record._id}`, {
                    user: record,
                  });
                }}
                hidden={record.code === undefined}
              >
                View
              </Link>
            </Menu.Item>
            {user.role === UserRole.MTGroup &&
              (record.status === PermitStatus.PendingCancel || record.status === PermitStatus.PendingClosure) && (
                <Menu.Item key="5">
                  <Link
                    style={{ marginRight: "15px" }}
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`permits/review/${record._id}`, {
                        user: record,
                      });
                    }}
                  >
                    Review
                  </Link>
                </Menu.Item>
              )}
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <EllipsisOutlined />
          </Dropdown>
        );
      },
    },
  ];

  if (error) {
    return <div className="card">Something went wrong</div>;
  }

  let createBtn = null;

  const onChangePagination = (p, pageSize) => {
    setPage(p);
    setLimit(pageSize);
  };

  return (
    <DynamicList
      title="List of Permit Pending Closure and Pending Cancellation"
      loading={loading}
      columns={columns}
      data={results}
      actions={createBtn}
      total={total}
      pageSize={limit}
      onChange={onChangePagination}
      pageNo={page}
      // showSearch={true}
      // onChangeSearch={onChangeSearch}
    />
  );
};
