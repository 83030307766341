// import {
//   UPDATE_PROFILE_INFO,
//   // UPDATE_CARD_INFO,
//   REGENERATOR_FACTOR,
// } from "../../graphql/modules";

//Constant
export const LOGIN_USER = "LOGIN_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_MENU_STYLE = "UPDATE_MENU_STYLE";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_RESTAURANT = "UPDATE_RESTAURANT";

// initial state
const initialState = {
  isAuthenticated: null,
  jwtToken: null,
  user: {},
  staff: {},
};

export const AuthReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGOUT_USER:
      return initialState;

    case LOGIN_USER:
      return {
        isAuthenticated: !!action.payload.token,
        jwtToken: action.payload.token,
        user: action.payload.user,
        staff: action.payload.staff,
      };

    case UPDATE_MENU_STYLE: {
      console.log(payload.resId);
      const index = state.user.restaurants.findIndex((item) => item._id === payload.resId);
      const statedata = {
        ...state.user.restaurants[index].menuStyle,
        ...payload,
      };
      state.user.restaurants[index].menuStyle = statedata;
      return state;
    }
    case UPDATE_PROFILE: {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    }
    case UPDATE_RESTAURANT: {
      const index = state.user.restaurants.findIndex((item) => item._id === payload.resId);
      const statedata = {
        ...state.user.restaurants[index],
        ...payload,
      };
      state.user.restaurants[index] = statedata;
      return state;
    }
    default: {
      return state;
    }
  }
};

export const logoutUser = () => ({
  type: LOGOUT_USER,
});

export const loginUser = (payload) => async (dispatch) => {
  await dispatch({
    type: LOGIN_USER,
    payload,
  });
  // window.location.reload();
};
export const onUpdateMenuStyle = (payload) => async (dispatch) => {
  await dispatch({
    type: UPDATE_MENU_STYLE,
    payload,
  });
};
export const updateProfileAction = (payload) => async (dispatch) => {
  await dispatch({
    type: UPDATE_PROFILE,
    payload,
  });
};
