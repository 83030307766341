import React, { useState } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Link, useHistory } from "react-router-dom";
import { EllipsisOutlined, SearchOutlined } from "@ant-design/icons";
import { PermitStatus, UserRole, StatusColor } from "../config";
import { GET_MY_DRAFT_PERMITS, DELETE_DRAFT_PERMIT, GET_SITE_LIST, GET_ALL_DISCIPLINE } from "../graphql/modules";
import { Pagination, Tag, Dropdown, Menu, Button, Input, Space } from "antd";
import Highlighter from "react-highlight-words";

import { DynamicList } from "../components/Shared";
import { useSelector } from "react-redux";
import { formatDate, successNotify, warnNotify, TABLE_LIST_LIMIT } from "../util";

export const AllMyDraftPermits = ({ match }) => {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const [limit, setLimit] = useState(TABLE_LIST_LIMIT);
  const [page, setPage] = useState(1);

  /** START For Applier Name Filtering */
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  /** END For Applier Name Filtering */

  const { data, loading, error, refetch } = useQuery(GET_MY_DRAFT_PERMITS, {
    variables: {
      limit,
      offset: page - 1,
    },
    fetchPolicy: "cache-and-network",
  });

  const { data: SiteList } = useQuery(GET_SITE_LIST, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  const { data: DisciplineList } = useQuery(GET_ALL_DISCIPLINE);

  const [DeleteDraftPermit, { loading: deleteDraftLoading }] = useMutation(DELETE_DRAFT_PERMIT);
  const handleDeleteDraftPermit = async (id) => {
    try {
      const { data: DeleteDraftData } = await DeleteDraftPermit({
        variables: {
          id: id,
        },
      });

      if (DeleteDraftData.DeleteDraftPermit.success) {
        successNotify(DeleteDraftData.DeleteDraftPermit.message);
        refetch();
      } else {
        warnNotify(DeleteDraftData.DeleteDraftPermit.message);
      }
    } catch (error) {
      console.log(error);
      warnNotify("Something went wrong trying to delete draft permit. Please try again later..");
    }
  };

  const onChangePagination = (p, size) => {
    setPage(p);
    setLimit(size);
  };

  /** START For Applier Name Filtering */
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getHostOwnerSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
    onFilter: (email, row) =>
      row?.hostSystemOwner ? row?.hostSystemOwner?.email?.toString().toLowerCase().includes(email.toLowerCase()) : "",
    render: (_text, _row) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={_row?.hostSystemOwner ? _row?.hostSystemOwner?.email.toString() : ""}
        />
      ) : (
        _row?.hostSystemOwner?.email
      ),
  });

  let disciplineList = [];

  if (DisciplineList?.GetAllDisciplines?.results?.length) {
    DisciplineList?.GetAllDisciplines?.results?.map((discipline) => {
      disciplineList.push({
        text: discipline.code,
        value: discipline.code,
      });
    });
  }

  const total = data?.GetMyDraftPermits?.count;
  const results = JSON.parse(data?.GetMyDraftPermits?.results || "[]");
  let siteList = [];

  if (SiteList?.GetSites?.results?.length) {
    SiteList?.GetSites?.results?.map((site) => {
      siteList.push({
        text: site.code,
        value: site.code,
      });
    });
  }

  const columns = [
    {
      title: "#",
      render: (_text, _row, index) => index + 1,
    },
    // {
    //   title: "Permit Number",
    //   dataIndex: "code",
    // },
    {
      title: "Applier name",
      render: (_text, _row) => _row?.submittedBy?.fullname,
    },
    {
      title: "Applier Site",
      filters: siteList,
      onFilter: (_text, _row) => _row?.site?.code?.indexOf(_text) === 0,
      render: (_text, _row) => _row?.site?.code,
    },
    {
      title: "Discipline",
      filters: disciplineList,
      onFilter: (_text, _row) => _row?.discipline?.code?.indexOf(_text) === 0,
      render: (_text, _row) => (_row?.discipline?.code !== undefined ? _row?.discipline?.code : ""),
    },
    {
      title: "Host/System Owner",
      ...getHostOwnerSearchProps("hostSystemOwner"),
      render: (_text, _row) => (_row?.hostSystemOwner?.email !== undefined ? _row?.hostSystemOwner?.email : ""),
    },
    {
      title: "Date applied",
      render: (_text, _row) => formatDate(_row.createdAt),
    },
    {
      title: "Start Date",
      render: (_text, _row) => formatDate(_row?.submission?.startDate),
    },
    {
      title: "End Date",
      render: (_text, _row) => formatDate(_row?.submission?.dateEnd),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status, row) => {
        let color = "orange";
        if (status === PermitStatus.Rejected) color = StatusColor.Rejected;
        else if (status === PermitStatus.Approved) color = StatusColor.Approved;
        else if (status === PermitStatus.Completed) color = StatusColor.Completed;
        else if (status === PermitStatus.Cancelled) color = StatusColor.Cancelled;
        else if (status === PermitStatus.PendingClosure) color = StatusColor.PendingClosure;
        else if (status === PermitStatus.PendingCancel) color = StatusColor.PendingCancel;

        const approvedBy = row?.approvedBy?.length ? row.approvedBy[row?.approvedBy?.length - 1] : null;
        const rejectedBy = row?.rejectedBy;
        return (
          <div>
            <Tag style={{ borderRadius: 10, padding: "0px 10px" }} color={color}>
              {status}
            </Tag>
            {status === PermitStatus.Approved &&
              (approvedBy ? (
                <span className="d-block badge-success badge badge-pill mt-1">By {approvedBy?.fullname}</span>
              ) : null)}

            {status === PermitStatus.Rejected && rejectedBy?.group?.name && (
              <span className="d-block badge-success badge badge-pill mt-1">By {rejectedBy?.fullname}</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Child permit",
      render: (_text, _row) => _row?.form?.title,
    },
    {
      title: "Action",
      width: "15%",
      render: (text, record) => {
        const menu = (
          <Menu>
            <Menu.Item key="0">
              <Link
                style={{ marginRight: "15px" }}
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`draftpermit/${record._id}`, {
                    user: record,
                  });
                }}
              >
                View
              </Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link
                style={{ marginRight: "15px" }}
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`draftpermit/review/${record._id}`, {
                    user: record,
                  });
                }}
              >
                Update
              </Link>
            </Menu.Item>

            <Menu.Item key="5">
              <Button
                type="text"
                danger
                onClick={(e) => {
                  e.preventDefault();
                  var deletePermit = window.confirm("Are you sure you wish to delete this draft permit?");

                  if (deletePermit) {
                    handleDeleteDraftPermit(record._id);
                  }
                }}
              >
                Delete
              </Button>
              {/* <Link
                style={{ marginRight: "15px", color: "red" }}
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                Delete
              </Link> */}
            </Menu.Item>
            {/* <Menu.Item key="0">
              <Link
                style={{ marginRight: "15px" }}
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  history.push(`permits/${record._id}`, {
                    user: record,
                  });
                }}
              >
                View
              </Link>
            </Menu.Item>
            {(user.role === UserRole.ContractorRequestor ||
              user.role === UserRole.User ||
              user.role === UserRole.MTGroup) &&
              record.status === PermitStatus.Approved && (
                <Menu.Item key="5">
                  <Link
                    style={{ marginRight: "15px" }}
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      history.push(`permits/review/${record._id}`, {
                        user: record,
                      });
                    }}
                  >
                    Review
                  </Link>
                </Menu.Item>
              )}
            {user.role === UserRole.ContractorRequestor && record.status === PermitStatus.Rejected && (
              <Menu.Item key="5">
                <Link
                  style={{ marginRight: "15px" }}
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(`permits/review/${record._id}`, {
                      user: record,
                    });
                  }}
                >
                  Resubmit
                </Link>
              </Menu.Item>
            )}

            {(user.role === UserRole.ContractorPM ||
              user.role === UserRole.MTGroup ||
              user.role === UserRole.MicronSupervisor ||
              user.role === UserRole.MicronManager ||
              user.role === UserRole.SiteAdmin) &&
              record.status !== PermitStatus.Approved && (
                <Fragment>
                  <Menu.Divider />
                  <Menu.Item key="3">
                    <Link
                      style={{ marginRight: "15px" }}
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push(`permits/review/${record._id}`, {
                          user: record,
                        });
                      }}
                    >
                      Review
                    </Link>
                  </Menu.Item>
                </Fragment>
              )} */}
          </Menu>
        );
        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <EllipsisOutlined />
          </Dropdown>
        );
      },
    },
  ];

  if (error) {
    return <div className="card">Something went wrong</div>;
  }

  let createBtn = null;

  return (
    <DynamicList
      title="List of Draft Permits"
      loading={loading}
      columns={columns}
      data={results}
      actions={createBtn}
      pagination={
        <Pagination
          className="pagination"
          total={total}
          pageSize={limit}
          current={page}
          showSizeChanger={false}
          onChange={onChangePagination}
        />
      }
      // showSearch={true}
      // onChangeSearch={onChangeSearch}
    />
  );
};
