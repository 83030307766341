import gql from "graphql-tag";
import { MAP_FRAGMENT } from "../fragments";
export const GET_MAP_LIST = gql`
  query($limit: Int, $offset: Int, $filterOption: String) {
    GetMap(limit: $limit, offset: $offset, filterOption: $filterOption) {
      code
      success
      message
      count
      results {
        ...MapType
      }
    }
  }
  ${MAP_FRAGMENT}
`;

export const CREATE_MAP = gql`
  mutation CreateMap($data: MapInput!) {
    CreateMap(data: $data) {
      code
      success
      message
    }
  }
`;

export const DELETE_MAP = gql`
  mutation ($id: ID!) {
    DeleteMap(id: $id) {
      code
      success
      message
    }
  }
`;

export const GET_SINGLE_MAP = gql`
  query ($id: ID!) {
    GetMapToEdit(id: $id) {
      code
      success
      message
      results {
        ...MapType
      }
    }
  }
  ${MAP_FRAGMENT}
`;

export const UPDATE_MAP = gql`
  mutation UpdateMap($id: ID!, $data: MapInput!) {
    UpdateMap(id: $id, data: $data) {
      code
      success
      message
    }
  }
`;
