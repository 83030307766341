import gql from "graphql-tag";
import { SITE_FRAGMENT, USER_FRAGMENT } from "../fragments";

export const SSO_USER_LOGIN = gql`
  mutation SSOLogin($email: String!) {
    SSOLogin(email: $email) {
      code
      success
      token
      message
      user {
        ...UserType
        sites {
          ...SiteType
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${SITE_FRAGMENT}
`;

export const USER_LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    Login(email: $email, password: $password) {
      code
      success
      token
      message
      user {
        ...UserType
        sites {
          ...SiteType
        }
      }
    }
  }
  ${USER_FRAGMENT}
  ${SITE_FRAGMENT}
`;

export const USER_SIGNUP = gql`
  mutation($data: RegistrationInput) {
    Register(data: $data) {
      code
      message
      success
    }
  }
`;

export const USER_FORGOT_PASSWORD = gql`
  mutation($email: String!) {
    ForgetPassword(email: $email) {
      code
      success
      message
    }
  }
`;

export const GET_VERIFICATION_LINK = gql`
  mutation userGetVerificationLink($email: String) {
    userGetVerificationLink(email: $email) {
      msg
      code
    }
  }
`;

export const USER_RESET_PASSWORD = gql`
  mutation userResetPassword($newPassword: String!, $securityCode: String!) {
    ResetPassword(newPassword: $newPassword, securityCode: $securityCode) {
      code
      success
      message
    }
  }
`;

export const ADMIN_RESET_PASSWORD = gql`
  mutation userResetPassword($userId: String) {
    ResetPasswordByAdmin(userId: $userId) {
      code
      success
      message
    }
  }
`;

export const USER_VERIFY_EMAIL = gql`
  mutation verifyEmail($securityCode: String!) {
    VerifyEmail(securityCode: $securityCode) {
      code
      success
      message
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($data: ProfileUpdateInput) {
    UpdateProfile(data: $data) {
      code
      success
      message
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation($oldPassword: String!, $newPassword: String!) {
    ChangePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      code
      message
      success
    }
  }
`;
