import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { Button, message, Modal, Select, Spin, Alert, Form } from "antd";
import React, { useEffect, useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { Divider } from "@material-ui/core";
import {
  warnNotify,
  A2,
  A3,
  A4,
  A5,
  A6,
  A7,
  A8,
  A9,
  A10,
  A11,
  FilterA2,
  FilterA3,
  FilterA4,
  FilterA5,
  FilterA6,
  FilterA7,
  FilterA8,
  FilterA9,
  FilterA10,
  FilterA11,
} from "../util";
import { Link } from "react-router-dom";
import { PermitSection } from "../components/Permit";
import {
  CREATE_PERMIT,
  GET_SITE_LIST,
  GET_TEMPLATE_LIST,
  GET_GROUP_LIST,
  GET_ALL_MTGROUPS,
  GET_ALL_MICRON_USERS,
  CREATE_DRAFT_PERMIT,
  GET_SPECIAL_TEMPLATE_LIST,
  GET_ALL_DISCIPLINE,
} from "../graphql/modules";

import Tooltip from "@material-ui/core/Tooltip";

import { validateFulfilledRequiredFields } from "../helpers";
import debounce from "lodash/debounce";
import _ from "lodash";
import { CustomButton } from "../components/Shared";
import { UserRole, UnfilledRequiredFieldsToolTip } from "../config";
const micronEmail = "@micron.com";

export const CreatePermit = ({ history }) => {
  const { user: authUser } = useSelector((_state) => _state.auth);
  const [form] = Form.useForm();

  const [template, setTemplate] = useState(null);
  const [site, setSite] = useState(null);
  const [showIndividualMtGroup, setShowIndividualMTGroup] = useState(false);
  const [mtgroup, setMTGroup] = useState([]);
  const [users, setUsers] = useState([]);
  const [childtemplate, setChildTemplate] = useState(null);
  const [visibleChildPermit, setVisibleChildPermit] = useState(null);
  const [state, setState] = useState({});
  const [childSubmissionState, setChildSubmissionState] = useState(null);
  const [isFormValid, setIsFormValid] = useState(true);
  const [formState, setFormState] = useState({});
  const [isChildIncluded, setChildIncluded] = useState(false);
  const [isChildPermit, setChildPermit] = useState([]);
  const [hostSystemOwner, setHostSystemOwner] = useState(null);
  const [discipline, setDiscipline] = useState(null);

  const [templateList, setTemplateList] = useState([]);

  const [showFormToolTip, setShowFormToolTip] = useState(false);

  const { data: SiteList } = useQuery(GET_SITE_LIST, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  const { data: MTGroupsList } = useQuery(GET_ALL_MTGROUPS, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  const [getGroups, { data: GroupList, loading: fetchingGroup }] = useLazyQuery(GET_GROUP_LIST, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  const [getUsers, { data: UserList, loading: fetchingUser }] = useLazyQuery(GET_ALL_MICRON_USERS, {
    variables: {
      limit: 1000,
      offset: 0,
      searchText: micronEmail,
    },
  });

  const [getHostOwner, { data: HostOwnerList, loading: fetchingHostOwner }] = useLazyQuery(GET_ALL_MICRON_USERS, {
    variables: {
      limit: 1000,
      offset: 0,
      searchText: micronEmail,
    },
  });

  const [getTemplates, { data: TemplateList, loading: fetchingTemplates }] = useLazyQuery(GET_TEMPLATE_LIST);

  //const [getDiscipline, { data: DisciplineList }] = useLazyQuery(GET_ALL_DISCIPLINE);

  const { data: DisciplineList } = useQuery(GET_ALL_DISCIPLINE);

  useEffect(() => {
    setTimeout(() => console.log("DisciplineList", DisciplineList?.data), 1000);
  }, []);

  useEffect(() => {
    if (template === null) {
      getTemplates();
      setTemplate(
        TemplateList?.GetTemplates?.results?.filter((_template) => _template._id === "60500a1f050c9442a394437c")
      );
    }
  }, template);

  useEffect(() => {
    if (site) {
      getUsers();
      getGroups();
      getHostOwner();
      // getDiscipline();
    }
  }, [site]);

  const { data: SpecialChildTemplates } = useQuery(GET_SPECIAL_TEMPLATE_LIST, {
    variables: {
      showInCreatePermit: true,
    },
  });

  const handleChildPermit = (_childSubmissionState) => {
    setChildSubmissionState(_childSubmissionState);
  };

  const handleFormTooltipClose = () => {
    setShowFormToolTip(false);
  };

  const handleFormTooltipOpen = () => {
    console.log("There's an empty required field.");
    setShowFormToolTip(true);
    message.warn("Please fill-in required fields before submitting the permit!");
  };

  useEffect(() => {
    console.log("showFormToolTip value changed: " + showFormToolTip);
  }, [showFormToolTip]);

  const onSearchUser = React.useMemo(() => {
    const loadOptions = (value) => {
      getUsers({
        variables: {
          limit: 1000,
          offset: 0,
          searchText: value,
        },
      });
    };
    return debounce(loadOptions, 800);
  }, []);

  const onSearchHostOwner = React.useMemo(() => {
    const loadOptions = (value) => {
      getHostOwner({
        variables: {
          limit: 1000,
          offset: 0,
          searchText: value,
        },
      });
    };
    return debounce(loadOptions, 800);
  }, []);

  const onSearchGroup = React.useMemo(() => {
    const loadOptions = (value) => {
      getGroups({
        variables: {
          limit: 1000,
          offset: 0,
          searchText: value,
        },
      });
    };
    return debounce(loadOptions, 800);
  }, []);

  useEffect(() => {
    if (Array.isArray(TemplateList?.GetTemplates?.results)) {
      const tmp = TemplateList?.GetTemplates?.results?.find((t) => t._id === "60500a1f050c9442a394437c");
      var parentTemplates = TemplateList?.GetTemplates?.results?.filter(
        (_template) => _template.asParentPermit === true
      );
      setTemplateList(parentTemplates);
      setTemplate(tmp);
    }
  }, [TemplateList]);

  const onChange = (values) => {
    // console.log("values: " + JSON.stringify(values, null, 2));
    // console.log("formState: " + JSON.stringify(formState, null, 2));
    setState({ ...state, ...values });
    if (values?.workWithHealthAndSafetyImapct) {
      var filteredA2 = values?.workWithHealthAndSafetyImapct.filter((str) => {
        return str.toLowerCase().indexOf(FilterA2.toLowerCase()) >= 0;
      });
      var filteredA3 = values?.workWithHealthAndSafetyImapct.filter((str) => {
        return str.toLowerCase().indexOf(FilterA3.toLowerCase()) >= 0;
      });
      var filteredA4 = values?.workWithHealthAndSafetyImapct.filter((str) => {
        return str.toLowerCase().indexOf(FilterA4.toLowerCase()) >= 0;
      });
      var filteredA5 = values?.workWithHealthAndSafetyImapct.filter((str) => {
        return str.toLowerCase().indexOf(FilterA5.toLowerCase()) >= 0;
      });
      var filteredA6 = values?.workWithHealthAndSafetyImapct.filter((str) => {
        return str.toLowerCase().indexOf(FilterA6.toLowerCase()) >= 0;
      });
      var filteredA7 = values?.workWithHealthAndSafetyImapct.filter((str) => {
        return str.toLowerCase().indexOf(FilterA7.toLowerCase()) >= 0;
      });
      var filteredA8 = values?.workWithHealthAndSafetyImapct.filter((str) => {
        return str.toLowerCase().indexOf(FilterA8.toLowerCase()) >= 0;
      });
      var filteredA10 = values?.workWithHealthAndSafetyImapct.filter((str) => {
        return str.toLowerCase().indexOf(FilterA10.toLowerCase()) >= 0;
      });

      if (filteredA2.length !== 0) {
        message.warn("A2 Child Permit is required!");
        setChildIncluded(true);
      } else if (filteredA3.length !== 0) {
        message.warn("A3 Child Permit is required!");
        setChildIncluded(true);
      } else if (filteredA4.length !== 0) {
        message.warn("A4 Child Permit is required!");
        setChildIncluded(true);
      } else if (filteredA5.length !== 0) {
        message.warn("A5 Child Permit is required!");
        setChildIncluded(true);
      } else if (filteredA6.length !== 0) {
        message.warn("A6 Child Permit is required!");
        setChildIncluded(true);
      } else if (filteredA7.length !== 0) {
        message.warn("A7 Child Permit is required!");
        setChildIncluded(true);
      } else if (filteredA8.length !== 0) {
        message.warn("A8 Child Permit is required!");
        setChildIncluded(true);
      } else if (filteredA10.length !== 0) {
        message.warn("A10 Child Permit is required!");
        setChildIncluded(true);
      } else {
        setChildIncluded(false);
      }
    }
  };

  const handleFormState = (value) => {
    setFormState(value);
  };

  const onChangeForm = (_v, obj) => {
    setTemplate(obj.template);
  };

  const onChangeChildPermit = (_v, obj) => {
    setChildTemplate(obj);

    if (state?.workWithHealthAndSafetyImapct) {
      var filtered = state?.workWithHealthAndSafetyImapct.filter((str) => {
        return (
          str.toLowerCase().indexOf(FilterA2.toLowerCase()) >= 0 ||
          str.toLowerCase().indexOf(FilterA3.toLowerCase()) >= 0 ||
          str.toLowerCase().indexOf(FilterA4.toLowerCase()) >= 0 ||
          str.toLowerCase().indexOf(FilterA5.toLowerCase()) >= 0 ||
          str.toLowerCase().indexOf(FilterA6.toLowerCase()) >= 0 ||
          str.toLowerCase().indexOf(FilterA7.toLowerCase()) >= 0 ||
          str.toLowerCase().indexOf(FilterA8.toLowerCase()) >= 0 ||
          str.toLowerCase().indexOf(FilterA10.toLowerCase()) >= 0
        );
      });

      if (filtered?.length !== 0) {
        setChildIncluded(false);
      } else {
        setChildIncluded(true);
      }
    }
    // console.log("onChangeChildPermit.obj: " + JSON.stringify(obj[0]?.template?.sections, null, 2));
    obj[0]?.template?.sections?.map((section) => {
      section?.form?.map((frm) => {
        if (frm.required) {
          var obj = {
            name: frm.name,
            required: frm.required,
          };
          temporaryChildFormState.push(obj);
        }
      });
    });
  };

  const onOkChildPermit = () => {
    setVisibleChildPermit(false);
  };

  function getGroupObj(id, groupArr) {
    for (var i = 0; i < groupArr.length; i++) {
      if (groupArr[i]._id === id) {
        return groupArr[i];
      }
    }
  }

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    handleSubmitPermit();
  };

  const handleSubmitPermit = (e) => {
    console.log("handleSubmitPermit");

    let fState = state !== {} ? state : null;

    if (fState !== null) {
      onSubmit();
    } else {
      message.warn("There is something wrong creating the permit. Please check for empty Required Fields.");
      handleFormTooltipOpen();
    }
  };

  const [DraftPermitMutation, { loading: createDrafPermitLoading }] = useMutation(CREATE_DRAFT_PERMIT);
  const onSaveAsDraft = async (e) => {
    e.preventDefault();
    let mtGroups = MTGroupsList?.GetAllMTGroups?.results;

    // let fState = state !== {} ? state : null;
    // let childPermits = childtemplate?.length > 0 ? childtemplate?.length : null;
    // let fChildState = childSubmissionState !== null ? childSubmissionState : null;

    let mtGroupSelectedUsers = [];
    let userGroupArr = [];

    try {
      // console.log("mtgroup: " + JSON.stringify(mtgroup, null, 2));
      console.log("mtGroups.length: " + MTGroupsList?.GetAllMTGroups?.count);

      if (JSON.stringify(mtgroup) !== null || JSON.stringify(mtgroup) !== [] || JSON.stringify(mtgroup) !== undefined) {
        for (var i = 0; i < MTGroupsList?.GetAllMTGroups?.count; i++) {
          if (mtgroup && mtgroup.some((pId) => pId.toString() === mtGroups[i]._id.toString())) {
            userGroupArr.push(mtGroups[i]);
          }
        }

        console.log("userGroupArr: " + JSON.stringify(userGroupArr, null, 2));

        userGroupArr &&
          userGroupArr.map((selectedGroups) => {
            if (
              selectedGroups.members !== [] ||
              selectedGroups.members !== null ||
              selectedGroups.members !== undefined
            ) {
              selectedGroups.members.map((member) => {
                mtGroupSelectedUsers.push(member._id);
              });
            }
          });
      }
    } catch (zError) {
      console.log(zError);
    }

    let allSelectedUsers = mtGroupSelectedUsers.concat(users);
    let _uniques = _.union(allSelectedUsers);

    var varData = {
      form: template?._id,
      submission: JSON.stringify(state),
      childrenState: JSON.stringify(childSubmissionState),
      site: site,
      selectedUser: _uniques,
      selectedMTGroupUsers: mtGroupSelectedUsers,
      selectedMTGroup: mtgroup,
      hostSystemOwner: hostSystemOwner,
      discipline: discipline,
    };

    console.log("DRAFT varData: " + JSON.stringify(varData, null, 2));
    // if (childPermits === null && fChildState === null) {
    try {
      console.log("saving permit as draft...");
      if (site === "" || site === null) {
        message.warn("Please select site!");
      } else if (users === null || users.length === 0) {
        message.warn("Please select individual reviewer!");
      } else if (mtGroupSelectedUsers === null || mtGroupSelectedUsers.length === 0) {
        message.warn("Please select mtgroup reviewer!");
      } else if (state.Checkbox === false || Object.keys(state).length === 0) {
        message.warn("Declaration is required!");
      } else {
        const { data: CreateDraftPermitData } = await DraftPermitMutation({
          variables: {
            data: varData,
          },
        });
        if (CreateDraftPermitData?.CreateDraftPermit?.success) {
          message.success(CreateDraftPermitData?.CreateDraftPermit?.message);
          history.push("/my-draft-permits");
        } else {
          message.warn(CreateDraftPermitData?.CreateDraftPermit?.message);
        }
      }
    } catch (_error) {}
    // } else {
    //   message.warn(
    //     "You cannot submit an empty child permit! Please fill-in some fields before saving as draft or submitting the permit!"
    //   );
    // }
  };

  const [CreatePermitMutation, { loading: createPermitLoading }] = useMutation(CREATE_PERMIT);
  const onSubmit = async (e) => {
    console.log("ONSUBMIT FUNC TRIGGERED");
    // e.preventDefault();
    getGroups();
    let mtGroups = MTGroupsList?.GetAllMTGroups?.results;

    let fState = state !== {} ? state : null;
    let childPermits = childtemplate?.length ? childtemplate?.length : null;
    let fChildState = childSubmissionState !== null ? childSubmissionState : null;

    // console.log("fState: " + JSON.stringify(fState));
    console.log("childPermits: " + childPermits);
    console.log(fChildState);
    if (fState !== null) {
      let mtGroupSelectedUsers = [];
      let userGroupArr = [];

      let isAllParentRequiredFieldsFilled = validateFulfilledRequiredFields(temporaryParentFormState, fState);

      if (isAllParentRequiredFieldsFilled) {
        try {
          console.log("mtgroup: " + JSON.stringify(mtgroup, null, 2));
          console.log("mtGroups.length: " + MTGroupsList?.GetAllMTGroups?.count);

          if (
            JSON.stringify(mtgroup) !== null ||
            JSON.stringify(mtgroup) !== [] ||
            JSON.stringify(mtgroup) !== undefined
          ) {
            for (var i = 0; i < MTGroupsList?.GetAllMTGroups?.count; i++) {
              if (mtgroup && mtgroup.some((pId) => pId.toString() === mtGroups[i]._id.toString())) {
                userGroupArr.push(mtGroups[i]);
              }
            }

            console.log("userGroupArr: " + JSON.stringify(userGroupArr, null, 2));

            userGroupArr &&
              userGroupArr.map((selectedGroups) => {
                if (
                  selectedGroups.members !== [] ||
                  selectedGroups.members !== null ||
                  selectedGroups.members !== undefined
                ) {
                  selectedGroups.members.map((member) => {
                    mtGroupSelectedUsers.push(member._id);
                  });
                }
              });
          }
        } catch (zError) {
          console.log(zError);
        }

        let allSelectedUsers = mtGroupSelectedUsers.concat(users);
        let _uniques = _.union(allSelectedUsers);

        var varData = {
          form: template?._id,
          submission: JSON.stringify(state),
          childrenState: JSON.stringify(childSubmissionState),
          site: site,
          selectedUser: _uniques,
          selectedMTGroupUsers: mtGroupSelectedUsers,
          selectedMTGroup: mtgroup,
          hostSystemOwner: hostSystemOwner,
          discipline: discipline,
        };

        console.log("varData: " + JSON.stringify(varData, null, 2));

        if (childPermits !== null) {
          if (fChildState !== null) {
            try {
              console.log("trying to create permit...");
              const { data: CreatePermitData } = await CreatePermitMutation({
                variables: {
                  data: varData,
                },
              });
              if (CreatePermitData?.CreatePermit?.success) {
                message.success(CreatePermitData?.CreatePermit?.message);
                if (authUser.role === UserRole.MTGroup) {
                  history.push("/all-my-permits");
                } else {
                  history.push("/permits");
                }
              } else {
                message.warn(CreatePermitData?.CreatePermit?.message);
              }
            } catch (_error) {}
          } else {
            message.warn(
              "You cannot submit an empty child permit! Please fill-in some fields before saving as draft or submitting the permit!"
            );
          }
        } else {
          try {
            console.log("trying to create permit...");
            const { data: CreatePermitData } = await CreatePermitMutation({
              variables: {
                data: varData,
              },
            });
            if (CreatePermitData?.CreatePermit?.success) {
              message.success(CreatePermitData?.CreatePermit?.message);
              if (authUser.role === UserRole.MTGroup) {
                history.push("/all-my-permits");
              } else {
                history.push("/permits");
              }
            } else {
              message.warn(CreatePermitData?.CreatePermit?.message);
            }
          } catch (_error) {}
        }
      } else {
        message.warn("Please fill-in required fields before saving as draft or submitting the permit!");
      }
    } else {
      message.warn(
        "You cannot submit an empty permit! Please fill-in some fields before saving as draft or submitting the permit!"
      );
    }
  };

  const disabled = !template || !site || !users?.length || !isFormValid;

  // console.log("template?.sections: " + JSON.stringify(template?.sections, null, 2));

  /** This array will be used to get all the required children permit fields, for validation */
  let temporaryChildFormState = [];

  /**
   *  This line will get the form field name and if the field is required
   *  Will be later used to check for required fields before submitting the permit.
   */
  let temporaryParentFormState = [];
  template?.sections?.map((section) => {
    section?.form?.map((frm) => {
      if (frm.required) {
        var obj = {
          name: frm.name,
        };
        temporaryParentFormState.push(obj);
      }
    });
  });

  /** END OF LINE */

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="create-permit-details">
            <div className="form-row">
              <div className="form-group col-md-4">
                <br />
                <br />
                Permit Type <br />
                <Select
                  style={{ width: "100%" }}
                  onChange={onChangeForm}
                  placeholder="Select template"
                  showSearch={true}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  value={template?._id}
                  // disabled
                >
                  {templateList
                    ?.sort((a, b) => a.title.localeCompare(b.title))
                    .map((_template) => (
                      <Select.Option key={_template._id} value={_template._id} template={_template}>
                        {_template.title}
                      </Select.Option>
                    ))}
                </Select>
                {template && (
                  <div>
                    <br /> Site <br />
                    <Select
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        setSite(value);
                        setShowIndividualMTGroup(true);
                      }}
                      placeholder="Select Site"
                    >
                      {SiteList?.GetSites?.results
                        ?.sort((a, b) => a.code.localeCompare(b.code))
                        .map((_site) => (
                          <Select.Option key={_site._id} value={_site._id} site={_site}>
                            {_site.code}
                          </Select.Option>
                        ))}
                    </Select>
                  </div>
                )}
              </div>

              {site && (
                <div className="form-group col-md-4">
                  <div>
                    <br />
                    <br />
                    Host/System Owner <br />
                    <Select
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        setHostSystemOwner(value);
                        console.log(hostSystemOwner, "host");
                        // setShowIndividualMTGroup(true);
                      }}
                      showSearch={true}
                      onSearch={onSearchHostOwner}
                      allowClear
                      onClear={() => {
                        getHostOwner();
                      }}
                      filterOption={false}
                      notFoundContent={fetchingHostOwner ? <Spin size="small" /> : null}
                      placeholder="Select Host/System Owner"
                      disabled={!site}
                    >
                      {HostOwnerList?.GetAllMicronUsers?.results
                        ?.sort((a, b) => a.email.localeCompare(b.email))
                        .filter((res) => res.email.toLowerCase().indexOf(micronEmail) !== -1)
                        .map((user) => (
                          <Select.Option key={user._id} value={user._id}>
                            <strong>{user.fullname}</strong>
                            <br />
                            <span>{user.email}</span>
                          </Select.Option>
                        ))}
                    </Select>
                  </div>
                  <div>
                    <br /> Disciplines <br />
                    <Select
                      style={{ width: "100%" }}
                      onChange={(value) => {
                        setDiscipline(value);
                        // setShowIndividualMTGroup(true);
                        console.log("disc", discipline);
                      }}
                      placeholder="Select Discipline"
                      disabled={!site}
                    >
                      {DisciplineList?.GetAllDisciplines?.results
                        ?.sort((a, b) => a.code.localeCompare(b.code))
                        .map((_site) => (
                          <Select.Option key={_site._id} value={_site._id} site={_site}>
                            {_site.code}
                          </Select.Option>
                        ))}
                    </Select>
                  </div>
                </div>
              )}

              {site && (
                <div className="form-group col-md-4">
                  <div>
                    <br />
                    Individual Reviewer(s) - Micron supervisor/ Micron Manager <br />
                    <Select
                      style={{ width: "100%" }}
                      onChange={(value) => setUsers(value)}
                      placeholder="Select Individual Reviewers"
                      showSearch={true}
                      onSearch={onSearchUser}
                      multiple
                      filterOption={false}
                      notFoundContent={fetchingUser ? <Spin size="small" /> : null}
                      mode="multiple"
                      autoClearSearchValue
                      onSelect={(value) => {
                        var hasDuplicateReviewer;

                        if (mtgroup !== null) {
                          let result_1 = MTGroupsList?.GetAllMTGroups?.results.filter((o1) =>
                            mtgroup?.some((o2) => o1._id === o2)
                          );
                          let combinedArray = result_1.map((res) => res.members);
                          let merged = [].concat.apply([], combinedArray);
                          hasDuplicateReviewer = merged.filter(
                            (ol) => ol._id.toLowerCase().indexOf(value.toLowerCase()) !== -1
                          );
                        }

                        if (hasDuplicateReviewer !== undefined && hasDuplicateReviewer.length !== 0) {
                          warnNotify("One of the User in MtGroup has same id in Individual Reviewer.");
                          setUsers([]);
                        }
                      }}
                      value={users}
                    >
                      {UserList?.GetAllMicronUsers?.results
                        ?.sort((a, b) => a.email.localeCompare(b.email))
                        .filter((res) => res.email.toLowerCase().indexOf(micronEmail) !== -1)
                        .map((user) => (
                          <Select.Option key={user._id} value={user._id}>
                            <strong>{user.fullname}</strong>
                            <br />
                            <span>{user.email}</span>
                          </Select.Option>
                        ))}
                    </Select>
                  </div>
                  <div>
                    <br /> MTGroup(s) <br />
                    <Select
                      style={{ width: "100%" }}
                      onChange={(value) => setMTGroup(value)}
                      placeholder="Select MT Group"
                      showSearch={true}
                      onSearch={onSearchGroup}
                      multiple
                      filterOption={false}
                      notFoundContent={fetchingGroup ? <Spin size="small" /> : null}
                      mode="multiple"
                      autoClearSearchValue
                      onSelect={(value) => {
                        var hasDuplicate;
                        var hasDuplicateReviewer;
                        let result = MTGroupsList?.GetAllMTGroups?.results.filter(
                          (ol) => ol._id.toLowerCase().indexOf(value.toLowerCase()) !== -1
                        );
                        let getMembersToCheck = result.map((res) => res.members);

                        if (mtgroup !== null) {
                          let result_1 = MTGroupsList?.GetAllMTGroups?.results.filter((o1) =>
                            mtgroup?.some((o2) => o1._id === o2)
                          );
                          let combinedArray = result_1.map((res) => res.members);
                          let merged = [].concat.apply([], combinedArray);
                          hasDuplicate = merged.filter((o1) => getMembersToCheck[0].some((o2) => o1._id === o2._id));
                          hasDuplicateReviewer = users.filter((o1) => getMembersToCheck[0].some((o2) => o1 === o2._id));
                        }

                        if (users !== null) {
                          hasDuplicateReviewer = users.filter((o1) => getMembersToCheck[0].some((o2) => o1 === o2._id));
                        }

                        if (hasDuplicate !== undefined && hasDuplicate.length !== 0) {
                          warnNotify("One of the User has same id in other MTgroup.");
                          setMTGroup([]);
                        } else if (hasDuplicateReviewer !== undefined && hasDuplicateReviewer.length !== 0) {
                          warnNotify("One of the User in MtGroup has same id in Individual Reviewer.");
                          setMTGroup([]);
                        }
                      }}
                      value={mtgroup}
                    >
                      {GroupList?.GetUserGroups?.results
                        ?.sort((a, b) => a.name.localeCompare(b.name))
                        .map((group) => (
                          <Select.Option key={group._id} value={group._id}>
                            {group.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="heading">
            <Link to="/permits" className="btn-link">
              <i className="far fa-arrow-left"></i> Back to view all permit{" "}
              {template?.title && <span>[{template?.title}]</span>}
            </Link>
          </div>

          {/* Add Child Permit Button */}
          {template && (
            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={handleFormTooltipOpen}
              name="createPermitForm"
              scrollToFirstError
            >
              {template?.sections?.map((section, key) => (
                <PermitSection
                  section={section}
                  key={key}
                  onChange={onChange}
                  state={state}
                  isParent={true}
                  handleFormState={handleFormState}
                  siteID={site}
                />
              ))}

              {childtemplate?.length > 0 && (
                <ChildPermit
                  templates={childtemplate}
                  onChange={onChange}
                  state={state}
                  onHandleSubmission={handleChildPermit}
                  isParent={false}
                />
              )}

              <div className="text-right w-100">
                <Button type="dashed" size="large" right onClick={() => setVisibleChildPermit(true)}>
                  Add child permit
                </Button>
              </div>

              <div className="account-details-btn ">
                <CustomButton
                  disabled={disabled || createDrafPermitLoading || !hostSystemOwner || !discipline}
                  loading={createDrafPermitLoading}
                  className="btn btn-primary"
                  onClick={onSaveAsDraft}
                  style={{ marginRight: "1%" }}
                  // disabled={createDrafPermitLoading || createPermitLoading}
                >
                  Save as Draft
                </CustomButton>

                <Tooltip
                  placement="top-end"
                  title={UnfilledRequiredFieldsToolTip}
                  disableFocusListener={!showFormToolTip}
                  disableHoverListener={!showFormToolTip}
                  disableTouchListener={!showFormToolTip}
                  onClose={handleFormTooltipClose}
                  open={showFormToolTip}
                >
                  <CustomButton
                    type="submit"
                    disabled={disabled || createPermitLoading || isChildIncluded || !hostSystemOwner || !discipline}
                    loading={createPermitLoading}
                    className="btn btn-primary"
                  >
                    Submit
                  </CustomButton>
                </Tooltip>
              </div>
            </Form>
          )}
        </div>
      </div>

      <Modal
        title="Child permit"
        visible={visibleChildPermit}
        onCancel={() => setVisibleChildPermit(false)}
        onOk={onOkChildPermit}
      >
        <div className="form-col">
          <Alert
            style={{ marginBottom: "5%" }}
            message="Looking for a specific child permit template?"
            description="Please create a separate child permit in My Child Permits Page after submitting this General Permit."
            type="info"
            showIcon
          />

          <div className="form-group">
            <p>Select From Available Child Permit Below: </p>
          </div>

          <div className="form-group col-md-4">
            <Select mode="multiple" style={{ width: 400 }} onChange={onChangeChildPermit} placeholder="Select template">
              {SpecialChildTemplates?.GetSpecialTemplates?.results?.map((tmp) =>
                tmp?._id === template?._id ? null : (
                  <Select.Option key={tmp._id} value={tmp._id} template={tmp}>
                    {tmp.title}
                  </Select.Option>
                )
              )}
            </Select>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const ChildPermit = ({ templates, onChange, state, onHandleSubmission }) => {
  const onChangeHandler = (values, children) => {
    // console.log("ChildPermit values: " + JSON.stringify(values));
    // console.log("ChildPermit children: " + JSON.stringify(children, null, 2));
    const child = state?.children?.find((d) => d.form === children?._id);
    if (child) {
      Object.assign(child.submission, values);
    } else {
      if (state?.children?.length > 0) {
        state.children.push({
          form: children?._id,
          submission: values,
        });
      } else {
        state.children = [
          {
            form: children?._id,
            submission: values,
          },
        ];
      }
    }

    // console.log("after if-else block, onChangeHandler" + JSON.stringify(state.children));
    onHandleSubmission(state.children);
  };

  return (
    <Fragment>
      <div style={{ display: "flex", justifyContent: "center", marginBottom: "2%" }}>
        <br />
        <h2>Child permit(s)</h2>
      </div>

      {templates?.map(({ template: children }, key) => (
        <React.Fragment>
          <Divider />
          <div>
            <h3>{children?.title}</h3>
          </div>
          {children?.sections?.map((section, _key) => (
            <PermitSection
              templateKey={key}
              section={section}
              onChange={(values) => onChangeHandler(values, children)}
              state={state}
              childrenId={children?._id}
              isParent={false}
            />
          ))}
          <br />
        </React.Fragment>
      ))}
    </Fragment>
  );
};
