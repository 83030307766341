import { useMutation, useQuery } from "@apollo/react-hooks";
import { Spin, message, Modal, Select, Tooltip } from "antd";
import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { PermitSingle } from "../components/Permit";
import { Divider } from "@material-ui/core";

import {
  GET_DRAFT_PERMIT_BY_ID,
  UPDATE_PERMIT_STATUS,
  CREATE_PERMIT,
  DELETE_DRAFT_PERMIT,
  GET_TEMPLATE_LIST,
} from "../graphql/modules";
import { successNotify, warnNotify } from "../util";
import { CustomButton } from "../components/Shared";
import { UserRole } from "../config";
import { GENERAL_PERMIT_ID } from "../util/";

import { useSelector } from "react-redux";

export const SingleDraftPermit = ({ match, history }) => {
  const { user: authUser } = useSelector((_state) => _state.auth);

  const [isFormValid, setIsFormValid] = useState(true);
  const [state, setState] = useState({});
  const [childrenState, setChildrenState] = useState([]);
  const [visibleChildPermit, setVisibleChildPermit] = useState(null);
  const [childtemplate, setChildTemplate] = useState(null);
  const [disabled, setStateDisabled] = useState(false);
  let temporaryChildFormState = [];

  const { data, loading, refetch } = useQuery(GET_DRAFT_PERMIT_BY_ID, {
    variables: {
      id: match.params.id,
    },
  });

  const { data: templateData, loading: loadingTemplateData } = useQuery(GET_TEMPLATE_LIST);

  const [CreatePermitMutation, { loading: createPermitLoading }] = useMutation(CREATE_PERMIT);
  const [DeleteDraftPermit] = useMutation(DELETE_DRAFT_PERMIT);
  const [UpdatePermitStatus, { loading: updateLoading }] = useMutation(UPDATE_PERMIT_STATUS);

  useEffect(() => {
    setState(permit?.submission || {});
    setChildrenState(permit?.children || []);
  }, [data]);

  let permit = {};
  if (data?.GetDraftPermitById?.permit) {
    permit = JSON.parse(data?.GetDraftPermitById?.permit || {});
  }

  if (data?.GetPermit?.success === false) {
    return <div className="card">Not found</div>;
  }

  console.log(permit);

  const onSubmit = async (e) => {
    e.preventDefault();
    let fState = state !== {} ? state : null;

    // if (isFormValid) {
    if (fState !== null) {
      let selectedUserIds = [];
      let selectedMTGroupUserIds = [];
      let selectedMTGroupIds = [];

      permit.selectedUser &&
        permit.selectedUser.map((usr) => {
          selectedUserIds.push(usr._id);
        });

      permit.selectedMTGroupUsers &&
        permit.selectedMTGroupUsers.map((mtGrpMmbrs) => {
          selectedMTGroupUserIds.push(mtGrpMmbrs._id);
        });

      permit.selectedMTGroup &&
        permit.selectedMTGroup.map((mtgrp) => {
          selectedMTGroupIds.push(mtgrp._id);
        });

      var varData = {
        form: permit?.form?._id,
        submission: JSON.stringify(state),
        childrenState: JSON.stringify(childrenState),
        site: permit?.site,
        selectedUser: selectedUserIds,
        selectedMTGroupUsers: selectedMTGroupUserIds,
        selectedMTGroup: selectedMTGroupIds,
        discipline: permit?.discipline?._id,
        hostSystemOwner: permit?.hostSystemOwner?._id,
      };

      console.log("varData: " + JSON.stringify(varData, null, 2));

      try {
        console.log("trying to create permit...");
        const { data: CreatePermitData } = await CreatePermitMutation({
          variables: {
            data: varData,
          },
        });

        if (CreatePermitData?.CreatePermit?.success) {
          message.success(CreatePermitData?.CreatePermit?.message);
          setStateDisabled(true);
          await DeleteDraftPermit({
            variables: {
              id: permit?._id,
            },
          });

          if (authUser.role === UserRole.MTGroup) {
            history.push("/all-my-permits");
          } else {
            history.push("/permits");
          }
        } else {
          message.warn(CreatePermitData?.CreatePermit?.message);
        }
      } catch (_error) {}
    } else {
      message.warn("Some required fields are not filled in.");
    }
  };

  const onUpdate = async (values) => {
    try {
      const { data } = await UpdatePermitStatus({
        variables: {
          id: match.params.id,
          status: values.status,
        },
      });
      if (data.UpdatePermitStatus.success) {
        successNotify(data.UpdatePermitStatus.message);
        refetch();
      } else {
        warnNotify(data.UpdatePermitStatus.message);
      }
    } catch (error) {}
  };

  const onChangeChildPermit = (_v, obj) => {
    setChildTemplate(obj);
    // console.log("onChangeChildPermit.obj: " + JSON.stringify(obj[0]?.template?.sections, null, 2));
    permitChildren = obj;
    obj[0]?.template?.sections?.map((section) => {
      section?.form?.map((frm) => {
        if (frm.required) {
          var obj = {
            name: frm.name,
            required: frm.required,
          };
          temporaryChildFormState.push(obj);
        }
      });
    });
  };

  const onOkChildPermit = () => {
    setVisibleChildPermit(false);
  };

  let permitChildren = permit?.children?.length ? permit?.children : null;

  if (permit !== null) {
    return (
      <Spin spinning={loading}>
        <PermitSingle permit={permit} onUpdate={onUpdate} updateLoading={updateLoading} />

        {Array.isArray(permit?.children) && (
          <Fragment>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: "2%" }}>
              <br />
              <h2>Child permit(s)</h2>
            </div>
            {permitChildren?.map((child) => (
              <div>
                <Divider />
                <div>
                  <h3>{child?.form?.title}</h3>
                </div>
                <PermitSingle permit={child} onUpdate={onUpdate} updateLoading={updateLoading} isChildren={true} />
              </div>
            ))}
          </Fragment>
        )}

        <div className="account-details-btn ">
          <CustomButton className="btn btn-primary">
            <Link to={`review/${permit?._id}`} className="btn-link" style={{ color: "white" }}>
              Update Draft Permit
            </Link>
          </CustomButton>

          <Tooltip title="Saving Draft Permit Requires to have Discipline and Host / System Owner Selected.">
            <div>
              <CustomButton
                onClick={onSubmit}
                type="submit"
                loading={createPermitLoading}
                className="btn ml-3"
                style={{ color: "white", background: "green", marginLeft: "1%" }}
                disabled={disabled || createPermitLoading || !permit?.discipline || !permit?.hostSystemOwner}
              >
                Submit Permit
              </CustomButton>
            </div>
          </Tooltip>
        </div>

        <Modal
          title="Child permit"
          visible={visibleChildPermit}
          onCancel={() => setVisibleChildPermit(false)}
          onOk={onOkChildPermit}
        >
          <div className="form-row">
            <div className="form-group col-md-4">
              <Select
                mode="multiple"
                style={{ width: 400 }}
                onChange={onChangeChildPermit}
                placeholder="Select template"
              >
                {templateData?.GetTemplates?.results?.map((tmp) =>
                  tmp?._id === GENERAL_PERMIT_ID ? null : (
                    <Select.Option key={tmp._id} value={tmp._id} template={tmp}>
                      {tmp.title}
                    </Select.Option>
                  )
                )}
              </Select>
            </div>
          </div>
        </Modal>
      </Spin>
    );
  } else {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <p> Viewing this Permit is not available at the moment. Kindly refresh and try again.</p>
      </div>
    );
  }
};
