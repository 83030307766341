import React, { useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { GET_SITE_LIST, GET_ALL_USERS_LIST } from "../../graphql/modules";
import { CustomButton } from "../Shared";

const TRUE = true;
const FALSE = false;

export const GroupForm = ({
  title,
  btnText,
  loading,
  state = {},
  error = {},
  handleChange = () => {},
  onSubmit = () => {},
}) => {
  const [getUsers, { data: UserList, loading: fetchingUser }] = useLazyQuery(GET_ALL_USERS_LIST, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });

  useEffect(() => {
    getUsers();
    console.log("Getting All Users...");
  }, []);

  // GET SITES LIST
  const { data } = useQuery(GET_SITE_LIST, {
    variables: {
      offset: 0,
      limit: 100,
    },
  });

  const onChange = (e) => handleChange({ [e.target.name]: e.target.value });

  const disable = !state.name || !state.site;

  return (
    <div>
      <div className="heading"></div>
      <h1 className="title">{title}</h1>
      <form onSubmit={onSubmit}>
        <div className="card">
          <div className="create-permit-details">
            <div className="form-row">
              <div className="form-group col-md-4">
                <input
                  type="name"
                  className="form-control"
                  placeholder="Group Name"
                  name="name"
                  value={state.name}
                  onChange={onChange}
                />
                {error?.fullname && <small className="text-danger">{error?.fullname}</small>}
              </div>

              <div className="form-group col-md-4">
                <select id="inputState" className="form-control" name="site" value={state.site} onChange={onChange}>
                  <option value="">Site Selection</option>
                  {data?.GetSites?.results?.map((site) => {
                    return (
                      <option key={site._id} value={site._id}>
                        {site.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="form-group col-md-4">
                <select
                  id="inputState"
                  className="form-control"
                  name="permitCloser"
                  value={state.permitCloser}
                  onChange={onChange}
                >
                  <option value="">MTGroup can close permits?</option>
                  <option value={TRUE}>Yes</option>
                  <option value={FALSE}>No</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className="account-details-btn">
          <CustomButton type="submit" disabled={disable} loading={loading} className="btn btn-primary">
            {btnText}
          </CustomButton>
        </div>
      </form>
    </div>
  );
};
