import React, { useEffect, useState } from "react";
import { CustomButton } from "../Shared";
import { useSelector } from "react-redux";
import { Space } from "antd";

export const DisciplineForm = ({
  title,
  btnText,
  loading,
  state = {},
  error = {},
  handleChange = () => {},
  onSubmit = () => {},
  userId = null,
}) => {
  const { user } = useSelector((state) => state.auth);

  const onChange = (e) => handleChange({ [e.target.name]: e.target.value });

  let disable = !state.code ;

  return (
    <div>
      <div className="heading"></div>
      <h1 className="title">{title}</h1>
      <form onSubmit={onSubmit}>
        <div className="card">
          <div className="create-permit-details">
            <div className="form-row">
              <div className="form-group col-md-4">
                <input
                  disabled={title === "Edit Discipline"}
                  type="code"
                  className="form-control"
                  placeholder="Code"
                  name="code"
                  value={state.code}
                  onChange={onChange}
                />
                {error?.code && <small className="text-danger">{error?.code}</small>}
              </div>
              <div className="form-group col-md-4">
                <input
                  type="description"
                  className="form-control"
                  placeholder="Description"
                  name="description"
                  value={state.description || ""}
                  onChange={onChange}
                />
                {error?.description && <small className="text-danger">{error?.description}</small>}
              </div>

            </div>

          </div>
        </div>
        <div className="account-details-btn">
          <Space>
          <CustomButton type="submit" disabled={disable} loading={loading} className="btn btn-primary">
            {btnText}
          </CustomButton>
          </Space>
        </div>
      </form>
    </div>
  );
};
