import { useMutation } from "@apollo/react-hooks";
import { Result, Spin } from "antd";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AuthLayout } from "../containers";
import { logoutUser } from "../store/modules";
import { USER_VERIFY_EMAIL } from "../graphql/modules";
import { Redirect } from "react-router-dom";

export const EmailVerification = ({ location, history }) => {
  const dispatch = useDispatch();

  const query = new URLSearchParams(location.search);
  const params = Object.fromEntries(query);

  const [VerifyEmailMutation, { loading, data }] = useMutation(USER_VERIFY_EMAIL);
  useEffect(() => {
    dispatch(logoutUser());
    VerifyEmailMutation({
      variables: {
        securityCode: params.security_code,
      },
    });
  }, []);

  let status = "403";
  let msg = "Verifying email...";

  if (data?.VerifyEmail?.success) {
    status = "success";
    msg = data?.VerifyEmail?.message;
  } else if (data?.VerifyEmail?.success === false) {
    status = "error";
    msg = data?.VerifyEmail?.message;
  }

  if (data?.VerifyEmail?.success && params.requirePassword === "true") {
    setTimeout(() => {
      msg = "Redirecting...";
    }, 1000);
    setTimeout(() => {
      return history.push("/reset-password" + location.search);
    }, 3000);
  }
  return (
    <AuthLayout>
      <Spin spinning={loading}>
        <div className="card" style={{ width: "50%", margin: "auto" }}>
          <Result
            status={status}
            title={msg}
            subTitle={status === "success" ? "Now you can login using your credentials" : "Something went wrong"}
          />
        </div>
      </Spin>
    </AuthLayout>
  );
};
