import { useMutation, useQuery } from "@apollo/react-hooks";
import { message, Select, Upload, Button, Spin } from "antd";
import React, { useState } from "react";
import { CustomButton } from "../components/Shared";
import { CREATE_SITE, GET_SITE_LIST, FILE_UPLOAD, DELETE_FILE, CREATE_MAP } from "../graphql/modules";
import { UploadOutlined } from "@ant-design/icons";

export const CreateMap = ({ history }) => {
  const [FileUpload, { loading: uploadLoading }] = useMutation(FILE_UPLOAD);
  const [DeleteFile, { loading: deleteLoading }] = useMutation(DELETE_FILE);

  const [item, setItem] = useState("");
  const [isSpinHidden, setIsSpinHidden] = useState(true);
  const { data: SiteList } = useQuery(GET_SITE_LIST, {
    variables: {
      limit: 1000,
      offset: 0,
    },
  });
  const [state, setState] = useState({
    name: "",
    site: "",
    mapImage: "",
  });

  const onChange = (e) => setState({ ...state, [e.target.name]: e.target.value });
  const [fileList, setFileList] = useState([]);
  const [CreateMapMutation, { loading }] = useMutation(CREATE_MAP);
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const { data } = await CreateMapMutation({
        variables: {
          data: state,
        },
      });
      if (data?.CreateMap?.success) {
        message.success(data?.CreateMap?.message);
        history.push("/maps");
      } else {
        message.warn(data?.CreateMap?.message);
      }
    } catch (error) {}
  };

  const disable = !state.name;

  const props = {
    name: "file",
    multiple: false,
    customRequest: async ({ file, onSuccess }) => {
      try {
        const {
          data: { SingleUpload },
        } = await FileUpload({
          variables: {
            file,
          },
        });
        file.url = SingleUpload?.url;
        file.filename = SingleUpload?.filename;

        // onChange({ [item.name]: [...[item.name], SingleUpload?.filename] });
        onSuccess("done");
      } catch (error) {
        setFileList([]);

        console.log(error);
        // console.log(error);
        message.error(`File upload failed. Please make sure the file you are uploading is less than 10MB.`);
        setIsSpinHidden(true);
      }
    },

    onRemove: async (file) => {
      try {
        await DeleteFile({
          variables: {
            filename: file.filename,
          },
        });

        const files = fileList.filter((f) => f.uid !== file.uid);
        setFileList(files);
      } catch (error) {
        console.log("onRemove");
        console.log(error);
      }
    },
    beforeUpload: (file) => {
      setFileList([file]);
    },
    fileList,
    // multiple: false,
    listType: "picture",
    onChange(info, list) {
      if (info.file.status === "uploading") {
        setIsSpinHidden(false);
      }

      if (info.file.status === "done") {
        message.success(`File uploaded successfully`);
        setState({ ...state, mapImage: info.file.filename });
        setIsSpinHidden(true);
      } else if (info.file.status === "error") {
        setIsSpinHidden(true);
        onChange({ [item.name]: [] });
        setFileList([]);
        message.error(`File upload failed. Please make sure the file you are uploading is less than 10MB.`);
      }
    },

    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
  };
  return (
    <div>
      <div className="heading"></div>
      <h1 className="title">Create New Map</h1>
      <form onSubmit={onSubmit}>
        <div className="card">
          <div className="create-permit-details">
            <div className="form-row">
              <div className="form-group col-md-6">
                <select name="col" className="form-control" onChange={onChange}>
                  <option key="" value=""></option>
                  {SiteList?.GetSites?.results?.map((_site) => (
                    <option key={_site._id} value={_site._id}>
                      {_site.code}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group col-md-6">
                <input type="name" className="form-control" placeholder="Name" name="name" onChange={onChange} />
              </div>
              <div className="form-group col-md-12">
                <Upload {...props} maxCount={1}>
                  <Button icon={<UploadOutlined />} style={{ width: "100%" }}>
                    Upload
                  </Button>
                </Upload>
                <div hidden={isSpinHidden} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Spin tip="Uploading..."></Spin>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="account-details-btn ">
          <CustomButton type="submit" loading={loading} className="btn btn-primary ml-auto">
            Create Map
          </CustomButton>
        </div>
      </form>
    </div>
  );
};
