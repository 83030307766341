import React from "react";
import { PermitFormItem } from "./PermitFormItem";

export const PermitSection = ({ templateKey, section, state, onChange, isParent, handleFormState, siteID }) => {
  return (
    <div className="card full-details">
      <h2>{section.title}</h2>
      <div className="form-row">
        {section?.form?.map((item, key) => (
          <PermitFormItem
            templateKey={templateKey}
            section={section}
            item={item}
            onChange={onChange}
            state={state}
            isParent={isParent}
            handleFormState={handleFormState}
            siteID={siteID}
          />
        ))}
      </div>
    </div>
  );
};
