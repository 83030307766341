import gql from "graphql-tag";

export const CREATE_PERMIT = gql`
  mutation ($data: PermitInput!) {
    CreatePermit(data: $data) {
      code
      success
      message
    }
  }
`;

export const UPDATE_PERMIT_STATUS = gql`
  mutation ($id: ID!, $status: PermitStatus, $reason: String) {
    UpdatePermitStatus(id: $id, status: $status, reason: $reason) {
      code
      success
      message
    }
  }
`;

export const UPDATE_PERMIT_STATUS_APPROVE_LAST_REVIEWER_REMOVED = gql`
  mutation ($id: ID!, $status: PermitStatus) {
    ApprovePermitWhenLastReviewerIsRemoved(id: $id, status: $status) {
      code
      success
      message
    }
  }
`;

export const UPDATE_PERMIT_REVIEWERS = gql`
  mutation ($id: ID!, $newIndividualReviewers: [String], $newMTGroups: [String]) {
    UpdatePermitReviewers(id: $id, newIndividualReviewers: $newIndividualReviewers, newMTGroups: $newMTGroups) {
      code
      success
      message
    }
  }
`;

export const UPDATE_PERMIT = gql`
  mutation (
    $id: ID!
    $submission: String
    $status: PermitStatus
    $childrenState: String # $discipline: ID! # $hostSystemOwner: ID!
  ) {
    UpdatePermit(
      id: $id
      submission: $submission
      status: $status
      childrenState: $childrenState # discipline: $discipline # hostSystemOwner: $hostSystemOwner
    ) {
      code
      success
      message
    }
  }
`;

export const GET_PERMITS = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
    $disciplineName: [String]
  ) {
    GetPermits(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
      disciplineName: $disciplineName
    ) {
      code
      success
      message
      results
      count
      isFinalApprover
      canCreatePermit
    }
  }
`;

export const GET_PENDING_PERMITS = gql`
  query (
    $limit: Int
    $offset: Int
    $searchText: String
    $searchCode: String
    $searchCreated: String
    $searchStartDate: String
    $searchEndDate: String
    $statusCheck: [String]
    $siteName: [String]
  ) {
    GetPendingPermits(
      limit: $limit
      offset: $offset
      searchText: $searchText
      searchCreated: $searchCreated
      searchStartDate: $searchStartDate
      searchEndDate: $searchEndDate
      statusCheck: $statusCheck
      searchCode: $searchCode
      siteName: $siteName
    ) {
      code
      success
      message
      results
      count
    }
  }
`;

export const GET_MY_PERMITS = gql`
  query ($limit: Int, $offset: Int) {
    GetMyPermits(limit: $limit, offset: $offset) {
      code
      success
      message
      results
      count
      canCreatePermit
    }
  }
`;

export const GET_PERMITS_I_APPROVED = gql`
  query ($limit: Int, $offset: Int) {
    GetPermitsIApproved(limit: $limit, offset: $offset) {
      code
      success
      message
      results
      count
    }
  }
`;

export const GET_PERMITS_I_APPROVED_COUNT = gql`
  query ($limit: Int, $offset: Int) {
    GetPermitsIApprovedCount(limit: $limit, offset: $offset) {
      code
      success
      message
      count
    }
  }
`;

export const GET_PERMIT = gql`
  query ($id: ID!) {
    GetPermit(id: $id) {
      code
      success
      message
      permit
      isFinalApprover
    }
  }
`;

export const GET_PERMIT_TO_GENERATE = gql`
  query ($id: ID) {
    GetPermitToGenerate(id: $id) {
      code
      success
      message
      permit
    }
  }
`;

export const UPDATE_ON_APPROVED_PERMIT = gql`
  mutation ($id: ID!, $submission: String, $status: PermitStatus, $childrenState: String) {
    UpdatesOnApprovedPermit(id: $id, submission: $submission, status: $status, childrenState: $childrenState) {
      code
      success
      message
    }
  }
`;

export const REMOVE_INDIVIDUAL_REVIEWER = gql`
  mutation ($id: ID!, $userId: ID!) {
    UpdateRemoveIndividualReviewer(id: $id, userId: $userId) {
      code
      success
      message
    }
  }
`;

export const REMOVE_MTGROUP_REVIEWER = gql`
  mutation ($id: ID!, $mtGroupId: ID!) {
    UpdateRemoveMTGroupReviewer(id: $id, mtGroupId: $mtGroupId) {
      code
      success
      message
    }
  }
`;

export const SAVE_CHILD_PERMIT = gql`
  mutation ($id: ID!, $childrenState: String) {
    SaveChildPermit(id: $id, childrenState: $childrenState) {
      code
      success
      message
    }
  }
`;

export const GET_MY_PENDING_APPROVAL_PERMITS = gql`
  query ($limit: Int, $offset: Int, $searchText: String) {
    GetMyPendingApprovalPermits(limit: $limit, offset: $offset, searchText: $searchText) {
      code
      success
      message
      results
      count
    }
  }
`;

export const GET_PENDING_FINAL_APPROVAL_LSS_PERMITS = gql`
  query ($limit: Int, $offset: Int, $searchText: String) {
    GetFinalPendingApprovalLSSPermits(limit: $limit, offset: $offset, searchText: $searchText) {
      code
      success
      message
      results
      count
    }
  }
`;

export const DUPLICATE_PERMIT = gql`
  mutation ($id: ID!) {
    DuplicatePermit(id: $id) {
      code
      success
      message
    }
  }
`;

export const GET_UNLCOSED_PERMIT = gql`
  query {
    CountUnClosedPermit {
      code
      success
      message
      isEnabled
      timerCount
    }
  }
`;

export const GET_MY_PERMIT_CODE = gql`
  query ($limit: Int, $offset: Int) {
    GetPermitCode(limit: $limit, offset: $offset) {
      code
      success
      message
      results
      count
    }
  }
`;

export const GET_SIPP_MONTHLY = gql`
  query ($limit: Int, $currentMonth: String) {
    GetSippMonthlyReports(limit: $limit, currentMonth: $currentMonth) {
      message
      success
      code
      data
    }
  }
`;
