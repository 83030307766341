import { Modal } from "antd";
import React from "react";

export const SectionModal = ({ visible, onCancel, onOk, title, setTitle }) => (
  <Modal
    visible={visible}
    title="Section"
    onCancel={onCancel}
    onOk={onOk}
    okButtonProps={{
      disabled: !title,
    }}
  >
    <div className="form-row">
      <div className="col-md-12">
        <input
          type="name"
          className="form-control"
          placeholder="Enter section title"
          name="title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
    </div>
  </Modal>
);
