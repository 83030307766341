import { useMutation, useQuery } from "@apollo/react-hooks";
import { Spin } from "antd";
import React, { Fragment } from "react";
import { PermitSingle } from "../components/Permit";
import { GET_PERMIT, UPDATE_PERMIT_STATUS } from "../graphql/modules";
import { successNotify, warnNotify } from "../util";
import { useHistory } from "react-router-dom";
import { CustomButton } from "../components/Shared";

import { useSelector } from "react-redux";
import { PermitStatus, UserRole } from "../config";
import { Divider } from "@material-ui/core";

export const SinglePermit = ({ match }) => {
  const { user: authUser } = useSelector((state) => state.auth);

  const { data, loading, refetch } = useQuery(GET_PERMIT, {
    variables: {
      id: match.params.id,
    },
  });

  const [UpdatePermitStatus, { loading: updateLoading }] = useMutation(UPDATE_PERMIT_STATUS);

  const onUpdate = async (values) => {
    try {
      const { data } = await UpdatePermitStatus({
        variables: {
          id: match.params.id,
          status: values.status,
        },
      });
      if (data.UpdatePermitStatus.success) {
        successNotify(data.UpdatePermitStatus.message);
        refetch();
      } else {
        warnNotify(data.UpdatePermitStatus.message);
      }
    } catch (error) {}
  };

  const onUpdateRecallPermit = async (values) => {
    try {
      const { data } = await UpdatePermitStatus({
        variables: {
          id: match.params.id,
          status: values.status,
        },
      });
      if (data.UpdatePermitStatus.success) {
        successNotify(data.UpdatePermitStatus.message);
        refetch();
      } else {
        warnNotify(data.UpdatePermitStatus.message);
      }
    } catch (error) {}
  };

  const history = useHistory();
  let hiddenReviewButton = true;
  let permit = {};
  let amIFinalApprover = data?.GetPermit?.isFinalApprover ? data?.GetPermit?.isFinalApprover : false;

  if (data?.GetPermit?.permit) {
    permit = JSON.parse(data?.GetPermit?.permit || {});
  }

  if (data?.GetPermit?.success === false) {
    return <div className="card">Not found</div>;
  }

  if (authUser.role !== UserRole.ContractorRequestor || authUser._id !== permit?.submittedBy?._id) {
    hiddenReviewButton = false;
  }

  // console.log(permit);

  if (permit !== null) {
    return (
      <Spin spinning={loading}>
        <PermitSingle permit={permit} onUpdate={onUpdate} updateLoading={updateLoading} />

        {Array.isArray(permit?.children) && (
          <Fragment>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: "2%" }}>
              <br />
              <h2>Child permit(s)</h2>
            </div>
            {permit?.children?.map((child) => (
              <div>
                <Divider />
                <div>
                  <h3>{child?.form?.title}</h3>
                </div>
                <PermitSingle permit={child} onUpdate={onUpdate} updateLoading={updateLoading} isChildren={true} />
              </div>
            ))}
          </Fragment>
        )}
        <div className="account-details-btn" hidden={hiddenReviewButton}>
          <CustomButton
            className="btn btn-primary ml-3"
            style={{ maxWidth: 300 }}
            onClick={(e) => {
              e.preventDefault();
              history.push(`review/${permit._id}`, {
                user: permit,
              });
            }}
          >
            Review Permit
          </CustomButton>

          {amIFinalApprover ? (
            <CustomButton
              className="btn btn-primary ml-3"
              hidden={permit.status !== PermitStatus.Approved}
              style={{ maxWidth: 300 }}
              onClick={(e) => {
                e.preventDefault();
                onUpdateRecallPermit({
                  status: PermitStatus.PendingApproval,
                });
                console.log("Recall Approved Status Button Pressed");
              }}
            >
              Recall Approved Status
            </CustomButton>
          ) : (
            ""
          )}
        </div>

        <div className="account-details-btn" hidden={hiddenReviewButton}></div>
      </Spin>
    );
  } else {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <p>
          {" "}
          Viewing Child Permits are not available at the moment. Kindly view the Parent Permit to see the Child Permit.
        </p>
      </div>
    );
  }
};
